import React, {useState} from 'react';
import styled from 'styled-components';
import { Link, useNavigate } from 'react-router-dom';
import Footer from '../components/Footer';
import NavBar from '../components/PublicNav/homenav';

const PricingSectionStyled = styled.div`
    background-color: white; 
    padding: 2vh 0;
    magrin-bottom: 5vh;
    margin-top: 10vh;
    border-radius: 2vh;
    text-align: center;
`;

const InfoSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url('/Background.png'); /* Adjust the path as needed */
  background-size: cover; /* Ensures the image covers the entire section */
  background-position: center; /* Centers the image */
  height: fit-conent;
  @media(orientation: portrait){
  height: fit-content;
  padding: 0vh 5vh;}
`;

const InfoHeader = styled.h2`
  color: white;
  margin-top: 10vh;
  font-size: 2vh;
  font-weight: lighter;
  text-align: center;
`;

const InfoSubHeader = styled.h1`
  color: white;
  margin-top: 1vh;
  font-size: 3vh;
  text-align: center;
`;

const InfoText = styled.p`
color: white;
  margin-top: 1vh;
  font-size: 2.5vh;
  text-align: center;
`;

const PriceText = styled.p`
color: rgba(7,55,99);
  margin-top: 10vh;
  font-size: 2.5vh;
  text-align: center;
`;

const PriceHeader = styled.h2`
  color: rgba(7,55,99);
  margin-top: 1vh;
  margin-bottom: 4vh;
  font-size: 3vh;
  font-weight: lighter;
  text-align: center;
`;

const SignUpButton = styled.button`
  background-color: rgba(0, 255, 9, 1);
  color: black;
  border: none;
  border-radius: 5vh;
  padding: 2.5vh;
  font-weight: bold;
  font-size: 2.5vh;
  cursor: pointer;
  margin-top: 1vh;
  margin-bottom: 10vh;
  transition: transform 0.3s;

  &:hover {
    background-color: rgba(84, 255, 90, 1);
    animation: none;
    transform: scale(1.03);
  }
  @media (orientation: portrait) {
    font-size: 1.8vh;
    padding: 1.3vh;
    margin-left: 0vh;
    margin-bottom: 5vh;
  }
`;

const PricingWrapper = styled.div`
    display: flex;
    background-color: white;
    justify-content: center;
    gap: 5vh;
    margin-bottom: 5%;

    @media (max-width: 1070px) and (orientation: portrait){
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
`;

const PriceCard = styled(Link)`
    background: rgba(7,55,99);
    padding: 2vh;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    width: 30vh;
    text-decoration: none; 
    color: white;

    &:hover {
        transform: translateY(-5px);  
        transition: all 0.3s ease; 
    }

    h3 {
        font-size: 3vh;
        margin-bottom: 2vh;
        font-weight: bold;
    }

    p {
        font-size: 3.5vh;
        font-weight: bold;
        margin-bottom: 0vh;
        @media (orientation: portrait){
            font-size: 1.3rem;
            margin-bottom: 10%;
        }
    }

    span {
        color: white;
        font-size: 2.5vh;
        @media (orientation: portrait){
            font-size: 100%;
        }
    }
`;

const PriceCardHighlighted = styled(PriceCard)`
    transform: scale(1.1);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    position: relative; 
    z-index: 1; 
    margin: 0 1vh; 
    padding: 2vh 3vh;
    @media (max-width: 1070px) and (orientation: portrait) {
        padding: 5% 10%;
    }
    &::before {
        content: "Most Popular"; 
        position: absolute;
        top: -3vh; 
        width: 15vh;
        left: 50%; 
        transform: translateX(-50%); 
        background-color: rgba(222, 222, 222);
        color: rgba(7,55,99);
        padding: 0.5vh 1vh; 
        border-radius: 5px;
        font-weight: bold; 
        font-size: 1.7vh; 
    }
    &:hover {
        transform: scale(1.1) translateY(-5px); 
        transition: all 0.3s ease;  
    }
    @media (max-width: 1070px) and (orientation: portrait) {
        font-size: 90%;
        padding: 6%;

        &::before {
        content: "Popular";
        position: absolute;
        top: -1vh; 
        left: 50%; 
        transform: translateX(-50%); 
        padding: 0.3vh 0.3vh;
        border-radius: 5px;
        font-weight: bold; 
        font-size: 80%;
    }
`;

const SectionStyled = styled.div`
justify-content: center;
align-items: center;
padding: 0vh 5vh;
`

const BestHeader = styled.h1`
  color: rgba(7,55,99);
  margin-top: 4vh;
  margin-bottom: 4vh;
  font-size: 4vh;
  text-align: center;
  @media (orientation: portrait) {
    font-size: 2.3vh;
  }
`;

const PlanButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 2vh;
  margin-top: 2vh;

  @media (orientation: portrait) {
    flex-direction: column;
    align-items: center;
  }
`;

const PlanButton = styled.button<{ active: boolean }>`
  background-color: ${({ active }) => (active ? 'rgba(7,55,99, 1)' : '#f8f8f8')};
  color: ${({ active }) => (active ? 'white' : 'rgba(7,55,99, 1)')};
  border: 1px solid rgba(7,55,99, 1);
  border-radius: 5vh;
  padding: 1.5vh;
  width: 20vh;
  font-size: 2.7vh;
  cursor: pointer;
  margin-bottom: 1vh;

  &:hover {
    background-color: rgba(0, 56, 255, 0.8);
    color: white;
  }

  @media (orientation: portrait) {
    width: 80%;
    font-size: 2.3vh;
    padding: 1vh;
    width: 16vh;
  }
`;

const PlanContentWrapper = styled.div`
  text-align: center;
  margin-top: 4vh;
`;

const PlanDescription = styled.p`
  color: rgba(7,55,99);
  font-size: 2.5vh;
  margin-bottom: 3vh;
  height: 15vh;
  width: 100vh;
  margin-left: auto;
  margin-right: auto;
  @media (orientation: portrait){
  max-width: 35vh;
  font-size: 2vh;
  height: fit-content;
  }
`;

const StyledIframe = styled.iframe`
  width: 40vw;   // Adjust the width as needed (e.g., 70% of the viewport width)
  height: 21vw;  // Adjust the height to maintain the aspect ratio
  max-width: 100%;  // Make sure it doesn't exceed the container's width
  border: none;
  @media (max-width: 768px) {  // For smaller screens
    width: 100%;  // Full width on smaller screens
    height: auto;
  }
    @media (orientation: portrait){
  width: 40vh;
  height: 20vh;}
`;

const CallToActionSection = styled.div`
  text-align: center; 
`;

const CallToActionText = styled.h2`
  font-size: 3vh;
  font-weight: bold;
  margin-top: 8vh;
  margin-bottom: 2vh;
  color: rgba(7,55,99);
  margin-bottom: 5vh;
  @media (orientation: portrait){
    font-size: 2vh;
    }
`;

const CallToActionButton = styled.button`
  background-color: rgba(0, 255, 9, 1);
  color: black;
  border: none;
  border-radius: 5vh;
  padding: 2.5vh;
  font-weight: bold;
  font-size: 2.5vh;
  cursor: pointer;
  margin-bottom: 3vh;
  transition: transform 0.3s;

  &:hover {
    background-color: rgba(84, 255, 90, 1);
    animation: none;
    transform: scale(1.03);
  }
  @media (orientation: portrait) {
    font-size: 1.8vh;
    padding: 1.3vh;
  }
`;

const FAQSection = styled.div`
        max-width: 120vh; // Or whatever maximum width you desire
        margin: 0 auto; // This centers the div on the page
        padding: 4vh; // Add some padding around the content
        margin-top: 0vh;
        margin-bottom: 10vh;
        display: flex;
        flex-direction: column;
        align-items: center; // This centers the items horizontally in a flex container
        gap: 2vh; // Adds space between each FAQ item
            @media (orientation: portrait){
                margin-top: 2%;
                padding: 2%;
                gap: 2%;
                font-size: 100%;
                margin: 0 5vh;
            }
        
    `;
    
    const FAQItem = styled.div`
        width: 100%; // Make each FAQ item take full width of the FAQSection
        border-bottom: 1px solid #ccc; // Optional: add a separator line between items
        padding: 1vh 0; // Add some padding above and below each item
    `;
    
    const FAQQuestion = styled.button`
        width: 100%; // Make the question button take the full width of its container
        background: none; // Remove default button styling
        border: none;
        color: rgba(7,55,99);
        border-radius: 1vh;
        text-align: center;
        padding: 1vh; // Add padding inside the button for spacing
        cursor: pointer; // Change the mouse cursor on hover
        &:hover {
            background-color: #f5f5f5; // Optional: add a background color on hover
            color: black;
        }
        font-size: 2.7vh; // Increase the font size a bit

        @media (max-width: 1000px){
            font-size: 4vh;
        }
        @media (orientation: portrait){
            font-size: 100%;
        }
    `;
    
    const FAQAnswer = styled.div`
        text-align: center; // Align the answer text to the left
        padding:  1.5vh; // Add padding on the sides
        color: rgba(7,55,99); // Optional: change the color of the answer text
        font-size: 2.5vh; // Set the font size of the answer

        @media (orientation: portrait){
            font-size: 100%;
            padding: 2%;
        }
    `;
    

        const FAQ: React.FC = () => {
            const [activeFAQIndex, setActiveFAQIndex] = useState<number | null>(null);
        
            const FAQs = [
                {
                    question: 'How do I use the Project Price app?',
                    answer: (
                        <span>
                        You can use the Project Price app by signing up <a href="/loginpage" style={{ textDecoration: 'underline' }}>here</a>. Check out our <a href='#plan'> Demo videos</a> above to find what is best for you.
                        </span>
                    )
                },
                {
                    question: 'How much does this cost?',
                    answer: (
                        <span>
                            We have different tiers allowing different access to project price. The premium plan at £49 per month allows full use of Project Price, including the design feature. the regular plan, gives full use excluding the design component.
                        </span>
                    )
                },
                {
                    question: 'Does this work outside of the UK?',
                    answer: 'Project Price is currently only operating in the United Kingdom. We are actively working to make Project Price international.'
                },
                {
                    question: 'What trades does this serve?',
                    answer: 'Project price is currently tailored to hard-landscaping professionals. We aim to streamline your business, making you more accurate, more reliable and save you time.'
                },
                {
                    question: 'Am I locked into a contract?',
                    answer: 'Nope. Depending on which subscription you choose, The free, regular or premium membership, these are charged on a monthly basis. Cancelling your subscription allows you to carry on using Project Price until the end of your current subscription.'
                },
                {
                    question: 'Can I cancel at any time?',
                    answer: 'You can cancel at any time. You can keep using Project Price until you cancellation date.'
                },
            ];
        
            const toggleFAQ = (index: number) => {
                setActiveFAQIndex(activeFAQIndex === index ? null : index);
            };
        
            return (
                <FAQSection>
                    {FAQs.map((faq, index) => (
                        <FAQItem key={index}>
                            <FAQQuestion onClick={() => toggleFAQ(index)}>
                                {faq.question}
                            </FAQQuestion>
                            {activeFAQIndex === index && <FAQAnswer>{faq.answer}</FAQAnswer>}
                        </FAQItem>
                    ))}
                </FAQSection>
            );
        }



const Pricing: React.FC = () => {
    const [selectedPlan, setSelectedPlan] = useState('premium');

    const navigate = useNavigate();

  const handleCreateAccount = () => {
    navigate('/loginpage?signup=true');
  };

  const getPlanContent = () => {
    switch (selectedPlan) {
      case 'free':
        return {
          description: 'The Free plan gives you access to the Area Calculator feature, perfect for basic measurements. To use the Project Price dashboard please select either our Regular or Premium plans.',
          video: "https://www.youtube.com/embed/G8Cny6pXBbk"
        };
      case 'regular':
        return {
          description: 'The Regular plan focuses on providing efficient pricing and quoting tools, streamlining your quoting process for speed and accuracy. While it doesn’t include the design component, this plan is perfect for professionals who want to enhance their quoting efficiency and deliver precise estimates to clients without the need for design features.',
          video: "https://www.youtube.com/embed/Y1xu3gnfoTI"
        };
      case 'premium':
      default:
        return {
          description: 'The Premium plan offers full access, including design tools, pricing, quoting, and more. Our most popular plan, ideal for professionals who wish to maximize their professionalism and efficiency by offering customers visually appealing 2D designs and pricing projects in minutes.',
          video: "https://www.youtube.com/embed/i1IEELWX3yo"
        };
    }
  };
  

    return (
        <>
            <NavBar />
            <InfoSection>
                <InfoHeader>PRODUCT PRICING</InfoHeader>
                    <InfoSubHeader>Begin your free trial or select a plan below</InfoSubHeader>
                    <InfoText>View our plans below to find out how we suit you best! Need an account? Sign up here for your 7 day trial.</InfoText>
                    <SignUpButton onClick={handleCreateAccount}>Sign up now! Free For 7 Days ➜</SignUpButton>
            </InfoSection>
            <PriceText>Find out more about our plans. <a href='/features'>Features ➜.</a></PriceText>
            <PriceHeader>All of our plans are great time savers! No hidden costs and cancel at any time.</PriceHeader>
            <PricingSectionStyled >
                <PricingWrapper>
                    <PriceCard to="/loginpage">
                        <h3>Free</h3>
                        <p>Free</p>
                        <p style={{ fontSize: '2vh', fontWeight: 'lighter', marginBottom: '3vh' }}>
                            Forever
                            </p>
                        <span>Area Calculator Access</span>
                    </PriceCard>

                    <PriceCardHighlighted to="/loginpage">
                        <h3>Premium</h3>
                        <p>£49</p>
                        <p style={{ fontSize: '2vh', fontWeight: 'lighter', marginBottom: '3vh' }}>
                            monthly
                            </p>
                        <span>Full Access</span>

                    </PriceCardHighlighted>

                    <PriceCard to="/loginpage">
                        <h3>Regular</h3>
                        <p>£29</p>
                        <p style={{ fontSize: '2vh', fontWeight: 'lighter', marginBottom: '3vh' }}>
                            monthly
                            </p>
                        <span>Pricing & Quoting Access</span>
                    </PriceCard>
                </PricingWrapper>
            </PricingSectionStyled>
            <SectionStyled>
            <BestHeader id='plan'>Which plan suits me best?</BestHeader>

                    <PlanButtonsWrapper>
                    <PlanButton active={selectedPlan === 'free'} onClick={() => setSelectedPlan('free')}>
                        Free
                    </PlanButton>
                    <PlanButton active={selectedPlan === 'premium'} onClick={() => setSelectedPlan('premium')}>
                        Premium
                    </PlanButton>
                    <PlanButton active={selectedPlan === 'regular'} onClick={() => setSelectedPlan('regular')}>
                        Regular
                    </PlanButton>
                    </PlanButtonsWrapper>

                    <PlanContentWrapper>
                    <PlanDescription>{getPlanContent().description}</PlanDescription>
                    <StyledIframe
                        src={getPlanContent().video}
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    />
                    </PlanContentWrapper>
                    <CallToActionSection>
                        <CallToActionText>Are you ready to save hours on quoting?</CallToActionText>
                        <CallToActionButton onClick={handleCreateAccount}>Sign up now! Free For 7 Days ➜</CallToActionButton>
                    </CallToActionSection>
                    <FAQ />
                    </SectionStyled>
            <Footer />
        </>
    );
};

export default Pricing;
