import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { FiUser } from 'react-icons/fi';
import { FaBars } from 'react-icons/fa';
import { useNavigate, useLocation } from 'react-router-dom';
import HomepageProfile from '../Profile/HomepageNav';

interface NavLinkProps {
  isActive: boolean;
}

const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5vh 1vh;
  border-bottom: 1px solid #ccc;
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1000;

  @media (max-width: 1070px) {
    .nav-items {
      display: none;
    }
    .menu-icon {
      display: block;
    }
  }

  @media (min-width: 1900px) {
    font-size: 2.4vh;
  }
`;

const MenuIcon = styled.div`
  display: none;
  cursor: pointer;

  @media (max-width: 1070px) {
    display: block;
  }
`;

const NavList = styled.ul<{ isOpen: boolean }>`
  display: flex;
  list-style-type: none;
  margin-left: 15vh;
  margin-top: 1.5vh;
  position: relative;

  @media (max-width: 1070px) {
    display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
    flex-direction: column;
    width: 100%;
    background-color: white;
    position: absolute;
    top: 100%;
    right: 0;
    padding: 10px 0;
    border-top: 1px solid #ccc;
  }
`;

const NavItem = styled.li`
  margin: 0 15px;
  position: relative;

  @media (max-width: 1070px) {
    margin: 2vh;
  }
`;

const NavLink = styled.a<NavLinkProps>`
  font-family: 'Ubuntu';
  font-weight: bold;
  text-decoration: none;
  color: ${({ isActive }) => (isActive ? 'rgba(7,55,99)' : 'rgba(7,55,99)')};
  border-bottom: ${({ isActive }) => (isActive ? '2px solid rgba(7,55,99)' : 'none')};
  padding-bottom: ${({ isActive }) => (isActive ? '2px' : 'none')};
  cursor: pointer;

  &:hover {
    text-decoration: none;
    border-bottom: ${({ isActive }) => (isActive ? '2px solid rgba(7,55,99)' : '2px solid rgba(7,55,99)')};
  }

  &:active {
    background-color: rgba(7,55,99, 0.1);
    border-bottom: ${({ isActive }) => (isActive ? '2px solid rgba(7,55,99)' : 'none')};
  }
`;

const LogoContainer = styled.div`
  cursor: pointer;

  @media (max-width: 1070px) {
    margin-right: 2vh;
  }

  @media (min-width: 1900px) {
    font-size: 2vh;
  }
`;

const DropdownMenu = styled.div`
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: white;
  border: 1px solid #ccc;
  z-index: 1001;
  padding: 10px;
  border-radius: 5px;

  ${NavItem}:hover & {
    display: block;
  }
`;

const DropdownLink = styled.a`
  display: block;
  padding: 0.5vh 1vh;
  text-decoration: none;
  color: rgba(7,55,99);

  &:hover {
    background-color: rgba(7,55,99, 0.1);
  }
`;

const LoginButton = styled.button`
  background-color: rgba(7, 55, 99);
  color: white;
  border: none;
  border-radius: 1vh;
  padding: 1vh;
  margin: 1vh;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #0056b3;
  }
`;

const Logo = styled.img`
  width: 25vh;

  @media (max-width: 1070px) {
    width: 15vh;
  }
`;

const ProfileButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  margin-right: 1vh;
  margin-left: 1vh;
`;

const NavBar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);
  const [profileButtonPosition, setProfileButtonPosition] = useState<{ top: number; left: number } | undefined>(undefined);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1070);

  const handleLogoClick = () => {
    navigate('/');
    window.scrollTo(0, 0);
  };

  const handleLogin = () => {
    navigate("/loginpage");
  };

  const toggleDropdown = () => {
    setIsDropdownOpen((prevState) => !prevState);
  };

  const toggleProfileModal = (event: React.MouseEvent) => {
    event.stopPropagation();
    const buttonRect = (event.currentTarget as HTMLElement).getBoundingClientRect();
    setProfileButtonPosition({
      top: buttonRect.bottom,
      left: buttonRect.right - 250
    });
    setIsProfileModalOpen(!isProfileModalOpen);
  };

  const handleCloseProfileModal = () => {
    setIsProfileModalOpen(false);
  };

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 1070);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (isProfileModalOpen) {
        setIsProfileModalOpen(false);
      }
    };

    window.addEventListener('click', handleClickOutside);

    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, [isProfileModalOpen]);

  const isResourcesActive = location.pathname === '/allblogposts' || location.pathname === '/ebook';

  return (
    <Nav>
      <MenuIcon className="menu-icon" onClick={toggleDropdown}>
        <div>
          <FaBars size={18} />
        </div>
      </MenuIcon>
      <LogoContainer onClick={handleLogoClick}>
        <Logo src="/project-price-logo-name.png" alt="Project Price Logo" />
      </LogoContainer>
      <NavList isOpen={isDropdownOpen}>
        <NavItem>
          <NavLink href="/" isActive={location.pathname === '/'}>Home</NavLink>
        </NavItem>
        <NavItem>
          <NavLink href="/features" isActive={location.pathname === '/features'}>Features</NavLink>
        </NavItem>
        <NavItem>
          <NavLink href="/pricing" isActive={location.pathname === '/pricing'}>Pricing</NavLink>
        </NavItem>
        <NavItem>
          <NavLink isActive={isResourcesActive}>
            Resources
          </NavLink>
          <DropdownMenu>
            <DropdownLink href="/allblogposts">Guides</DropdownLink>
            <DropdownLink href="/ebook">eBook</DropdownLink>
          </DropdownMenu>
        </NavItem>
        <NavItem>
          <NavLink href="/areacalculator" isActive={location.pathname === '/areacalculator'}>Area Calculator</NavLink>
        </NavItem>
        <NavItem>
          <NavLink href="/contactus" isActive={location.pathname === '/contactus'}>{isMobile ? 'Contact' : 'Contact us'}</NavLink>
        </NavItem>
      </NavList>

      <div style={{ display: 'flex', alignItems: 'center' }}>
        <ProfileButton onClick={toggleProfileModal}>
          <FiUser size={24} />
        </ProfileButton>
        <LoginButton onClick={handleLogin}>Dashboard</LoginButton>
      </div>

      {isProfileModalOpen && (
        <HomepageProfile onClose={handleCloseProfileModal} position={profileButtonPosition} />
      )}
    </Nav>
  );
};

export default NavBar;
