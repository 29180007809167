import React from 'react';
import styled from 'styled-components';
import Footer from '../components/Footer';
import NavBar from '../components/PublicNav/homenav';
import { useNavigate } from 'react-router-dom';
import { FaFilePdf, FaFileExport } from 'react-icons/fa';
import { FiCopy } from 'react-icons/fi';
import Testimonials from '../Showcase/testimonials';


const FeaturesContainer = styled.div`
    background-image: url('/Background.png'); /* Adjust the path as needed */
  background-size: cover; /* Ensures the image covers the entire section */
  background-position: center; /* Centers the image */
`;

const FeaturesContainerBlue = styled.div`
  background-color: #fff;
  margin-top: 0vh;
`;

const MainContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0vh 20vh;
  
  background-image: url('/Background.png'); /* Adjust the path as needed */
  background-size: cover; /* Ensures the image covers the entire section */
  background-position: center; /* Centers the image */

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
    @media (orientation: portrait){
    margin-top: 2vh;
    padding: 0vh 2vh;
    margin-bottom: 3vh;
    }
`;

const TextContent = styled.div`
  flex: 1;
  padding-right: 10vh;
  margin-top: 15vh;

  @media (max-width: 768px) {
    padding-right: 0;
    text-align: center;
    margin-bottom: 20px;
  }

  @media (orientation: portrait){
    margin-top: 2vh;
    padding: 0vh 2vh;
    margin-bottom: 3vh;
    }
`;

const VideoContent = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30vh;

  @media (max-width: 768px) {
    width: 100%;
  }
    
`;

const VideoBox = styled.iframe`
  width: 100vh;
  height: 38vh;
  border: none;
  border-radius: 8px;
  margin-top: 10vh;

  @media (max-width: 768px) {
    max-width: 100%;
    height: auto;
  }
    @media (orientation: portrait){
    height: 20vh;
    margin-top: 0vh;
    }
    @media (orientation: portrait) and (min-width: 1000px) {
  height: 20vh;
  margin-top: 10vh;
}

`;

const SectionTitle = styled.h3`
  font-size: 3vh;
  font-weight: lighter;
  color: rgba(230, 230, 230, 0.8);
  margin-bottom: 3vh;
  @media (orientation: portrait) {
  font-size: 2vh;
}
  @media (orientation: portrait) and (min-width: 1000px) {
  font-size: 2vh;
}
`;

const SectionSubTitle = styled.h1`
  font-size: 5vh;
  color: #fff;
  margin-bottom: 1vh;
  @media (orientation: portrait) {
  font-size: 2.3vh;
}
  @media (orientation: portrait) and (min-width: 1000px) {
  font-size: 4vh;
}
`;

const SectionDescription = styled.p`
  font-size: 3vh;
  color: rgba(230, 230, 230, 0.8);
  @media (orientation: portrait) {
  font-size: 2vh;
}
  @media (orientation: portrait) and (min-width: 1000px) {
  font-size: 2vh;
}
`;

const FeatureSection = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 40px 20px;
  background-color: #fff;
  margin-bottom: 5vh;
 margin-top: 0vh;

  @media (orientation: portrait){
    margin-top: 5vh;
    padding: 0vh 2vh;
    margin-bottom: 3vh;
    flex-direction: column;
    }
`;

const TextContentLeft = styled.div`
  flex: 1;
  padding-left: 5vh;
  color: rgba(7,55,99);

  @media (max-width: 768px) {
    text-align: center;
    padding-right: 0;
    margin-bottom: 20px;
  }
   
    @media (orientation: portrait){
    padding-left: 0;
    order: 1;
    }
`;

const TextContentRight = styled.div`
  flex: 1;
  padding-right: 4vh;
  color: rgba(7,55,99);

  @media (max-width: 768px) {
    text-align: center;
    padding-left: 0;
    margin-bottom: 20px;
  }
    @media (orientation: portrait){
    padding-right: 0;
    order: 1;
    }
`;

const ImageBox = styled.img`
  border-radius: 1.5vh;
  width: 100vh;
  height: auto;
  margin-left: 15vh;
  @media (orientation: portrait){
    width: 30vh;
    margin-left: 0vh;
    margin-top: 5vh;
    }
`;

const ImageBoxRight = styled.img`
  border-radius: 1.5vh;
  width: 100vh;
  height: auto;
  margin-right: 15vh;
  @media (orientation: portrait){
    width: 30vh;
     margin-right: 0vh;
    }
`;

const FeatureHeading = styled.h2`
  font-size: 5vh;
  margin-bottom: 2vh;
  @media (orientation: portrait){
    font-size: 2vh;
    }
`;

const FeatureDescription = styled.p`
  font-size: 3vh;
  @media (orientation: portrait){
    font-size: 1.8vh;
    }
`;

const SignUpButton = styled.button`
  background-color: rgba(0, 255, 9, 1);
  color: black;
  border: none;
  border-radius: 5vh;
  padding: 2vh;
  font-weight: bold;
  font-size: 2.5vh;
  cursor: pointer;
  margin-top: 1vh;
  margin-left: 20vh;
  margin-bottom: 10vh;
  transition: transform 0.3s;

  &:hover {
    background-color: rgba(84, 255, 90, 1);
    animation: none;
    transform: scale(1.03);
  }
  @media (orientation: portrait) {
    font-size: 1.8vh;
    padding: 1.3vh;
    margin-left: 0vh;
    margin-bottom: 4vh;
  }
`;

const CallToActionSection = styled.div`
  text-align: center;
  padding: 40px 20px;

  color: white;
 
`;

const CallToActionText = styled.h2`
  font-size: 3vh;
  font-weight: bold;
  margin-bottom: 2vh;
  color: white;
  margin-bottom: 5vh;
  @media (orientation: portrait){
    font-size: 2vh;
    margin-bottm: 3vh;
    }
`;

const CallToActionButton = styled.button`
  background-color: rgba(0, 255, 9, 1);
  color: black;
  border: none;
  border-radius: 5vh;
  padding: 2vh;
  font-weight: bold;
  font-size: 2.5vh;
  cursor: pointer;
  margin-bottom: 3vh;
  transition: transform 0.3s;

  &:hover {
    background-color: rgba(84, 255, 90, 1);
    animation: none;
    transform: scale(1.03);
  }
  @media (orientation: portrait) {
    font-size: 1.8vh;
    padding: 1.3vh;
  }
`;

const IconSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5vh 0;
  @media (orientation: portrait) {
  margin: 3vh;
}
`;

const IconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 20vh;
  text-align: center;

  @media (max-width: 768px) {
    margin: 0 5vh;
  }
    @media (orientation: portrait) {
    margin: 0 2vh; /* Reduce the margin to bring icons closer together in portrait mode */
  }
`;

const IconText = styled.p`
  font-size: 3vh;
  color: white;
  margin-top: 1vh;
  @media (orientation: portrait) {
  font-size: 1.8vh;
}
`;

const PdfIcon = styled(FaFilePdf)`
  color: white;
  font-size: 9vh;
  @media (orientation: portrait) {
  font-size: 6vh;
}
`;

const CopyIcon = styled(FiCopy)`
  color: white;
  font-size: 9vh;
  @media (orientation: portrait) {
  font-size: 6vh;
}
`;

const ExportIcon = styled(FaFileExport)`
  color: white;
  font-size: 9vh;
  @media (orientation: portrait) {
  font-size: 6vh;
}
`;

const ImageContainer = styled.div`
  display: flex;
   justify-content: center;
  gap: 10vh;
  align-items: center;
  margin-top: 15vh;
  margin-bottom: 4vh;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
    @media (orientation: portrait) {
  margin-top: 5vh;
}
`;

const SketchUpImage = styled.img`
  width: 30%;
  border-radius: 8px;

  @media (max-width: 768px) {
    width: 80%;
    margin-bottom: 2vh;
  }
`;

const ExportText = styled.p`
  font-size: 2.8vh;
  color: white;
  text-align: center;
  width: 75%;
  margin-top: 7vh;
  margin-bottom: 15vh;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: 768px) {
    font-size: 1.8vh;
    padding: 0 2vh;
  }
    @media (orientation: portrait) {
    width: 40vh;
  }
`;




const Features: React.FC = () => {
  const navigate = useNavigate();

  const handleCreateAccount = () => {
    navigate('/loginpage?signup=true');
  };

  return (
    <FeaturesContainer>
      <NavBar />

      <MainContent>
        <TextContent>
          <SectionTitle>Features</SectionTitle>
          <SectionSubTitle>Design, Calculate & Send Professional Quotes in an instant.</SectionSubTitle>
          <SectionDescription>
            Provide your clients with accurate professional quotations quickly and easily with Project Price.
          </SectionDescription>
          <SignUpButton onClick={handleCreateAccount}>Sign up now! Free For 7 Days ➜</SignUpButton>
        </TextContent>

        <VideoContent>
          <VideoBox
            src="https://www.youtube.com/embed/UvuI3MZtNJg"
            title="Project Price Overview"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </VideoContent>
      </MainContent>

      <FeaturesContainerBlue>
        <FeatureSection>
          <TextContentLeft>
            <FeatureHeading>Easy to use interface.</FeatureHeading>
            <FeatureDescription>
              The user friendly apps allows you to simply plot your design. This is all we need to calculate your materials.
            </FeatureDescription>
          </TextContentLeft>
          <ImageBox src="/design.png" alt="Design" />
        </FeatureSection>

        <FeatureSection>
          <ImageBoxRight src="/dashboard.png" alt="Dashboard" />
          <TextContentRight>
            <FeatureHeading>Let us do the hard work.</FeatureHeading>
            <FeatureDescription>
              Upload your measurements from the design you created, make any tweaks and in seconds you have your Project completely priced.
            </FeatureDescription>
          </TextContentRight>
        </FeatureSection>

        <FeatureSection>
          <TextContentLeft>
            <FeatureHeading>Have your quote ready in seconds.</FeatureHeading>
            <FeatureDescription>
              View the professional quote of the project, ready to send to your customer.
            </FeatureDescription>
          </TextContentLeft>
          <ImageBox src="/quoter.png" alt="Quoter" />
        </FeatureSection>
      </FeaturesContainerBlue>

      <CallToActionSection>
        <CallToActionText>Are you ready to save hours on quoting?</CallToActionText>
        <CallToActionButton onClick={handleCreateAccount}>Sign up now! Free For 7 Days ➜</CallToActionButton>
      </CallToActionSection>

      <IconSection>
        <IconWrapper>
          <PdfIcon />
          <IconText>Download your materials and prices of each project.</IconText>
        </IconWrapper>

        <IconWrapper>
          <CopyIcon />
          <IconText>Copy and send your quotes to customers.</IconText>
        </IconWrapper>

        <IconWrapper>
          <ExportIcon />
          <IconText>Have Sketchup Pro? Export your design directly.</IconText>
        </IconWrapper>
      </IconSection>
      <ImageContainer>
    <SketchUpImage src="/sketchup4.png" alt="SketchUp 4" />
    <SketchUpImage src="/sketchup6.png" alt="SketchUp 6" />
  </ImageContainer>

  <ExportText>
    If you're a SketchUp Pro member, you can seamlessly export your designs into SketchUp, making it easier than ever to create stunning 3D visuals of your projects. This integration allows you to bring your Project Price designs to life with professional-grade rendering and modeling tools. To set this up and start transforming your designs, visit our setup guide here <a href="https://www.projectprice.co.uk/sketchuppage" target="_blank" rel="noopener noreferrer" style={{ color: '#FFFFFF', textDecoration: 'underline' }}>SketchUp Guide</a>. Get started today and elevate your landscape design process to the next level!
  </ExportText>
  <Testimonials />
      <Footer />
    </FeaturesContainer>
  );
};

export default Features;
