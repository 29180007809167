import React from 'react';
import styled from 'styled-components';
import NavBar from '../components/PublicNav/homenav';
import Footer from '../components/Footer';

const BackgroundContainer = styled.div`
  background: #f8f8f8;
`;

const BlogContainer = styled.div`
  margin: 0 auto;
  padding: 20px;
  padding-top: 10vh;
  color: rgba(7,55,99);
`;

const BlogTitle = styled.h1`
  text-align: center;
  margin-bottom: 30px;
  padding: 5vh;
`;

const Title = styled.h4`
  margin-bottom: 2%;
  margin-top: 5%;
  margin-left: 10%;
`;

const IntroParagraph = styled.p`
  font-size: 1.1rem;
  line-height: 1.6;
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
  color: #333;
  margin-bottom: 30px; // Space between the paragraph and the first image
  text-align: left;
  @media (orientation: portrait){
    max-width: 100%;
  }
`;

const BlogDesign: React.FC = () => {
  return (
    <BackgroundContainer>
      <NavBar />
      <BlogContainer>
        <BlogTitle>Innovative Design Ideas for Small Gardens</BlogTitle>
        <IntroParagraph>
          Small gardens can be just as charming and functional as larger spaces with the right design strategies. Whether you have a tiny backyard, a petite patio, or a compact urban balcony, there are numerous ways to maximize the space and create a beautiful, inviting outdoor area. This guide will explore creative design ideas to transform your small garden into a stunning retreat.
        </IntroParagraph>
        <Title>Vertical Gardening</Title>
        <IntroParagraph>
          When floor space is limited, think vertically. Vertical gardening is an excellent way to make use of available space by growing plants upwards rather than outwards.
        </IntroParagraph>
        <IntroParagraph>
          <strong>Living Walls</strong>: Install a living wall or green wall using modular panels or DIY with wall-mounted planters. This can create a lush, green backdrop and add visual interest.
        </IntroParagraph>
        <IntroParagraph>
          <strong>Trellises and Arbors</strong>: Use trellises, arbors, and obelisks to support climbing plants like ivy, roses, or clematis. These structures add height and greenery without occupying much ground space.
        </IntroParagraph>
        <Title>Multi-Functional Furniture</Title>
        <IntroParagraph>
          In small gardens, furniture that serves multiple purposes can save space and add convenience.
        </IntroParagraph>
        <IntroParagraph>
          <strong>Storage Benches</strong>: Opt for benches with built-in storage to keep garden tools, cushions, and other essentials out of sight but easily accessible.
        </IntroParagraph>
        <IntroParagraph>
          <strong>Folding Furniture</strong>: Use folding tables and chairs that can be easily stored away when not in use, freeing up valuable space.
        </IntroParagraph>
        <IntroParagraph>
          <strong>Built-In Seating</strong>: Incorporate built-in seating along the perimeter of your garden or around a fire pit to maximize seating without crowding the area.
        </IntroParagraph>
        <Title>Layered Planting</Title>
        <IntroParagraph>
          Layered planting involves arranging plants at different heights to create a sense of depth and lushness in a small garden.
        </IntroParagraph>
        <IntroParagraph>
          <strong>Use Tiers</strong>: Plant taller shrubs or small trees at the back, medium-height plants in the middle, and low-growing ground covers at the front. This tiered approach makes the garden look fuller and more dynamic.
        </IntroParagraph>
        <IntroParagraph>
          <strong>Hanging Planters</strong>: Add hanging planters or baskets to introduce more plants without taking up ground space. This can also draw the eye upwards, enhancing the sense of space.
        </IntroParagraph>
        <Title>Mirrors and Reflective Surfaces</Title>
        <IntroParagraph>
          Mirrors and reflective surfaces can create the illusion of more space in a small garden.
        </IntroParagraph>
        <IntroParagraph>
          <strong>Garden Mirrors</strong>: Position mirrors on garden walls or fences to reflect light and greenery, making the space feel larger and more open.
        </IntroParagraph>
        <IntroParagraph>
          <strong>Reflective Accessories</strong>: Use reflective garden ornaments, metallic planters, or water features with a reflective surface to add brightness and a sense of expansiveness.
        </IntroParagraph>
        <Title>Maximizing Light</Title>
        <IntroParagraph>
          Good lighting is crucial for small gardens, especially if they are shaded or receive limited sunlight.
        </IntroParagraph>
        <IntroParagraph>
          <strong>Fairy Lights</strong>: String fairy lights along fences, trellises, or pergolas to create a cozy and inviting atmosphere in the evening.
        </IntroParagraph>
        <IntroParagraph>
          <strong>Spotlights</strong>: Use spotlights to highlight key features such as sculptures, water features, or particularly beautiful plants, adding depth and drama.
        </IntroParagraph>
        <IntroParagraph>
          <strong>Solar Lights</strong>: Solar-powered lights are eco-friendly and can be placed anywhere to enhance visibility and ambiance without the need for electrical wiring.
        </IntroParagraph>
        <Title>Color and Texture</Title>
        <IntroParagraph>
          Thoughtful use of color and texture can make a small garden feel more vibrant and interesting.
        </IntroParagraph>
        <IntroParagraph>
          <strong>Bold Colors</strong>: Use bold, bright colors in your planting scheme or garden accessories to create focal points and draw attention away from the garden's size.
        </IntroParagraph>
        <IntroParagraph>
          <strong>Textured Plants</strong>: Incorporate plants with different textures, such as feathery grasses, broad-leafed perennials, and spiky succulents, to add visual interest and complexity.
        </IntroParagraph>
        <IntroParagraph>
          <strong>Thematic Consistency</strong>: Choose a consistent color palette and theme to unify the space, making it appear more cohesive and less cluttered.
        </IntroParagraph>
        <Title>Water Features</Title>
        <IntroParagraph>
          A water feature can add a sense of tranquility and movement to a small garden without taking up much space.
        </IntroParagraph>
        <IntroParagraph>
          <strong>Fountains</strong>: A small fountain can serve as a focal point, providing soothing sounds and a visual centerpiece.
        </IntroParagraph>
        <IntroParagraph>
          <strong>Pond-in-a-Pot</strong>: Consider a mini pond in a large container or pot, which can house aquatic plants and small fish, adding a unique element to your garden.
        </IntroParagraph>
        <IntroParagraph>
          <strong>Water Walls</strong>: A water wall or cascading water feature can fit against a wall, adding the relaxing sound of running water without using much space.
        </IntroParagraph>
      </BlogContainer>
      <Footer />
    </BackgroundContainer>
  );
};

export default BlogDesign;
