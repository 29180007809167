/* eslint-disable @typescript-eslint/no-unused-vars */
import styled from 'styled-components';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useSearchParams } from 'react-router-dom';

const HeroComponent = styled.header`
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(to right, #1b2b52, #2c51a8);
  padding: 0; // No vertical padding needed since the content is centered
  height: 100vh;
`;

const HeaderContainer = styled.div`
  background-color: transparent;
  border-radius: 1.5rem;
  border: 4px solid white;
  padding: 4vh;
  color: white;
  width: 70vh;
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center; // Center the content inside the container
  align-items: center; // Center the content horizontally
  @media (orientation: portrait){
    width: 60%;
    height: 60%;
  }
`;

const Heading = styled.h1`
  font-size: 6vh;
  font-family: 'Ubuntu', sans-serif;
  a {
    text-decoration: none;  // Removes the underline
    color: inherit;         // Inherits the color from the parent element
    &:hover {
      text-decoration: none;  // Removes the underline on hover as well
    }
  }
  @media (max-height: 360px) {
    font-size: 150%;
  }
  @media (orientation: portrait){
    font-size: 150%;
  }
`;

const SubHeading = styled.h4`
  font-weight: 80;
  margin: 0 auto;
  justify-content: center; // Center the content inside the container
  align-items: center;
  text-align: center; 
  @media (max-width: 1000px) {
    font-size: 100%;
    margin-bottom: 2vw;
  }
  @media (orientation: portrait){
    font-size: 100%;
  }
  `

  const UnderHeading = styled.h4`
  font-weight: 40;
  font-size: 0.7rem;
  font-style: italic;
  margin: 0 auto;
  justify-content: center; // Center the content inside the container
  align-items: center;
  text-align: center; 
  `

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center; // Center form items horizontally
  width: 100%; // Take the full width of the container
`;



const FormField = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center; // Align the form field centrally
  width: 100%;
  margin-top: 15vh; // Adjust top margin as needed
  @media (max-width: 1000px) {
    margin-top:0;
   }
`;

const Label = styled.label`
  margin-bottom: 0.5rem;
  color: white;
  margin-right: 20vh;
  text-align: center; // Ensure the label is centered
  width: 100%; // Make label full width to center text
    @media (orientation: portrait){
    margin-right: 20%;
    font-size: 100%;
  }
`;

// Modify the StyledInput width to be consistent
const StyledInput = styled.input`
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 60%; // Match the width of the SubmitButton
  @media (max-height: 360px) {
    margin-bottom: 0;
    height: 40%;
  }
`;

const SubmitButton = styled.button`
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: white;
  color: rgba(7,55,99);
  margin-top: 5vh;
  cursor: pointer;
  width: 40%; // Match the input width

  &:hover {
    background-color: rgba(239,239,239);
  }
  @media (max-width: 1000px) {
    width: 30%;
    margin-top: 9%;
  }
  @media (orientation: portrait){
    width: fit-content;
  }
`;


const ResetPassword = () => {
  const [email, setEmail] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [token, setToken] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    const resetToken = searchParams.get('token');
    const encodedEmail = searchParams.get('email');
    if (resetToken && encodedEmail) {
      setToken(resetToken);
      setEmail(decodeURIComponent(encodedEmail)); // Set email in state, decode if necessary
  } else {
      // No token or email found, navigate to /emailpassword
      navigate('/emailpassword');
  }
}, [searchParams, navigate]);



const handleResetSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
  e.preventDefault();

  // Check if the newPassword meets the criteria
  const passwordRegex = /(?=.*\d)(?=.*[A-Z]).{6,}/;
  if (!passwordRegex.test(newPassword)) {
      setErrorMessage('Password must contain at least one number, include at least one uppercase letter, and be at least 6 characters long.');
      return; // Prevent the form from submitting
  }

  // Reset the errorMessage if criteria are met
  setErrorMessage('');

  try {
      // Proceed to submit the form data
      const response = await axios.post('https://projectprice-ad06ee250897.herokuapp.com/updatepassword', { email, newPassword, token });
      navigate('/loginpage');
  } catch (error: any) {
      if (error.response) {
          // Update to handle error based on your API response structure
          setErrorMessage(error.response.data.message || 'An error occurred while updating the password.');
      } else {
          setErrorMessage('An unexpected error occurred.');
      }
  }
};

      if (token) {
        return (
            <HeroComponent>
                <HeaderContainer>
                    <Heading><a href="/">Project Price</a></Heading>
                    <SubHeading>Enter your new password</SubHeading>
                    <StyledForm onSubmit={handleResetSubmit}>
                        <FormField>
                            <Label htmlFor="newPassword">New Password</Label>
                            <StyledInput 
                                id="newPassword"
                                type="password"
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                                required
                            />
                        </FormField>
                        {errorMessage && <div style={{ color: 'red', textAlign: 'center', marginBottom: '10px' }}>{errorMessage}</div>}
                        <SubmitButton type="submit">Update</SubmitButton>
                    </StyledForm>
                </HeaderContainer>
            </HeroComponent>
        );
    }
    return null
    }
export default ResetPassword;