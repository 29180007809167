import React from 'react';
import styled from 'styled-components';
import NavBar from '../components/PublicNav/homenav';
import Footer from '../components/Footer';

const BackgroundContainer = styled.div`
  background: #f8f8f8;
`;

const SketchUpContainer = styled.div`
  margin: 0 auto;
  padding: 20px;
  padding-top: 10vh;
  color: rgba(7,55,99);
`;

const CenteredContainer = styled.div`
  text-align: center;
  max-width: 80%;
  margin: 0 auto;
  @media (orientation: portrait){
    max-width: 100%;
  }
`;

const PageTitle = styled.h1`
  text-align: center;
  margin-bottom: 30px;
  padding: 5vh;
`;

const IntroParagraph = styled.p`
  font-size: 1.1rem;
  line-height: 1.6;
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
  color: #333;
  margin-bottom: 30px; // Space between the paragraph and the first image
  text-align: left;
  @media (orientation: portrait){
    max-width: 100%;
  }
`;

const DownloadButton = styled.a`
  display: inline-block;
  background-color: rgba(7, 55, 99);
  color: white;
  padding: 10px 20px;
  margin: 20px 0;
  border-radius: 5px;
  text-align: center;
  font-size: 16px;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    background-color: #0056b3;
  }
`;

const ImagePlaceholder = styled.img`
  width: 50%;
  height: auto;
  margin: 20px 0;
`;

const StepContainer = styled.div`
  margin: 20px 0;
`;

const StepTitle = styled.h2`
  font-size: 1.5rem;
  color: rgba(7,55,99);
  margin-bottom: 10px;
`;

const StepDescription = styled.p`
  font-size: 1rem;
  color: #333;
  margin-bottom: 20px;
`;


const SketchUpPage: React.FC = () => {
  return (
    <BackgroundContainer>
      <NavBar />
      <SketchUpContainer>
        <PageTitle>Download SketchUp Extension</PageTitle>
        <CenteredContainer>
        <IntroParagraph>
         We will take you through the steps of how to intergrate your Project Price design plan through to
        SketchUp Pro.  By following this guide, you will be able to transform your 2D drawings into 3D models with ease using a single tool.  
        scroll through the guide to find out how we can get you set up. 
        </IntroParagraph>
        <DownloadButton href="/downloads/Project_Price_Extension.rbz" download>
          Download SketchUp Extension
        </DownloadButton>
        </CenteredContainer>

        <StepContainer>
          <StepTitle>Step 1: Download and Install SketchUp</StepTitle>
          <StepDescription>
            Go to the <a href="https://www.sketchup.com/en/try-sketchup?utm_source=google&utm_medium=paid_search&utm_campaign=SU_Brand_Search_Brand_UK">SketchUp website</a> and download SketchUp Pro. You can have a free trial for 7 days if you are not already subscribed.
          </StepDescription>
          <ImagePlaceholder src={'/sketchup1.png'} alt="Image of SketchUp Download Page" />
        </StepContainer>

        <StepContainer>
          <StepTitle>Step 2: Download Our Extension</StepTitle>
          <StepDescription>
            Download our file extension from the link provided.
          </StepDescription>
          <DownloadButton href="/downloads/Project_Price_Extension.rbz" download>
          Download SketchUp Extension
        </DownloadButton>
          
        </StepContainer>

        <StepContainer>
          <StepTitle>Step 3: Open Extension Manager</StepTitle>
          <StepDescription>
            Open a SketchUp plan, navigate to the Extensions tab, and click on Extensions Manager.
          </StepDescription>
          <ImagePlaceholder src={'/sketchup2.png'} alt="Image of Extension Download Page" />
        </StepContainer>

        <StepContainer>
          <StepTitle>Step 4: Install Extension</StepTitle>
          <StepDescription>
            Click on Install Extension, then navigate to where you downloaded the rbz file from Project Price.
          </StepDescription>
          <ImagePlaceholder src={'/sketchup3.png'} alt="Image of Extensions Manager" />
        </StepContainer>

        <StepContainer>
          <StepTitle>Step 5: Export Your Design</StepTitle>
          <StepDescription>
            In the Project Price Design tab, click the export button found next to the save project button on a design.
          </StepDescription>
          <ImagePlaceholder src={'/sketchup4.png'} alt="Image of Install Extension" />
        </StepContainer>

        <StepContainer>
          <StepTitle>Step 6: Import Your CSV</StepTitle>
          <StepDescription>
            In SketchUp, click Extensions and 'Import Project Price Plan', then select the CSV file.
          </StepDescription>
          <ImagePlaceholder src={'/sketchup5.png'} alt="Image of Export Button" />
        </StepContainer>

        <StepContainer>
          <StepTitle>Step 7: Bring Your Drawing to Life</StepTitle>
          <StepDescription>
            Use the Push/Pull tool to bring the drawing to life.
          </StepDescription>
          <ImagePlaceholder src={'/sketchup6.png'} alt="Image of Import Project Price Plan" />
          
        </StepContainer>

      </SketchUpContainer>
      <Footer />
    </BackgroundContainer>
  );
};

export default SketchUpPage;
