/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: fit-content;
  margin-top: -5vh;
  @media (max-height: 350px) { 
    margin-top: 0%;
    width: 100%;
  }
`;

const BackgroundContainer = styled.div`
  background-color: white;
  width: 70%;
  height: 50vh;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  @media (max-height: 350px){
   width: fit-content;
   height: 45vh;
  }
`;

const ResultTable = styled.table`
  width: fit-content;
  border-collapse: collapse;
`;

const ResultTableRow = styled.tr`
  &:nth-child(even) {
    background-color: rgba(170,179,184)
  }
  border: 0.1vh solid black;
  font-family: 'barlow';
  font-weight: 500;
  @media (max-height: 350px) {
    font-size: 70%;
  }
    @media (min-width: 1900px){

    font-size: 1.8vh;
  }
`;

const ResultTableData = styled.td`
  padding: 0.3rem;
  font-size: 0.8rem;
  width: 400px;
  @media (max-height: 350px) {
    width: 150px;
    font-size: 90%;
  }
    @media (min-width: 1900px){
    width: 50vh;
    font-size: 1.8vh;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  bottom: 10px;
  right: 25%;
  @media (max-height: 350px) {
    right: 4%;
  }
`;

const Button = styled.button`
  display: flex;
  right: 10px;
  background-color: white;
  width: 90px;
  font-size: 0.9rem;
  color: black;
  border-radius: 3px;
  border: none;

  @media (max-height: 350px){
    font-size: 80%;
    width: fit-content;
  }
    @media (min-width: 1900px){
    width: fit-content;
    font-size: 1.8vh;
  }
`;

const MaterialRow = styled(ResultTableRow)`
  display: flex;
  align-items: center;
`;

const MaterialLabel = styled(ResultTableData)`
  margin-right: 10px;
`;

const MaterialInput = styled.input`
  width: 30px;
  background-color: rgba(0, 0, 0, 0.3);
  border: none;
  /* Disable spinners */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const WhiteText = styled.p`
  color: white;
  margin-top: 2vh;
  font-size: 2.5vh;
   @media (max-height: 350px) {
    margin-top: 1vh;
  }
`;

const PoundSymbol = styled.span`
  margin-right: 5px;
`;

const MaterialUnit = styled.span`
  margin-left: 5px;
`;

const Header = styled.h2`
  font-size: 200%;
  margin-bottom: 0%;
  @media (max-height:350px){
    display: none;
  }
    @media (min-width: 1000px) and (max-width: 1900px){
    display: none;
}
`;

const SuccessMessage = styled.div`
  position: fixed; 
  bottom: 0vh;
  left: 50%;
  transform: translateX(-50%); 
  color: rgba(0, 255, 9, 1); 
  padding: 10px 20px;
  zIndex: 1000; // Ensure it appears above other elements
  
`;


export interface FencingMaterial {
  name: string;
  width: number;
  materials: { materialName: string; quantity: number }[];
  postMaterial: string; 
}

interface NewMaterial {
  materialName: string;
  price: number | string | null;
  quantity: number;
}

export interface PriceData {
  [key: string]: number | string | null;
}

interface PricingProps {
  backgroundColor: string;
}

const MaterialUnitMapping: { [key: string]: string } = {
  Waste: 'per ton',
  Hardcore: 'per ton',
  Edgings: 'per m²',
  Setts: 'per m²',
  'Screed material': 'per ton',
  'Kiln sand': 'per 25kg bag',
  Membrane: 'per m²',
  Pointing: 'per unit',
  'Paving sand': 'per ton',
  Cement: 'per 25kg bag',
  Primer: 'per unit',
  'Plasticiser concentrate': 'per litre',
  Posts: 'per post',
  'Fencing bay': 'per bay',
  Postcrete: 'per bag',
  Ballast: 'per ton',
  'Block work': 'per unit',
  'Brick work': 'per unit',
  'Building sand': 'per ton',
  Cladding: 'per m²',
  'Tile adhesive': 'per unit',
  'Stone facing': 'per m²',
  Render: 'per ton',
  Sleepers: 'per unit',
  Decking: 'per board',
  'Fixing screws': 'per screw',
  'Decking screws': 'per screw',
  'Decking frame work timber': 'per length',
  'Decking fascia board': 'per board',
  'Channel drains': 'per m',
  'Drainage pipe': 'per m',
  'Gully pot': 'per unit',
  'Drainage fittings': 'per unit',
  'Manhole cover': 'per unit',
  Bark: 'per m3',
  Gravel: 'per ton',
  Turf: 'per m²',
  'Artificial turf': 'per m²',
  Soil: 'per ton',
  Digger: 'per day',
  Dumper: 'per day',
  Concrete: 'per m³',
  'Rebar sheet': 'per sheet',
  Curb: 'per unit',
  Skip: 'per skip',
  Asphalt: 'per m³',
};

const defaultMaterialPrices: PriceData = {
  Waste: '',
  Hardcore: '',
  Edgings: '',
  Setts: '',
  'Screed material': '',
  'Kiln sand': '',
  Membrane: '',
  'Paving sand': '',
  Cement: '',
  Primer: '',
  Pointing: '',
  'Plasticiser concentrate': '',
  Posts: '',
  'Fencing bay': '',
  Postcrete: '',
  Ballast: '',
  'Block work': '',
  'Brick work': '',
  'Building sand': '',
  Cladding: '',
  'Tile adhesive': '',
  'Stone facing': '',
  Render: '',
  Sleepers: '',
  Decking: '',
  'Fixing screws': '',
  'Decking screws': '',
  'Decking frame work timber': '',
  'Decking fascia board': '',
  'Channel drains': '',
  'Drainage pipe': '',
  'Gully pot': '',
  'Drainage fittings': '',
  'Manhole cover': '',
  Bark: '',
  Gravel: '',
  Turf: '',
  'Artificial turf': '',
  Soil: '',
  Digger: '',
  Dumper: '',
  Concrete: '',
  'Rebar sheet': '',
  Curb: '',
  Skip: '',
  Asphalt: '',
};

const Pricing: React.FC<PricingProps> = ({ backgroundColor }) => {
  const [loadingData, setLoadingData] = useState(false);
  const [materialPrices, setMaterialPrices] = useState<PriceData>(defaultMaterialPrices);
  const [fetchedPrices, setFetchedPrices] = useState<PriceData>({});
  const [isCopied, setIsCopied] = useState(false);
  const [fencingMaterials, ] = useState<FencingMaterial[]>([]);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);


  const isBackgroundWhite = backgroundColor === 'rgba(245, 245, 245)' || backgroundColor === 'white';

  const fetchPriceData = async () => {
    try {
      setLoadingData(true);
  
      // Initialize with default material prices
      let loadedData: PriceData = { ...defaultMaterialPrices };
  
      try {
        // Fetch pricing data
        const pricingResponse = await axios.get('https://projectprice-ad06ee250897.herokuapp.com/pricelist/pricelist');
        const pricesData = pricingResponse.data.data || {};
  
        // If pricesData is empty, use default prices and trigger upload
        if (Object.keys(pricesData).length === 0) {
          console.warn("No pricing data found. Initializing with default values.");
          await axios.post('https://projectprice-ad06ee250897.herokuapp.com/pricelist/pricelist', loadedData);
        } else {
          // Populate pricing data for standard fields
          for (const material in pricesData) {
            if (material !== 'prices' && pricesData[material] !== null && pricesData[material] !== undefined) {
              loadedData[material] = pricesData[material].toString();
            }
          }
  
          // Handle prices array separately
          if (Array.isArray(pricesData.prices)) {
            pricesData.prices.forEach((item: { materialName: string, price: number }) => {
              loadedData[item.materialName] = item.price.toString();
            });
          }
        }
      } catch (error) {
        console.warn("Error fetching pricing data. Initializing with default values.", error);
        await axios.post('https://projectprice-ad06ee250897.herokuapp.com/pricelist/pricelist', loadedData);
      }
  
      try {
        // Fetch settings data to get fencing materials
        const settingsResponse = await axios.get('https://projectprice-ad06ee250897.herokuapp.com/settingscontroller/settingscontroller');
        const settingsData = settingsResponse.data.data || {};
  
        // Ensure fencing materials are included
        if (settingsData.fencingMaterials && Array.isArray(settingsData.fencingMaterials)) {
          settingsData.fencingMaterials.forEach((fencingMaterial: FencingMaterial) => {
            fencingMaterial.materials.forEach((material) => {
              if (!loadedData[material.materialName]) {
                loadedData[material.materialName] = ''; // Ensure it's included even if not fetched
              }
            });
  
            // Add post material with " + post"
            if (fencingMaterial.postMaterial) {
              const postMaterialKey = `${fencingMaterial.postMaterial} post`;
              if (!loadedData[postMaterialKey]) {
                loadedData[postMaterialKey] = ''; // Ensure post material is included
              }
            }
          });
        }
      } catch (error) {
        console.warn("Error fetching settings data. Fencing materials may not be fully initialized.", error);
      }
  
      // Set the loaded prices
      setMaterialPrices(loadedData);
      setFetchedPrices(loadedData);
    } catch (error) {
      console.error("Error fetching price data:", error);
    } finally {
      setLoadingData(false);
    }
  };
  



  useEffect(() => {
    fetchPriceData();
  }, []);

  const handlePost = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    try {
      const dataToSave = { ...materialPrices };
  
      const newMaterials: NewMaterial[] = [];
  
      if (Object.keys(fetchedPrices).length === 0) {
        // New user, upload default prices
        await axios.post('https://projectprice-ad06ee250897.herokuapp.com/pricelist/pricelist', dataToSave);
        setFetchedPrices(materialPrices);
  
        setShowSuccessMessage(true);
        setTimeout(() => {
          setShowSuccessMessage(false);
        }, 3000);
      } else {
        const updatedData: { [key: string]: number | string | null } = {};
        for (const material in materialPrices) {
          if (materialPrices[material] !== fetchedPrices[material]) {
            updatedData[material] = materialPrices[material];
          }
        }
  
        if (Object.keys(updatedData).length > 0) {
          await axios.put('https://projectprice-ad06ee250897.herokuapp.com/pricelist/pricelist', updatedData);
          setFetchedPrices((prevFetchedPrices) => ({ ...prevFetchedPrices, ...updatedData }));
  
          setShowSuccessMessage(true);
          setTimeout(() => {
            setShowSuccessMessage(false);
          }, 3000);
        } else {
          console.log("No changes in form data.");
        }
      }
  
      // Save the new materials with their prices and quantities
      if (newMaterials.length > 0) {
        for (const newMaterial of newMaterials) {
          await axios.post('https://projectprice-ad06ee250897.herokuapp.com/pricelist/add-material', newMaterial);
        }
      }
    } catch (error: any) {
      console.error("Error submitting form:", error.response?.data);
    }
  };
  

  const handleMaterialPriceChange = (material: string, value: string) => {
    setMaterialPrices(prevMaterialPrices => ({
      ...prevMaterialPrices,
      [material]: value,
    }));
  
  };

  const materials = Object.keys(materialPrices);
  const hiddenFields = ["__v", "_id", "userId"];

  return (
    <div>
      <MainContainer>
        <Header style={{ color: isBackgroundWhite ? 'black' : 'white' }}>Pricing</Header>
        <WhiteText style={{ color: isBackgroundWhite ? 'black' : 'white' }}> All prices are excluding VAT.</WhiteText>
        <BackgroundContainer>
          <ResultTable>
            <tbody>
            {materials
    .filter((material) => material !== 'prices') // Exclude 'fencingMaterials'
    .map((material) => (
        !hiddenFields.includes(material) && (
            <MaterialRow key={material}>
                <MaterialLabel>{material}</MaterialLabel>
                <ResultTableData>
                    <PoundSymbol>£</PoundSymbol>
                    <MaterialInput
                        type="text"
                        value={materialPrices[material]?.toString() || ''}
                        onChange={(e) => handleMaterialPriceChange(material, e.target.value)}
                    />{" "}
                    <MaterialUnit>{MaterialUnitMapping[material]}</MaterialUnit>
                </ResultTableData>
            </MaterialRow>
        )
    ))}
            </tbody>
          </ResultTable>
        </BackgroundContainer>
        <WhiteText style={{ color: isBackgroundWhite ? 'black' : 'white' }}> Each Project will remember what prices are set on creation. Unless the 'Update Prices' button is clicked, prices for the project will not change.</WhiteText>
        <ButtonContainer>
          <Button
            style={{ color: isBackgroundWhite ? 'white' : 'black', backgroundColor: isBackgroundWhite ? 'grey' : 'white' }}
            onClick={(e) => handlePost(e)}
          >
            Save Prices
          </Button>
          {showSuccessMessage && (
  <SuccessMessage>Prices saved successfully!</SuccessMessage>
)}
        </ButtonContainer>
       </MainContainer>
    </div>
  );
};

export default Pricing;
