import React from 'react';
import styled from 'styled-components';
import NavBar from '../components/PublicNav/homenav';
import Footer from '../components/Footer';

interface StepContainerProps {
    index: number;
  }

const BackgroundContainer = styled.div`
  background: #f8f8f8;
  font-family: Arial, sans-serif;
`;

const BlogContainer = styled.div`
  margin: 0 auto;
  padding: 20px;
  @media (orientation: portrait){
    padding 1%;
  }
`;

const BlogTitle = styled.h1`
font-family: 'Ubuntu', sans-serif;
  text-align: center;
  margin-bottom: 30px;
  color: rgba(7,55,99);
`;

const StepContainer = styled.div<StepContainerProps>`
  padding: 20px;
  background-color: ${props => props.index % 2 === 0 ? '#f0f0f0' : '#fff'};
  flex-direction: ${props => props.index % 2 === 0 ? 'row' : 'row-reverse'};
  display: flex;    

  @media(orientation: portrait){
    padding 2%;
    flex-direction: column;
  }
`;

const StepImage = styled.img`
  width: 50%;
  height: auto;
  margin-bottom: 4vh; // Space between the image and the text
  
  display: block;
  margin-left: auto;
  margin-right: 5vh;
  @media (orientation: portrait){
    margin-bottom: 0%;
    width: 100%;
    margin-right: 0%;
  }
`;

const StepDescription = styled.p`
  font-size: 1rem;
  line-height: 1.6;
  color: #333;
  max-width: 80%;
  margin-top: 16vh;
  margin-left: auto;
  margin-right: auto;
  @media (orientation: portrait){
    margin-top: 2%;
  }
`;

const IntroParagraph = styled.p`
  font-size: 1rem;
  line-height: 1.6;
  max-width: 70%;
  margin-left: auto;
  margin-right: auto;
  color: #333;
  margin-bottom: 30px; // Space between the paragraph and the first image
  text-align: center;
`;

const Guide: React.FC = () => {
  const steps = [
    {
      image: '/walkthrough-images/1.png',
      description: 'Once you have logged into your account you will need to set up your settings, which can be found in navigation bar on the right of the screen. These settings are how you generally work and the measurements you use, such as excavation depths, sand / cement ratios, pointing coverage, etc.'
    },
    {
      image: '/walkthrough-images/2.png',
      description: 'Once you have entered all of your settings, press the save button found under the settings. By default we have entered standard values, if you are to keep these values, you should still press the save settings button to allow the app to use these measurements. You should only ever need to visit the settings menu again, if your working methods change.'
    },
    {
      image: '/walkthrough-images/4.png',
      description: 'Navigate to the prices tab in the navigation bar and enter your prices for individual materials. Again press the save button to save your prices that will work across board for all of your projects.'
    },
    {
      image: '/walkthrough-images/6.png',
      description: 'Once your prices and settings have been set. Navigate to the Project tab in the navigation bar. At the top of the page you will find a create new project button, first type in the name of your project and press the button, this will allow you to start working on your project.'
    },
    {
        image: '/walkthrough-images/7.png',
        description: 'Depending on your requirements, you will find all aspects of hard landscaping in the materials table. Add in your measurements for what your project requires.'
      },
      {
        image: '/walkthrough-images/8.png',
        description: 'In the middle table, you can add how long each phase should take you to complete. You can also input how much your company charges for labour per day and add VAT if applicable.'
      },
      {
        image: '/walkthrough-images/9.png',
        description: 'In the table on the far right, you will find all the materials and cost per individual item, you are also able to add on your % margin on top of materials, which will be calculated in your total cost.'
      },
      {
        image: '/walkthrough-images/13.png',
        description: 'You are able to directly edit the values in the table, adjust these to your needs and the app will remember you new values. If a material is not needed, check the box in the "Needed" column and the value of that material will become 0.'
      },
      {
        image: '/walkthrough-images/10.png',
        description: 'Click the "Hide unused values" button to remove all unused materials and values from view, making it easier to oversee your project.'
      },
      {
        image: '/walkthrough-images/11.png',
        description: 'Once you have entered all the necessary measurements, head over to the Quoting tab in the navigation bar.'
      },
      {
        image: '/walkthrough-images/12.png',
        description: 'Select your project in the dropdown menu and generate a full custom quote tailored to the information your have entered. Clicking the "Copy quote" button, copies all of the quote to which you can paste it in your usual text editor, to which you can make any edits or send directly to your customer.'
      },
  ];

  return (
    <BackgroundContainer>
      <NavBar />
      <BlogContainer>
        <BlogTitle>Project Price Guide</BlogTitle>
        <IntroParagraph>
            Here is a guide to help you use the Project Price app. We have aimed to keep simplicity for our users while we handle all of the intricacies. In this walkthrough you'll find out exactly how easy our app is to use. The initial set-up of setting the prices and settings should take around 20 minutes. Once these have been set up, you should only need to revisit or change these if your material prices change or if your working methods change. Remember to save all changes as your input data may be lost. 
        </IntroParagraph>
        {steps.map((step, index) => (
          <StepContainer key={index} index={index}>
            <StepImage src={step.image} alt={`Step ${index + 1}`} />
            <StepDescription>{step.description}</StepDescription>
          </StepContainer>
        ))}
      </BlogContainer>
      <Footer />
    </BackgroundContainer>
  );
};

export default Guide;
