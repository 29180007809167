import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import Footer from '../components/Footer';
import NavBar from '../components/PublicNav/homenav';
import Showcase from '../Showcase/showcase';
import EbookModal from '../Showcase/ebookmodal';
import SmallModal from '../Showcase/smallmodal';
import Testimonials from '../Showcase/testimonials';


const Container = styled.div`
    font-family: Arial, sans-serif;
    width: 100%;
    margin: 0 auto; // to center the container
    overflow: visible;
`;

const SecondContainer = styled.div`
    font-family: Arial, sans-serif;
    padding: 5vh;
    padding-top: 10vh;
    margin: 0 ; // to center the container
    background: rgba(7,55,99);
`;


const IntroSectionStyled = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 10vh;
    margin: 5vh 10vh;
    gap: 10vw;

    .text {
        flex: 1;
        max-width: 100%; // you can adjust this as per your design
    }

    img {
        max-width: 30%;  // adjust based on the image and desired size
        height: auto;
    }
    @media (max-width: 1070px) and (orientation: portrait) {
        padding-top: 4vh;
        margin: 3vh;
      }
`;


    const StyledHeader = styled.h1`
    font-family: 'Ubuntu', sans-serif;
    font-style: italic;
    font-size: 4vh; 
    font-weight: bold;
    color: rgba(7,55,99);
    
    `;


const StyledParagraph = styled.p`
    margin-top: 8vh;
    font-size: 2.9vh; // Base font size

    .highlight {
        font-weight: bold;
        font-size: 3.1vh; // Slightly larger
    }

    .divider {
        display: block;
        width: 100%; // Adjust width as needed
        height: 2px;
        margin: 10px auto;
        background-color: rgba(0,0,0,0.8); // Adjust color as needed
    }

    .bullet {
        display: flex;
        align-items: center;
        margin: 3vh 0;

        &::before {
            content: '✔'; // Using a checkmark character here, but you can replace this with SVG or another symbol.
            margin-right: 8px;
            color: green; // Adjust color as needed
        }
    }
    @media (orientation: portrait){
        padding-bottom: 30%;
    }
        @media (min-width: 1900px){
        font-size: 2.4vh;
        }
`;

const ResponsiveVideo = styled.video`
  width: 440px; // Default size
  height: 240px; // Default size

  @media (max-width: 999px) {
    width: 250px; // Smaller size for screens less than 1000px wide
    height: 150px; // Smaller size for screens less than 1000px wide
  }
   @media (max-width: 1070px) and (orientation: portrait) {
    display: none; 
  }
`;

const StyledImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0vh 0;
`;

const StyledImage = styled.img`
  width: 100%;
  width: 180vh;
  height: auto;
  border-radius: 10px;
  margin-bottom: 5vh;
  @media (orientation: portrait){
  display: none;
  }
`;


                const Button = styled.button`
                    background-color: #0056b3;
                    color: white;
                    padding: 1vh 3vh;
                    border: none;
                    border-radius: 5px;
                    font-size: 2.8vh;
                    margin-top: 5vh;
                    cursor: pointer;
                    transition: background-color 0.3s;

                    &:hover {
                        background-color: #007BFF;
                    }
                    @media (orientation: portrait){
                        padding: 2%;
                        font-size: 100%;
                        margin-top: 2%;
                        margin-bottom: 10%;
                    }
                `;

                const SavingsDisplay = styled.div`
                    margin-top: 5vh;
                    font-size: 5vh;
                    align: center;
                  
                    font-weight: bold;

                    @media (orientation: portrait) {
                        font-size: 3vh;
                    }
                `;

                const CalculatorWrapper = styled.div`
                    display: flex;
                    justify-content: space-between;
                    padding: 5vh 10vh;
                    margin-top: 1vh;
                    @media (orientation: portrait) {
                    flex-direction: column;
                    padding: 0%;
                    margin-top: 0%;
                    justify-content: none;
                        }
                `;
                        
                const StyledH1 = styled.h3`
                    text-align: left;
                    margin-bottom: 5vh;
                    color: white;
                    font-family: 'Ubuntu', sans-serif; // Added a fallback font
                    font-size: 6vh;
                @media (orientation: portrait){
                    margin-bottom: 10%;
                    font-size: 3vh;
                    text-align: center;
                }
              `;

              const NewStyledH1 = styled.h3`
                    text-align: left;
                    margin-bottom: 5vh;
                    color: rgba(7,55,99);
                    font-family: 'Ubuntu', sans-serif; // Added a fallback font
                    font-size: 6vh;
                @media (orientation: portrait){
                    margin-bottom: 10%;
                    font-size: 1.5rem;
                    text-align: center;
                }
              `;

              const WhiteStyledH1 = styled.h3`
                    text-align: center;
                    margin-bottom: 5vh;
                    color: white;
                    font-family: 'Ubuntu', sans-serif; // Added a fallback font
                    font-size: 6vh;
                @media (orientation: portrait){
                    margin-bottom: 10%;
                    font-size: 3vh;
                    text-align: center;
                }
              `;
              
              const NewStyledParagraph = styled.p`
              font-size: 2.8vh;
              @media (orientation: portrait){
                font-size: 100%;
                margin-top: 5vh;
              }`;

             

        
            const SVGWaveContainer = styled.div`
            overflow: hidden;

            @media (orientation: portrait) {
            display: none;
            }
            `;


        const SVGWave: React.FC = () => {
            return (
                <SVGWaveContainer>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 305">
                    <defs>
                        <linearGradient id="splitGradient" x1="0%" y1="0%" x2="0%" y2="100%">
                            <stop offset="50%" stop-color="white" />
                            <stop offset="50%" stop-color="rgba(7,55,99)" />
                        </linearGradient>
                    </defs>
                    <rect x="0" y="0"  fill="url(#splitGradient)"></rect>
                    <path fill="rgba(7,55,99)" fill-opacity="1" d="M0,256L40,234.7C80,213,160,171,240,170.7C320,171,400,213,480,229.3C560,245,640,235,720,224C800,213,880,203,960,186.7C1040,171,1120,149,1200,149.3C1280,149,1360,171,1400,181.3L1440,192L1440,320L1400,320C1360,320,1280,320,1200,320C1120,320,1040,320,960,320C880,320,800,320,720,320C640,320,560,320,480,320C400,320,320,320,240,320C160,320,80,320,40,320L0,320Z"></path>
                </svg>       
                </SVGWaveContainer>         
            );
        }

        const IntroSection: React.FC = () => {
            return (
                <IntroSectionStyled>
                    <div className="text">
                    <StyledHeader>The fastest, simplest and most reliable way to price your landscaping projects.</StyledHeader>
                    <StyledParagraph>
                        <span className="highlight">Streamline your pricing & quoting.</span>
                        <span className="divider"></span>
                        <div className="bullet">Tailored to how you work.</div>
                        <div className="bullet">Instantly receive material quantities.</div>
                        <div className="bullet">Generate custom quotes.</div>

                    </StyledParagraph>
                    </div>
                    <ResponsiveVideo autoPlay loop muted playsInline>
                <source src="pp.mp4" type="video/mp4" />
            </ResponsiveVideo>
                </IntroSectionStyled>
            );
        }

        
        const SavingsCalculatorWrapper = styled.div`
    display: flex;
    margin-top: 15vh;
    flex: 1;
    flex-direction: column;
    align-items: center;
    padding: 3.5vh;
    background: white;
    border-radius: 3vh;
    margin: 4vh 0;
    @media (orientation: portrait) {
        padding: 5%;
        width: 30vh;
        height: 50vh;
        justify-content: center;
        margin: auto;
        align-items: center;
        text-align: center; // Center text within the wrapper
    }
`;

const Input = styled.input`
    padding: 0.9vh;
    margin: 1vh;
    border-radius: 5px;
    border: 1px solid #ddd;
    width: 7vh;
    background: transparent;

    /* Disable spinners */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type="number"] {
    -moz-appearance: textfield;
  }
  @media (orientation: portrait){
    width: 15%;
    padding 2%;
  }
`;

const Label = styled.label`
    margin-right: 3vh;
    font-size: 2.7vh;
    @media (orientation: portrait){
        font-size: 100%;
    }
`;


const TextWrapper = styled.div`
    flex: 3;
    color: white;
    margin-top: 10vh;
    font-size: 2.6vh;
    padding-right: 10vh; // or any spacing you prefer between the text and the calculator
    @media (max-width: 999px) {
        font-size: 3vh;
    }
    @media (orientation: portrait) {
        font-size: 100%;
        margin-top: 20%;
        margin-bottom: 5%;
        flex: 1;
        padding-right: 0;
        text-align: center;
    }
`;
const SavingsCalculator: React.FC = () => {
    const [quotesPerMonth, setQuotesPerMonth] = useState<string>('');
    const [hoursSaved, setHoursSaved] = useState<number>(0);  

    const calculateHoursSaved = () => {
        const numQuotesPerMonth = Number(quotesPerMonth);
        
        const hoursWithoutApp = (numQuotesPerMonth * 12) * 3.5; // Total hours without the app per year
        const hoursWithApp = (numQuotesPerMonth * 12) * (5 / 60); // Total hours with the app per year
        const hoursSaved = hoursWithoutApp - hoursWithApp;
        
        setHoursSaved(hoursSaved);
    };
    

    return (
        <CalculatorWrapper>
        <TextWrapper>
        <StyledH1>How much time can we save you?</StyledH1>
                <p>
                    At Project Price, we recognize that the intricacies of 
                    pricing and quoting often consume valuable hours. With our tools, not only will 
                    your quotes be more precise, but you'll also reclaim those lost hours.
                    Our survey conducted an average pricing designing & quoting time to be approximately 3.5 hours
                    per quote, when using traditional methods. Where users found our Project Pricing app to 
                    cut that time down by an incredible 98%!
                    Let us tackle the numbers while you concentrate on the heart of your business. 
                    Use our savings calculator to discover the benefits of partnering with us. &nbsp;
                    <strong><em>What will you do with the extra time?</em></strong>
                </p>
            </TextWrapper>
            <SavingsCalculatorWrapper>
                    <div>
                        <Label>Quotes sent per month:</Label>
                        <Input type="number" value={quotesPerMonth} onChange={(e) => setQuotesPerMonth(e.target.value)} />
                    </div>
                    <Button onClick={calculateHoursSaved}>Calculate Hours Saved</Button>
                    <SavingsDisplay>
                        {hoursSaved.toFixed(0)} hours
                    </SavingsDisplay>
                    {hoursSaved > 0 ? "per year saved!" : "" }
                </SavingsCalculatorWrapper>
                </CalculatorWrapper>

    );
}


        const FAQSection = styled.div`
        max-width: 120vh; // Or whatever maximum width you desire
        margin: 0 auto; // This centers the div on the page
        padding: 4vh; // Add some padding around the content
        margin-top: 7vh;
        display: flex;
        flex-direction: column;
        align-items: center; // This centers the items horizontally in a flex container
        gap: 2vh; // Adds space between each FAQ item
            @media (orientation: portrait){
                margin-top: 2%;
                padding: 2%;
                gap: 2%;
                font-size: 100%;
                margin: 0 5vh;
            }
        
    `;
    
    const FAQItem = styled.div`
        width: 100%; // Make each FAQ item take full width of the FAQSection
        border-bottom: 1px solid #ccc; // Optional: add a separator line between items
        padding: 1vh 0; // Add some padding above and below each item
    `;
    
    const FAQQuestion = styled.button`
        width: 100%; // Make the question button take the full width of its container
        background: none; // Remove default button styling
        border: none;
        color: white;
        border-radius: 1vh;
        text-align: center;
        padding: 2vh; // Add padding inside the button for spacing
        cursor: pointer; // Change the mouse cursor on hover
        &:hover {
            background-color: #f5f5f5; // Optional: add a background color on hover
            color: black;
        }
        font-size: 3vh; // Increase the font size a bit

        @media (max-width: 1000px){
            font-size: 4vh;
        }
        @media (orientation: portrait){
            font-size: 100%;
        }
    `;
    
    const FAQAnswer = styled.div`
        text-align: left; // Align the answer text to the left
        padding:  1.5vh; // Add padding on the sides
        color: white; // Optional: change the color of the answer text
        font-size: 2.8vh; // Set the font size of the answer
        font-style: italic;
        @media (orientation: portrait){
            font-size: 100%;
            padding: 2%;
        }
    `;
    

        const FAQ: React.FC = () => {
            const [activeFAQIndex, setActiveFAQIndex] = useState<number | null>(null);
        
            const FAQs = [
                {
                    question: 'How do I use the Project Price app?',
                    answer: (
                        <span>
                        You can use the Project Price app by signing up <a href="/loginpage" style={{ textDecoration: 'underline' }}>here</a>. Check out our 
                        <a href="/guide" style={{ textDecoration: 'underline' }}> in-depth guide</a> for a full breakdown and walkthrough.
                        </span>
                    )
                },
                {
                    question: 'How much does this cost?',
                    answer: (
                        <span>
                            We have different tiers allowing different access to project price. The premium plan at £49 per month allows full use of Project Price, including the design feature. the regular plan, gives full use excluding the design component.
                            <a href="#pricing" style={{ textDecoration: 'underline' }}> here</a>.
                        </span>
                    )
                },
                {
                    question: 'Does this work outside of the UK?',
                    answer: 'Project Price is currently only operating in the United Kingdom. We are actively working to make Project Price international.'
                },
                {
                    question: 'What trades does this serve?',
                    answer: 'Project price is currently tailored to hard-landscaping professionals. We aim to streamline your business, making you more accurate, more reliable and save you time.'
                },
                {
                    question: 'Am I locked into a contract?',
                    answer: 'Nope. Depending on which subscription you choose, The free, regular or premium membership, these are charged on a monthly basis. Cancelling your subscription allows you to carry on using Project Price until the end of your current subscription.'
                },
                {
                    question: 'Can I cancel at any time?',
                    answer: 'You can cancel at any time. You can keep using Project Price until you cancellation date.'
                },
            ];
        
            const toggleFAQ = (index: number) => {
                setActiveFAQIndex(activeFAQIndex === index ? null : index);
            };
        
            return (
                <FAQSection id='faq'>
                    {FAQs.map((faq, index) => (
                        <FAQItem key={index}>
                            <FAQQuestion onClick={() => toggleFAQ(index)}>
                                {faq.question}
                            </FAQQuestion>
                            {activeFAQIndex === index && <FAQAnswer>{faq.answer}</FAQAnswer>}
                        </FAQItem>
                    ))}
                </FAQSection>
            );
        }

        const DeviceUsageSection = styled.div`
        display: flex;
        align-items: center; // Vertically aligns items in the middle
        justify-content: center; // Horizontally centers the content
        padding: 2vh;
        margin-top: 10vh;
        background: white;
        border-radius: 5vh;
        @media (orientation: portrait){
            flex-direction: column;
            padding: 0%;
            margin-top: 10%;
        }
        `;

        // Styled component for the text content
        const DeviceUsageText = styled.div`
        margin-left: 20vh; // Adds space between the image and the text
        max-width: 90vh; // Restricts the width of the text for readability
        color: rgba(7,55,99);
        text-align: left;
        @media (orientation: portrait){
            margin-left: 0%;
            text-align: center;
            max-width: 100%;
        }
        `;

        const DeviceImage = styled.img`
            max-width: 30%; // Set maximum width to 50% of the container
            height: auto; // Maintain aspect ratio
            @media (orientation: portrait){
                width: 100%;
                max-width: 100%;
                padding: 4vh;
            }
           
            `;

        const DeviceUsage: React.FC = () => {
            return (
              <DeviceUsageSection>
                 <DeviceImage src="/laptop-tablet-phone.png" alt="App on various devices" />
                <DeviceUsageText>
                  <NewStyledH1>Use Anywhere, Anytime...</NewStyledH1>
                  <NewStyledParagraph>
                   Project Price is designed for flexibility, allowing you to stay productive
                    whether you are on the desktop, tablet or phone. 
                    Switch between devices and manage your projects at your convenience with real-time data synchronizing.
                    Find out how easy our app is to use <a href="/demo" style={{ textDecoration: 'underline' }}> here</a>. Or dive 
                    into the dashboard <a href="/dashboard" style={{ textDecoration: 'underline' }}> here</a>.


                  </NewStyledParagraph>
                </DeviceUsageText>
              </DeviceUsageSection>
            );
          };

          const AreaCalcSection = styled.div`
          display: flex;
          color: white;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          padding: 0vh;
          margin-top: 10%;
          border-radius: 5vh;
      
          @media (orientation: portrait) {
              padding: 0%;
              margin-top: 10%;
          }
      `;
      
      const AreaCalcContent = styled.div`
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 5vh;
      
          @media (orientation: portrait) {
              flex-direction: column;
          }
          @media (orientation: landscape) {
              flex-direction: row;
          }
      `;
      
      const AreaCalcImage = styled.img`
          max-width: 40%;
          height: auto;
          border-radius: 15px;
          margin-right: 5vh;
          
          @media (orientation: portrait){
              width: 80%;
              max-width: 100%;
              margin-right: 0;
          }
      `;
      
      const AreaCalcText = styled.div`
          max-width: 50%;
          color: white;
          text-align: left;
      
          @media (orientation: portrait){
              text-align: center;
              max-width: 100%;
          }
      `;
      
      const AreaCalc: React.FC = () => {
        const navigate = useNavigate();

        const handleNavigate = () => {
            navigate('/areacalculator');
        };
          return (
              <AreaCalcSection>
                  <WhiteStyledH1>Need to calculate an area?</WhiteStyledH1>
                  <AreaCalcContent>
                      <AreaCalcImage src="/appcalc.png" alt="Area Calculator" />
                      <AreaCalcText>
                          <NewStyledParagraph>
                              Do you need to accurately calculate the area for your project? Our innovative Area Calculator is here to help! Simply outline the dimensions of your desired area, and we'll effortlessly compute the total area required for you. It's the perfect tool for precise and hassle-free area calculations.
                          </NewStyledParagraph>
                          <Button onClick={handleNavigate}>Area Calculator</Button>
                      </AreaCalcText>
                  </AreaCalcContent>
              </AreaCalcSection>
          );
      };
      
          const DemoContainer = styled.div`
            margin: 0 auto;
            padding: 20px;
            `;

            const VideoContainer = styled.div`
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 20px;
                @media (orientation: portrait){
                        height: 25vh;
                        width: 30vh;
                        margin-left: auto;
                        margin-right: auto;
                    }
                         @media (orientation: portrait) and ( min-width: 800px){
                        height: 25vh;
                        width: 40vh;
                        margin-left: auto;
                        margin-right: auto;
                    }
                `;

                const StyledIframe = styled.iframe`
                    width: 49vw;      // Responsive width
                    height: 25vw; // Responsive height, maintaining a 16:9 aspect ratio

                    border: none;
                    @media (orientation: portrait){
                        height: 20vh;
                        width: 50vh;
                    }
                    `;

                    const TextContainer = styled.div`
                        flex: 3;
                        color: white;
                        margin-top: 10vh;
                    `;
                
            const Demo: React.FC = () => {

                return (
                    <DemoContainer>
                        <TextContainer>
                        <WhiteStyledH1>Watch us price a complete Project!</WhiteStyledH1>
                        </TextContainer>
                    <VideoContainer>
                    <StyledIframe 
                                src="https://www.youtube.com/embed/i1IEELWX3yo?si=HuregWESpEZh1Oh-" 
                                title="Project Price Demo Video"
                                allowFullScreen
                            />

                    </VideoContainer>
                    </DemoContainer>
      
                )
            };

    const BackgroundContainer = styled.div`
    background: #f8f8f8;
    min-height: 100vh;
    border-radius: 3vh;
    margin-bottom: 5vh;
    margin-top: 5vh;
    `;

    const BlogPostsContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2vh;
    padding: 4vh;
  
    @media (max-width: 999px) {
      grid-template-columns: repeat(2, 1fr); /* Two columns for medium screens */
    }
  
    @media (max-width: 600px) {
      grid-template-columns: 1fr; /* One column for small screens */
    }

    @media (min-width: 1900px) {
      grid-template-columns: repeat(4, 1fr); /* One column for small screens */
    }
  `;

  const BlogPostContainer = styled.div<{ backgroundImage: string }>`
  position: relative;
  background-image: url(${props => props.backgroundImage});
  background-size: cover;
  background-position: center;
  padding: 6vh 3vh;
  text-align: center;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 250px;
  overflow: hidden;
  color: #fff;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6); /* Adjust the alpha (fourth value) for transparency */
    z-index: 1;
    border-radius: 8px;
  }
`;

const BlogPostContent = styled.div`
  position: relative;
  z-index: 2;
  color: white;
`;

const BlogPostTitle = styled.h2`
  color: #fff;
  margin-bottom: 5vh;
  font-size: 4vh;
  font-weight: bold;

  @media (orientation: portrait) {
    font-size: 100%;
    margin-bottom: 5%;
  }
`;

const BlogPostDescription = styled.p`
  color: #fff;
  font-size: 2.5vh;
  margin-bottom: 6vh;
  flex-grow: 1;

  @media (orientation: portrait) {
    margin-bottom: 10%;
    font-size: 2vh;
  }
`;

const BlogPostLinkContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const BlogPostLink = styled.button`
  background-color: #0056b3;
  color: white;
  font-size: 2vh;
  padding: 1.5vh 2.5vh;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: #007bff;
  }

  @media (orientation: portrait) {
    padding: 5%;
    font-size: 100%;
  }
`;

const Blip = styled.div<{ type: 'blog' | 'guide' | 'more' }>`
  position: absolute;
  top: 0;
  left: 0;
  padding: 5px 10px;
  background-color: ${props => {
    if (props.type === 'guide') return 'green';
    if (props.type === 'more') return 'white';
    return 'blue';
  }};
  color: ${props => (props.type === 'more' ? 'rgba(7,55,99)' : '#ffffff')};
  font-size: 0.8rem;
  font-weight: bold;
  border-radius: 0 0 3px 0;
  text-transform: capitalize; /* Capitalize only the first letter */
  z-index: 2; /* Ensure blip is above the overlay */
`;


const BlogTitle = styled.h1`
text-align: center;
color: white;
font-family: 'Ubuntu', sans-serif; // Added a fallback font
font-size: 6vh;
margin-top: 4vh;
@media (orientation: portrait){
margin-bottom: 2%;
font-size: 2.5vh;
text-align: center;
}
`;



interface BlogPostProps {
    title: string;
    description: string;
    link: string;
    backgroundImage: string;
    buttonText: string;
    type: 'blog' | 'guide' | 'more'; // Add this line
  }

const BlogPost: React.FC<BlogPostProps> = ({ title, description, link, backgroundImage, buttonText, type }) => {
  const navigate = useNavigate();

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    navigate(link);
    window.scrollTo(0, 0);
  };

  return (
    <BlogPostContainer id='guide' backgroundImage={backgroundImage}>
      <Blip type={type}>{type}</Blip>
      <BlogPostContent>
        <BlogPostTitle>{title}</BlogPostTitle>
        <BlogPostDescription>{description}</BlogPostDescription>
        <BlogPostLinkContainer>
          <BlogPostLink onClick={handleClick}>{buttonText}</BlogPostLink>
        </BlogPostLinkContainer>
      </BlogPostContent>
    </BlogPostContainer>
  );
};


const CallToActionSection = styled.div`
  text-align: center;
  padding: 40px 20px;

  color: white;
 
`;

const CallToActionText = styled.h2`
  font-size: 3vh;
  font-weight: bold;
  color: white;
  margin-bottom: 3vh;
  @media (orientation: portrait){
    font-size: 2vh;
    margin-bottm: 3vh;
    }
`;

const CallToActionButton = styled.button`
  background-color: rgba(0, 255, 9, 1);
  color: black;
  border: none;
  border-radius: 5vh;
  padding: 2vh;
  font-weight: bold;
  font-size: 2.5vh;
  cursor: pointer;
  margin-bottom: 0vh;
  transition: transform 0.3s;

  &:hover {
    background-color: rgba(84, 255, 90, 1);
    animation: none;
    transform: scale(1.03);
  }
  @media (orientation: portrait) {
    font-size: 1.8vh;
    padding: 1.3vh;
  }
`;




const HomePage: React.FC = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [isSmallModalVisible, setSmallModalVisible] = useState(false);
  const sectionRef = useRef<HTMLDivElement>(null);

  const navigate = useNavigate();

  const handleEbookClick = () => {
    navigate('/ebook');
    setModalOpen(false); // Close the modal after navigation
  };

  const handleOpenEbookModal = () => {
    setModalOpen(true);
    setSmallModalVisible(false); // Close small modal when the main modal is opened
    localStorage.setItem('hasSeenModal', 'true'); // Store that the user has seen the modal
  };

  const handleCloseSmallModal = () => {
    setSmallModalVisible(false);
    localStorage.setItem('hasSeenModal', 'true'); // Store that the user has dismissed the modal
  };

  useEffect(() => {
    const hasSeenModal = localStorage.getItem('hasSeenModal');

    if (!hasSeenModal) {
      const handleScroll = () => {
        if (sectionRef.current) {
          const sectionElement = sectionRef.current;
          const rect = sectionElement.getBoundingClientRect();
          const isVisible = rect.top <= window.innerHeight && rect.bottom >= 0;

          if (isVisible) {
            setSmallModalVisible(true);
          }
        }
      };

      window.addEventListener('scroll', handleScroll);
      return () => window.removeEventListener('scroll', handleScroll);
    }
  }, []);

  const handleCreateAccount = () => {
    navigate('/loginpage?signup=true');
  };
    return (
        <Container>
            <NavBar />
            <IntroSection />
            <SVGWave />
            <SecondContainer>
          <Showcase />
          <CallToActionSection>
                <CallToActionText>Are you ready to save hours on quoting?</CallToActionText>
                <CallToActionButton onClick={handleCreateAccount}>Sign up now! Free For 7 Days ➜</CallToActionButton>
              </CallToActionSection>
                <SavingsCalculator />
                <Demo />
                <FAQ />
                <DeviceUsage />
                <AreaCalc />
                <StyledImageWrapper ref={sectionRef}>
                  <StyledImage src="/projects.png" alt="Project Display" />
                </StyledImageWrapper>
                <Testimonials />
                <CallToActionSection>
                <CallToActionText>Are you ready to save hours on quoting?</CallToActionText>
                <CallToActionButton onClick={handleCreateAccount}>Sign up now! Free For 7 Days ➜</CallToActionButton>
              </CallToActionSection>
                <BlogTitle>Every helpful detail is in our Library!</BlogTitle>
                <BackgroundContainer>
                <BlogPostsContainer>
                <BlogPost
                  title='Exporting Project Price designs into SketchUp'
                  description='View this step by step guide on how you are able to upload your designs directly into SketchUp'
                  link='/sketchuppage'
                  backgroundImage='/3dgarden.png'
                  buttonText='Read more'
                  type='guide'
                />
                    <BlogPost
                        title='Our complete set up demo'
                        description='See our complete guide in how to get your account set up and how to send quotes in our guide video.'
                        link='/fulldemo'
                        backgroundImage='/laptopscreen.png'
                        buttonText='View video'
                        type='guide'
                        />
                        <BlogPost
                        title='Check out our free to use Area Calculator'
                        description='See our unmatched, FREE Area Calculator in action! Find out the area of your irregular shapes in seconds.'
                        link='/areacalculatordemo'
                        backgroundImage='/papercalc.png'
                        buttonText='View video'
                        type='guide'
                        />
                        <BlogPost
                    title='Watch how our automatic quoting tool works'
                    description='Get professional automated quotes for your customers at a touch of a button.'
                    link='/quotedemo'
                    backgroundImage='/clipboardpaper.png'
                    buttonText='View video'
                    type='guide'
                    />
                    <BlogPost
                    title='View our demo'
                    description='View our quick demo video to find out how easy Project Price is to use.'
                    link='/demo'
                    backgroundImage='/laptoptable.png'
                    buttonText='View video'
                    type='guide'
                    />
                    <BlogPost
                    title='Check out our quote builder'
                    description='View our 1 of a kind custom quote builder.'
                    link='/quoter'
                    backgroundImage='/laptop.png'
                    buttonText='Read more'
                    type='guide'
                    />
                                        
      </BlogPostsContainer>    
      
      </BackgroundContainer>  
     
          <BackgroundContainer>
            <BlogPostsContainer>
                    <BlogPost
                    title='Building a Social media presence for your business'
                    description='Find out ways to give your business maximum exposure on social media.'
                    link='/blogsm'
                    backgroundImage='/laptopgarden.png'
                    buttonText='Read more'
                    type='blog'
                    />
                    <BlogPost
                    title='Small things, big difference!'
                    description='Find out why it is important to keep to small details in mind when transforming a Project.'
                    link='/blogdetails'
                    backgroundImage='/garden.png'
                    buttonText='Read more'
                    type='blog'
                />
                <BlogPost
                    title='Why us?'
                    description='Take a look at why landscaping professionals are choosing to use our pricing app.'
                    link='/aboutus'
                    backgroundImage='/questionmark.png'
                    buttonText='Read more'
                    type='blog'
                />
                <BlogPost
                    title='The importance of accurate pricing'
                    description='Discover valuable insights of why it is crucial to deliver accurate pricing when quoting.'
                    link='/blogquotes'
                    backgroundImage='/barchart.png'
                    buttonText='Read more'
                    type='blog'
                />
                <BlogPost
                    title='Growing your business'
                    description='View our helpful guide on how to gain quality leads for your landscaping business.'
                    link='/blogleads'
                    backgroundImage='/wateringcan.png'
                    buttonText='Read more'
                    type='blog'
                />
                <BlogPost
                    title='View all Project Price posts and walkthroughs'
                    description='A full library of Project Price guides and blogs'
                    link='/allblogposts'
                    backgroundImage='/projectpricethumbnail.png'
                    buttonText='Read more'
                    type='more'
                    />
            </BlogPostsContainer>
          </BackgroundContainer>
            </SecondContainer>
      <SmallModal isOpen={isSmallModalVisible} onClick={handleOpenEbookModal} onClose={handleCloseSmallModal}  />
      <EbookModal isOpen={isModalOpen} onClose={() => setModalOpen(false)} onEbookClick={handleEbookClick} />

            <Footer />
        </Container>
    );
}


export default HomePage;
