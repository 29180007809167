import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import Footer from '../components/Footer';
import NavBar from '../components/PublicNav/homenav';

const BackgroundContainer = styled.div`
  background: #f8f8f8;
`;

const BlogContainer = styled.div`
  margin: 0 auto;
  padding: 20px;
`;

const IntroParagraph = styled.p`
  font-size: 1.6rem;
  font-weight: bold;
  font-style: italic;
  font-family: 'Ubuntu';
  line-height: 1.6;
  max-width: 100%;
  padding-top: 10vh;
  margin-left: auto;
  margin-right: auto;
  color: #333;
  margin-bottom: 30px; // Space between the paragraph and the first image
  text-align: center;
`;

const VideoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  padding-bottom: 10vh;
`;

const StyledIframe = styled.iframe`
  width: 60vw;      // Responsive width
  height: 30vw; // Responsive height, maintaining a 16:9 aspect ratio
  max-width: 80%; // Maximum size
  max-height: 80%;
  border: none;
`;

const OtherVideosContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 20px;
`;

const VideoThumbnail = styled.div`
  width: 150px;
  margin: 10px;
  cursor: pointer;
  

  img {
    width: 100%;
    border-radius: 10px;
  }

  p {
    text-align: center;
    margin-top: 5px;
    font-size: 14px;
    color: #333;
  }
`;

const PricingDemo: React.FC = () => {

    const navigate = useNavigate();
    
  const handleDemoClick = () => {
    navigate("/fulldemo");
  };

  const handleQuoteClick = () => {
    navigate("/quotedemo");
  };

  const handleAreaClick = () => {
    navigate("/areacalculatordemo");
  };

  return (
    <BackgroundContainer>
      <NavBar />
      <BlogContainer>
        <IntroParagraph>
          Find out how to Price a landscaping project
        </IntroParagraph>
        <VideoContainer>
        <StyledIframe 
            src="https://www.youtube.com/embed/g1wqa2KmhNs?modestbranding=1&autohide=1&showinfo=0" 
            title="Using Project Price to price a landscaping project"
            allowFullScreen
            />
        </VideoContainer>
        <OtherVideosContainer>
        <VideoThumbnail onClick={handleDemoClick}>
            <img src="/laptopscreen.png" alt="Video 1" />
            <p>View the full set up guide</p>
          </VideoThumbnail>
          <VideoThumbnail onClick={handleQuoteClick}>
            <img src="/clipboardpaper.png" alt="Video 2" />
            <p>Check out our quoting tool</p>
          </VideoThumbnail>
          <VideoThumbnail onClick={handleAreaClick}>
            <img src="/papercalc.png" alt="Video 2" />
            <p>More on our area calculator</p>
          </VideoThumbnail>
        </OtherVideosContainer>
      </BlogContainer>
      <Footer />
    </BackgroundContainer>
  );
};

export default PricingDemo;
