import React, { useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import styled from 'styled-components';
import NavBar from '../components/PublicNav/homenav';
import Footer from '../components/Footer';

const Container = styled.div`
    background-color: rgba(7, 55, 99, 1);
    width: 100%;
    color: white;

`;

const ItemContainer = styled.div`
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5vh;
    background-color: rgba(7, 55, 99, 1);
    color: white;
    width: 80%;
    min-height: 100vh; /* Ensure it covers the full viewport height */
`;

const Heading = styled.h2`
    font-size: 4vh;
    margin-bottom: 2vh;
`;

const Paragraph = styled.p`
    font-size: 2.8vh;
    margin-bottom: 30px;
`;

const Button = styled.button<{ bgColor: string }>`
    padding: 10px 20px;
    background-color: ${({ bgColor }) => bgColor};
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin: 10px;
    font-size: 16px;
    
    &:hover {
        opacity: 0.9;
    }
`;

const UnsubscribeButton = styled.button`
    padding: 8px 16px;
    background: none;
    color: white;
    border: none;
    font-size: 14px;
    text-decoration: underline;
    cursor: pointer;
    margin-top: 10px;
    
    &:hover {
        opacity: 0.7;
    }
`;

const ErrorText = styled.p`
    color: red;
    margin-top: 20px;
`;

const SuccessText = styled.p`
    color: green;
    margin-top: 20px;
`;

const EbookImage = styled.img`
    width: 20vh;
    margin: 20px 0;
`;

const Unsubscribe: React.FC = () => {
    const [isUnsubscribed, setIsUnsubscribed] = useState(false);
    const [successMessage, setSuccessMessage] = useState<string | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const userId = searchParams.get('userId');

    const handleUnsubscribe = async () => {
        if (!userId) {
            setError('User ID is missing.');
            return;
        }

        try {
            const response = await axios.post('https://projectprice-ad06ee250897.herokuapp.com/auth/unsubscribe', { userId });

            if (response.status === 200) {
                setIsUnsubscribed(true);
                setSuccessMessage('You have been unsubscribed. Subscribe back at any time in your profile on the homepage.');
                setTimeout(() => {
                    navigate('/features');
                }, 4000); // Redirect after 4 seconds
            } else {
                setError('There was an issue with your request. Please try again later.');
            }
        } catch (error) {
            console.error('Error unsubscribing:', error);
            setError('There was an issue with your request. Please try again later.');
        }
    };

    const handleStaySubscribed = () => {
        navigate('/'); // Navigate to the homepage or another preferred page
    };

    const handleEbookClick = () => {
        navigate('/ebook'); // Navigate to the eBook page
    };

    return (
        <Container>
            <NavBar />
            <ItemContainer>
                {!isUnsubscribed ? (
                    <>
                        <Heading>Are you sure you want to unsubscribe?</Heading>
                        <Paragraph>
                            We try to keep emails to a minimum and only send you <strong>important information</strong> about Project Price and <strong>promotional offers</strong> to help keep your Landscaping business performing at its best.
                        </Paragraph>
                        <Paragraph>
                            Why not take a look at our carefully crafted eBook, designed to help Landscaping business owners earn more out of their business.
                        </Paragraph>
                        <Button
                            bgColor="#28a745"
                            style={{ textDecoration: 'none', display: 'inline-block' }}
                            onClick={handleEbookClick}
                        >
                            View eBook
                        </Button>
                        <EbookImage src="/ebookcover.png" alt="eBook" />
                        <Button bgColor="#007BFF" onClick={handleStaySubscribed}>
                            Keep me subscribed
                        </Button>
                        <UnsubscribeButton onClick={handleUnsubscribe}>
                            Yes, Unsubscribe Me
                        </UnsubscribeButton>
                        {error && <ErrorText>{error}</ErrorText>}
                    </>
                ) : (
                    <>
                        <Heading>Unsubscribed</Heading>
                        {successMessage && <SuccessText>{successMessage}</SuccessText>}
                    </>
                )}
            </ItemContainer>
            <Footer />
        </Container>
    );
};

export default Unsubscribe;
