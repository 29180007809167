import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { FaInfoCircle, FaTimesCircle, FaChevronDown } from 'react-icons/fa';

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: fit-content;
  height: 100%;
 
  @media (max-height: 350px) {
    margin-top: 0%;
  }
`;

const BackgroundContainer = styled.div`
  background-color: white;
  width: fit-content;
  height: auto;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  @media (max-height: 350px) {
    width: 65%;
  }
`;

const ResultTable = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const ResultTableRow = styled.tr`
  &:nth-child(even) {
    background-color: rgba(170,179,184)
  }
  border: 0.1vh solid black;
  font-family: 'barlow';
  font-weight: 500;
  @media (max-height: 350px) {
    font-size: 70%;
  }
  @media (min-width: 1900px) {
    font-size: 1.8vh;
  }
`;

const ResultTableData = styled.td`
  padding: 0.3rem;
  font-size: 0.8rem;
  max-width: 500px;
  min-width: 300px;
  @media (max-height: 350px) {
    width: 150px;
    font-size: 90%;
  }
  @media (min-width: 1900px) {
    width: 50vh;
    font-size: 1.8vh;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
`;

const Button = styled.button`
  display: flex;
  background-color: white;
  width: fit-content;
  font-size: 0.9rem;
  color: black;
  border-radius: 3px;
  border: none;
  margin-left: 10px;
  @media (max-height: 350px) {
    font-size: 80%;
    width: fit-content;
  }
  @media (min-width: 1900px) {
    font-size: 1.8vh;
  }
`;

const MaterialRow = styled(ResultTableRow)`
  display: flex;
  align-items: center;
`;

const MaterialLabel = styled(ResultTableData)`
  margin-right: 10px;
  @media (max-height: 350px) {
    margin-right: 0%;
  }
`;

const MaterialInput = styled.input`
  width: 30px;
  background-color: rgba(0, 0, 0, 0.3);
  border: none;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const UserInput = styled.input`
  width: 16vh;
  background-color: rgba(0, 0, 0, 0.3);
  border: none;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;


const MaterialUnit = styled.span`
  margin-left: 5px;
`;

const Tooltip = styled.div`
  position: absolute;
  background-color: rgba(0,0,0,0.6);
  color: white;
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  z-index: 100;
`;

const Modal = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  width: 400px;
  border-radius: 8px;
`;

const WhiteText = styled.p`
  color: white;
  margin-top: 2vh;
  font-size: 2.5vh;
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
`;

const SuccessMessage = styled.div`
  position: fixed; 
  bottom: 0vh;
  left: 50%;
  transform: translateX(-50%); 
  color: rgba(0, 255, 9, 1); 
  padding: 10px 20px;
  zIndex: 1000; // Ensure it appears above other elements
  
`;

export interface FencingMaterial {
  name: string;
  width: number;
  materials: { materialName: string; quantity: number }[];
  postMaterial: string; 
}

export interface SettingsData {
  [key: string]: number | string | FencingMaterial[];
  'Paving sand / cement ratio': number;
  'Building sand / cement ratio': number;
  'Ballast / cement ratio': number;
  'Edging width': number;
  'Edging length': number;
  'Paving sub-base depth': number;
  'Paving bed depth': number;
  'Block paving sub-base depth': number;
  'Screed depth': number;
  'Pointing coverage': number;
  'Primer coverage': number;
  'Tile adhesive coverage': number;
  'Footing depth': number;
  'Footing width': number;
  'Sleeper length': number;
  'Postcrete per post': number;
  'Fence bay length': number;
  'Decking board length': number;
  'Decking board width': number;
  'Decking frame timber length': number;
  'Decking gap between joists': number;
  'Fascia board height': number;
  'Fascia board length': number;
  'Concrete pad depth': number;
  'Post length': number;
  'Curb height': number;
  'Curb length': number;
  'Gravel depth': number;
  'Bark depth': number;
  'Asphalt depth': number;
  'Asphalt sub-base depth': number;
  fencingMaterials: FencingMaterial[];
}

interface SettingsProps {
  backgroundColor: string;
}

const SettingsUnitMapping: { [key: string]: string | string[] } = {
  'Paving sand / cement ratio': ': 1',
  'Building sand / cement ratio': ': 1',
  'Ballast / cement ratio': ': 1',
  'Edging width': 'mm',
  'Edging length': "mm",
  'Paving sub-base depth': 'mm',
  'Paving bed depth': 'mm',
  'Block paving sub-base depth': 'mm',
  'Screed depth': 'mm',
  'Pointing coverage': 'm²',
  'Primer coverage': 'm²',
  'Tile adhesive coverage': 'm²',
  'Footing depth': 'mm',
  'Footing width': 'mm',
  'Sleeper length': 'm',
  'Postcrete per post': 'bags',
  'Fence bay length': 'm',
  'Decking board length': 'm',
  'Decking board width': 'mm',
  'Decking frame timber length': 'm',
  'Decking gap between joists': 'm',
  'Fascia board height': 'mm',
  'Fascia board length': 'm',
  'Concrete pad depth': 'mm',
  'Post length': 'm',
  'Curb height': 'mm',
  'Curb length': 'mm',
  'Gravel depth': 'mm',
  'Bark depth': 'mm',
  'Asphalt depth': 'mm',
  'Asphalt sub-base depth': 'mm',
};

const SettingsInfoMapping: { [key: string]: { type: 'image' | 'text', content: string } } = {
  'Paving sand / cement ratio': { type: 'text', content: 'Paving mortar sand / cement ratio' },
  'Building sand / cement ratio': { type: 'text', content: 'Building mortar sand / cement ratio' },
  'Ballast / cement ratio': { type: 'text', content: 'Concrete mortar sand / cement ratio' },
  'Edging width': { type: 'text', content: 'The smallest side of the edging material in mm.' },
  'Edging length': { type: 'text', content: 'The largest side of the edging material in mm.' },
  'Paving sub-base depth': { type: 'text', content: 'Depth of the hardcore sub-base layer for paving, measured in mm.' },
  'Paving bed depth': { type: 'text', content: 'Depth of the mortar bed layer beneath paving, measured in mm.' },
  'Block paving sub-base depth': { type: 'text', content: 'Depth of the sub-base layer specifically for block paving, in mm.' },
  'Screed depth': { type: 'text', content: 'Depth of the screed layer, used in block paving and artificial turf, measured in mm.' },
  'Pointing coverage': { type: 'text', content: 'Coverage area for pointing in square meters per unit.' },
  'Primer coverage': { type: 'text', content: 'Area that can be covered by a unit of primer, measured in square meters.' },
  'Tile adhesive coverage': { type: 'text', content: 'Coverage area for tile adhesive, used in cladding, measured in square meters per unit.' },
  'Footing depth': { type: 'text', content: 'Depth of the walling footing, measured in mm.' },
  'Footing width': { type: 'text', content: 'Width of the walling footing in a construction, measured in mm.' },
  'Sleeper length': { type: 'text', content: 'Length of sleepers used in construction, measured in meters.' },
  'Postcrete per post': { type: 'text', content: 'Amount of Postcrete used per post, measured in bags.' },
  'Fence bay length': { type: 'text', content: 'Length of a single bay in a fence line, between posts, measured in meters.' },
  'Decking board length': { type: 'text', content: 'Length of individual decking boards, measured in meters.' },
  'Decking board width': { type: 'text', content: 'Width of individual decking boards, measured in mm.' },
  'Decking frame timber length': { type: 'text', content: 'Length of timber used in decking frames, measured in meters.' },
  'Decking gap between joists': { type: 'text', content: 'Gap between each joist used in decking frames.' },
  'Fascia board height': { type: 'text', content: 'Width of fascia boards, used in decking risers, measured in mm.' },
  'Fascia board length': { type: 'text', content: 'Length of fascia boards, measured in meters.' },
  'Concrete pad depth': { type: 'text', content: 'Depth of concrete pads, measured in mm.' },
  'Post length': { type: 'text', content: 'Length of posts used in raised decking construction and in fencing, measured in meters.' },
  'Curb height': { type: 'text', content: 'Height of curbs, measured in mm. Eg. Pin curb/ Key curb' },
  'Curb length': { type: 'text', content: 'Length of individual curb, measured in mm.  Eg. Pin curb/ Key curb' },
  'Gravel depth': { type: 'text', content: 'Depth of gravel layers, measured in mm.' },
  'Bark depth': { type: 'text', content: 'Depth for laying bark, measured in mm.' },
  'Asphalt depth': { type: 'text', content: 'Depth for asphalt(Tarmac), measured in mm.' },
  'Asphalt sub-base depth': { type: 'text', content: 'Depth for asphalt(Tarmac) hardcore, measured in mm.' },
};

const defaultSettings: SettingsData = {
  'Paving sand / cement ratio': 5,
  'Building sand / cement ratio': 4,
  'Ballast / cement ratio': 5,
  'Edging width': 100,
  'Edging length': 200,
  'Paving sub-base depth': 100,
  'Paving bed depth': 40,
  'Block paving sub-base depth': 125,
  'Screed depth': 20,
  'Pointing coverage': 12,
  'Primer coverage': 15,
  'Tile adhesive coverage': 5,
  'Footing depth': 300,
  'Footing width': 300,
  'Sleeper length': 2.8,
  'Postcrete per post': 2,
  'Fence bay length': 1.83,
  'Decking board length': 2.4,
  'Decking board width': 120,
  'Decking frame timber length': 2.4,
  'Decking gap between joists': 0.4,
  'Fascia board height': 100,
  'Fascia board length': 2.4,
  'Concrete pad depth': 100,
  'Post length': 2.4,
  'Curb height': 200,
  'Curb length': 120,
  'Gravel depth': 40,
  'Bark depth': 40,
  'Asphalt depth': 70,
  'Asphalt sub-base depth': 125,
  fencingMaterials: [], // Initialize with an empty array
};

const Settings: React.FC<SettingsProps> = ({ backgroundColor }) => {
  const [, setLoadingData] = useState(false);
  const [settingsList, setSettingsList] = useState<SettingsData>(defaultSettings);
  const [fetchedSettingsList, setFetchedSettingsList] = useState<SettingsData>(defaultSettings);
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });
  const [activeSetting, setActiveSetting] = useState<string | null>(null);
  const [fencingMaterials, setFencingMaterials] = useState<FencingMaterial[]>([]);
  const [fencingMaterialName, setFencingMaterialName] = useState('');
  const [fencingMaterialWidth, setFencingMaterialWidth] = useState<number | ''>('');
  const [fencingMaterialComponents, setFencingMaterialComponents] = useState<{ materialName: string; quantity: number }[]>([{ materialName: '', quantity: 0 }]);
  const [postMaterial, setPostMaterial] = useState('');  // New state for post material
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showLimitMessage, setShowLimitMessage] = useState(false);


  const isBackgroundWhite = backgroundColor === 'rgba(245, 245, 245)' || backgroundColor === 'white';

  const handleIconClick = (setting: string, event: React.MouseEvent<SVGElement, MouseEvent>) => {
    const iconPos = event.currentTarget.getBoundingClientRect();
    setTooltipPosition({
      top: iconPos.top + window.scrollY,
      left: iconPos.left + window.scrollX + iconPos.width // Positioning it to the right of the icon
    });

    if (activeSetting === setting) {
      setTooltipVisible(false);
      setActiveSetting(null);
    } else {
      setActiveSetting(setting);
      setTooltipVisible(true);
    }
  };

  const fetchSettingsData = async () => {
    try {
        setLoadingData(true);
        const response = await axios.get('https://projectprice-ad06ee250897.herokuapp.com/settingscontroller/settingscontroller');
        const loadedData: SettingsData = response.data.data || defaultSettings;

        setSettingsList(loadedData);
        setFetchedSettingsList(loadedData);
        setFencingMaterials(loadedData.fencingMaterials || []);

        // Check if the data is still default and hasn't been saved for new users
        if (Object.keys(response.data.data).length === 0) {
            await saveSettings(loadedData); // Automatically save the default settings
        }

    } catch (error) {
        if (axios.isAxiosError(error) && error.response?.status === 404) {
            setSettingsList(defaultSettings);
            setFetchedSettingsList(defaultSettings);
            setFencingMaterials([]);

            // Automatically save the default settings for new users
            await saveSettings(defaultSettings);
        } else {
            console.error("Error fetching settings data:", error);
        }
    } finally {
        setLoadingData(false);
    }
};
const saveSettings = async (dataToSave: SettingsData) => {
  try {
      await axios.post('https://projectprice-ad06ee250897.herokuapp.com/settingscontroller/settingscontroller', dataToSave);
      setFetchedSettingsList(dataToSave);
  } catch (error) {
      console.error("Error saving default settings:", error);
  }
};
  

  useEffect(() => {
    fetchSettingsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePost = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
  
    try {
      const dataToSave: SettingsData = {
        ...settingsList,
        fencingMaterials: fencingMaterials,
      };
      if (Object.keys(fetchedSettingsList).length === 0) {
        await axios.post(
          'https://projectprice-ad06ee250897.herokuapp.com/settingscontroller/settingscontroller',
          dataToSave,
        );
        setFetchedSettingsList(dataToSave);
      } else {
        const updatedData: Partial<SettingsData> = {};
  
        for (const key in dataToSave) {
          if (dataToSave[key as keyof SettingsData] !== fetchedSettingsList[key as keyof SettingsData]) {
            updatedData[key as keyof SettingsData] = dataToSave[key as keyof SettingsData];
          }
        }
  
        const cleanedUpdatedData = Object.keys(updatedData).reduce((acc, key) => {
          const value = updatedData[key as keyof SettingsData];
          if (value !== undefined) {
            acc[key as keyof SettingsData] = value;
          }
          return acc;
        }, {} as Partial<SettingsData>);
  
        if (Object.keys(cleanedUpdatedData).length > 0) {
          await axios.put(
            'https://projectprice-ad06ee250897.herokuapp.com/settingscontroller/settingscontroller',
            cleanedUpdatedData
          );
          setFetchedSettingsList((prevSettingsList) => {
            const result: SettingsData = { ...prevSettingsList };
  
            Object.keys(cleanedUpdatedData).forEach(key => {
              const value = cleanedUpdatedData[key as keyof SettingsData];
              if (value !== undefined) {
                result[key as keyof SettingsData] = value;
              }
            });
  
            return result;
          });
        } else {
        }
      }
    } catch (error: any) {
      console.error("Error submitting form:", error.response?.data);
    }
  
    setShowSuccessMessage(true);
        setTimeout(() => {
          setShowSuccessMessage(false);
        }, 3000); 
  };
  
  const handleSettingsChange = (material: keyof SettingsData, price: number) => {
    setSettingsList((prevSettingsList) => ({
      ...prevSettingsList,
      [material]: price,
    }));
  };

  const handleAddFencingMaterial = () => {
    if (fencingMaterials.length >= 5) {
      setShowLimitMessage(true);
      setTimeout(() => {
        setShowLimitMessage(false);
      }, 3000); // Hide the message after 3 seconds
      return;
    }
  
    if (
      !fencingMaterialName ||
      fencingMaterialWidth === '' ||
      fencingMaterialComponents.some((component) => !component.materialName || component.quantity === 0)
    ) {
      alert('Please fill in all fields.');
      return;
    }
  
    const newFencingMaterial: FencingMaterial = {
      name: fencingMaterialName,
      width: fencingMaterialWidth as number,
      materials: fencingMaterialComponents.map((component) => ({
        materialName: component.materialName,
        quantity: component.quantity,
      })),
      postMaterial: postMaterial // Add post material
    };
  
    setFencingMaterials((prevMaterials) => [...prevMaterials, newFencingMaterial]);
    setFencingMaterialName('');
    setFencingMaterialWidth('');
    setFencingMaterialComponents([{ materialName: '', quantity: 0 }]);
    setPostMaterial(''); // Reset post material field
    setIsModalOpen(false);
  };
  

  const handleRemoveFencingMaterial = async (index: number) => {
    const materialToRemove = fencingMaterials[index];
  
    // Collect all materials associated with the fencing material to be removed
    const removedMaterials = [
      ...materialToRemove.materials.map((m) => m.materialName),
      materialToRemove.postMaterial
    ];
  
    try {
      // Make an API call to remove these materials from the price list
      await axios.post('https://projectprice-ad06ee250897.herokuapp.com/pricelist/remove-materials', { removedMaterials });
  
      // If successful, remove the fencing material from the state
      setFencingMaterials((prevMaterials) => prevMaterials.filter((_, i) => i !== index));
  
    } catch (error) {
      console.error('Error removing materials:', error);
    }
  };
  


  const handleAddMaterialField = () => {
    setFencingMaterialComponents([...fencingMaterialComponents, { materialName: '', quantity: 0 }]);
  };

  const handleMaterialChange = (
    index: number,
    value: string | number,
    field: 'materialName' | 'quantity'
  ) => {
    const updatedMaterials = [...fencingMaterialComponents];
    updatedMaterials[index] = {
      ...updatedMaterials[index],
      [field]: value,
    };
    setFencingMaterialComponents(updatedMaterials);
  };

  const handleRemoveMaterialField = (index: number) => {
    const updatedMaterials = fencingMaterialComponents.filter((_, i) => i !== index);
    setFencingMaterialComponents(updatedMaterials);
  };

  const settings = Object.keys(settingsList);
  const hiddenFields = ["__v", "_id", "userId"];
  const uniquePostMaterials = Array.from(new Set(fencingMaterials.map(material => material.postMaterial).filter(Boolean)));

  return (
    <MainContainer>
      <BackgroundContainer>
        <ResultTable>
          <tbody>
            {settings
              .filter((setting) => setting !== 'fencingMaterials') // Exclude 'fencingMaterials'
              .map((setting) => (
                !hiddenFields.includes(setting) && (
                  <MaterialRow key={setting}>
                    <MaterialLabel>{setting}</MaterialLabel>
                    <ResultTableData>
                      <MaterialInput
                        type="number"
                        value={settingsList[setting] as string | number}
                        onChange={(e) =>
                          handleSettingsChange(setting, Number(e.target.value))
                        }
                      />
                      <MaterialUnit>{SettingsUnitMapping[setting]}</MaterialUnit>
                    </ResultTableData>
                    <FaInfoCircle
                      onClick={(event) => handleIconClick(setting, event)}
                      style={{ cursor: 'pointer', marginLeft: '10px' }}
                    />
                  </MaterialRow>
                )
              ))}
            {fencingMaterials.map((fencingMaterial, index) => (
              <MaterialRow key={index}>
                <MaterialLabel>{fencingMaterial.name}</MaterialLabel>
                <ResultTableData>{fencingMaterial.width} m</ResultTableData>
                <FaTimesCircle
                  onClick={() => handleRemoveFencingMaterial(index)}
                  style={{ cursor: 'pointer', marginLeft: '10px', color: 'red' }}
                />
              </MaterialRow>
            ))}
          </tbody>
        </ResultTable>
      </BackgroundContainer>
      <WhiteText style={{ color: isBackgroundWhite ? 'black' : 'white' }}> Each Project will remember what settings are set on creation. Unless the 'Update Settings' button is clicked, settings for the project will not change.</WhiteText>
      <ButtonContainer>
      <Button
  style={{ color: isBackgroundWhite ? 'white' : 'black', backgroundColor: isBackgroundWhite ? 'grey' : 'white', marginLeft: '10px' }}
  onClick={() => {
    if (fencingMaterials.length >= 5) {
      setShowLimitMessage(true);
      setTimeout(() => {
        setShowLimitMessage(false);
      }, 3000); // Hide the message after 3 seconds
    } else {
      setIsModalOpen(true);
    }
  }}
>
  Add Fencing Material
</Button>


        <Button style={{ color: isBackgroundWhite ? 'white' : 'black', backgroundColor: isBackgroundWhite ? 'grey' : 'white' }} onClick={(e) => handlePost(e)}>Save Settings</Button>
      </ButtonContainer>
      {showSuccessMessage && (
  <SuccessMessage>Settings saved successfully!</SuccessMessage>
)}
{showLimitMessage && (
  <SuccessMessage>Maximum of 5. Please remove a bay setting to add another.</SuccessMessage>
)}

      {tooltipVisible && activeSetting && (
        <Tooltip style={{ top: `${tooltipPosition.top}px`, left: `${tooltipPosition.left}px` }}>
          {SettingsInfoMapping[activeSetting].content}
        </Tooltip>
      )}

      <BackgroundContainer>
        {isModalOpen && (
          <>
            <Overlay onClick={() => setIsModalOpen(false)} />
            <Modal>
              <h3>Materials Per Bay</h3>
              <div>
                <MaterialLabel>Fence Bay Name:</MaterialLabel>
                <UserInput
                  type="text"
                  value={fencingMaterialName}
                  onChange={(e) => setFencingMaterialName(e.target.value)}
                />
              </div>
              <div>
                <MaterialLabel>Fence Bay Width (m):</MaterialLabel>
                <MaterialInput
                  type="number"
                  value={fencingMaterialWidth}
                  onChange={(e) => setFencingMaterialWidth(Number(e.target.value))}
                />
              </div>
              <div style={{ position: 'relative' }}>
  <MaterialLabel>Select or Add Post Material:</MaterialLabel>
  
  <div style={{ display: 'flex', alignItems: 'center' }}>
    <UserInput
      type="text"
      value={postMaterial}
      onChange={(e) => {
        const value = e.target.value;
        setPostMaterial(value);

        if (value.length >= 3) {
          setShowDropdown(true);
        } else {
          setShowDropdown(false);
        }
      }}
      placeholder="Post Material"
      style={{ marginTop: '10px', width: 'calc(100% - 30px)' }}
    />
    
    <FaChevronDown
      style={{ cursor: 'pointer', marginLeft: '5px' }}
      onClick={() => setShowDropdown(!showDropdown)}
    />
  </div>

  {showDropdown && (
    <div style={{
      position: 'absolute',
      top: '100%',
      left: 0,
      right: 0,
      backgroundColor: 'white',
      border: '1px solid #ccc',
      borderRadius: '4px',
      maxHeight: '150px',
      overflowY: 'auto',
      zIndex: 1000,
    }}>
      {uniquePostMaterials
        .filter(material => material.toLowerCase().includes(postMaterial.toLowerCase()))
        .map((material, index) => (
          <div
            key={index}
            style={{
              padding: '8px',
              cursor: 'pointer',
              borderBottom: '1px solid #ccc',
            }}
            onClick={() => {
              setPostMaterial(material);
              setShowDropdown(false);
            }}
          >
            {material}
          </div>
        ))}
    </div>
  )}
</div>

              <div>
                <MaterialLabel>Materials / Quantity:</MaterialLabel>
                {fencingMaterialComponents.map((material, index) => (
                  <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                    <UserInput
                      type="text"
                      value={material.materialName}
                      onChange={(e) => handleMaterialChange(index, e.target.value, 'materialName')}
                      placeholder={`Material ${index + 1}`}
                    />
                    <UserInput
                      type="number"
                      value={material.quantity}
                      onChange={(e) => handleMaterialChange(index, Number(e.target.value), 'quantity')}
                      placeholder={`Quantity`}
                      style={{ marginLeft: '10px', width: '60px' }}
                      onFocus={(e) => e.target.select()} 
                    />
                    <Button onClick={() => handleRemoveMaterialField(index)} style={{ marginLeft: '10px' }}>Remove</Button>
                  </div>
                ))}
                <Button onClick={handleAddMaterialField}>Add Material</Button>
              </div>
             
              <Button onClick={handleAddFencingMaterial}>Save</Button>
            </Modal>
          </>
        )}
       
      </BackgroundContainer>
    </MainContainer>
  );
};

export default Settings;