/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import axios from 'axios';

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  margin-top: -6vh;
  @media (max-height: 350px){
    margin-top: 0%;
    width: 100%;
    height: 100%;
  }
`;

const QuoteContainer = styled.div`
  overflow-y: auto;
  background-color: rgba(255, 255, 255);  // This is slightly transparent black
  color: black;  
  padding: 20px;  // Add some padding so the text isn't stuck to the edges
  border-radius: 7px;  // Optional, but gives the container rounded corners
  margin-top: 20px;  // Spacing between the dropdown and the quote
  max-width: 60%;  // Optional, to limit the width of the quote
  height: 80%;
  @media (max-height: 350px){
    padding: 10px;
    max-width: 90%;
    font-size: 80%;
    height: auto;
    margin-top: 2vh;
  }
`;

const TopContainer = styled.div`
  display: flex;
  justify-content: center; // This will center the dropdown horizontally
  padding-top: 5%; // Some spacing from the top
  width: 100%;
  @media (max-width) {
    padding-top: 0%;
  }
`;
const ButtonContainer = styled.div`
  display: flex;
  bottom: 10px;         // Push it up 10px from the bottom
  margin-left: 0px;          // Push it 10px from the right
  margin-top: 20px;
  @media (max-height: 350px){
    right: 0%;
  }
`;

const Button = styled.button`
  display: flex;
  right: 10px;
  background-color: white;
  width: 90px;
  font-size: 0.9rem;
  color: black;
  border-radius: 3px;
  border: none;
`;

const SuccessMessage = styled.div`
  position: fixed; 
  bottom: 0vh;
  left: 50%;
  transform: translateX(-50%); 
  color: rgba(0, 255, 9, 1); 
  padding: 10px 20px;
  zIndex: 1000; // Ensure it appears above other elements
  
`;


interface Project {
    _id: string;
    userId: string;
    name: string;
    projectName: string;

    [key: string]: any;
  }

  interface ProjectQuoteProps {
    backgroundColor: string;
  }
  

  const ProjectQuote: React.FC<ProjectQuoteProps> = ({ backgroundColor }) => {
    const [projects, setProjects] = useState<Project[]>([]);
    const [loadingData, setLoadingData] = useState(false);
    const [selectedProjectId, setSelectedProjectId] = useState<string | null>(null);
    const [selectedProject, setSelectedProject] = useState<Project | null>();
    const [inputValues, setInputValues] = useState<{ [projectId: string]: { [phaseName: string]: number | undefined } }>({});
    const quoteRef = useRef(null);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);

    const isBackgroundWhite = backgroundColor === 'rgba(245, 245, 245)' || backgroundColor === 'white';

    const handleCopy = () => {
      const range = document.createRange();
      
      if (quoteRef.current) {
          range.selectNodeContents(quoteRef.current);
          const selection = window.getSelection();
  
          if (selection) {
              selection.removeAllRanges();
              selection.addRange(range);
              document.execCommand('copy');
              selection.removeAllRanges();
  
              setShowSuccessMessage(true);
        setTimeout(() => {
          setShowSuccessMessage(false);
        }, 3000); 
          }
      }
  };

  
    


  const loadSelectedProjectData = async (projectId: string) => {
    try {
        setLoadingData(true);

        // Fetch data for the selected project based on its ID from localStorage
        const storedDataString = localStorage.getItem(`formData_${projectId}`);

        if (storedDataString) {
            const loadedData = JSON.parse(storedDataString);
            if (loadedData) {
                // Set the loaded data into state
                setSelectedProject(loadedData);

                // Update the inputValues state
                setInputValues((prevInputValues) => ({
                    ...prevInputValues,
                    [projectId]: loadedData,
                }));
            } else {
                console.warn('No data found in localStorage for this project:', projectId);
                // Fetch from the database if not found in localStorage
                await fetchDataFromDB(projectId);
            }
        } else {
            // Fetch from the database if no entry in localStorage
            await fetchDataFromDB(projectId);
        }
    } catch (error) {
        console.error('Error fetching project data:', error);
    } finally {
        setLoadingData(false);
    }
};

const fetchDataFromDB = async (projectId: string) => {
  try {
      const response = await axios.get(`https://projectprice-ad06ee250897.herokuapp.com/form/form/${projectId}`);
      const loadedData = response.data.data;
      setSelectedProject(loadedData);
      // Here, you should update the inputValues state with the loadedData
      setInputValues((prevInputValues) => ({
          ...prevInputValues,
          [projectId]: loadedData, // Assuming loadedData contains the values for the selected project
      }));
  } catch (error) {
      console.error('Error fetching project data from DB:', error);
  }
}

    useEffect(() => {
      const fetchProjects = async () => {
          try {
              const response = await axios.get('https://projectprice-ad06ee250897.herokuapp.com/form/form');
              const projectList = response.data.data;
              if (Array.isArray(projectList)) {
                  setProjects(projectList);
              } else {
                  console.warn("Received non-array data:", projectList);
              }
          } catch (error) {
              console.error('Error fetching projects:', error);
          }
      };
  
      fetchProjects();
  }, []);
  
  
  useEffect(() => {
    // Check if a project name exists in local storage
    const lastUsedProjectName = localStorage.getItem('lastUsedProject');
    if (lastUsedProjectName) {
      // Find the selected project in the list of projects
      const selectedProject = projects.find((project: any) => project.projectName === lastUsedProjectName);
      if (selectedProject) {
        // Set the selectedProjectId state
        setSelectedProjectId(selectedProject._id);
        // Load data for the selected project
        loadSelectedProjectData(selectedProject._id);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projects]);

  const handleProjectChange = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    const projectId = e.target.value;
    setSelectedProjectId(projectId); 
  
      // Fetch data for the selected project from localStorage
      const storedDataString = localStorage.getItem(`formData_${projectId}`);
      if (storedDataString) {
          const loadedData = JSON.parse(storedDataString);
          if (loadedData) {
              setSelectedProject(loadedData);
              //console.log(loadedData);
              
              // Update the inputValues state with the loadedData
              setInputValues((prevInputValues) => ({
                  ...prevInputValues,
                  [projectId]: loadedData,
              }));
          } else {
              console.warn('No data found in localStorage for this project:', projectId);
              await fetchDataFromDB(projectId);
          }
      } else {
          console.warn('No entry in localStorage for this project:', projectId);
          await fetchDataFromDB(projectId);
      }
  };

    const renderQuote = () => {
      if (!selectedProject) return null;
    
      const { inputValueSetts, hardcoreCalc, wasteCalc, checkedItems, pointingCalc, bPavingHardcore, postCalc, pavingHardcore, rebarCalc, concreteDepth, inputValue47, bPavingDepth, edgingCalc, inputValue51, inputValue18, inputValue50, adhesiveCalc, membraneCalc, projectPrice, pavingDepth, inputValue7, inputValue8, inputValue1, inputValue2, inputValue5, inputValue6, inputValue16, inputValue17, inputValue37, inputValue38, inputValue42, inputValue46, inputValue44, inputValue45, inputValue13, inputValue11, inputValue9, inputValue19 } = selectedProject; 
      let quoteComponents = [];
      //console.log(bPavingHardcore)
      const actualbPavingHardcore = bPavingHardcore * 1000;
      const actualPavingHardcore = pavingHardcore * 1000;
      const actualBlockPavingDepth = bPavingDepth * 1000;
      const actualPavingDepth = pavingDepth * 1000;
      const concreteDepthInMm = concreteDepth * 1000;
      
      
      if (inputValue1 > 0) {
        quoteComponents.push(
            <div key="Paving"> 
            <p>Paving:</p>
            <ul>
                    <li>Patio area to be constructed at an area of {inputValue1} m².</li>     
                    <li>Excavation depth of approximately {actualPavingDepth}mm. Dependant on ground conditions.</li>
                    {!checkedItems[1] && hardcoreCalc > 0 && <li>Install approximately {actualPavingHardcore} mm of compacted type 1 hardcore.</li>}
                    {!checkedItems[0] && wasteCalc > 0 && <li>All waste to be cleared from site.</li>}
                    {!checkedItems[8] && pointingCalc > 0 && <li>Paving joints to be pointed.</li>}
                    {edgingCalc > 0 && <li>Edgings to be laid around perimeter of paving.</li>}
                    {!checkedItems[6] && membraneCalc > 0 && <li>Install heavy duty membrane over all paving areas.</li>}
                    {!checkedItems[7] && adhesiveCalc > 0 && <li>Paving laid on full concrete bed with primer product used to bond bed to paving slab.</li>}
                </ul>
            </div>
        )
    };

    if (inputValue2 > 0) {
      quoteComponents.push(
          <p key="BlockPaving"> 
          <p>Block paving:</p>
          <ul>
              <li>Block paving to be constructed at an area of {inputValue2}m².</li>   
                  <li>Excavation depth of {actualBlockPavingDepth}mm. Dependant on ground conditions.</li>
                  {!checkedItems[1] && hardcoreCalc > 0 && <li>Install approximately {actualbPavingHardcore} mm of compacted type 1 hardcore.</li>}
                  {!checkedItems[0] && wasteCalc > 0 && <li>All waste to be cleared from site.</li>}
                  {!checkedItems[6] && membraneCalc && <li>Install heavy duty membrane over all block paving areas.</li>}
              </ul>
          </p>
      )
  }; 

  if (inputValueSetts > 0) {
    quoteComponents.push(
        <p key="Setts"> 
        <p>Setts:</p>
        <ul>
            <li>Setts to be installed at an area of {inputValueSetts}m².</li>   
                <li>Excavation depth of {actualBlockPavingDepth}mm. Dependant on ground conditions.</li>
                {!checkedItems[1] && hardcoreCalc > 0 && <li>Install approximately {actualbPavingHardcore} mm of compacted type 1 hardcore.</li>}
                {!checkedItems[0] && wasteCalc > 0 && <li>All waste to be cleared from site.</li>}
                {!checkedItems[6] && membraneCalc && <li>Install heavy duty membrane under installed setts areas.</li>}
                {!checkedItems[8] && pointingCalc > 0 && <li>Joints to be pointed.</li>}
            </ul>
        </p>
    )
}; 

      const drainageComponents = [];

      if (!checkedItems[34] && inputValue13 && inputValue13 > 0) {
        drainageComponents.push(
            <li key="manhole">  {inputValue13} x Manhole cover to be installed.</li>
        );
    }
    

    if (!checkedItems[32] && inputValue11 && inputValue11 > 0) {
      drainageComponents.push(
          <li key="gully"> {inputValue11} x Gully pot to be installed.</li>
      );
  }
  
  if (!checkedItems[30] && inputValue9 && inputValue9 > 0) {
      drainageComponents.push(
          <li key="drain"> Channel drains to be installed at a length of {inputValue9}m.</li>
      );
  }
  

  if (drainageComponents.length > 0) {
    quoteComponents.push(
        <div key="drainageSection">
            <p>Drainage:</p>
            <ul key="drainageList">
                {drainageComponents}
            </ul>
        </div>
    );
}

const decorativeComponents = [];

if (!checkedItems[36] && inputValue18 && inputValue18 > 0) {
  decorativeComponents.push(
      <li key="gravel"> Area of gravel to be installed at an area of {inputValue18}m²</li>
  );
}
  

  if (!checkedItems[37] && inputValue50 && inputValue50 > 0) {
    decorativeComponents.push(
        <li key="bark"> Area of bark to be installed at an area of {inputValue50}m²</li>
    );
}


if (decorativeComponents.length > 0) {
  quoteComponents.push(
      <div key="decorativeSection">
          <p>Decorative:</p>
          <ul key="decorativeList">
              {decorativeComponents}
          </ul>
      </div>
  );
}

if (inputValue7 > 0 && inputValue8 > 0) {
    let materials = [];

    if (!checkedItems[24] && selectedProject.blockworkCalc > 0) {
        materials.push('block work');
    }
    if (!checkedItems[25] && selectedProject.brickworkCalc > 0) {
        materials.push('brick work');
    }
    if (!checkedItems[29] && selectedProject.renderCalc > 0) {
        materials.push('render');
    }
    if (!checkedItems[26] && selectedProject.claddingCalc > 0) {
        materials.push('cladding');
    }
    if (!checkedItems[28] && selectedProject.stoneFacingCalc > 0) {
        materials.push('stone facing');
    }

    let materialsString = materials.join(', ');

  quoteComponents.push(
    <div key="Walling"><p>Walling</p><ul><li>Wall to be constructed at a length of {inputValue7}m and a height of {inputValue8}m, constructed from {materialsString}. 
    </li>
    </ul>
    </div>
    )
}

if (!checkedItems[14] && inputValue47 > 0) {
  quoteComponents.push(
    <div key="Concrete"><p>Concrete:</p><ul><li>Concrete pad to be installed at an area of {inputValue47}m² with a depth of {concreteDepthInMm} mm. 
    {rebarCalc ? " Reinforced steel mesh to be used for concrete strength." : ""}
    </li></ul>
    </div>
    )
}



      if (inputValue5 && inputValue5 > 0) {
        quoteComponents.push(
          <div key="Fencing"><p>Fencing:</p><ul><li>Fencing to be installed at a length of {inputValue5}m.
          </li></ul>
          </div>
          )
      }

      if (inputValue6 && inputValue46) {
        const area = (inputValue6 * inputValue46).toFixed(0); 
        const fasciaArea = (inputValue45 * inputValue44).toFixed(0);
        
        if (area !== "0") {
            quoteComponents.push(
                <div key="Decking">
                    <p>Decking:</p>
                    <ul>
                    <li>Decking to be installed at an area of approximately {area}m². Supported by a network of timber joists.</li>
                    {inputValue44 !== 0 && <li> Decking constructed at a height of {inputValue44}m.</li>}
                    {inputValue45 !== 0 && inputValue46 !== 0 && 
                         <li> Fascia Boards to be installed on face of decking at an approximate area of {fasciaArea}m².</li>}
                    </ul>
                </div>
            );
        }
    }
        if (!checkedItems[35] && inputValue16 > 0 && inputValue17 > 0) {
          quoteComponents.push(
            <div key="Sleepers"><p>Sleepers:</p><ul><li>Sleepers to be installed at a length of {inputValue17}m and a height of {inputValue16} units tall. 
            </li></ul>
            </div>
            )
        }

        if (!checkedItems[39] && inputValue19 && inputValue19 > 0) {
          quoteComponents.push(
          <div key="Turf"><p>Turfing:</p><ul><li> Turf to be prepped and laid at an area of {inputValue19}m².
          </li></ul>
          </div>
          )
        };

        if (!checkedItems[38] && inputValue51 && inputValue51 > 0) {
          quoteComponents.push(
          <div key="ArtificialTurf"><p>Artificial turfing:</p><ul><li> Artificial turf to be prepped and laid at an area of {inputValue51}m².
          </li></ul>
          </div>
          )
        };

        const finalComponents = [];

        if (inputValue42 > 0) {
            finalComponents.push(<li key="timber">Timber work to be carried out.</li>);
        }        

      if (inputValue37 > 0) {
          finalComponents.push(
              <li key="electrical"> Electrical work to be carried out.</li>
          );
      }

      if (inputValue38) {
          finalComponents.push(
              <li key="waterfeature"> Water feature to be installed.</li>
          );
      }

      if (finalComponents.length > 0) {
        quoteComponents.push(
            <ul key="finalList">
                {finalComponents}
            </ul>
        );
    }
    

    if (projectPrice) {
        quoteComponents.push(
          <p key="Price">< hr/> Cost of total project to be completed: <strong style={{ textDecoration: 'underline' }}> £{projectPrice}</strong>
          <p />
        
        
        </p>
        )};

    quoteComponents.push(
      <div key="ElectricWaterStatement"><p><strong style={{ textDecoration: 'underline' }}><hr />Requirements</strong></p><ul><li>Electricity and water to be provided by the customer, throughout the project until completion.
      </li>
      <li>All garden furniture and ornaments to be cleared from working area prior to start.</li>
      </ul>
      </div>
    );

    
    if (projectPrice) {
        quoteComponents.push(
            <p key="Extra">
                <p><strong style={{ textDecoration: 'underline' }}><hr />Validity</strong></p> <p>Our quotation is valid for a period of 14 days from the date of issue to acceptance of this contract, with 
                the rates contained within this pricing schedule being fixed for the works completed.</p>
            </p>
        )
    if (projectPrice) {
        quoteComponents.push(
            <p key="Added">
                <p><strong style={{ textDecoration:'underline' }}> Added notes </strong></p>
                <p>At times, there may be plant and machinery operating on site. This makes it an unsafe area that must not be entered.
                    All planning and building regulations (if required) must be supplied and paid for by the customer prior to the commencement of works.
                    Photographs may be taken throughout various stages of the work and may be used for promotional purposes on social media or website portfolios. Please inform us if you have any objections.
                    </p>
                    <p>Should any additional work or modifications be required after the placement of your purchase order, we will issue 
                        a response detailing these changes, including any impact on cost or timeline. We cannot proceed with any work until 
                        the appropriate representative has approved and agreed to these changes. If we are successful with this tender submission, 
                        we will assist you with prompt responses. We trust that we have interpreted your requirements correctly and welcome discussions 
                        on any aspect of the quotation.
                        </p>
            </p>
        )
    }
    };

      return quoteComponents || null; // Return the combined quote or null if no conditions match
        };

        //console.log(selectedProject)
            return (
                <MainContainer>
                <TopContainer>
                <select
            value={selectedProjectId || ""}
            onChange={handleProjectChange}
            style={{
                background: 'rgba(0, 0, 0, 0.3)',
                border: 'none',
                borderRadius: '0.3rem',
                color: 'white',
                outline: 'none',
                width: '150px',
            }}
        >
            <option value="" disabled>
                {selectedProject ? selectedProject.projectName : "Select a project"}
            </option>
            {projects.map((project) => (
                <option key={project._id} value={project._id}>
                    {project.projectName}
                </option>
            ))}
        </select>
          </TopContainer>
            <QuoteContainer style={{ color: isBackgroundWhite ? 'white' : 'rgba(36, 36, 36)', backgroundColor: isBackgroundWhite ? 'rgba(36,36,36)' : 'white' }} ref={quoteRef}>
            <p>The following description is the outlay of the landscaping project to be completed.</p>
            {renderQuote()}
            
        </QuoteContainer>
        <div>
          <ButtonContainer>
          <Button style={{ color: isBackgroundWhite ? 'white' : 'black', backgroundColor: isBackgroundWhite ? 'grey' : 'white' }} onClick={handleCopy}>Copy Quote</Button>
          {showSuccessMessage && (
  <SuccessMessage>Quote Copied!</SuccessMessage>
)}
          </ButtonContainer>
        </div>
        </MainContainer>
    );
};
  
  export default ProjectQuote;