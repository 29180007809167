import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { Container } from 'react-bootstrap';
import ModalComponent from '../Modal/Modal';
import LoginForm from '../login/LoginForm';
import axios from 'axios';
import { useLocation } from 'react-router-dom';

const HeroComponent = styled.header`
  background: linear-gradient(to right, #1b2b52, #2c51a8);
  padding: 3% 0;
  height: 100vh;
  
  @media (max-width: 1000px) {
    padding: 3% 0; // Reduce padding for smaller screens
    height: fit-content;
  }
  @media (orientation: portrait){
    padding: 5%;
    height: 100vh;
    justify-content: center;
  }
`;

const HeaderContainer = styled.div`
  background-color: transparent;
  border-radius: 1.5rem;
  border: 4px solid white;
  margin-top: 0%;
  padding: 3%;
  color: white;
  width: 35%;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 900px) {
    width: 40%; // Adjust width for smaller screens
    height: 80%; // Let the height grow with content
    padding: 3% 2%; // Adjust padding
    flex-direction: column;
  }
  @media (orientation: portrait){
    width: 100%;
    margin-top: 10%;
  }
`;

const Heading = styled.h1`
  font-size: 200%;
  font-family: 'Ubuntu', sans-serif;
  a {
    text-decoration: none;  // Removes the underline
    color: inherit;         // Inherits the color from the parent element
    &:hover {
      text-decoration: none;  // Removes the underline on hover as well
    }
  }

  @media (max-width: 600px) {
    font-size: 90%; // Reduce font size for smaller screens
  }
  @media (max-height: 370px) {
    font-size: 150%; // Reduce font size for smaller screens
  }
  @media (orientation: portrait){
    font-size: 1.5rem;  
  }
`;

const SubHeading = styled.h3`
  margin: 0vh, 0;
  font-weight: 100;

   // Smaller screens (e.g., phones)
  @media (max-width: 600px) {
    display: none;  // Smaller font size for small screens
  }
  

  // Medium screens (e.g., tablets)
  @media (min-width: 601px) and (max-width: 1024px) {
    font-size: 16px;  // Medium font size for medium screens
  }

  @media (orientation: portrait){
    display: none;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: 601px) and (max-width: 1024px)
    align-items: center; // Center buttons on smaller screens
  }
  @media (max-height: 350px){
    height: 3vh;
    width: 2vh;
    text-size: 1vh;
  }
  @media (max-width: 600px) {
    margin-top: 2%; // Add some space on top
  }
  @media (orientation: portrait) {
   margin-top: 2%; 
  }
`;

const LoginFormContainer = styled.div`
  display: flex;
  width: 150%;
  align-items: center;
  @media (orientation: portrait) {
    width: 120%;
  }
`;

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const fadeOut = keyframes`
  from { opacity: 1; }
  to { opacity: 0; }
`;

const StaticPhrase = styled.div`
  font-size: 2rem; // Adjust the font size as needed for the static phrase
  position: static;
  font-family: 'Ubuntu';
  font-weight: bold;
`;

const TransitionPhrase = styled.div`
  animation: ${fadeIn} 1s ease-in, ${fadeOut} 2s ease-out 2s;
  animation-fill-mode: forwards;
  will-change: opacity;
  font-size: 2rem;
`;

const RightSideContainer = styled.div`
  width: 50%; // Adjust the width as needed
  position: absolute; // Position it absolutely relative to its parent
  right: 0; // Align to the right side
  top: 0; // Align to the top
  height: 100vh; // Full height of the viewport
  display: flex; // Use flexbox for alignment
  justify-content: center; // Center content horizontally
  align-items: center; // Center content vertically
  padding: 2%; // Add some padding
  @media (orientation: portrait){
    display: none;
  }
`;


const AnimatedTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center; // Keep items centered
  justify-content: center;
  height: 200px; // Adjust as needed to fit the content
  @media (max-width: 600px) {
    height: auto; // Let the container grow with content
  }
`;

const spinnerAnimation = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const Spinner = styled.div`
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid white;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: ${spinnerAnimation} 1s linear infinite;
  margin: 0 auto;
`;

const AnimatedText = () => {
  const phrases = [
    'Be precise',
    'Save important time',
    'Be professional',
    'Manage your business',
  ];

  const [currentPhrase, setCurrentPhrase] = useState(0);
  const [key, setKey] = useState(0); // New state to reset component

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentPhrase((prevPhrase) => (prevPhrase + 1) % phrases.length);
      setKey((prevKey) => prevKey + 1); // Increment key to force re-render
    }, 4000); // Match the total duration of the animations

    return () => clearInterval(intervalId); // Clean up the interval
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AnimatedTextContainer key={key}> {/* Use key to reset */}
      <StaticPhrase>Helping you...</StaticPhrase>
      <TransitionPhrase>{phrases[currentPhrase]}</TransitionPhrase>
    </AnimatedTextContainer>
  );
};

const LoginPage = () => {
  const [loading, setLoading] = useState(true);
  const [showSignupModal, setShowSignupModal] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const checkLoginStatus = async () => {
      const token = localStorage.getItem('token');
      if (token) {
        try {
          await axios.get('https://projectprice-ad06ee250897.herokuapp.com/auth/me', {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          // User is logged in, you can handle redirection or state update here if needed
        } catch (error) {
          console.error('Error checking login status:', error);
        }
      }
      setLoading(false);
    };

    checkLoginStatus();
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.get('signup') === 'true') {
      setShowSignupModal(true);
    }
  }, [location]);

  return (
    <HeroComponent>
      <Container>
        {loading ? (
          <Spinner />
        ) : (
          <HeaderContainer>
            <Heading><a href="/">Project Price</a></Heading>
            <SubHeading>Your instant quote builder!</SubHeading>
            <LoginFormContainer>
              <LoginForm />
            </LoginFormContainer>
            <ButtonContainer>
              <ModalComponent 
                text="Need an account? Signup here" 
                variant="link" 
                isSignupFlow={true} 
                show={showSignupModal} 
                onShow={() => setShowSignupModal(true)}
                onHide={() => setShowSignupModal(false)} 
              />
            </ButtonContainer>
          </HeaderContainer>
        )}
      </Container>
      <RightSideContainer>
        <AnimatedText />
      </RightSideContainer>
    </HeroComponent>
  );
};

export default LoginPage;
