import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  padding: 5vh 10vh;
  display: flex;
  flex-direction: column;
  align-items: center; 
  margin-bottom: 0vh;
`;

const Header = styled.h1`
  text-align: center;
  color: white;
  font-family: 'Ubuntu', sans-serif;
  margin-bottom: 5vh;
  font-size: 4vh;
  width: 100%;
  max-width: 100vh;
  @media (orientation: portrait) {
    font-size: 2.5vh;
    width: 30vh;
     margin-bottom: 0vh;
  }
`;

const ContentWrapper = styled.div<{ reverse?: boolean }>`
  display: flex;
  align-items: center;
  margin-bottom: 10vh;
  margin-top: 10vh;
  opacity: 0;
  transform: ${({ reverse }) => (reverse ? 'translateX(20px)' : 'translateX(-20px)')};
  transition: opacity 1s ease-out, transform 1s ease-out;
  flex-direction: ${({ reverse }) => (reverse ? 'row-reverse' : 'row')};

  &.visible {
    opacity: 1;
    transform: translateX(0);
  }

  @media (max-width: 1070px) and (orientation: portrait) {
    flex-direction: column;
    text-align: center;
    transform: translateX(0); /* Reset translation for mobile view */
    margin-bottom: 4vh;
  }
`;
const ImageBox = styled.div`
  width: 50%;
  padding-right: 2vh;
  display: flex;

  @media (max-width: 1070px) and (orientation: portrait) {
    width: 100%;
    padding-right: 0;
    margin-bottom: 2vh;
    justify-content: center;
  }
`;

const Image = styled.img`
  width: 100%;
  height: auto;
  border-radius: 10px;
  @media(orientation: portrait){
  width: 35vh;
  }
`;

const Description = styled.div<{ reverse?: boolean }>`
  width: 50%;
  padding-left: 5vh;
  font-size: 3vh;
  color: white;
  transform: ${({ reverse }) => (reverse ? 'translateX(20px)' : 'translateX(-20px)')};
  transition: opacity 1s ease-out, transform 1s ease-out;

  &.visible {
    opacity: 1;
    transform: translateX(0);
  }

  @media (max-width: 1070px) and (orientation: portrait) {
    width: 40vh;
    font-size: 2vh;
    padding-left: 0;
    transform: translateX(0); /* Reset translation for mobile view */
  }
`;

const phases = [
    {
      imgSrc: '/design.png',
      text: 'Simply plot your design in a few clicks. Our intuitive interface allows you to quickly create stunning designs with ease. Whether you are a beginner or a professional, our tools are designed to help you bring your ideas to life with minimal effort. Customize every aspect of your design and see instant results.',
      phaseTime: 'Design Phase: 10 minutes'
    },
    {
      imgSrc: '/dashboard.png',
      text: 'Make any necessary tweaks to your project and add in the time spent on each phase. You will automatically receive a complete breakdown of the project, including every detail needed to complete the job.',
      phaseTime: 'Time Allocation Phase: 5 minutes'
    },
    {
      imgSrc: '/quoter.png',
      text: 'In seconds, send your tailored quote to your customer. Our quotation system is fast and efficient, allowing you to generate quotes based on the specifics of the project. This ensures transparency and accuracy in your pricing, making it easier for you to close deals and maintain customer satisfaction.',
      phaseTime: 'Quotation Phase: Instant'
    }
  ];
  

const Showcase = () => {
  const contentRefs = useRef<(HTMLDivElement | null)[]>([]);

  useEffect(() => {
    const currentRefs = contentRefs.current;
    const options = {
      threshold: 0.6, // Trigger when the element is halfway in the viewport
    };

    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('visible');
        } else {
          entry.target.classList.remove('visible');
        }
      });
    }, options);

    currentRefs.forEach(ref => {
      if (ref) {
        observer.observe(ref);
      }
    });

    return () => {
      currentRefs.forEach(ref => {
        if (ref) {
          observer.unobserve(ref);
        }
      });
    };
  }, []);

  return (
    <Container>
      <Header>Provide Professional Experiences to Put You Ahead!</Header>
      {phases.map((phase, index) => (
        <ContentWrapper
          key={index}
          ref={el => (contentRefs.current[index] = el)}
          reverse={index === 1} // Reverse the layout for the second item
        >
          <ImageBox>
            <Image src={phase.imgSrc} alt={`Phase ${index + 1}`} />
          </ImageBox>
          <Description>{phase.text}</Description>
        </ContentWrapper>
      ))}
    </Container>
  );
};

export default Showcase;
