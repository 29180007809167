import React from 'react';
import styled from 'styled-components';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  onEbookClick: () => void; // Add this prop
}

const ModalOverlay = styled.div<{ isOpen: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  z-index: 1000;
`;

const ModalContent = styled.div<{ isOpen: boolean }>`
  position: fixed;
  top: 50%;
  left: ${({ isOpen }) => (isOpen ? '50%' : '100%')};
  transform: translate(-50%, -50%);
  background: white;
  padding: 4vh;
  border-radius: 10px;
  text-align: center;
  max-width: 60vh;
  z-index: 1001;
  transition: left 2s ease-in-out; /* Transition effect */
  @media (orientation: portrait){
  padding: 2vh;
  max-width: 100%;
  }
`;

const ModalHeader = styled.h1`
  font-size: 2.5vh;
  font-weight: bold;
  color: #333;
  margin-bottom: 1vh;
`;

const ModalText = styled.p`
  font-size: 2vh;
  color: #666;
  margin-bottom: 2vh;
`;

const ModalImage = styled.img`
  width: 40%;
  height: auto;
  border-radius: 10px;
  margin-bottom: 1.5vh;
  @media (orientation: portrait){
  width: 60%;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ModalButton = styled.button`
  background-color: rgba(7,55,99);
  color: white;
  padding: 0.5rem 1.5rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 2.5vh;
  margin-top: 2vh;  /* Add margin to separate it from the image */

  &:hover {
    background-color: #0056b3;
  }
    @media{
    font-size: 2vh;
    padding: 1.5vh;}
`;

const EbookModal: React.FC<ModalProps> = ({ isOpen, onClose, onEbookClick }) => {
  const handleClickOutside = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <ModalOverlay isOpen={isOpen} onClick={handleClickOutside}>
      <ModalContent isOpen={isOpen}>
        <ContentWrapper>
          <ModalHeader>Are you getting the most out of your business?</ModalHeader>
          <ModalText>Discover the strategies of top landscaping business owners and start maximising your business potential.</ModalText>
          <ModalImage src="/ebookcover.png" alt="eBook Cover" />
          <ModalButton onClick={onEbookClick}>Download eBook ➜</ModalButton>
        </ContentWrapper>
      </ModalContent>
    </ModalOverlay>
  );
};

export default EbookModal;
