import styled from "styled-components";
import React from "react";
import { FaFacebook, FaTwitter, FaInstagram } from "react-icons/fa";

const FooterContainer = styled.footer`
  background-color: #333;
  color: white;
  text-align: center;
  padding: 10vh;
  @media (orientation: portrait) {
    padding: 5%;
  }
`;

const FooterLink = styled.a`
  color: white;
  margin: 2vh;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const Divider = styled.hr`
  border: none;
  height: 1px;
  background-color: white;
  margin: 2vh 0;
`;

const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;
`;

const ArrowDown = styled.span`
  display: inline-block;
  margin-left: 5px;
  &::after {
    content: '▼';
  }
`;

const DropdownContent = styled.div`
  display: none;
  position: absolute;
  background-color: #333;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  bottom: 100%;
  margin-bottom: 5px;

  ${DropdownContainer}:hover & {
    display: block;
  }
`;

const DropdownLink = styled(FooterLink)`
  display: block;
  text-align: left;
`;

const SocialMediaLink = styled.a`
  color: white;
  margin: 0 10px;
  font-size: 24px;

  &:hover {
    color: #ddd;
  }
`;

const Footer: React.FC = () => {
  return (
    <FooterContainer>
      <div>
        <FooterLink href="/">Home</FooterLink>
        <FooterLink href="/features">Features</FooterLink>
        <FooterLink href="/pricing">Pricing</FooterLink>
        <DropdownContainer>
          <FooterLink href="#">
            Resources
          </FooterLink>
          <DropdownContent>
            <DropdownLink href="/allblogposts">Guides</DropdownLink>
            <DropdownLink href="/ebook">eBook</DropdownLink>
          </DropdownContent>
        </DropdownContainer>
        <FooterLink href="/areacalculator">Area Calculator</FooterLink>
        <FooterLink href="/contactus">Contact us</FooterLink>
        <DropdownContainer>
          <FooterLink href="#">
            T&Cs <ArrowDown />
          </FooterLink>
          <DropdownContent>
            <DropdownLink href="/privacy">Privacy Policy</DropdownLink>
            <DropdownLink href="/disclaimer">Disclaimer</DropdownLink>
          </DropdownContent>
        </DropdownContainer>
      </div>
      <div>
        <SocialMediaLink
          href="https://www.facebook.com/projectpriceuk"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaFacebook />
        </SocialMediaLink>
        <SocialMediaLink
          href="https://www.twitter.com/projectpriceuk"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaTwitter />
        </SocialMediaLink>
        <SocialMediaLink
          href="https://www.instagram.com/projectpriceuk"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaInstagram />
        </SocialMediaLink>
      </div>
      <Divider />
      <div>© {new Date().getFullYear()} Project Price. All rights reserved.</div>
      <a href="mailto:contact@projectprice.co.uk">contact@projectprice.co.uk</a>
    </FooterContainer>
  );
};

export default Footer;
