import React, { createContext, useState } from "react";

interface ProjectData {
  id: string;
  name: string;
  formData: FormData;
}

interface FormData {
  inputValue1: string;
  calculatedValue1: string;
  inputValue2: string;
  calculatedValue2: string;
  inputValue3: string;
  calculatedValue3: string;
  calculatedValue4: string;
  inputValue4: string;
  inputValue5: string;
  inputValue6: string;
  inputValue7: string;
  inputValue8: string;
  inputValue9: string;
  inputValue10: string;
  inputValue11: string;
  inputValue12: string;
  inputValue13: string;
  inputValue14: string;
  inputValue15: string;
  inputValue16: string;
  inputValue17: string;
  selectedPosts: string;
  selectedPanel: string;
  calculatedValue5: string;
}



interface ProjectsContextData {
  projects: ProjectData[];
  addProject: (project: ProjectData) => void;
  formData: FormData; // Add formData property
}

const ProjectsContext = createContext<ProjectsContextData | undefined>(
  undefined
);

interface ProjectsProviderProps {
  children: React.ReactNode;
}

const ProjectsProvider = ({ children }: ProjectsProviderProps) => {
  const [projects, setProjects] = useState<ProjectData[]>([]);
  const [formData] = useState<FormData>({
    inputValue1: "",
    calculatedValue1: "",
    inputValue2: "",
    calculatedValue2: "",
    inputValue3: "",
    calculatedValue3: "",
    calculatedValue4: "",
    inputValue4: "",
    inputValue5: "",
    inputValue6: "",
    inputValue7: "",
    inputValue8: "",
    inputValue9: "",
    inputValue10: "",
    inputValue11: "",
    inputValue12: "",
    inputValue13: "",
    inputValue14: "",
    inputValue15: "",
    inputValue16: "",
    inputValue17: "",
    selectedPosts: "",
    selectedPanel: "",
    calculatedValue5: "",
  });

  const addProject = (project: ProjectData) => {
    setProjects((prevProjects) => [...prevProjects, project]);
  };

  const projectsContextData: ProjectsContextData = {
    projects,
    addProject,
    formData,
  };

  return (
    <ProjectsContext.Provider value={projectsContextData}>
      {children}
    </ProjectsContext.Provider>
  );
};


export { ProjectsContext, ProjectsProvider };
