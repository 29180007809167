import React from 'react';
import styled from 'styled-components';
import { Modal, Button, FormControl } from "react-bootstrap";
import axios from "axios";
import { useState } from "react";

interface ModalProps {
  text: string;
  variant: "primary" | "secondary" | "danger" | "link" | "outline-light";
  isSignupFlow: boolean;
  buttonStyle?: React.CSSProperties; // Prop for custom button style
  show: boolean;
  onShow: () => void;
  onHide: () => void;
};

const SignupButton = styled.button`
  background-color:rgba(7, 55, 99);
  color: white;
  border: none;
  border-radius: 5px;
  padding: 1%;
  margin: 1%;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
      background-color: #0056b3;
  }

  @media (max-width: 1000px) {
      width: fit-content;
  }
`;

const CloseButton = styled.button`
  background-color: rgba(211,211,211);
  color: white;
  border: none;
  border-radius: 5px;
  padding: 1%;
  margin: 1%;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
      background-color: #696969;
  }

  @media (max-width: 1000px) {
      width: 15vh;
  }
  @media (orientation: portrait) {
    width: fit-content;
  }
`;

const StyledModalDialog = styled(Modal.Dialog)`
  &&& {
    background-color: transparent;
    border-radius: 1%;
    margin: 0;
    padding: 5%;
    width: 100%;
    height: 100%;
    @media (orientation: portrait){
      margin: 2% 0%;
    }

    .modal-content {
      height: 100%;
      border-radius: 0rem;
      background-color: white;
      border: none;
      padding: 0;
      margin: 0;
      color: blue;

      .modal-header, .modal-body, .modal-footer {
        padding: 0;
        margin: 0;
      }

      .modal-header {
        border-bottom: none;
        text-align: center;
        align-items: center;
        justify-content: center;
      }
      .modal-body {
        padding-top: 15%;
        border-bottom: none;
        @media (max-width: 1000px) {
          padding-top: 2%;
        }
      }
      
      .modal-footer {
        border-top: none;
      }
    }
  }
`;

const StyledFormControl = styled(FormControl)`
  width: 60%;
  margin: 0 auto;

  @media (max-width: 1000px) {
    width: 40%;
  }
  @media (orientation: portrait){
    width: 60%;
   }
`;

const Heading = styled.h1`
  font-size: 6vh;
  margin-top: 5%;
  font-family: 'Ubuntu', sans-serif;
  color: rgba(7, 55, 99);
  text-align: center;
  justify-content: center;
  display: flex;
  align-items: center;

  @media (max-width: 1000px) {
    margin-top: 0vh;
    font-size: 120%;
  }
  @media (orientation: portrait) {
    font-size: 1.6rem;
  }
`

const FormInput = styled.div`
  max-width: 60%;
  margin: 0 auto;
  color: rgba(7,55,99);
   @media (max-width: 1000px) {
    max-width: 40%;
   }
   @media (orientation: portrait){
    max-width: 60%;
   }
`

const ErrorMessage = styled.p`
  color: red;
  margin: 0 auto;
  max-width: 60%;
  height: 25px;
  overflow: hidden;

  @media (max-width: 1000px) {
    max-height: 25px;
   }
`;

const SubHeading = styled.h3`
  margin: 2%, 0;
  font-weight: 100;
   color: rgba(7,55,99);
  margin: 0 auto;

  @media (max-width: 1000px) {
    font-size: 0vh;
  }
  `

  const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 0%;

  label {
    display: flex;
    align-items: center;
    margin-bottom: 1vh;
  }

  input[type="checkbox"] {
    margin-right: 10px; /* Adds space between checkbox and text */
  }

  @media (max-width: 1000px) {
    margin-top: 0vh;
    margin-right: 30%;
  }
`;

const StyledButton = styled(Button)`
  color: white; 
  font-size: 90%;
  @media (orientation: portrait){
    margin-left: 20%;
  }
`;

const ModalComponent = ({ text, variant, isSignupFlow, show, onShow, onHide }: ModalProps) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [agreedToTerms, setAgreedToTerms] = useState(false);
  const [agreedToMarketing, setAgreedToMarketing] = useState(false); // New state for marketing consent
  const [, setIsSubmitting] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleCheckboxChange = () => {
    if (agreedToTerms) {
      setErrorMsg(""); // Clear the error message if the checkbox is being checked
    }
    setAgreedToTerms(!agreedToTerms);
  };

  const handleMarketingCheckboxChange = () => {
    setAgreedToMarketing(!agreedToMarketing);
  };

  const handleClick = async () => {
    if (!agreedToTerms) {
      setErrorMsg("Please agree to the T&Cs.");
    } else if (password !== confirmPassword) {
      setErrorMsg("Passwords do not match.");
    } else {
      setIsSubmitting(true);
      setErrorMsg("");
    
      let response;
      if (isSignupFlow) {
        const { data: signUpData } = await axios.post(
          "https://projectprice-ad06ee250897.herokuapp.com/auth/signup",
          {
            email,
            password,
            agreeToMarketing: agreedToMarketing, // Include the marketing consent
          }
        );
        response = signUpData;
      } else {
        const { data: loginData } = await axios.post(
          "https://projectprice-ad06ee250897.herokuapp.com/auth/login",
          {
            email,
            password,
          }
        );
        response = loginData;
      }
  
      if (response.errors.length) {
        return setErrorMsg(response.errors[0].msg);
      }
  
      localStorage.setItem("token", response.data.token);
      axios.defaults.headers.common["authorization"] = `Bearer ${response.data.token}`;
    
      window.location.href = "/emailvalidation";
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <StyledButton
        onClick={onShow}
        variant={variant}
        size="sm"
      >
        {text}
      </StyledButton>
      <Modal show={show} onHide={onHide}>
        <StyledModalDialog>
        <Modal.Header>
          <Heading>Project Price</Heading>
        </Modal.Header>
        <SubHeading>Your instant quote builder!</SubHeading>
        <Modal.Body>
          <div className="mb-3">
            <StyledFormControl
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e: any) => setEmail(e.target.value)}
            />
            <FormInput>Email address</FormInput>
          </div>

          <div className="mb-3">
            <StyledFormControl
              type="password"
              placeholder="********"
              value={password}
              onChange={(e: any) => setPassword(e.target.value)}
            />
            <FormInput>Password</FormInput>
          </div>

          <div className="mb-3">
            <StyledFormControl
              type="password"
              placeholder="********"
              value={confirmPassword}
              onChange={(e: any) => setConfirmPassword(e.target.value)}
            />
            <FormInput>*Confirm Password</FormInput>
          </div>

          <ErrorMessage style={{ visibility: errorMsg ? 'visible' : 'hidden' }}>
            {errorMsg || ' '}
          </ErrorMessage>
        </Modal.Body>
        <CheckboxContainer>
  <label htmlFor="termsCheckbox">
    <input
      type="checkbox"
      id="termsCheckbox"
      checked={agreedToTerms}
      onChange={handleCheckboxChange}
    />
     I agree to the&nbsp; <a href="/terms" target="_blank" rel="noopener noreferrer">  Terms and Conditions</a>
  </label>
  <label htmlFor="marketingCheckbox">
    <input
      type="checkbox"
      id="marketingCheckbox"
      checked={agreedToMarketing}
      onChange={handleMarketingCheckboxChange}
    />
    I agree to receive marketing emails
  </label>
</CheckboxContainer>


        <Modal.Footer>
          <CloseButton onClick={onHide}>
            Close
          </CloseButton>
          <SignupButton onClick={handleClick}>
            Sign up
          </SignupButton>
        </Modal.Footer>
        </StyledModalDialog>
      </Modal>
    </>
  );
};

export default ModalComponent;
