import React from "react";
import styled from "styled-components";

const Button = styled.button`
  margin-right: 5%;
  background-color: white;
  width: fit-content;
  font-size: 0.9rem;
  color: black;
  border-radius: 3px;
  border: none;
  @media (max-height: 350px) {
    font-size: 80%;
  }
`;

interface UpgradePromptProps {
  backgroundColor: string;
}

const handleManageSubscription = () => {
  // Redirect the user to the Stripe subscription management link
  window.location.href = 'https://payments.projectprice.co.uk/p/login/14k8yZfmJbt1eBi8ww';
};

const UpgradePrompt: React.FC<UpgradePromptProps> = ({ backgroundColor }) => {
  const isBackgroundWhite = backgroundColor === 'rgba(245, 245, 245)' || backgroundColor === 'white';

  return (
    <div>
      <h2 style={{ color: isBackgroundWhite ? 'black' : 'white' }}>Upgrade to Premium</h2>
      <p style={{ color: isBackgroundWhite ? 'black' : 'white' }}>To access the design feature, please upgrade to a premium membership.</p>
      <div style={{ marginBottom: '20px' }}>
      <iframe
          width="600"
          height="315"
          src="https://www.youtube.com/embed/i1IEELWX3yo"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Project Price Design Feature"
        ></iframe>

      </div>
      <div>
        <Button
          style={{ color: isBackgroundWhite ? 'white' : 'black', backgroundColor: isBackgroundWhite ? 'grey' : 'white' }}
          onClick={handleManageSubscription}
        >
          Manage Subscription
        </Button>
      </div>
    </div>
  );
};

export default UpgradePrompt;
