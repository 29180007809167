import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { FiArrowLeftCircle, FiDownload, FiPenTool, FiZoomIn, FiZoomOut, FiCompass } from 'react-icons/fi';
import { FaRuler, FaUpload } from 'react-icons/fa';
import styled from 'styled-components';
import axios from 'axios';
import { jsPDF } from 'jspdf';

interface CanvasProps {
  isPanMode?: boolean;
}

const MainContainer = styled.div`
  background-color: white;
  height: 80vh;

`;

const FlexContainer = styled.div`
  background-color: white;
  display: flex;
  margin-bottom: 5vh;
  padding: 0 80px;
  flex-direction: row;
  align-items: start;
  justify-content: space-between;
 
  @media (max-width: 1000px) {

    align-items: start;
    margin-bottom: 0vh;
    width: 100%;
  }

`;

const ArrowButton = styled.div`
  display: flex;
  flex-direction: row;
`;

const SideContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 60vh;
  padding: 2vh;
  margin-left: 2vh;
  @media (max-width: 1000px) {
    padding 2%;
    margin-left: 10%;
    flex-direction: column;
    width: 40vh;
  align-items: center;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  background-color: rgba(0, 0, 0, 0.2);
  width: 50vh;
  flex-direction: column;
  padding: 2vh;
  align-items: center;
  margin-bottom: 1.5vh;
  border-radius: 20px;
  @media (max-width: 1000px){
  width: 40vh
  } 
 
`;

const StyledCanvas = styled.canvas<CanvasProps>`
  width: 100vh;
  height: 100vh;
  touch-action: none;
  ${(props) => props.isPanMode && 'cursor: move;'} // Apply cursor style if pan mode is active
  // Media query for screens with a width of 500px or less
  @media (max-width: 1080px) and (orientation: landscape) {
    width: 100vh; // Set width to 100% of the parent container
    height: 100vh; // Adjust height automatically
  }
 
`;

const ZoomButtonsContainer = styled.div`
  display: flex;
  gap: 10px; // Add some space between the buttons if needed
  @media (max-width: 1000px)
  gap: 5px;
  @media (max-height: 350px) {
  gap: 0vh;}
`;

const EditButton = styled.button`
background-color: rgba(7, 55, 99);
  color: white;
  margin-left: 5px;
  margin-bottom: 2vh;
  padding: 5;
  border: none;
  border-radius: 5px;
  font-size: 2.5vh;
  margin-top: 2vh;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #007bff;
  }
`;

const Button = styled.button`
  background-color: rgba(7, 55, 99);
  color: white;
  margin-left: 5px;
  margin-bottom: 2vh;
  padding: 5;
  border: none;
  border-radius: 5px;
  font-size: 2.5vh;
  margin-top: 2vh;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #007bff;
  }
  
`;

const CenteredMessage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh; // full height of the viewport
  text-align: center;
`;

const LogoImage = styled.img`
  width: 200px; // adjust as needed
  margin-bottom: 20px;
`;

const InputBox = styled.div`
  position: absolute;
  z-index: 1000;
  display: flex;
  align-items: center;

  input {
    width: 5vh; /* Smaller width */
    font-size: 2vh; /* Adjust font size if needed */
    padding: 0.5vh; /* Adjust padding if needed */
    border: 1px solid #ccc;
    border-radius: 3px;
    outline: none;
    overflow: hidden; /* Hide the scroll bar */
    -moz-appearance: textfield; /* Disable spinner in Firefox */
  }

  /* Disable spinner in Chrome, Safari, Edge, and Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  span {
    margin-left: 4px;
    font-size: 12px; /* Adjust font size to match input */
  }
`;

const Tooltip = styled.div`
  position: absolute;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 5px;
  border-radius: 3px;
  font-size: 12px;
  pointer-events: none;
  transform: translate(-50%, -50%);
`;

const ProjectContainer = styled.div`
  position: fixed;
  display: flex;
  top: 0.2rem;      
  padding-top: 0rem;
  flex-direction: row;
  left: 40%;
  transform: translateX(50%);
  `

  const OptionsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  background-color: rgba(7, 55, 99);
  padding: 1vh;
  max-width: 50vh;
  border-radius: 10px;
  gap: 1vh; // Add some space between the options
  max-height: 30vh;
  overflow-y: auto;
  @media (max-width: 1080px) {

    align-items: start;
    margin-bottom: 0vh;
    width: 100%;
  }
`;

const OptionButton = styled.button<{ selected: boolean; hasPoints: boolean }>`
  background-color: ${(props) =>
    props.selected
      ? '#007bff'
      : props.hasPoints
      ? '#ffc107' // Highlight color for options with points
      : 'transparent'};
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 2.5vh;
  padding: 1vh;
  cursor: pointer;
  transition: background-color 0.3s;
  flex: 1 1 45%; // Adjust width to fit two columns

  &:hover {
    background-color: #0056b3;
  }
`;



interface DesignProps {
  backgroundColor: string;
}

interface Dimensions {
  length: number;
  width: number;
}


interface Project {
  _id: string;
  userId: string;
  name: string;
  projectName: string;

  [key: string]: any;
}

interface Point {
  x: number;
  y: number;
}

interface Line {
  startPoint: Point;
  endPoint: Point;
  length: number;
}



const Design: React.FC<DesignProps> = ({ backgroundColor }) => {
    const [points, setPoints] = useState<{ [key: string]: Point[] }>({
        garden: [],
        patio: [],
        edgings: [],
        blockpaving: [],
        setts: [],
        concretepad: [],
        decking: [],
        gravel: [],
        fencing: [],
        walling: [],
        artificialturf: [],
        sleepers: [],
        turf: [],
        bark: [],
        manholecover: [],
        channeldrain: [],
        drainagepipe: [],
        gullypot: [],
        asphalt: [],
      });
      
      const [lines, setLines] = useState<{ [key: string]: Line[] }>({
        garden: [],
        patio: [],
        edgings: [],
        blockpaving: [],
        setts: [],
        concretepad: [],
        decking: [],
        gravel: [],
        fencing: [],
        walling: [],
        artificialturf: [],
        sleepers: [],
        turf: [],
        bark: [],
        manholecover: [],
        channeldrain: [],
        drainagepipe: [],
        gullypot: [],
        asphalt: []
      });
      
      const [isShapeClosed, setIsShapeClosed] = useState<{ [key: string]: boolean }>({
        garden: false,
        patio: false,
        blockpaving: false,
        setts: false,
        concretepad: false,
        decking: false,
        gravel: false,
        artificialturf: false,
        turf: false,
        bark: false,
        edgings: false,
        fencing: false,
        walling: false,
        sleepers: false,
        manholecover: true,
        channeldrain: false,
        drainagepipe: false,
        gullypot: true,
        asphalt: false,
      });
      

  const [currentLine, setCurrentLine] = useState<Line | null>(null);
  const [inputPosition, setInputPosition] = useState<{ x: number; y: number } | null>(null);
  const [inputValue, setInputValue] = useState('');
  const [selectedArea, setSelectedArea] = useState<string>('garden');
  const [showTooltip, setShowTooltip] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState<{ x: number; y: number } | null>(null);
  const [selectedPointIndex, setSelectedPointIndex] = useState<number | null>(null);
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [recentlyMoved, setRecentlyMoved] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [showLoginMessage, setShowLoginMessage] = useState(false);
  const [projects, setProjects] = useState<Project[]>([]);// eslint-disable-next-line
  const [pointsData, setPointsData] = useState<any>({});
  const [selectedProjectId, setSelectedProjectId] = useState<string | null>(null);
  const [selectedProjectName, setSelectedProjectName] = useState<string | null>(null);
  const [tempPoints, setTempPoints] = useState<Point[] | null>(null);
  const [tempLines, setTempLines] = useState<Line[] | null>(null);
  const [isPanning, setIsPanning] = useState(false);
  const [panStart, setPanStart] = useState<{ x: number; y: number } | null>(null);
  const [panOffset, setPanOffset] = useState<{ x: number; y: number }>({ x: 0, y: 0 });
  const [isPanMode, setIsPanMode] = useState(false);
  const [isPKeyPressed, setIsPKeyPressed] = useState(false);
  const [showSaveMessage, setShowSaveMessage] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [manholeCoverCount, setManholeCoverCount] = useState(0);
  const [gullyPotCount, setGullyPotCount] = useState(0);
  const [, setDeckingDimensions] = useState<{ [key: string]: { length: number, width: number } }>({});
  const [isSnapEnabled, setIsSnapEnabled] = useState(false);
  const [, setIsLengthLocked] = useState<boolean>(false);
  const [preSnapLength, setPreSnapLength] = useState<number | null>(null);
  const [isUserInputLengthLocked, setIsUserInputLengthLocked] = useState(false);
  const [curbCount, setcurbCount] = useState(0);



  const exportPointsToSketchUp = () => {
    const SCALE_FACTOR = 40;
    const RECTANGLE_WIDTH_METERS = 0.2;
    const RECTANGLE_WIDTH_PIXELS = RECTANGLE_WIDTH_METERS * PIXELS_PER_METER;
  
    const validPoints: { shape: string; x: number; y: number }[] = [];
  
    const calculateRectanglePointsForExport = (startPoint: Point, endPoint: Point, width: number): Point[] => {
      const angle = Math.atan2(endPoint.y - startPoint.y, endPoint.x - startPoint.x);
      const dx = (width / 2) * Math.sin(angle);
      const dy = (width / 2) * Math.cos(angle);
  
      return [
        { x: startPoint.x - dx, y: startPoint.y + dy },
        { x: startPoint.x + dx, y: startPoint.y - dy },
        { x: endPoint.x + dx, y: endPoint.y - dy },
        { x: endPoint.x - dx, y: endPoint.y + dy },
      ];
    };
  
    Object.entries(points).forEach(([shape, shapePoints]) => {
      if (shapePoints.length < 2) {
        console.warn(`Not enough points to form a line for shape: ${shape}`);
        return;
      }
  
      if (['manholecover', 'drainagepipe', 'gullypot'].some(prefix => shape.startsWith(prefix))) {
        console.warn(`Skipping export for shape: ${shape}`);
        return;
      }
  
      if (['walling', 'drainagepipe', 'edgings', 'curb', 'sleepers', 'fencing', 'channeldrain'].some(prefix => shape.startsWith(prefix))) {
        for (let i = 0; i < shapePoints.length - 1; i++) {
          const startPoint = shapePoints[i];
          const endPoint = shapePoints[i + 1];
  
          const rectanglePoints = calculateRectanglePointsForExport(
            { x: startPoint.x, y: startPoint.y },
            { x: endPoint.x, y: endPoint.y },
            RECTANGLE_WIDTH_PIXELS
          );
  
          rectanglePoints.forEach(point => {
            validPoints.push({
              shape: `${shape}_${i}`, // Unique shape name for each segment
              x: (point.x / PIXELS_PER_METER) * SCALE_FACTOR,
              y: (-point.y / PIXELS_PER_METER) * SCALE_FACTOR,
            });
          });
        }
  
        // Add closing line for single line shapes if the shape is closed
        if (isShapeClosed[shape]) {
          const startPoint = shapePoints[shapePoints.length - 1];
          const endPoint = shapePoints[0];
          const closingRectanglePoints = calculateRectanglePointsForExport(
            { x: startPoint.x, y: startPoint.y },
            { x: endPoint.x, y: endPoint.y },
            RECTANGLE_WIDTH_PIXELS
          );
  
          closingRectanglePoints.forEach(point => {
            validPoints.push({
              shape: `${shape}_closing`, // Unique shape name for closing segment
              x: (point.x / PIXELS_PER_METER) * SCALE_FACTOR,
              y: (-point.y / PIXELS_PER_METER) * SCALE_FACTOR,
            });
          });
        }
      } else {
        const shapeValidPoints: { shape: string; x: number; y: number }[] = [];
  
        shapePoints.forEach(point => {
          const scaledPoint = {
            shape,
            x: (point.x / PIXELS_PER_METER) * SCALE_FACTOR,
            y: (-point.y / PIXELS_PER_METER) * SCALE_FACTOR,
          };
  
          shapeValidPoints.push(scaledPoint);
        });
  
        if (shapeValidPoints.length >= 3) {
          validPoints.push(...shapeValidPoints);
        } else {
          console.warn(`Not enough valid points to form a face for shape: ${shape}`);
        }
      }
    });
  
    const csvContent = "data:text/csv;charset=utf-8,"
      + validPoints.map(e => `${e.shape},${e.x},${e.y}`).join("\n");
  
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
  
    // Set the download filename based on the project title
    const filename = selectedProjectName ? `${selectedProjectName} points.csv` : 'points.csv';
   
    link.setAttribute("download", filename);
    document.body.appendChild(link);
  
    link.click();
  };
  


  const isSmallScreen = (): boolean => {
    return window.innerWidth <= 1180; // Adjust the width threshold as needed
  };

  const [showEditButton, setShowEditButton] = useState(true);
  const [showUploadButton, setShowUploadButton] = useState(false);
  const [showInput, setShowInput] = useState(false);

  useEffect(() => {
    if (!isSmallScreen()) {
      setShowEditButton(false);
      setShowUploadButton(true);
      setShowInput(true)
    }
  }, []);


  const isBackgroundWhite = backgroundColor === 'rgba(245, 245, 245)' || backgroundColor === 'white';

  const [texturesLoaded, setTexturesLoaded] = useState(false);
  const [textures, setTextures] = useState<{ [key: string]: CanvasPattern | null }>({});
  const [zoomLevel, setZoomLevel] = useState(1);

  useEffect(() => {
    setPointsData({
      points,
      lines,
      isShapeClosed
    });
  }, [points, lines, isShapeClosed]);

  const handleZoomIn = () => {
    setZoomLevel(prevZoomLevel => Math.min(prevZoomLevel * 1.2, 5)); // Maximum zoom level of 5x
  };
  
  const handleZoomOut = () => {
    setZoomLevel(prevZoomLevel => Math.max(prevZoomLevel / 1.2, 0.2)); // Minimum zoom level of 0.2x
  };

  useEffect(() => {
    redrawCanvasContent(selectedArea);// eslint-disable-next-line 
  }, [zoomLevel]);

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await axios.get('https://projectprice-ad06ee250897.herokuapp.com/form/form');
        const projectList = response.data.data;
        if (Array.isArray(projectList)) {
          setProjects(projectList);
  
          // Check for last used project name in local storage
          const lastUsedProjectName = localStorage.getItem('lastUsedProject');
  
          if (lastUsedProjectName) {
            // Find the project with the matching name
            const matchedProject = projectList.find(project => project.projectName === lastUsedProjectName);
            if (matchedProject) {
              setSelectedProjectId(matchedProject._id);
              setSelectedProjectName(matchedProject.projectName); 
              await loadProjectData(matchedProject._id);
            } else {
              console.warn(`Project with name ${lastUsedProjectName} not found`);
            }
          }
        } else {
          console.warn("Received non-array data:", projectList);
        }
      } catch (error) {
        console.error('Error fetching projects:', error);
      }
    };
  
    fetchProjects(); // eslint-disable-next-line
  }, []);
  
  const handleProjectChange = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    const projectId = e.target.value;
    const selectedProject = projects.find(project => project._id === projectId);
    if (selectedProject) {
      setSelectedProjectId(projectId);
      setSelectedProjectName(selectedProject.projectName); // Set the selected project name
      
      localStorage.setItem('lastUsedProject', selectedProject.projectName); // Save project name to local storage
  
      try {
        await loadProjectData(projectId);
      } catch (error) {
        console.error('Error loading project data:', error);
      }
    } else {
      console.error('Selected project not found');
    }
  };
  
  
  const calculateBoundingBoxDimensions = (points: Point[]): { length: number, width: number } => {
    if (points.length === 0) return { length: 0, width: 0 };
  
    let minX = points[0].x;
    let maxX = points[0].x;
    let minY = points[0].y;
    let maxY = points[0].y;
  
    points.forEach(point => {
      if (point.x < minX) minX = point.x;
      if (point.x > maxX) maxX = point.x;
      if (point.y < minY) minY = point.y;
      if (point.y > maxY) maxY = point.y;
    });
  
    const width = (maxX - minX) / PIXELS_PER_METER;
    const length = (maxY - minY) / PIXELS_PER_METER;
  
    return { length, width };
  };
  
  const downloadPDF = async () => {
    const canvas = canvasRef.current;
    if (!canvas) return;
  
    // Check if there is any data to draw
    const hasData = Object.keys(points).some(shape => points[shape].length > 0);
    if (!hasData) {
      alert('No data available on the canvas to generate a PDF.');
      return;
    }
  
    // Calculate the bounding box of all shapes
    let minX = Infinity, minY = Infinity, maxX = -Infinity, maxY = -Infinity;
  
    Object.keys(points).forEach(shape => {
      if (shape.startsWith('drainagepipe')) return; // Skip drainage pipe in the bounding box calculation
  
      points[shape].forEach(point => {
        if (point.x < minX) minX = point.x;
        if (point.y < minY) minY = point.y;
        if (point.x > maxX) maxX = point.x;
        if (point.y > maxY) maxY = point.y;
      });
    });
  
    const boundingBoxWidth = maxX - minX;
    const boundingBoxHeight = maxY - minY;
  
    // Set a higher resolution for the offscreen canvas
    const scaleFactor = 4; // Increase this value for higher resolution
    const offscreenCanvas = document.createElement('canvas');
    const offscreenCtx = offscreenCanvas.getContext('2d');
  
    if (!offscreenCtx) return;
  
    offscreenCanvas.width = (boundingBoxWidth + 200) * scaleFactor; // Add padding to the canvas
    offscreenCanvas.height = (boundingBoxHeight + 200) * scaleFactor; // Add padding to the canvas
  
    // Draw all shapes on the offscreen canvas without drainage pipes
    offscreenCtx.save();
    offscreenCtx.scale(scaleFactor, scaleFactor); // Scale the context for higher resolution
    offscreenCtx.translate(-minX + 100, -minY + 100); // Adjust for the bounding box with padding
    drawAllPointsAndLines(offscreenCtx, selectedArea, false, true); // Pass true to exclude drainage pipes
    offscreenCtx.restore();
  
    // Generate the PDF from the offscreen canvas
    const imgData = offscreenCanvas.toDataURL('image/png');
    const pdf = new jsPDF('landscape', 'mm', 'a4');
  
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();
  
    // Calculate the scale factor to fit the bounding box into the PDF dimensions
    const pdfScaleFactor = Math.min(pdfWidth / (offscreenCanvas.width / scaleFactor), pdfHeight / (offscreenCanvas.height / scaleFactor));
  
    pdf.addImage(imgData, 'PNG', 0, 0, (offscreenCanvas.width / scaleFactor) * pdfScaleFactor, (offscreenCanvas.height / scaleFactor) * pdfScaleFactor);
  
    const filename = selectedProjectName ? `${selectedProjectName} drawing.pdf` : 'Drawing.pdf';
    pdf.save(filename);
  };
  
  
  const [area, setArea] = useState<{ [key: string]: number | null }>({
    garden: null,
    patio: null,
    blockpaving: null,
    setts: null,
    concretepad: null,
    decking: null,
    gravel: null,
    artificialturf: null,
    turf: null,
    bark: null,
    edgings: null,
    fencing: null,
    walling: null,
    sleepers: null,
    manholecover: null,
    channeldrain: null,
    drainagepipe: null,
    gullypot: null,
    curb: null,
    asphalt: null,
  });
  const [perimeter, setPerimeter] = useState<{ [key: string]: number | null }>({
    garden: null,
    patio: null,
    blockpaving: null,
    setts: null,
    concretepad: null,
    decking: null,
    gravel: null,
    artificialturf: null,
    turf: null,
    bark: null,
    edgings: null,
    fencing: null,
    walling: null,
    sleepers: null,
    manholecover: null,
    channeldrain: null,
    drainagepipe: null,
    gullypot: null,
    curb: null,
    asphalt: null,
  });

  const navigate = useNavigate();
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    const loadTexture = (src: string, scale: number): Promise<HTMLImageElement> => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = src;
        img.onload = () => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          if (ctx) {
            canvas.width = img.width * scale;
            canvas.height = img.height * scale;
            ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
            const scaledImg = new Image();
            scaledImg.src = canvas.toDataURL();
            scaledImg.onload = () => resolve(scaledImg);
            scaledImg.onerror = (err) => reject(err);
          } else {
            reject(new Error('Could not get canvas context'));
          }
        };
        img.onerror = (err) => reject(err);
      });
    };
  
    const loadTextures = async () => {
      try {
        const patioImg = await loadTexture('patio.png', 0.17);
        const turfImg = await loadTexture('grass.png', 0.25);
        const blockpavingImg = await loadTexture('blockpaving.png', 0.35);
        const settsImg = await loadTexture('setts.png', 0.2);
        const concretepadImg = await loadTexture('concrete.png', 0.25);
        const deckingImg = await loadTexture('decking.png', 0.2);
        const gravelImg = await loadTexture('gravel.png', 0.4);
        const barkImg = await loadTexture('bark.png', 0.2);
        const edgingImg = await loadTexture('edgings.png', 0.1);
        const asphaltImg = await loadTexture('asphalt.png', 0.2);
    
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
    
        if (ctx) {
          const patioPattern = ctx.createPattern(patioImg, 'repeat');
          const turfPattern = ctx.createPattern(turfImg, 'repeat');
          const blockpavingPattern = ctx.createPattern(blockpavingImg, 'repeat');
          const settsPattern = ctx.createPattern(settsImg, 'repeat');
          const concretepadPattern = ctx.createPattern(concretepadImg, 'repeat');
          const deckingPattern = ctx.createPattern(deckingImg, 'repeat');
          const gravelPattern = ctx.createPattern(gravelImg, 'repeat');
          const barkPattern = ctx.createPattern(barkImg, 'repeat');
          const edgingPattern = ctx.createPattern(edgingImg, 'repeat');
          const asphaltPattern = ctx.createPattern(asphaltImg, 'repeat');
    
          setTextures({
            patio: patioPattern,
            turf: turfPattern,
            artificialturf: turfPattern,
            blockpaving: blockpavingPattern,
            setts: settsPattern,
            concretepad: concretepadPattern,
            decking: deckingPattern,
            gravel: gravelPattern,
            bark: barkPattern,
            edgings: edgingPattern,
            asphalt: asphaltPattern,
          });
          setTexturesLoaded(true);
        }
      } catch (error) {
        console.error('Error loading textures:', error);
      }
    };
    
  
    loadTextures();
  }, []);
  

  useEffect(() => {
    const validateToken = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        setShowLoginMessage(true);
        setTimeout(() => {
          navigate('/loginpage');
        }, 3000); // 3 seconds delay
      }
    };

    validateToken();
  }, [navigate]);

  const PIXELS_PER_METER = 25; // Example: 25 pixels represent 1 meter

const drawGrid = (ctx: CanvasRenderingContext2D, canvasWidth: number, canvasHeight: number, zoomLevel: number, panOffset: { x: number; y: number }) => {
    ctx.save();
    ctx.clearRect(0, 0, canvasWidth, canvasHeight); // Clear the canvas

    ctx.scale(zoomLevel, zoomLevel); // Apply the zoom level
    ctx.translate(panOffset.x / zoomLevel, panOffset.y / zoomLevel); // Apply the pan offset

    const gridColor = '#cccccc'; // Set a color that contrasts well with the background
    ctx.strokeStyle = gridColor;
    ctx.lineWidth = 1 / zoomLevel; // Adjust line width based on zoom level

    const gridSpacing = PIXELS_PER_METER; // The spacing between grid lines

    ctx.beginPath();

    // Calculate the starting points based on the pan offset and zoom level
    const startX = Math.floor(-panOffset.x / zoomLevel / gridSpacing) * gridSpacing;
    const startY = Math.floor(-panOffset.y / zoomLevel / gridSpacing) * gridSpacing;

    // Draw vertical grid lines
    for (let x = startX; x <= (canvasWidth / zoomLevel - panOffset.x / zoomLevel); x += gridSpacing) {
        ctx.moveTo(x, startY);
        ctx.lineTo(x, canvasHeight / zoomLevel - panOffset.y / zoomLevel);
    }

    // Draw horizontal grid lines
    for (let y = startY; y <= (canvasHeight / zoomLevel - panOffset.y / zoomLevel); y += gridSpacing) {
        ctx.moveTo(startX, y);
        ctx.lineTo(canvasWidth / zoomLevel - panOffset.x / zoomLevel, y);
    }

    ctx.stroke();
    ctx.restore();
};


  const redrawCanvasContent = (selectedArea: string, drawPoints: boolean = true) => {
    if (!texturesLoaded) return; // Wait until textures are loaded
  
    const canvas = canvasRef.current;
    if (!canvas || !canvas.getContext) return;
  
    const ctx = canvas.getContext('2d');
    if (!ctx) return;
  
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    drawGrid(ctx, canvas.width, canvas.height, zoomLevel, panOffset);
    drawAllPointsAndLines(ctx, selectedArea, drawPoints);
  };
  

  type ShapeKey = 'garden' | 'patio' | 'blockpaving' | 'setts' | 'concretepad' | 'decking' | 'gravel' | 'artificialturf' | 'turf' | 'bark' | 'edgings' | 'fencing' | 'walling' | 'sleepers' | 'manholecover' | 'channeldrain' | 'drainagepipe' | 'gullypot' | 'curb' | 'asphalt';

  const shapeColors: Record<ShapeKey, string> = {
    garden: 'rgba(211, 211, 211, 0.6)',
    patio: 'blue',
    blockpaving: 'orange',
    setts: 'darkblue',
    concretepad: 'grey',
    decking: 'brown',
    gravel: 'black',
    artificialturf: 'lightgreen',
    turf: 'darkgreen',
    bark: 'darkbrown',
    edgings: 'rgba(26, 36, 67, 1)',
    fencing: 'rgba(194, 169, 78, 1)',
    walling: 'rgba(151, 72, 17, 1)',
    sleepers: 'rgba(153, 137, 58, 1)',
    manholecover: 'black',
    channeldrain: 'grey',
    drainagepipe: 'rgba(255, 131, 0, 0.2)',
    gullypot: 'rgba(255, 131, 0, 1)',
    curb: 'rgba(6, 9, 20, 1)',
    asphalt: 'black',
  };

 
  const NORMAL_LINE_WIDTH = 1;

  const FIXED_WIDTH_METERS = 0.2;
  const FIXED_WIDTH_PIXELS = FIXED_WIDTH_METERS * PIXELS_PER_METER;

  const calculateRectanglePoints = (startPoint: Point, endPoint: Point, width: number): Point[] => {
    const angle = Math.atan2(endPoint.y - startPoint.y, endPoint.x - startPoint.x);
    const dx = (width / 2) * Math.sin(angle);
    const dy = (width / 2) * Math.cos(angle);
  
    return [
      { x: startPoint.x - dx, y: startPoint.y + dy },
      { x: startPoint.x + dx, y: startPoint.y - dy },
      { x: endPoint.x + dx, y: endPoint.y - dy },
      { x: endPoint.x - dx, y: endPoint.y + dy },
    ];
  };
  
  const drawRectangle = (ctx: CanvasRenderingContext2D, points: Point[], color: string | CanvasPattern | null) => {
    ctx.beginPath();
    ctx.moveTo(points[0].x, points[0].y);
    for (let i = 1; i < points.length; i++) {
      ctx.lineTo(points[i].x, points[i].y);
    }
    ctx.closePath();
    ctx.fill();
    ctx.stroke();
  };

  const drawAllPointsAndLines = (
    ctx: CanvasRenderingContext2D,
    selectedArea: string,
    drawPoints: boolean = true,
    excludeDrainagePipe: boolean = false
  ) => {
    ctx.save();
    ctx.scale(zoomLevel, zoomLevel); // Apply the zoom level
    ctx.translate(panOffset.x / zoomLevel, panOffset.y / zoomLevel); // Apply the pan offset
  
    const allShapes = Object.keys(points);
    const normalShapes = allShapes.filter(
      (shape) => !['edgings', 'walling', 'fencing', 'sleepers', 'manholecover', 'channeldrain', 'drainagepipe', 'gullypot', 'curb'].some((suffix) => shape.startsWith(suffix))
    );
    const overlayShapes = allShapes.filter(
      (shape) => ['edgings', 'walling', 'fencing', 'sleepers', 'manholecover', 'channeldrain', 'drainagepipe', 'gullypot', 'curb'].some((suffix) => shape.startsWith(suffix))
    );
  
    // Draw all normal shapes first
    normalShapes.forEach((shape) => {
      if (excludeDrainagePipe && shape.startsWith('drainagepipe')) return; // Skip drawing drainage pipe
  
      const color = shapeColors[shape.replace(/\d+$/, '') as ShapeKey];
      ctx.fillStyle = color;
  
      // Set line width based on shape type
      const shapeBase = shape.replace(/\d+$/, '');
      ctx.lineWidth = 1; // Default line width
  
      ctx.strokeStyle = color;
      ctx.beginPath();
      if (lines[shape]) {
        lines[shape].forEach((line, index) => {
          if (index === 0) {
            ctx.moveTo(line.startPoint.x, line.startPoint.y);
          }
          ctx.lineTo(line.endPoint.x, line.endPoint.y);
        });
      }
  
      if (points[shape] && points[shape].length > 1 && isShapeClosed[shape]) {
        const firstPoint = points[shape][0];
        ctx.lineTo(firstPoint.x, firstPoint.y);
      }
  
      ctx.stroke();
  
      // Fill the shape if it's closed and not a manhole cover or gullypot
      if (isShapeClosed[shape] && points[shape] && points[shape].length > 2 && !['manholecover', 'gullypot'].includes(shapeBase)) {
        const texture = textures[shapeBase] || color;
        ctx.fillStyle = texture;
        ctx.beginPath();
        ctx.moveTo(points[shape][0].x, points[shape][0].y);
        for (let i = 1; i < points[shape].length; i++) {
          ctx.lineTo(points[shape][i].x, points[shape][i].y);
        }
        ctx.closePath();
        ctx.fill();
      }
  
      ctx.lineWidth = 1; // Reset line width to default after drawing each shape
    });
  
    // Draw overlay shapes (edgings, walling, fencing, sleepers, manholecover, channeldrain, drainagepipe, and gullypot) last to ensure they are on top
    overlayShapes.forEach((shape) => {
      if (excludeDrainagePipe && shape.startsWith('drainagepipe')) return; // Skip drawing drainage pipe
  
      const color = shapeColors[shape.replace(/\d+$/, '') as ShapeKey];
  
      ctx.strokeStyle = color;
      ctx.lineWidth = 1 / zoomLevel; // Use thin line width, adjusted for zoom
  
      if (shape.startsWith('manholecover') || shape.startsWith('gullypot')) {
        ctx.beginPath();
        points[shape].forEach((point, index) => {
          if (index === 0) {
            ctx.moveTo(point.x, point.y);
          } else {
            ctx.lineTo(point.x, point.y);
          }
        });
        ctx.closePath();
        ctx.stroke();
      } else if (['edgings', 'walling', 'fencing', 'sleepers', 'drainagepipe', 'curb', 'channeldrain'].some((suffix) => shape.startsWith(suffix))) {
        lines[shape].forEach((line) => {
          const rectPoints = calculateRectanglePoints(line.startPoint, line.endPoint, FIXED_WIDTH_PIXELS);
          ctx.fillStyle = color;
          drawRectangle(ctx, rectPoints, color);
        });
      } else {
        ctx.beginPath();
        if (lines[shape]) {
          lines[shape].forEach((line, index) => {
            if (index === 0) {
              ctx.moveTo(line.startPoint.x, line.startPoint.y);
            }
            ctx.lineTo(line.endPoint.x, line.endPoint.y);
          });
        }
  
        if (points[shape] && points[shape].length > 1 && isShapeClosed[shape]) {
          const firstPoint = points[shape][0];
          ctx.lineTo(firstPoint.x, firstPoint.y);
        }
  
        ctx.stroke();
      }
    });
  
    // Draw points for the selected shape last to ensure they are on top
    if (drawPoints) {
      const color = shapeColors[selectedArea.replace(/\d+$/, '') as ShapeKey];
      const pointColor =
        ['curb', 'edgings'].some((suffix) => selectedArea.startsWith(suffix)) ? 'blue' : ['walling', 'fencing', 'sleepers', 'drainagepipe', 'channeldrain'].some((suffix) => selectedArea.startsWith(suffix)) ? 'black' : color;
      ctx.fillStyle = pointColor;
      points[selectedArea].forEach((point) => {
        ctx.fillRect(point.x - 3, point.y - 3, 6, 6); // Draw square points
      });
    }
  
    ctx.restore(); // Restore the context to its original state
  };
  
  
  

  const handlePanStart = (event: React.MouseEvent | React.TouchEvent) => {
    if ((isPanMode || isPKeyPressed) && ('touches' in event ? event.touches.length === 1 : true)) {
      setIsPanning(true);
      setPanStart('touches' in event ? { x: event.touches[0].clientX, y: event.touches[0].clientY } : { x: event.clientX, y: event.clientY });
    }
  };
  
  const handlePanMove = (event: React.MouseEvent | React.TouchEvent) => {
    if (isPanning && panStart) {
      const { clientX, clientY } = 'touches' in event ? event.touches[0] : event;
      const deltaX = clientX - panStart.x;
      const deltaY = clientY - panStart.y;
      setPanOffset(prevOffset => ({ x: prevOffset.x + deltaX, y: prevOffset.y + deltaY }));
      setPanStart({ x: clientX, y: clientY });
      redrawCanvasContent(selectedArea);
    }
  };
  
  const handlePanEnd = () => {
    setIsPanning(false);
    setPanStart(null);
  };
  

  const resizeCanvas = () => {
    if (!canvasRef.current) return;

    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect(); // Get the displayed size

    // Set the internal size to match the displayed size
    canvas.width = rect.width;
    canvas.height = rect.height;

    redrawCanvasContent(selectedArea); // Redraw everything after resizing
  };

  useEffect(() => {
    const handleResize = () => {
      resizeCanvas();
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Initial setup

    return () => {
      window.removeEventListener('resize', handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Empty dependency array to run only on mount and unmount
  

  const handleCanvasClick = (event: React.MouseEvent) => {
    if (isPanMode || isPKeyPressed) {
      return; // Skip point creation if panning mode is active
    }
  
    if (!selectedProjectId) {
      const rect = canvasRef.current?.getBoundingClientRect();
      if (rect) {
        setTooltipPosition({ x: rect.width / 1, y: rect.height / 2 });
        setShowTooltip(true);
        setTimeout(() => setShowTooltip(false), 2000);
      }
      return;
    }
  
    if (!selectedArea) {
      const rect = canvasRef.current?.getBoundingClientRect();
      if (rect) {
        setTooltipPosition({ x: event.clientX - rect.left, y: event.clientY - rect.top });
        setShowTooltip(true);
        setTimeout(() => setShowTooltip(false), 2000);
      }
      return;
    }
  
    const canvas = canvasRef.current;
    if (!canvas) return;
  
    const rect = canvas.getBoundingClientRect();
    const x = (event.clientX - rect.left) / zoomLevel - panOffset.x / zoomLevel; // Adjust for zoom level and pan offset
    const y = (event.clientY - rect.top) / zoomLevel - panOffset.y / zoomLevel; // Adjust for zoom level and pan offset
  
    const newPoint: Point = { x, y };

    if (selectedArea.startsWith('manholecover') || selectedArea.startsWith('gullypot')) {
      // Handle manhole cover or gully pot as a fixed size shape
      const size = selectedArea.startsWith('manholecover') ? 0.6 * PIXELS_PER_METER : 0.3 * PIXELS_PER_METER; // 0.6 meters for manhole, 0.3 meters for gully pot
      const squarePoints: Point[] = [
        { x: newPoint.x - size / 2, y: newPoint.y - size / 2 },
        { x: newPoint.x + size / 2, y: newPoint.y - size / 2 },
        { x: newPoint.x + size / 2, y: newPoint.y + size / 2 },
        { x: newPoint.x - size / 2, y: newPoint.y + size / 2 }
      ];
  
      setPoints(prevPoints => ({
        ...prevPoints,
        [selectedArea]: squarePoints
      }));
      setIsShapeClosed(prev => ({ ...prev, [selectedArea]: true }));
  
      // Increment the count if it's the first point
      if (selectedArea.startsWith('manholecover') && points[selectedArea].length === 0) {
        setManholeCoverCount(prevCount => prevCount + 1);
      } else if (selectedArea.startsWith('gullypot') && points[selectedArea].length === 0) {
        setGullyPotCount(prevCount => prevCount + 1);
      }
  
      redrawCanvasContent(selectedArea);
      return; // Early return to avoid further processing for these shapes
    }
  
    if (
      points[selectedArea]?.length > 0 &&
      isNearFirstPoint(newPoint, points[selectedArea][0])
    ) {
      finishShape(selectedArea);
    } else {
      if (recentlyMoved || isDragging || isShapeClosed[selectedArea]) {
        return;
      }
  
      if (points[selectedArea]?.length > 0) {
        const lastPoint = points[selectedArea][points[selectedArea].length - 1];
        const lineLength = calculateLineLength(lastPoint, newPoint);
        const newLine = { startPoint: lastPoint, endPoint: newPoint, length: lineLength };
        setLines((prevLines) => ({
          ...prevLines,
          [selectedArea]: [...prevLines[selectedArea], newLine],
        }));
  
        // Immediately calculate and update the perimeter (length) of the current shape
        calculatePerimeter(selectedArea, [...lines[selectedArea], newLine]);
  
        setInputPosition({ x: event.clientX, y: event.clientY });
        setInputValue('');
        setCurrentLine(newLine); // Set the current line for reference
      }
      setPoints((prevPoints) => ({
        ...prevPoints,
        [selectedArea]: [...(prevPoints[selectedArea] || []), newPoint],
      }));
      drawLinesAfterAddingPoint([
        ...(points[selectedArea] || []),
        newPoint,
        isShapeClosed[selectedArea] ? points[selectedArea][0] : newPoint,
      ]);
  
      // Increment curb count if this is the first point added to the curb shape
      if (selectedArea.startsWith('curb') && points[selectedArea]?.length === 0) {
        setcurbCount((prevCount) => prevCount + 1);
      }
  
      if (selectedArea.startsWith('decking')) {
        const { length, width } = calculateBoundingBoxDimensions([...points[selectedArea], newPoint]);
        setDeckingDimensions((prevDimensions) => ({
          ...prevDimensions,
          [selectedArea]: { length, width },
        }));
      }
    }
  };
  
  
  const finishShape = (shape: string) => {
    if (points[shape].length < 3) {
     
      return;
    }

    if (recentlyMoved) {
     
      return;
    }
  
    if (points[shape].length > 1 && !isShapeClosed[shape]) {
      const firstPoint = points[shape][0];
      const lastPoint = points[shape][points[shape].length - 1];
      const closingLineLength = calculateLineLength(lastPoint, firstPoint);
      const closingLine = {
        startPoint: lastPoint,
        endPoint: firstPoint,
        length: closingLineLength
      };
  
      setLines(prevLines => ({
        ...prevLines,
        [shape]: [...prevLines[shape], closingLine]
      }));
  
      setIsShapeClosed(prev => ({
        ...prev,
        [shape]: true
      }));
  
      calculateArea(shape);
      calculatePerimeter(shape, [...lines[shape], closingLine]);
  
      setInputPosition(null);
      setCurrentLine(null);
  
  
      // Determine if a new shape should be created
      const shapeBase = shape.replace(/\d+$/, ''); // Remove any trailing numbers
      const allShapes = Object.keys(points).filter(key => key.startsWith(shapeBase));
      const completedShapes = allShapes.filter(key => isShapeClosed[key]);
      const newShape = `${shapeBase}${completedShapes.length + 1}`;
  
      if (completedShapes.length === allShapes.length) {
        // Add the new shape to the state
        setPoints(prevPoints => ({
          ...prevPoints,
          [newShape]: []
        }));
        setLines(prevLines => ({
          ...prevLines,
          [newShape]: []
        }));
        setIsShapeClosed(prev => ({
          ...prev,
          [newShape]: false
        }));
        setArea(prev => ({
          ...prev,
          [newShape]: null
        }));
        setPerimeter(prev => ({
          ...prev,
          [newShape]: null
        }));
  
        // Automatically switch to the new shape
        setSelectedArea(newShape);
      }
    }
  };
  
  

  const calculateLineLength = (startPoint: Point, endPoint: Point): number => {
    const dx = endPoint.x - startPoint.x;
    const dy = endPoint.y - startPoint.y;
    return Math.sqrt(dx * dx + dy * dy) / PIXELS_PER_METER; // Return the length in meters
  };

  const drawLinesAfterAddingPoint = (updatedPoints: Point[]) => {
    if (recentlyMoved) return;
  
    const canvas = canvasRef.current;
    if (!canvas) return;
    const ctx = canvas.getContext('2d');
    if (!ctx) return;
  
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    drawGrid(ctx, canvas.width, canvas.height, zoomLevel, panOffset);
  
    const allShapes = Object.keys(points);
    const normalShapes = allShapes.filter(shape => !['edgings', 'walling', 'fencing', 'sleepers', 'channeldrain', 'drainagepipe', 'gullypot', 'curb'].some(suffix => shape.startsWith(suffix)));
    const overlayShapes = allShapes.filter(shape => ['edgings', 'walling', 'fencing', 'sleepers', 'channeldrain', 'drainagepipe', 'gullypot', 'curb'].some(suffix => shape.startsWith(suffix)));
  
    // Draw all normal shapes first
    normalShapes.forEach(shape => {
      const color = shapeColors[shape.replace(/\d+$/, '') as ShapeKey];
      ctx.fillStyle = color;
  
      // Set line width based on shape type
      const shapeBase = shape.replace(/\d+$/, '');
      ctx.lineWidth = 1; // Default line width
  
      updatedPoints.forEach(point => {
        ctx.fillRect(point.x - 3, point.y - 3, 6, 6); // Draw square points
      });
  
      ctx.strokeStyle = color;
      ctx.beginPath();
      if (lines[shape]) {
        lines[shape].forEach((line, index) => {
          if (index === 0) {
            ctx.moveTo(line.startPoint.x, line.startPoint.y);
          }
          ctx.lineTo(line.endPoint.x, line.endPoint.y);
        });
      }
  
      if (updatedPoints.length > 1 && isShapeClosed[shape]) {
        const firstPoint = updatedPoints[0];
        ctx.lineTo(firstPoint.x, firstPoint.y);
      }
  
      if (isShapeClosed[shape] && updatedPoints.length > 1) {
        const firstPoint = updatedPoints[0];
        const lastPoint = updatedPoints[updatedPoints.length - 1];
        ctx.moveTo(lastPoint.x, lastPoint.y);
        ctx.lineTo(firstPoint.x, firstPoint.y);
      }
  
      ctx.stroke();
  
      // Fill the shape if it's closed
      if (isShapeClosed[shape] && updatedPoints.length > 2) {
        const texture = textures[shapeBase] || color;
        ctx.fillStyle = texture;
        ctx.beginPath();
        ctx.moveTo(updatedPoints[0].x, updatedPoints[0].y);
        for (let i = 1; i < updatedPoints.length; i++) {
          ctx.lineTo(updatedPoints[i].x, updatedPoints[i].y);
        }
        ctx.closePath();
        ctx.fill();
      }
  
      ctx.lineWidth = 1; // Reset line width to default after drawing each shape
    });
  
    // Draw overlay shapes (edgings, walling, fencing, sleepers, and channeldrain) last to ensure they are on top
    overlayShapes.forEach(shape => {
      const color = shapeColors[shape.replace(/\d+$/, '') as ShapeKey];
      const texture = shape.startsWith('edgings') ? textures.edgings : color; // Use texture for edgings, fall back to color if texture is null
  
      ctx.fillStyle = color;
  
      // Set line width for overlay shapes
      ctx.lineWidth = shape.startsWith('manholecover') ? 1 / zoomLevel : 5 / zoomLevel; // Adjusted for zoom
  
      updatedPoints.forEach(point => {
        ctx.fillRect(point.x - 3 / zoomLevel, point.y - 3 / zoomLevel, 6 / zoomLevel, 6 / zoomLevel); // Draw square points
      });
  
      ctx.strokeStyle = texture || color; // Ensure strokeStyle is never null
      ctx.beginPath();
      if (lines[shape]) {
        lines[shape].forEach((line, index) => {
          if (index === 0) {
            ctx.moveTo(line.startPoint.x, line.startPoint.y);
          }
          ctx.lineTo(line.endPoint.x, line.endPoint.y);
        });
      }
  
      if (updatedPoints.length > 1 && isShapeClosed[shape]) {
        const firstPoint = updatedPoints[0];
        ctx.lineTo(firstPoint.x, firstPoint.y);
      }
  
      if (isShapeClosed[shape] && updatedPoints.length > 1) {
        const firstPoint = updatedPoints[0];
        const lastPoint = updatedPoints[updatedPoints.length - 1];
        ctx.moveTo(lastPoint.x, lastPoint.y);
        ctx.lineTo(firstPoint.x, firstPoint.y);
      }
  
      ctx.stroke();
    });
  };
  
  
  
  const isNearFirstPoint = (point: Point, firstPoint: Point): boolean => {
    const threshold = 10;
    const distance = Math.sqrt(
      Math.pow(point.x - firstPoint.x, 2) + Math.pow(point.y - firstPoint.y, 2)
    );
    return distance < threshold;
  };

  const drawLines = () => {
    const canvas = canvasRef.current;
    if (!canvas) return;
    const ctx = canvas.getContext('2d');
    if (!ctx) return;
  
    ctx.clearRect(0, 0, canvas.width, canvas.height);
  
    ctx.fillStyle = 'black';
  
    Object.keys(points).forEach(shape => {
      if (!points[shape]) return;
      points[shape].forEach(point => {
        ctx.beginPath();
        ctx.arc(point.x, point.y, 3, 0, 2 * Math.PI);
        ctx.fill();
      });
  
      ctx.strokeStyle = 'black';
      ctx.beginPath();
      if (lines[shape]) {
        lines[shape].forEach((line, index) => {
          if (index === 0) {
            ctx.moveTo(line.startPoint.x, line.startPoint.y);
          }
          ctx.lineTo(line.endPoint.x, line.endPoint.y);
        });
      }
  
      if (points[shape] && points[shape].length > 1 && isShapeClosed[shape]) {
        const firstPoint = points[shape][0];
        ctx.lineTo(firstPoint.x, firstPoint.y);
      }
  
      ctx.stroke();
    });
  
    drawGrid(ctx, canvas.width, canvas.height, zoomLevel, panOffset);
  };

  const calculateArea = (shape: string) => {
    let pixelArea = 0;
    const numPoints = points[shape].length;

    for (let i = 0; i < numPoints; i++) {
      const j = (i + 1) % numPoints;
      pixelArea += points[shape][i].x * points[shape][j].y;
      pixelArea -= points[shape][j].x * points[shape][i].y;
    }

    pixelArea = Math.abs(pixelArea) / 2;
    const areaInSquareMeters = pixelArea * Math.pow(PIXELS_PER_METER, -2);

    setArea(prev => ({
      ...prev,
      [shape]: areaInSquareMeters
    }));
  };

  const calculatePerimeter = (shape: string, linesArray: Line[]) => {
    let totalPerimeter = 0;
    linesArray.forEach((line: Line) => {
      totalPerimeter += calculateLineLength(line.startPoint, line.endPoint);
    });

    setPerimeter(prev => ({
      ...prev,
      [shape]: totalPerimeter
    }));
  };

  const removeLastPoint = useCallback(() => {
    if (points[selectedArea].length > 0) {
      let newPoints = points[selectedArea];
      let newLines = lines[selectedArea];

      if (isShapeClosed[selectedArea]) {
        newLines = newLines.length > 1 ? newLines.slice(0, -2) : [];
        newPoints = newPoints.slice(0, -1);
        setIsShapeClosed(prev => ({ ...prev, [selectedArea]: false }));
      } else {
        newLines = newLines.length > 0 ? newLines.slice(0, -1) : [];
        newPoints = newPoints.slice(0, -1);
      }

      setLines(prevLines => ({
        ...prevLines,
        [selectedArea]: newLines
      }));
      setPoints(prevPoints => ({
        ...prevPoints,
        [selectedArea]: newPoints
      }));
      setArea(prev => ({ ...prev, [selectedArea]: 0 }));
      setPerimeter(prev => ({ ...prev, [selectedArea]: 0 }));

      // Hide the input box when a point is removed
      setInputPosition(null);
      setCurrentLine(null);

      drawLinesAfterRemovingPoint(newPoints);
    }  /* eslint-disable-next-line react-hooks/exhaustive-deps */ 
  }, [points, selectedArea, lines, isShapeClosed]); // Add relevant dependencies
  

  const drawLinesAfterRemovingPoint = (updatedPoints: Point[]) => {
    const canvas = canvasRef.current;
    if (!canvas) return;
    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    ctx.clearRect(0, 0, canvas.width, canvas.height);
    drawGrid(ctx, canvas.width, canvas.height, zoomLevel, panOffset);

    const color = shapeColors[selectedArea.replace(/\d+$/, '') as ShapeKey];
    ctx.fillStyle = color;
    updatedPoints.forEach(point => {
      ctx.beginPath();
      ctx.arc(point.x, point.y, 3, 0, 2 * Math.PI);
      ctx.fill();
    });

    ctx.strokeStyle = color;
    ctx.beginPath();
    lines[selectedArea].slice(0, updatedPoints.length - 1).forEach((line, index) => {
      if (index === 0) {
        ctx.moveTo(line.startPoint.x, line.startPoint.y);
      }
      ctx.lineTo(line.endPoint.x, line.endPoint.y);
    });

    if (updatedPoints.length > 1 && isShapeClosed[selectedArea]) {
      ctx.lineTo(updatedPoints[0].x, updatedPoints[0].y);
    }

    ctx.stroke();
  };

  const handleMouseDown = (event: React.MouseEvent) => {
    if (isPanMode || isPKeyPressed) {
      if (event.button === 0) { // Left mouse button for panning
        setIsPanning(true);
        setPanStart({ x: event.clientX, y: event.clientY });
      }
    } else {
      const canvas = canvasRef.current;
      if (!canvas) return;
  
      const rect = canvas.getBoundingClientRect();
      const x = (event.clientX - rect.left - panOffset.x) / zoomLevel; // Adjust for zoom level and pan offset
      const y = (event.clientY - rect.top - panOffset.y) / zoomLevel; // Adjust for zoom level and pan offset
      const THRESHOLD = 10 / zoomLevel; // Adjust threshold for zoom level
  
      const currentPoints = points[selectedArea] || []; // Ensure points array exists
  
      // Check if the shape is a manhole cover and handle it as a single unit
      if (selectedArea.startsWith('manholecover' || 'gullypot')) {
        const hitIndex = currentPoints.findIndex(
          p => Math.sqrt(Math.pow(p.x - x, 2) + Math.pow(p.y - y, 2)) < THRESHOLD
        );
  
        if (hitIndex !== -1) {
          setSelectedPointIndex(0); // Treat the entire manhole cover as one entity
          setIsDragging(true);

          setTempPoints([...currentPoints]);
          setTempLines([...lines[selectedArea]]);
        }
      } else {
        const hitIndex = currentPoints.findIndex(
          p => Math.sqrt(Math.pow(p.x - x, 2) + Math.pow(p.y - y, 2)) < THRESHOLD
        );
  
        if (hitIndex !== -1) {
          setSelectedPointIndex(hitIndex);
          setIsDragging(true);
          setTempPoints([...currentPoints]);
          setTempLines([...lines[selectedArea]]);
          
          if (lines[selectedArea] && lines[selectedArea].length > 0) {
            const currentLine = lines[selectedArea].find(
              line => line.startPoint === currentPoints[hitIndex] || line.endPoint === currentPoints[hitIndex]
            );
            
            if (currentLine) {
              const currentLineLength = calculateLineLength(currentLine.startPoint, currentLine.endPoint);
              setPreSnapLength(currentLineLength); // Update preSnapLength with the length of the current line
            }
          }
        }
      }
    }
  };

  const toDegrees = (radians: number) => radians * (180 / Math.PI);

const toRadians = (degrees: number) => degrees * (Math.PI / 180);

const snapAngle = (angle: number, increment: number) => {
  return Math.round(angle / increment) * increment;
};

const SNAP_THRESHOLD = 5;
const handleMouseMove = (event: React.MouseEvent) => {
  if (isPanning && panStart) {
    const deltaX = event.clientX - panStart.x;
    const deltaY = event.clientY - panStart.y;
    setPanOffset(prevOffset => ({ x: prevOffset.x + deltaX, y: prevOffset.y + deltaY }));
    setPanStart({ x: event.clientX, y: event.clientY });
    redrawCanvasContent(selectedArea);
  } else if (isDragging && selectedPointIndex !== null && tempPoints && tempLines) {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const rect = canvas.getBoundingClientRect();
    const mouseX = (event.clientX - rect.left - panOffset.x) / zoomLevel;
    const mouseY = (event.clientY - rect.top - panOffset.y) / zoomLevel;

    let snappedPoint = { x: mouseX, y: mouseY };

    // Check if the cursor should snap to a nearby line from any shape
    let minDistance = Infinity;
    Object.keys(lines).forEach(shape => {
      lines[shape].forEach(line => {
        const closestPoint = closestPointOnLine(snappedPoint, line.startPoint, line.endPoint);
        const distance = Math.sqrt(Math.pow(closestPoint.x - mouseX, 2) + Math.pow(closestPoint.y - mouseY, 2));
        if (distance < SNAP_THRESHOLD && distance < minDistance) {
          snappedPoint = closestPoint;
          minDistance = distance;
        }
      });
    });

    // Update the point to the snapped position
    const newX = snappedPoint.x;
    const newY = snappedPoint.y;

    setInputPosition(null); // Remove the input box when a point is moved
    updatePointsAndLines(newX, newY);
    setRecentlyMoved(true); // Set recently moved to true after dragging
  
    setTimeout(() => setRecentlyMoved(false), 400);
   
    

  }
};

const updatePointsAndLines = (newX: number, newY: number, isTouch: boolean = false) => {
  if (isDragging && selectedPointIndex !== null && tempPoints && tempLines) {
    const startPoint = tempPoints[selectedPointIndex === 0 ? tempPoints.length - 1 : selectedPointIndex - 1];
    const dx = newX - startPoint.x;
    const dy = newY - startPoint.y;

    let snappedNewX = newX;
    let snappedNewY = newY;

    if (isSnapEnabled) {
      const angle = toDegrees(Math.atan2(dy, dx));
      const snappedAngle = snapAngle(angle, 15);

      let length: number;
      if (isUserInputLengthLocked && preSnapLength !== null) {
        length = preSnapLength * PIXELS_PER_METER;
      } else if (currentLine && preSnapLength === null) {
        length = currentLine.length * PIXELS_PER_METER;
        setPreSnapLength(currentLine.length); // Update preSnapLength if not locked by user input
      } else if (preSnapLength === null) {
        length = Math.sqrt(dx * dx + dy * dy);
        setPreSnapLength(length / PIXELS_PER_METER); // Update preSnapLength if not locked by user input
      } else {
        length = preSnapLength * PIXELS_PER_METER;
      }

      const snappedDx = length * Math.cos(toRadians(snappedAngle));
      const snappedDy = length * Math.sin(toRadians(snappedAngle));

      snappedNewX = startPoint.x + snappedDx;
      snappedNewY = startPoint.y + snappedDy;


    } else {
      // Update preSnapLength during normal drag move if not set by user input
      if (!isUserInputLengthLocked) {
        const length = Math.sqrt(dx * dx + dy * dy) / PIXELS_PER_METER;
        if (preSnapLength !== length) {
          setPreSnapLength(length);
        }
      }
    }

    let deltaX = snappedNewX - tempPoints[selectedPointIndex].x;
    let deltaY = snappedNewY - tempPoints[selectedPointIndex].y;

    const updatedPoints = tempPoints.map((point, index) => {
      if (selectedArea.startsWith('manholecover') || selectedArea.startsWith('gullypot')) {
        return { x: point.x + deltaX, y: point.y + deltaY };
      } else {
        return index === selectedPointIndex ? { x: snappedNewX, y: snappedNewY } : point;
      }
    });

    const updatedLines = tempLines.map(line => {
      if (selectedArea.startsWith('manholecover') || selectedArea.startsWith('gullypot')) {
        return {
          ...line,
          startPoint: { x: line.startPoint.x + deltaX, y: line.startPoint.y + deltaY },
          endPoint: { x: line.endPoint.x + deltaX, y: line.endPoint.y + deltaY }
        };
      } else {
        if (line.startPoint === tempPoints[selectedPointIndex]) {
          const updatedLine = { ...line, startPoint: updatedPoints[selectedPointIndex] };
          if (line === currentLine) {
            updatedLine.length = calculateLineLength(updatedLine.startPoint, updatedLine.endPoint);
            setPreSnapLength(updatedLine.length); // Update preSnapLength to the new length
          }
          return updatedLine;
        } else if (line.endPoint === tempPoints[selectedPointIndex]) {
          const updatedLine = { ...line, endPoint: updatedPoints[selectedPointIndex] };
          if (line === currentLine) {
            updatedLine.length = calculateLineLength(updatedLine.startPoint, updatedLine.endPoint);
            setPreSnapLength(updatedLine.length); // Update preSnapLength to the new length
          }
          return updatedLine;
        }
        return line;
      }
    });

    setTempPoints(updatedPoints);
    setTempLines(updatedLines);
    drawLinesAfterMovingPoint(updatedPoints, updatedLines);

    calculateAreaForTempPoints(updatedPoints);
    calculatePerimeter(selectedArea, updatedLines);

    if (selectedArea.startsWith('decking')) {
      const { length, width } = calculateBoundingBoxDimensions(updatedPoints);
      setDeckingDimensions(prevDimensions => ({
        ...prevDimensions,
        [selectedArea]: { length, width }
      }));
    }

    if (isTouch) {
      setTimeout(() => {
        setRecentlyMoved(false);
      }, 300);
    }
  }
};


const handleMouseUp = () => {
  if (isPanning) {
      setIsPanning(false);
      setPanStart(null);
  } else if (isDragging) {
      setIsDragging(false);
      setSelectedPointIndex(null);

      if (tempPoints && tempLines) {
          const originalPoints = points[selectedArea]; // Points before dragging
          let hasMoved = false;

          // Check if any point has actually moved
          for (let i = 0; i < originalPoints.length; i++) {
              if (
                  originalPoints[i].x !== tempPoints[i].x ||
                  originalPoints[i].y !== tempPoints[i].y
              ) {
                  hasMoved = true;
                  break;
              }
          }

          // Update points and lines only if there was movement
          if (hasMoved) {
              setPoints(prevPoints => ({ ...prevPoints, [selectedArea]: tempPoints }));
              setLines(prevLines => ({ ...prevLines, [selectedArea]: tempLines }));
              calculateArea(selectedArea);
              calculatePerimeter(selectedArea, tempLines);
          }

          setTempPoints(null);
          setTempLines(null);

          if (hasMoved) {
              // Set recentlyMoved to true only if the point actually moved
              setRecentlyMoved(true);

              setTimeout(() => {
                  setRecentlyMoved(false);
                  
              }, 100); // 100ms delay (adjust as needed)
          } else {
              return;
          }
      }
  }
};


  const redrawCanvas = () => {
  if (!texturesLoaded) return; // Ensure textures are loaded
  saveFormDataToLocalStorage()

  const canvas = canvasRef.current;
  if (!canvas) return;
  const ctx = canvas.getContext('2d');
  if (!ctx) return;

  ctx.clearRect(0, 0, canvas.width, canvas.height);
  drawGrid(ctx, canvas.width, canvas.height, zoomLevel, panOffset);
  drawAllPointsAndLines(ctx, selectedArea);
};


  useEffect(() => {
    const handleResize = () => {
      resizeCanvas();
      redrawCanvas();
    };

    const handleScroll = () => {
      redrawCanvas();
    };

    window.addEventListener('resize', handleResize);
    window.addEventListener('scroll', handleScroll);

    redrawCanvas();

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('scroll', handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [points, lines]);

  const drawLinesAfterMovingPoint = (updatedPoints: Point[] = [], updatedLines: Line[] = []) => {
    const canvas = canvasRef.current;
    if (!canvas) return;
    const ctx = canvas.getContext('2d');
    if (!ctx) return;
  
    ctx.clearRect(0, 0, canvas.width, canvas.height);
  
    // Draw the grid before applying zoom and pan transformations
    drawGrid(ctx, canvas.width, canvas.height, zoomLevel, panOffset);
  
    ctx.save();
    ctx.scale(zoomLevel, zoomLevel); // Apply the zoom level
    ctx.translate(panOffset.x / zoomLevel, panOffset.y / zoomLevel); // Apply the pan offset
  
    // Draw all other shapes first
    Object.keys(points).forEach((shape) => {
      const shapePoints = points[shape];
      const shapeLines = lines[shape];
      const shapeColor = shapeColors[shape.replace(/\d+$/, '') as ShapeKey];
  
      ctx.strokeStyle = shapeColor;
      ctx.fillStyle = shapeColor;
  
      const shapeBase = shape.replace(/\d+$/, '');
  
      if (['edgings', 'walling', 'fencing', 'sleepers', 'drainagepipe', 'curb', 'channeldrain'].includes(shapeBase)) {
        ctx.lineWidth = 7 / zoomLevel; // Increase this value for thicker lines, adjusted for zoom
      } else {
        ctx.lineWidth = 1 / zoomLevel; // Increase the default line width if needed, adjusted for zoom
      }
  
      if (shape !== selectedArea) {
        // Draw all other shapes
        shapeLines.forEach(line => {
          ctx.beginPath();
          ctx.moveTo(line.startPoint.x, line.startPoint.y);
          ctx.lineTo(line.endPoint.x, line.endPoint.y);
          ctx.stroke();
        });
  
        if (isShapeClosed[shape] && shapePoints.length > 2) {
          if (!['edgings', 'walling', 'fencing', 'sleepers', 'manholecover', 'channeldrain', 'drainagepipe', 'gullypot', 'curb'].includes(shapeBase)) {
            const shapeTexture = textures[shapeBase] || shapeColor;
            ctx.fillStyle = shapeTexture;
            ctx.beginPath();
            ctx.moveTo(shapePoints[0].x, shapePoints[0].y);
            for (let i = 1; i < shapePoints.length; i++) {
              ctx.lineTo(shapePoints[i].x, shapePoints[i].y);
            }
            ctx.closePath();
            ctx.fill();
          }
        }
      }
    });
  
    const color = shapeColors[selectedArea.replace(/\d+$/, '') as ShapeKey];
    ctx.fillStyle = color;
  
    // Set line width based on shape type
    const shapeBase = selectedArea.replace(/\d+$/, '');
    if (['edgings', 'walling', 'fencing', 'sleepers', 'drainagepipe', 'curb', 'channeldrain'].includes(shapeBase)) {
      ctx.lineWidth = 1 / zoomLevel; // Maintain thicker line width for these shapes, adjusted for zoom
    } else {
      ctx.lineWidth = 1 / zoomLevel; // Default line width, adjusted for zoom
    }
  
    updatedPoints.forEach((point: Point) => {
      ctx.fillRect(point.x - 3 / zoomLevel, point.y - 3 / zoomLevel, 6 / zoomLevel, 6 / zoomLevel); // Draw square points
    });
  
    ctx.strokeStyle = color;
  
    if (['edgings', 'walling', 'fencing', 'sleepers', 'drainagepipe', 'curb', 'channeldrain'].some(suffix => selectedArea.startsWith(suffix))) {
      updatedLines.forEach(line => {
        const rectPoints = calculateRectanglePoints(line.startPoint, line.endPoint, FIXED_WIDTH_PIXELS);
        drawRectangle(ctx, rectPoints, color);
      });
    } else {
      ctx.beginPath();
      updatedLines.forEach((line: Line) => {
        ctx.moveTo(line.startPoint.x, line.startPoint.y); // Adjust for zoom level
        ctx.lineTo(line.endPoint.x, line.endPoint.y); // Adjust for zoom level
      });
  
      if (isShapeClosed[selectedArea] && updatedPoints.length > 1) {
        ctx.moveTo(updatedPoints[updatedPoints.length - 1].x, updatedPoints[updatedPoints.length - 1].y); // Adjust for zoom level
        ctx.lineTo(updatedPoints[0].x, updatedPoints[0].y); // Adjust for zoom level
      }
  
      ctx.stroke();
    }
  
    // Fill the shape if it's closed and not one of the specific single-line shapes
    if (isShapeClosed[selectedArea] && updatedPoints.length > 2) {
      if (!['edgings', 'walling', 'fencing', 'sleepers', 'manholecover', 'channeldrain', 'drainagepipe', 'gullypot', 'curb'].includes(shapeBase)) {
        const texture = textures[shapeBase] || color;
        ctx.fillStyle = texture;
        ctx.beginPath();
        ctx.moveTo(updatedPoints[0].x, updatedPoints[0].y); // Adjust for zoom level
        for (let i = 1; i < updatedPoints.length; i++) {
          ctx.lineTo(updatedPoints[i].x, updatedPoints[i].y); // Adjust for zoom level
        }
        ctx.closePath();
        ctx.fill();
      }
    }
  
    ctx.restore();
    ctx.lineWidth = NORMAL_LINE_WIDTH; // Reset line width to default after drawing
  };
  
  
  useEffect(() => {
    drawLinesAfterMovingPoint(points[selectedArea], lines[selectedArea]);
    drawLines();
    redrawCanvas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lines, isShapeClosed]);

  const clearAll = () => {
  const currentPoints = points[selectedArea];

  if (selectedArea.startsWith('manholecover') && currentPoints.length > 0) {
    setManholeCoverCount(prevCount => Math.max(prevCount - 1, 0));
  }
  if (selectedArea.startsWith('gullypot') && currentPoints.length > 0) {
    setGullyPotCount(prevCount => Math.max(prevCount - 1, 0));
  }
  if (selectedArea.startsWith('curb') && currentPoints.length > 0) {
    setcurbCount(prevCount => Math.max(prevCount - 1, 0));
  }

  setPoints(prevPoints => ({ ...prevPoints, [selectedArea]: [] }));
  setLines(prevLines => ({ ...prevLines, [selectedArea]: [] }));
  setIsShapeClosed(prev => ({ ...prev, [selectedArea]: false }));
  setArea(prev => ({ ...prev, [selectedArea]: 0 }));
  setPerimeter(prev => ({ ...prev, [selectedArea]: 0 }));
  setInputPosition(null);
  setCurrentLine(null);
};

const closestPointOnLine = (p: Point, v: Point, w: Point): Point => {
  const l2 = Math.pow(v.x - w.x, 2) + Math.pow(v.y - w.y, 2); // Length of the line squared
  if (l2 === 0) return v; // v == w case
  const t = Math.max(0, Math.min(1, ((p.x - v.x) * (w.x - v.x) + (p.y - v.y) * (w.y - v.y)) / l2));
  return { x: v.x + t * (w.x - v.x), y: v.y + t * (w.y - v.y) };
};

  
  
  const calculateAreaForTempPoints = (tempPoints: Point[]) => {
    let pixelArea = 0;
    const numPoints = tempPoints.length;
  
    for (let i = 0; i < numPoints; i++) {
      const j = (i + 1) % numPoints;
      pixelArea += tempPoints[i].x * tempPoints[j].y;
      pixelArea -= tempPoints[j].x * tempPoints[i].y;
    }
  
    pixelArea = Math.abs(pixelArea) / 2;
    const areaInSquareMeters = pixelArea * Math.pow(PIXELS_PER_METER, -2);
  
    setArea(prev => ({
      ...prev,
      [selectedArea]: areaInSquareMeters
    }));
  };

  const handleTouchStart = (event: React.TouchEvent<HTMLCanvasElement>) => {
    
    if (isPanMode || isPKeyPressed) return;
    if (!isEditMode) return;
    event.preventDefault();
    document.body.style.overflow = 'hidden';
  
    if (event.touches.length === 1) {
      const touch = event.touches[0];
      const canvas = canvasRef.current;
      if (!canvas) return;
  
      const rect = canvas.getBoundingClientRect();
      const x = (touch.clientX - rect.left - panOffset.x) / zoomLevel;
      const y = (touch.clientY - rect.top - panOffset.y) / zoomLevel;
      const THRESHOLD = 10 / zoomLevel;
  
      const currentPoints = points[selectedArea] || [];
      const hitIndex = currentPoints.findIndex(
        p => Math.sqrt(Math.pow(p.x - x, 2) + Math.pow(p.y - y, 2)) < THRESHOLD
      );
  
     
  
      if (hitIndex !== -1) {
        setSelectedPointIndex(hitIndex);
        setIsDragging(true);
        setTempPoints([...currentPoints]);
        setTempLines([...lines[selectedArea]]);
       
      }
    }
  };
  
  const handleTouchMove = (event: React.TouchEvent<HTMLCanvasElement>) => {
    if (isPanMode || isPKeyPressed) return;
    if (!isEditMode) return;
    event.preventDefault();
    document.body.style.overflow = 'hidden';
  
    if (isDragging && selectedPointIndex !== null) {
      const canvas = canvasRef.current;
      if (!canvas) return;
  
      if (event.touches.length === 0) return;
  
      const touch = event.touches[0];
      const rect = canvas.getBoundingClientRect();
      const newX = (touch.clientX - rect.left - panOffset.x) / zoomLevel;
      const newY = (touch.clientY - rect.top - panOffset.y) / zoomLevel;
  
      
  
      updatePointsAndLines(newX, newY, true);
    }
  };
  
  const handleTouchEnd = () => {
    if (isDragging) {
      setIsDragging(false);
      setSelectedPointIndex(null);
  
      
  
      if (tempPoints && tempLines) {
        setPoints(prevPoints => ({ ...prevPoints, [selectedArea]: tempPoints }));
        setLines(prevLines => ({ ...prevLines, [selectedArea]: tempLines }));
        calculateArea(selectedArea);
        calculatePerimeter(selectedArea, tempLines);
        setTempPoints(null);
        setTempLines(null);
        setRecentlyMoved(true);
        setTimeout(() => setRecentlyMoved(false), 300);
        setPreSnapLength(null);
        setIsLengthLocked(false);
        saveFormDataToLocalStorage();
      }
    }
    document.body.style.overflow = '';
  };
  
  

  const handleLineMeasurementInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const handleLineMeasurementInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && currentLine) {
      const newLengthInMeters = parseFloat(inputValue);
      if (!isNaN(newLengthInMeters)) {
        const newLengthInPixels = newLengthInMeters * PIXELS_PER_METER;
        const currentLengthInPixels = currentLine.length * PIXELS_PER_METER;
        const scaleFactor = newLengthInPixels / currentLengthInPixels;
        const deltaX = (currentLine.endPoint.x - currentLine.startPoint.x) * scaleFactor;
        const deltaY = (currentLine.endPoint.y - currentLine.startPoint.y) * scaleFactor;
        const newEndPoint = {
          x: currentLine.startPoint.x + deltaX,
          y: currentLine.startPoint.y + deltaY
        };
  
        const updatedLines = lines[selectedArea].map(line =>
          line === currentLine ? { ...line, endPoint: newEndPoint, length: newLengthInMeters } : line
        );
        const updatedPoints = points[selectedArea].map(point =>
          point === currentLine.endPoint ? newEndPoint : point
        );
  
        setLines(prevLines => ({ ...prevLines, [selectedArea]: updatedLines }));
        setPoints(prevPoints => ({ ...prevPoints, [selectedArea]: updatedPoints }));
        setCurrentLine(null);
        setInputPosition(null);
        setIsLengthLocked(true); // Lock the length after setting it
        setIsUserInputLengthLocked(true); // Set the user input length locked flag
        setPreSnapLength(newLengthInMeters); // Store the pre-snap length in meters
  
        calculatePerimeter(selectedArea, updatedLines); // Update perimeter
        drawLinesAfterMovingPoint(updatedPoints, updatedLines); // Redraw
  
      }
    }
  };
  
  
  const calculateAreaFromPoints = (points: Point[]): number => {
    let pixelArea = 0;
    const numPoints = points.length;
  
    for (let i = 0; i < numPoints; i++) {
      const j = (i + 1) % numPoints;
      pixelArea += points[i].x * points[j].y;
      pixelArea -= points[j].x * points[i].y;
    }
  
    pixelArea = Math.abs(pixelArea) / 2;
    return pixelArea * Math.pow(PIXELS_PER_METER, -2);
  };
  
  const calculatePerimeterFromLines = (lines: Line[]): number => {
    return lines.reduce((total, line) => total + calculateLineLength(line.startPoint, line.endPoint), 0);
  };

  const handleAreaChange = (newSelectedArea: string) => {
    // Check if the current selected area has no points plotted
    if (points[selectedArea] && points[selectedArea].length === 0) {
      // Determine if the selected area is a base shape without a number suffix
      const isBaseShape = selectedArea.match(/^\D+$/) !== null;
  
      // Only remove shapes with a number suffix when no data is entered
      if (!isBaseShape) {
        const updatedPoints = { ...points };
        const updatedLines = { ...lines };
        const updatedIsShapeClosed = { ...isShapeClosed };
        const updatedArea = { ...area };
        const updatedPerimeter = { ...perimeter };
  
        delete updatedPoints[selectedArea];
        delete updatedLines[selectedArea];
        delete updatedIsShapeClosed[selectedArea];
        delete updatedArea[selectedArea];
        delete updatedPerimeter[selectedArea];
  
        setPoints(updatedPoints);
        setLines(updatedLines);
        setIsShapeClosed(updatedIsShapeClosed);
        setArea(updatedArea);
        setPerimeter(updatedPerimeter);
      }
    }
  
    // Set the new selected area
    setSelectedArea(newSelectedArea);
  
    if (points[newSelectedArea].length > 0) {
      calculateArea(newSelectedArea);
      calculatePerimeter(newSelectedArea, lines[newSelectedArea]);
  
      // Check and update dimensions for any decking shape
      if (newSelectedArea.startsWith('decking')) {
        const { length, width } = calculateBoundingBoxDimensions(points[newSelectedArea]);
        setDeckingDimensions(prevDimensions => ({
          ...prevDimensions,
          [newSelectedArea]: { length, width }
        }));
      }
    }
  
    // Redraw the canvas to show points for the newly selected area
    redrawCanvasContent(newSelectedArea);
  };
  
  
  
  const renderShapeOptions = () => {
    const shapeBases: ShapeKey[] = [
      'garden', 'patio', 'edgings', 'blockpaving', 'setts', 'concretepad', 'asphalt', 'decking', 'fencing', 
      'gravel', 'walling', 'artificialturf', 'turf', 'bark', 'sleepers', 'manholecover', 'channeldrain', 
      'drainagepipe', 'gullypot', 'curb',
    ];
  
    const formatShapeName = (base: string, index: number) => {
      let formattedName = base;
      switch(base) {
        case 'blockpaving':
          formattedName = 'Block Paving';
          break;
        case 'concretepad':
          formattedName = 'Concrete Pad';
          break;
        case 'artificialturf':
          formattedName = 'Artificial Turf';
          break;
        case 'manholecover':
          formattedName = 'Manhole Cover';
          break;
        case 'channeldrain':
          formattedName = 'Channel Drain';
          break;
        case 'drainagepipe':
          formattedName = 'Drainage Pipe';
          break;
        case 'gullypot':
          formattedName = 'Gully pot';
          break;
        default:
          formattedName = base.charAt(0).toUpperCase() + base.slice(1);
      }
      return index === 0 ? formattedName : `${formattedName} ${index + 1}`;
    };
  
    const options: JSX.Element[] = [];
  
    shapeBases.forEach(base => {
      const shapes = Object.keys(points).filter(key => key.startsWith(base)).sort((a, b) => {
        const aNum = parseInt(a.replace(base, ''), 10) || 1;
        const bNum = parseInt(b.replace(base, ''), 10) || 1;
        return aNum - bNum;
      });
      shapes.forEach((shape, index) => {
        const shapeName = formatShapeName(base, index);
        options.push(
          <OptionButton
            key={shape}
            value={shape}
            selected={shape === selectedArea}
            hasPoints={hasPointsPlotted(shape)}
            onClick={() => handleAreaChange(shape)}
          >
            {shapeName}
          </OptionButton>
        );
      });
    });
  
    return options;
  };
  
  
  const hasPointsPlotted = (shape: string) => {
    return points[shape] && points[shape].length > 0;
  };
  
  
const getButtonText = (area: string) => {
    const baseArea = area.replace(/\d+$/, '');
    switch (baseArea) {
      case 'edgings':
        return 'Add New Edgings';
      case 'walling':
        return 'Add New Wall';
      case 'sleepers':
        return 'Add New Sleepers';
      case 'fencing':
        return 'Add New Fence';
      default:
        return `Create New ${formatShapeName(baseArea)} Area`;
    }
  };
  
  const createNextShape = () => {
    const shapeBase = selectedArea.replace(/\d+$/, ''); // Remove any trailing numbers
    const allShapes = Object.keys(points).filter(key => key.startsWith(shapeBase));
    const newShapeNumber = allShapes.length + 1;
    const newShape = `${shapeBase}${newShapeNumber}`;
  
    // Initialize the new shape in the state
    setPoints(prevPoints => ({
      ...prevPoints,
      [newShape]: []
    }));
    setLines(prevLines => ({
      ...prevLines,
      [newShape]: []
    }));
    setIsShapeClosed(prev => ({
      ...prev,
      [newShape]: false
    }));
    setArea(prev => ({
      ...prev,
      [newShape]: null
    }));
    setPerimeter(prev => ({
      ...prev,
      [newShape]: null
    }));
 
    // Automatically switch to the new shape
    setSelectedArea(newShape);
  };
  
  
  
  const formatShapeName = (base: string, index: number = 0) => {
    let formattedName = base;
    switch(base) {
      case 'blockpaving':
        formattedName = 'Block Paving';
        break;
      case 'concretepad':
        formattedName = 'Concrete Pad';
        break;
      case 'artificialturf':
        formattedName = 'Artificial Turf';
        break;
        case 'manholecover':
        formattedName = 'Manhole Cover';
        break;
        case 'channeldrain':
        formattedName = 'Channel Drain';
        break;
        case 'drainagepipe':
        formattedName = 'Drainage Pipe';
        break;
        case 'gullypot':
        formattedName = 'Gully Pot';
        break;
      default:
        formattedName = base.charAt(0).toUpperCase() + base.slice(1) ;
    }
    return index === 0 ? formattedName : `${formattedName} ${index + 1}`;
  };

  const saveProjectData = async () => {
    if (!selectedProjectId) {
      console.error('No project selected.');
      return;
    }
  
    // Recalculate line lengths before saving
    const updatedLines = { ...lines };
  
    Object.keys(points).forEach((shape) => {
      calculateArea(shape);
      calculatePerimeter(shape, updatedLines[shape]);
      const shapePoints = points[shape];
      if (shapePoints.length > 1) {
        const shapeLines: Line[] = [];
        for (let i = 0; i < shapePoints.length - 1; i++) {
          const startPoint = shapePoints[i];
          const endPoint = shapePoints[i + 1];
          const length = calculateLineLength(startPoint, endPoint);
          shapeLines.push({ startPoint, endPoint, length });
        }
  
        // Handle closed shapes if necessary
        if (isShapeClosed[shape]) {
          const closingLineLength = calculateLineLength(shapePoints[shapePoints.length - 1], shapePoints[0]);
          shapeLines.push({
            startPoint: shapePoints[shapePoints.length - 1],
            endPoint: shapePoints[0],
            length: closingLineLength,
          });
        }
  
        updatedLines[shape] = shapeLines;
      }
    });
  
    setLines(updatedLines); // Update the lines in state
  
    // Prepare the data to be saved
    const updateDeckingDimensions = (): { [key: string]: Dimensions } => {
      const deckingShapes = Object.keys(points).filter(key => key.startsWith('decking'));
      const dimensions = deckingShapes.reduce((acc: { [key: string]: Dimensions }, key: string) => {
        const { length, width } = calculateBoundingBoxDimensions(points[key]);
        acc[key] = { length, width };
        return acc;
      }, {});
      return dimensions;
    };
  
    const pointsData = {
      points,
      lines: updatedLines, // Use the recalculated lines
      isShapeClosed,
      area,
      perimeter,
      deckingDimensions: updateDeckingDimensions(), // Include any specific dimensions
      manholeCoverCount,
      gullyPotCount,
      curbCount,
    };
  
    try {
      const token = localStorage.getItem('token');
      // Save to the server
      await axios.post(`https://projectprice-ad06ee250897.herokuapp.com/points/save/${selectedProjectId}`, pointsData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      // Save to local storage as a backup or for quick retrieval later
      localStorage.removeItem(`pointsData_${selectedProjectId}`);
  
      // Show the save confirmation message
      setShowSaveMessage(true);
      setTimeout(() => setShowSaveMessage(false), 2000); // Hide the message after 2 seconds
  
    } catch (error) {
      console.error('Error saving project data:', error);
    }
  };
  
  
  
  const processProjectData = (projectData: any) => {
    const updatedPoints = projectData.points || {};
    const updatedLines: { [key: string]: Line[] } = {};
    const updatedAreas: { [key: string]: number | null } = {};
    const updatedPerimeters: { [key: string]: number | null } = {};
  
    Object.keys(updatedPoints).forEach(shape => {
      const shapePoints = updatedPoints[shape] || [];
  
      // Validate shapePoints
      if (!shapePoints || !Array.isArray(shapePoints)) {
        console.error(`Invalid points data for shape: ${shape}`);
        return;
      }
  
      const shapeLines: Line[] = [];
  
      for (let i = 0; i < shapePoints.length - 1; i++) {
        const startPoint = shapePoints[i];
        const endPoint = shapePoints[i + 1];
  
        // Ensure both startPoint and endPoint are defined
        if (!startPoint || !endPoint) {
          console.error(`Invalid line data for shape: ${shape}, index: ${i}`);
          continue;
        }
  
        const length = calculateLineLength(startPoint, endPoint);
        shapeLines.push({ startPoint, endPoint, length });
      }
  
      // Only create closing lines for shapes that are not manholecover
      if (projectData.isShapeClosed[shape] && shape !== 'manholecover' && shape !== 'gullypot') {
        const startPoint = shapePoints[shapePoints.length - 1];
        const endPoint = shapePoints[0];
  
        // Ensure both startPoint and endPoint are define
        if (!startPoint || !endPoint) {
          console.error(`Invalid closing line data for shape: ${shape}`);
          return;
        }
  
        const length = calculateLineLength(startPoint, endPoint);
        shapeLines.push({ startPoint, endPoint, length });
      }
  
      updatedLines[shape] = shapeLines;
      updatedAreas[shape] = calculateAreaFromPoints(shapePoints);
      updatedPerimeters[shape] = calculatePerimeterFromLines(shapeLines);
    });
  
    return {
      updatedPoints,
      updatedLines,
      updatedAreas,
      updatedPerimeters,
      manholeCoverCount: projectData.manholeCoverCount || 0,
      gullyPotCount: projectData.gullyPotCount || 0,
      curbCount: projectData.curbCount || 0,
      isShapeClosed: projectData.isShapeClosed || {}
    };
  };
  
  const loadProjectData = async (projectId: string) => {
  try {
    // Check local storage for points data
    const localStorageData = localStorage.getItem(`pointsData_${projectId}`);
    if (localStorageData) {
      const projectData = JSON.parse(localStorageData);

      if (projectData && projectData.points && Object.keys(projectData.points).length > 0) {
        const {
          updatedPoints,
          updatedLines,
          updatedAreas,
          updatedPerimeters,
          manholeCoverCount,
          gullyPotCount,
          curbCount,
          isShapeClosed
        } = processProjectData(projectData);

        setPoints(updatedPoints);
        setLines(updatedLines);
        setIsShapeClosed(isShapeClosed);
        setArea(updatedAreas);
        setPerimeter(updatedPerimeters);
        setManholeCoverCount(manholeCoverCount);
        setGullyPotCount(gullyPotCount);
        setcurbCount(curbCount);
        setDataLoaded(true);
        
        return;
      }
    }

    const token = localStorage.getItem('token');
    const response = await axios.get(`https://projectprice-ad06ee250897.herokuapp.com/points/load/${projectId}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    const projectData = response.data.data;

    if (!projectData || !projectData.points || Object.keys(projectData.points).length === 0) {
      // Reset points, lines, and counts when there is no data for the project
      resetPointsAndLines();
      setManholeCoverCount(0);
      setGullyPotCount(0);
      setcurbCount(0);
    } else {
      const {
        updatedPoints,
        updatedLines,
        updatedAreas,
        updatedPerimeters,
        manholeCoverCount,
        gullyPotCount,
        curbCount,
        isShapeClosed
      } = processProjectData(projectData);

      setPoints(updatedPoints);
      setLines(updatedLines);
      setIsShapeClosed(isShapeClosed);
      setArea(updatedAreas);
      setPerimeter(updatedPerimeters);
      setManholeCoverCount(manholeCoverCount);
      setGullyPotCount(gullyPotCount);
      setcurbCount(curbCount);
      setDataLoaded(true); // Set dataLoaded flag to true
    }
  } catch (error) {
    console.error('Error loading project data:', error);
    resetPointsAndLines();
    setManholeCoverCount(0);
    setGullyPotCount(0);
    setcurbCount(0);
  }
};

  

  useEffect(() => {
    if (dataLoaded) {
      redrawCanvasContent(selectedArea);
      setDataLoaded(false); // Reset the flag after redrawing
    }// eslint-disable-next-line
  }, [dataLoaded, points, lines, texturesLoaded]);

  useEffect(() => {
    const handleResize = () => {
      resizeCanvas();
      redrawCanvas();
    };
  
    const handleScroll = () => {
      redrawCanvas();
    };
  
    window.addEventListener('resize', handleResize);
    window.addEventListener('scroll', handleScroll);
  
    redrawCanvas();
  
    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('scroll', handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [points, lines, texturesLoaded, dataLoaded]);
      
  
  const resetPointsAndLines = () => {
    setPoints({
      garden: [],
      patio: [],
      edgings: [],
      blockpaving: [],
      setts: [],
      concretepad: [],
      decking: [],
      gravel: [],
      fencing: [],
      walling: [],
      artificialturf: [],
      sleepers: [],
      turf: [],
      bark: [],
      manholecover: [],
      channeldrain: [],
      drainagepipe: [],
      gullypot: [],
      curb: [],
      asphalt: [],
    });
    setLines({
      garden: [],
      patio: [],
      edgings: [],
      blockpaving: [],
      setts: [],
      concretepad: [],
      decking: [],
      gravel: [],
      fencing: [],
      walling: [],
      artificialturf: [],
      sleepers: [],
      turf: [],
      bark: [],
      manholecover: [],
      channeldrain: [],
      drainagepipe: [],
      gullypot: [],
      curb: [],
      asphalt: []
    });
    setIsShapeClosed({
      garden: false,
      patio: false,
      blockpaving: false,
      setts: false,
      concretepad: false,
      decking: false,
      gravel: false,
      artificialturf: false,
      turf: false,
      bark: false,
      edgings: false,
      fencing: false,
      walling: false,
      sleepers: false,
      manholecover: true,
      channeldrain: false,
      drainagepipe: false,
      gullypot: true,
      curb: false,
      asphalt: false,
    });
  };

  const saveFormDataToLocalStorage = () => {

    const hasPoints = Object.values(points).some((areaPoints: Point[]) => areaPoints.length > 0);

    if (!hasPoints) {
      return; 
    }

    const updateDeckingDimensions = (): { [key: string]: Dimensions } => {
      const deckingShapes = Object.keys(points).filter(key => key.startsWith('decking'));
      const dimensions = deckingShapes.reduce((acc: { [key: string]: Dimensions }, key: string) => {
        const { length, width } = calculateBoundingBoxDimensions(points[key]);
        acc[key] = { length, width };
        return acc;
      }, {});
      return dimensions;
    };

    try {
      if (selectedProjectId) {
        const dataToSave = {
          points,
          lines,
          isShapeClosed,
          area,
          perimeter,
          deckingDimensions: updateDeckingDimensions(), // Add decking dimensions here
          manholeCoverCount, // Ensure this is included
          gullyPotCount,
          curbCount, 
         
        };
  
        // Storing data to local storage
        localStorage.setItem(`pointsData_${selectedProjectId}`, JSON.stringify(dataToSave));
      }
    } catch (error) {
      console.error("Error saving data:", error);
    }
  }
  
  // Move the handleWheel function outside of useEffect
const handleWheel = useCallback((event: WheelEvent) => {
  event.preventDefault(); // Prevent the default scroll behavior

  const canvas = canvasRef.current;
  if (!canvas) return;

  const rect = canvas.getBoundingClientRect();
  const mouseX = event.clientX - rect.left;
  const mouseY = event.clientY - rect.top;

  // Calculate world coordinates before zoom
  const worldXBeforeZoom = (mouseX - panOffset.x) / zoomLevel;
  const worldYBeforeZoom = (mouseY - panOffset.y) / zoomLevel;

  // Adjust the zoom level
  const zoomFactor = event.deltaY < 0 ? 1.1 : 1 / 1.1;
  const newZoomLevel = Math.min(Math.max(zoomLevel * zoomFactor, 0.2), 5);

  // Calculate new pan offset to keep mouse position stable
  const newPanOffsetX = panOffset.x - (worldXBeforeZoom * (newZoomLevel - zoomLevel));
  const newPanOffsetY = panOffset.y - (worldYBeforeZoom * (newZoomLevel - zoomLevel));

  // Update zoom level and pan offset
  setZoomLevel(newZoomLevel);
  setPanOffset({ x: newPanOffsetX, y: newPanOffsetY });

  // Redraw the canvas content with the new zoom and pan
  redrawCanvasContent(selectedArea);
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, [zoomLevel, panOffset, selectedArea]); // Dependencies for the useCallback hook

useEffect(() => {
  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'P' || event.key === 'p') {
      setIsPKeyPressed(true);
    }
    if (event.key === 'S' || event.key === 's') {
      setIsSnapEnabled(true);
    }
    // Handle Ctrl + Z for undo functionality
    if (event.ctrlKey && event.key === 'z') {
      event.preventDefault(); // Prevent the default undo action in the browser
      removeLastPoint(); // Call the function to remove the last point
    }
  };

  const handleKeyUp = (event: KeyboardEvent) => {
    if (event.key === 'P' || event.key === 'p') {
      setIsPKeyPressed(false);
    }
    if (event.key === 'S' || event.key === 's') {
      setIsSnapEnabled(false);
    }
  };

  const canvas = canvasRef.current;
  if (canvas) {
    canvas.addEventListener('wheel', handleWheel, { passive: false });
  }

  window.addEventListener('keydown', handleKeyDown);
  window.addEventListener('keyup', handleKeyUp);

  return () => {
    if (canvas) {
      canvas.removeEventListener('wheel', handleWheel);
    }
    window.removeEventListener('keydown', handleKeyDown);
    window.removeEventListener('keyup', handleKeyUp);
  };
}, [handleWheel, removeLastPoint]); // Ensure the handleWheel and removeLastPoint are included in the dependencies

  
 return (
    <MainContainer>
      <ProjectContainer>
          <FiDownload
            className="download-input"
            onClick={downloadPDF}
            cursor={"pointer"}
            style={{ 
              color: isBackgroundWhite ? 'black' : 'white',
              marginLeft: '-10px',  // Move to the left
              marginTop: '5px'     // Move down slightly
            }}
          />
          <select
            value={selectedProjectId || ""}
            onChange={handleProjectChange}
            style={{
              background: 'rgba(0, 0, 0, 0.3)',
              border: 'none',
              borderRadius: '0.3rem',
              color: 'white',
              outline: 'none',
              width: '150px',
            }}
          >
            <option value="" disabled>Select a project</option>
            {projects.map((project) => (
              <option key={project._id} value={project._id}>
                {project.projectName}
              </option>
            ))}
          </select>
        </ProjectContainer>

      {showLoginMessage && (
        <CenteredMessage>
          <LogoImage src="project-price-logo-name.png" />
          Please log in to use this free feature.
        </CenteredMessage>
      )}
      {!showLoginMessage && (
        <>
          <FlexContainer>
          <StyledCanvas
            ref={canvasRef}
            isPanMode={isPanMode}
            onClick={handleCanvasClick}
            onMouseDown={(event) => {
              handleMouseDown(event);
              handlePanStart(event);
            }}
            onMouseMove={(event) => {
              handleMouseMove(event);
              handlePanMove(event);
            }}
            onMouseUp={(event) => {
              handleMouseUp();
              handlePanEnd();
            }}
            onTouchStart={(event) => {
              handleTouchStart(event);
              handlePanStart(event);
            }}
            onTouchMove={(event) => {
              handleTouchMove(event);
              handlePanMove(event);
            }}
            onTouchEnd={(event) => {
              handleTouchEnd();
              handlePanEnd();
            }}
          />

            <SideContainer>
              <ButtonsContainer>
           
              <ArrowButton>
              <Button 
                    title="Clear last point" 
                    onClick={() => {
                      if (selectedArea.startsWith('manholecover') || selectedArea.startsWith('gullypot')) {
                        clearAll();
                      } else {
                        removeLastPoint();
                      }
                    }}
                  >
                  <FiArrowLeftCircle />
                </Button>
                 
                <Button 
                    title="Clear All" 
                    onClick={() => clearAll()} 
                  >
                  Clear All
                  </Button>
                  {showEditButton && (
                  <EditButton onClick={() => setIsEditMode(!isEditMode)}>
                    {isEditMode ? 'Disable Edit' : 'Enable Edit'}
                  </EditButton>
                )}

              </ArrowButton>
              <Button onClick={createNextShape} style={{ marginTop: '10px' }}>
                {getButtonText(selectedArea)}
              </Button>
              <ZoomButtonsContainer>
              <Button onClick={handleZoomIn} title="Zoom in"><FiZoomIn /></Button>
              <Button onClick={handleZoomOut} title="Zoom out"><FiZoomOut /></Button>
              <Button 
                  onClick={() => setIsPanMode(!isPanMode)}
                  title={isPanMode ? "Pen Tool (P)" : "Panning Tool (P)"}
                >
                  {isPanMode ? <FiPenTool /> : <FiCompass />}
                </Button>
                <Button onClick={() => setIsSnapEnabled(!isSnapEnabled)} title="Snap Tool (S)">
                    <FaRuler style={{ color: isSnapEnabled ? 'blue' : 'inherit' }} />
                  </Button>
              </ZoomButtonsContainer>
              
              <ZoomButtonsContainer>
              <Button onClick={saveProjectData}>Save Project</Button>
              {showSaveMessage && (
                  <div style={{ 
                    position: 'absolute', 
                    top: '48%',       // This centers the span vertically within the ActionContainer
                    right: '16%',      // Adjust this as per your requirements
                    transform: 'translateY(-50%)', // This ensures the centering is accurate regardless of the span's height
                    backgroundColor: '#333', 
                    color: '#fff', 
                    padding: '5px', 
                    borderRadius: '5px' 
                  }}>Saved!
                  </div>
                )}
              {showUploadButton && (
                  <Button onClick={exportPointsToSketchUp}>
                    <FaUpload />
                  </Button>
                )}
              </ZoomButtonsContainer>
                </ButtonsContainer>
             
                <OptionsContainer>
                  {renderShapeOptions().map((option) => (
                    <OptionButton
                    key={option.key}
                    selected={option.props.value === selectedArea}
                    hasPoints={hasPointsPlotted(option.props.value)}
                    onClick={() => handleAreaChange(option.props.value)}
                  >
                    {option.props.children}
                  </OptionButton>
                  ))}
                </OptionsContainer>

              {selectedArea === 'patio' && (
                    <>
                   
                      
                    </>
                )}
                {
                      selectedArea.startsWith('curb') ? (
                        <div key={selectedArea}>
                          <div>{`curbs: ${curbCount}`}</div>
                        </div>
                      ) : selectedArea.startsWith('manholecover') ? (
                        <div key={selectedArea}>
                          <div>{`Manhole Covers: ${manholeCoverCount}`}</div>
                        </div>
                      ) : selectedArea.startsWith('gullypot') ? (
                        <div key={selectedArea}>
                          <div>{`Gully Pots: ${gullyPotCount}`}</div>
                        </div>
                      ) : (
                        Object.keys(perimeter).map((shape) => {
                          const shapeBase = shape.replace(/\d+$/, ''); // Remove any trailing numbers
                          const index = parseInt(shape.match(/\d+$/)?.[0] || '1', 10) - 1; // Extract the number from shape name

                          if (shape === selectedArea) { // Only render for the selected area
                            if (['edgings', 'fencing', 'walling', 'sleepers', 'channeldrain', 'drainagepipe', 'curb'].includes(shapeBase)) {
                              if (perimeter[shape] !== null && perimeter[shape]! > 0) {
                                return (
                                  <div key={shape}>
                                    <div>{`${formatShapeName(shapeBase, index)} Length: ${perimeter[shape]?.toFixed(2)} m`}</div>
                                  </div>
                                );
                              }
                            } else if (area[shape] !== null && area[shape]! > 0) {
                              return (
                                <div key={shape}>
                                  <div>{`${formatShapeName(shapeBase, index)} Area: ${area[shape]!.toFixed(2)} m²`}</div>
                                  <div>{`${formatShapeName(shapeBase, index)} Perimeter: ${perimeter[shape]?.toFixed(2)} m`}</div>
                                </div>
                              );
                            }
                          }
                          return null;
                        })
                      )
                    }



            </SideContainer>
          </FlexContainer>
          {inputPosition && showInput && (
          <InputBox style={{ left: (inputPosition.x ?? 0) + 40, top: inputPosition.y ?? 0 }}>
            <input
              type="number"
              value={inputValue}
              onChange={handleLineMeasurementInputChange}
              onKeyDown={handleLineMeasurementInputKeyDown}
              autoFocus
            />
            <span>m</span>
          </InputBox>
        )}


          {showTooltip && tooltipPosition && (
            <Tooltip style={{ left: tooltipPosition.x, top: tooltipPosition.y }}>
              Please select a Project from the menu
            </Tooltip>
          )}
        </>
      )}
    </MainContainer>
  );
};
export default Design;
