import React from 'react';
import styled from 'styled-components';
import NavBar from '../components/PublicNav/homenav';
import Footer from '../components/Footer';

const BackgroundContainer = styled.div`
  background: url('/your-background-image.jpg') no-repeat center center fixed;
  background-size: cover;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const ContactContainer = styled.div`
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.8);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  text-align: center;
  margin-top: 20vh;
  margin-bottom: 20vh;
`;

const ContactTitle = styled.h2`
  font-size: 2.5rem;
  margin-bottom: 1rem;
  color: #073763;
`;

const ContactInfo = styled.p`
  font-size: 1.2rem;
  margin-bottom: 20px;
  color: #333;

  a {
    color: #0056b3;
    text-decoration: none;
    font-weight: bold;

    &:hover {
      color: #007bff;
    }
  }
`;

const ContactIntro = styled.p`
  font-size: 1.1rem;
  margin-bottom: 20px;
  color: #666;
`;

const ContactPage = () => {
  return (
    <BackgroundContainer>
      <NavBar />
      <ContactContainer>
        <ContactTitle>Contact Us</ContactTitle>
        <ContactIntro>
          We would love to hear from you! Whether you have a question about our services, need assistance, or just want to give feedback, please don't hesitate to reach out.
        </ContactIntro>
        <ContactInfo>
          For inquiries or assistance, please contact us at:{' '}
          <a href="mailto:contact@projectprice.co.uk">contact@projectprice.co.uk</a>.
        </ContactInfo>
      </ContactContainer>
      <Footer />
    </BackgroundContainer>
  );
};

export default ContactPage;
