import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { FaFacebook } from 'react-icons/fa';

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Dark overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it's on top of other content */
`;

const ModalContainer = styled.div`
  background-color: rgba(7,55,99);
  color: white;
  padding: 30px;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 60vh;
  width: 60%; /* Responsive */
`;

const NotificationButton = styled.button`
 background-color: rgba(0, 255, 9, 1);
  color: black;
  border: none;
  border-radius: 1vh;
  padding: 1.5vh;
  font-weight: bold;
  font-size: 2.5vh;
  cursor: pointer;
  margin-top: 1vh;

  transition: transform 0.3s;

  &:hover {
    background-color: rgba(84, 255, 90, 1);
    animation: none;
    transform: scale(1.03);
  }
  @media (orientation: portrait) {
    font-size: 1.8vh;
    padding: 1.3vh;
    margin-left: 0vh;
    margin-bottom: 4vh;
  }
`;

interface NotificationProps {
  onHighlightButton: (highlight: boolean) => void;
}

const Notification: React.FC<NotificationProps> = ({ onHighlightButton }) => {
  const [showNotification, setShowNotification] = useState(false);

  useEffect(() => {
    const checkNotificationStatus = async () => {
      try {
        const response = await axios.get('https://projectprice-ad06ee250897.herokuapp.com/auth/notification-status');
    
        if (!response.data.hasSeenNotification) {
          setShowNotification(true);
          onHighlightButton(true); // Highlight the button when the notification is shown
     
        }
      } catch (error) {
        console.error('Error checking notification status:', error);
      }
    };

    checkNotificationStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAcknowledgeNotification = async () => {
    try {
      await axios.post('https://projectprice-ad06ee250897.herokuapp.com/auth/update-notification-status');
      setShowNotification(false);
      onHighlightButton(false); // Remove highlight when notification is acknowledged
    } catch (error) {
      console.error('Error updating notification status:', error);
    }
  };

  if (!showNotification) {
    return null; // Return null instead of false
  }

  return (
    <ModalOverlay>
      <ModalContainer>
      <p>Hi there!</p> <p>Just a quick heads up—we've moved everything you might need into the "Project Actions" button in the bottom right corner. It's your go-to spot for saving, deleting, downloading, uploading, and updating prices for your projects.</p>
<p>Stay connected with us on Facebook for regular updates and more!</p><p> <a href="https://facebook.com/projectpriceuk" target="_blank" rel="noreferrer"><FaFacebook style={{ fontSize: '5vh' }} /></a>
</p>

        <NotificationButton onClick={handleAcknowledgeNotification}>Got it!</NotificationButton>
      </ModalContainer>
    </ModalOverlay>
  );
};

export default Notification;
