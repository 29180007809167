import React from 'react';
import styled from 'styled-components';

const TestimonialSection = styled.div`
  padding: 5vh 35vh;
  text-align: center;
  @media (orientation: portrait) {
    padding: 0;
  }
`;

const Title = styled.h2`
  font-size: 4vh;
  font-weight: bold;
  color: white;
  margin-bottom: 4vh;
  @media (orientation: portrait) {
  font-side: 2vh;
  margin-bottom: 2vh;
  }
`;

const TestimonialsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  justify-items: center;
  margin-bottom: 4vh;

  @media (max-width: 768px) {
     grid-template-columns: 2fr;
  }
    @media (orientation: portrait) {
   grid-template-columns: repeat(1, 1fr);
   margin-bottom: 2vh;
  }
`;

const TestimonialsGridBottom = styled.div`
    display: grid;
  grid-template-columns: repeat(2, 1fr);

  justify-items: center;
  margin-bottom: 0vh;
  padding: 0 25vh;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
    @media (orientation: portrait) {
  padding: 0;
  grid-template-columns: 1fr;
  }
`;

const TestimonialBlock = styled.div`
 
  padding: 3vh;
  border-radius: 1.5vh;
  text-align: center;
  width: 100%; /* Ensures uniform width */
 
  @media (orientation: portrait) {
  width: 100%;
  }
`;

const TestimonialTitle = styled.h3`
  font-size: 2.5vh;
  text-decoration: underline;
  font-weight: bold;
  color: white;
  margin-bottom: 1.5vh;
`;

const TestimonialText = styled.p`
  font-size: 2vh;
  color: white;
  line-height: 1.6;
`;

const SaidByText = styled.p`
margin-left: 75vh;
font-size: 2vh;
font-weight: lighter;
color: white;
@media(orientation: portrait) {
margin-left: 0vh;
margin-bottm: 4vh;}
`

const Testimonials = () => {
  return (
    <TestimonialSection>
      <Title>Why Landscapers are Choosing Us</Title>
      <TestimonialsGrid>
        <TestimonialBlock>
          <TestimonialTitle>Deadly Accurate</TestimonialTitle>
          <TestimonialText>
            "Being in the game for many years, I would still miss off materials from a job in the quote. This has helped massively."
          </TestimonialText>
        </TestimonialBlock>
        <TestimonialBlock>
          <TestimonialTitle>Saves Hours on Quoting</TestimonialTitle>
          <TestimonialText>
            "Using Project Price has reduced my time spent quoting substantially. We are able to send more quotes per month, which has led to more work coming our way."
          </TestimonialText>
        </TestimonialBlock>
        <TestimonialBlock>
          <TestimonialTitle>Tailored to You</TestimonialTitle>
          <TestimonialText>
            "I find it genius that you are able to set your own working ways so it calculates your materials specific to you."
          </TestimonialText>
        </TestimonialBlock>
      </TestimonialsGrid>
      <TestimonialsGridBottom>
        <TestimonialBlock>
          <TestimonialTitle>Leveraging Professionalism</TestimonialTitle>
          <TestimonialText>
            "Before I would only have the time to create drawings for more expensive jobs. Now I send every customer a drawing, which gets us on the same page and saves time down the line."
          </TestimonialText>
        </TestimonialBlock>
        <TestimonialBlock>
          <TestimonialTitle>Increased Efficiency</TestimonialTitle>
          <TestimonialText>
            "Since using Project Price, our workflow has become significantly smoother. The ability to quickly generate accurate quotes has freed up time to focus on growing the business."
          </TestimonialText>
        </TestimonialBlock>
      </TestimonialsGridBottom>
      <SaidByText>- Project Price Users</SaidByText>
    </TestimonialSection>
  );
};

export default Testimonials;
