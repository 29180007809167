import React from 'react';
import styled from 'styled-components';

interface SmallModalProps {
  isOpen: boolean;
  onClick: () => void;
  onClose: () => void; // Add this prop to handle closing the modal
}

const ModalOverlay = styled.div<{ isOpen: boolean }>`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: auto;
  background-color: rgba(0, 0, 0, 0.0);
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  align-items: flex-end;
  justify-content: space-between;
  z-index: 1000;
  padding: 2vh;
  box-sizing: border-box;
`;

const ModalContent = styled.div`
  background: white;
  padding: 2vh;
  border-radius: 1vh;
  max-width: 30vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
`;

const ModalButton = styled.button`
  background-color: rgba(7,55,99);
  color: white;
  padding: 1vh 1vh;
  border: none;
  border-radius: 1vh;
  cursor: pointer;
  font-size: 2.5vh;
  margin-top: 2vh;

  &:hover {
    background-color: rgba(0, 56, 255, 0.8);
  }
`;

const CloseButton = styled.button`
  background-color: transparent;
  color: rgba (7,55,99);
  border: none;
  border-radius: 1vh;
  cursor: pointer;
  font-size: 2vh;
  margin-left: 0vh;
  margin-top: 2vh;

`;

const ModalText = styled.p`
font-size: 2.5vh;

`

const SmallModal: React.FC<SmallModalProps> = ({ isOpen, onClick, onClose }) => {
  return (
    <ModalOverlay isOpen={isOpen}>
      <ModalContent>
        <ModalText>Would you like to be able to charge more for your work?</ModalText>
        <ModalButton onClick={onClick}>Find out how</ModalButton>
        <CloseButton onClick={onClose}>No, thank you</CloseButton>
      </ModalContent>
    </ModalOverlay>
  );
};

export default SmallModal;
