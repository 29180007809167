import React from 'react';
import styled from 'styled-components';
import NavBar from '../components/PublicNav/homenav';
import Footer from '../components/Footer';

const BackgroundContainer = styled.div`
  background: #f8f8f8;
`;

const BlogContainer = styled.div`
  margin: 0 auto;
  padding: 20px;
  padding-top: 10vh;
  color: rgba(7,55,99);
`;

const BlogTitle = styled.h1`
  text-align: center;
  margin-bottom: 30px;
  padding: 5vh;
`;

const Title = styled.h4`
  margin-bottom: 2%;
  margin-top: 5%;
  margin-left: 10%;
`;

const IntroParagraph = styled.p`
  font-size: 1.1rem;
  line-height: 1.6;
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
  color: #333;
  margin-bottom: 30px; // Space between the paragraph and the first image
  text-align: left;
  @media (orientation: portrait){
    max-width: 100%;
  }
`;

const BlogImage = styled.img`
  display: block;
  max-width: 20%; // Adjusted to a more reasonable width for left alignment
  height: auto;
  margin: 2vh 20px 2vh 0; // Add margin to the right for spacing
  border-radius: 8px;
  float: right; // Float the image to the left
`;



const BlogLights: React.FC = () => {
  return (
    <BackgroundContainer>
      <NavBar />
      <BlogContainer>
        <BlogTitle>Integrating Light into Your Garden: A Comprehensive Guide</BlogTitle>
        <IntroParagraph>
          A well-lit garden can transform your outdoor space into a magical retreat, extending its usability into the evening and enhancing its aesthetic appeal. Integrating lighting into your garden involves a blend of creativity and technical know-how. This guide will cover the essentials of electrical lighting, solar and battery options, various types of lights, and the multifunctional use of fire pits. Let’s explore how to illuminate your garden effectively and beautifully.
        </IntroParagraph>
        <Title>Electrical Lighting</Title>
        <IntroParagraph>
          <strong>Mains-Powered Lights</strong>: Using mains-powered lights provides a reliable and continuous source of illumination for your garden. This type of lighting is ideal for areas that require consistent brightness, such as pathways, patios, and dining areas.
        </IntroParagraph>
        <IntroParagraph>
          <strong>Key Considerations</strong>:
          <ul>
            <li><strong>Safety First</strong>: Ensure all electrical installations are waterproof and suitable for outdoor use. Consulting a professional electrician is highly recommended.</li>
            <li><strong>Planning</strong>: Map out where you need the lights, considering power outlets and cable routes.</li>
            <li><strong>Energy Efficiency</strong>: Opt for LED bulbs, which consume less energy and have a longer lifespan.</li>
          </ul>
        </IntroParagraph>
        <IntroParagraph>
          <strong>Types of Electrical Lights</strong>:
          <ul>
            <li><strong>Spotlights</strong>: Perfect for highlighting focal points like trees, sculptures, or water features.</li>
            <li><strong>Pathway Lights</strong>: Illuminate walkways to enhance safety and aesthetics.</li>
            <li><strong>Wall Lights</strong>: Ideal for lighting up patios and decks, providing ambient light without taking up space.</li>
          </ul>
        </IntroParagraph>
        <Title>Solar and Battery-Powered Lighting</Title>
        <IntroParagraph>
          For a more eco-friendly and flexible solution, consider solar and battery-powered lights. These options are easy to install and can be placed anywhere without the need for wiring.
        </IntroParagraph>
        <IntroParagraph>
          <strong>Solar Lights</strong>:
          <ul>
            <li><strong>Fairy Lights</strong>: Create a whimsical atmosphere by draping solar-powered fairy lights over bushes, trees, or pergolas.</li>
            <li><strong>Garden Stakes</strong>: Insert solar stakes along pathways or flower beds for subtle, enchanting light.</li>
            <li><strong>Spotlights</strong>: Solar spotlights can highlight key garden features without the need for electrical wiring.</li>
          </ul>
        </IntroParagraph>
        <IntroParagraph>
          <strong>Battery-Powered Lights</strong>:
          <ul>
            <li><strong>Lanterns</strong>: Battery-operated lanterns can be hung or placed on tables for a cozy, portable lighting option.</li>
            <li><strong>String Lights</strong>: Similar to fairy lights but often larger, these can be used to decorate fences, railings, and trees.</li>
          </ul>
        </IntroParagraph>
        <BlogImage src="/lamps.png" alt="Lamps" />
        <Title>Types of Lights and Their Uses</Title>
        <IntroParagraph>
          <strong>Strip Lights</strong>: LED strip lights are incredibly versatile and can be used to outline steps, edges of flower beds, or under benches. They provide a modern, sleek look and are excellent for accentuating architectural elements in your garden.
        </IntroParagraph>
        <IntroParagraph>
          <strong>Fairy Lights</strong>: Fairy lights add a touch of magic and are perfect for creating a relaxed, festive atmosphere. They are ideal for draping over shrubs, trees, or garden structures like gazebos and trellises.
        </IntroParagraph>
        <IntroParagraph>
          <strong>Spotlights</strong>: Spotlights are powerful tools for drawing attention to specific garden features. Use them to illuminate statues, water features, or particularly beautiful plants. Adjustable spotlights allow you to direct the light precisely where you want it.
        </IntroParagraph>
        <Title>Fire Pits: Warmth and Multifunctionality</Title>
        <IntroParagraph>
          A fire pit can serve as a focal point in your garden, providing both light and warmth. Fire pits come in various designs, from traditional stone structures to modern metal bowls.
        </IntroParagraph>
        <IntroParagraph>
          <strong>Benefits of Fire Pits</strong>:
          <ul>
            <li><strong>Seating Area</strong>: Arrange seating around the fire pit to create a cozy gathering spot for friends and family.</li>
            <li><strong>Cooking</strong>: Some fire pits come with grill attachments, allowing you to cook over an open flame.</li>
            <li><strong>Ambiance</strong>: The natural light from a fire pit adds a warm, inviting glow to your garden.</li>
          </ul>
        </IntroParagraph>
        <BlogImage src="/lightbulb.png" alt="Lightbulbs" />
        <Title>Highlighting Focal Points</Title>
        <IntroParagraph>
          Strategically placed lighting can highlight the best features of your garden, adding depth and dimension.
        </IntroParagraph>
        <IntroParagraph>
          <strong>Tips for Highlighting</strong>:
          <ul>
            <li><strong>Layering</strong>: Use a combination of lighting types (spotlights, fairy lights, strip lights) to create layers of light and shadow.</li>
            <li><strong>Contrast</strong>: Illuminate dark corners and use shadows to your advantage to add drama and intrigue.</li>
            <li><strong>Focus on Key Areas</strong>: Prioritize areas such as seating zones, water features, and unique plants or structures.</li>
          </ul>
        </IntroParagraph>
        <Title>Additional Tips for Garden Lighting</Title>
        <IntroParagraph>
          <strong>Dimmers and Timers</strong>: Installing dimmers and timers allows you to control the intensity and duration of your garden lights, saving energy and extending the lifespan of your lighting fixtures.
        </IntroParagraph>
        <IntroParagraph>
          <strong>Color Temperature</strong>: Choose the right color temperature for your garden lights. Warm white light (2700K-3000K) is ideal for creating a cozy ambiance, while cool white light (3500K-4100K) is better for task lighting.
        </IntroParagraph>
        <IntroParagraph>
          <strong>Weather Resistance</strong>: Ensure all lights and fixtures are rated for outdoor use and can withstand various weather conditions.
        </IntroParagraph>
      </BlogContainer>
      <Footer />
    </BackgroundContainer>
  );
};

export default BlogLights;
