import React from 'react';
import styled from 'styled-components';
import NavBar from '../components/PublicNav/homenav';
import Footer from '../components/Footer';


const BackgroundContainer = styled.div`
  background: #f8f8f8;
`;

const BlogContainer = styled.div`
  margin: 0 auto;
  padding: 20px;
`;

const BlogTitle = styled.h1`
  text-align: center;
  margin-bottom: 30px;
`;

const IntroParagraph = styled.p`
  font-size: 1rem;
  line-height: 1.6;
  max-width: 70%;
  margin-left: auto;
  margin-right: auto;
  color: #333;
  margin-bottom: 30px; // Space between the paragraph and the first image
  text-align: left;
  @media (orientation: portrait){
    max-width: 100%;
  }
`;


const Aboutus: React.FC = () => {

  return (
    <BackgroundContainer>
      <NavBar />
      <BlogContainer>
        <BlogTitle>About us</BlogTitle>
        <IntroParagraph>
          In the fast-paced world of landscaping, time is as valuable as accuracy. Whether it's meticulously measuring spaces or crafting detailed quotes, the demands of precision and efficiency weigh heavily on business owners. This is where Project Price, a groundbreaking app specifically designed for landscaping professionals, transforms the game.
        </IntroParagraph>
        <IntroParagraph>
          At its core, Project Price was born out of necessity. As a landscaping company owner, I was all too familiar with the time-consuming process of job pricing and the risk of errors in measurements and quantities. These challenges weren't just operational hiccups; they had real financial implications. Recognizing the need for a tool that could streamline these tasks, Project Price was developed as a solution not just for my business, but for the landscaping industry at large.
        </IntroParagraph>
        <IntroParagraph>
          <strong>Why Use Project Price?</strong><br />
          <ul>
            <li><strong>Accuracy in Measurements and Estimates:</strong> One of the most significant benefits of Project Price is its ability to minimize errors in measurements and quantities. With its intuitive interface, the app ensures that all calculations are precise, reducing the chances of financial loss due to underquoting. Find out how <a href="/guide"> our app</a> works</li>
            <li><strong>Time Efficiency:</strong> Time spent manually working out measurements and quotes is time not spent on the actual landscaping work. Project Price automates these processes, freeing up valuable hours that can be redirected to more productive tasks or securing new business opportunities.</li>
            <li><strong>Professional and Fast Quotes:</strong> First impressions matter. Project Price enables you to quickly generate and send out professional-looking quotes to your customers. This not only enhances your company's image but also speeds up the decision-making process for your clients. Check out our 1-of-a-kind <a href="/quoter">Quote builder</a>.</li>
            <li><strong>Reduced Workload:</strong> The burden of administrative tasks can be overwhelming. Project Price lightens this load significantly by handling the computational and documentation aspects of job pricing. This reduces stress and allows for a more focused approach to the physical aspects of landscaping.</li>
            <li><strong>Increased Profit Margins:</strong> By reducing errors and saving time, Project Price indirectly contributes to better profit margins. More accurate quotes mean fewer losses, and faster turnaround times can lead to more jobs and, consequently, increased revenue.</li>
          </ul>
        </IntroParagraph>
        <IntroParagraph>
          Project Price isn't just another business tool; it's a strategic asset for anyone in the landscaping industry. Its development was driven by real-world challenges and a deep understanding of the landscaping business's nuances. By embracing Project Price, landscapers can look forward to a future where accuracy, efficiency, and profitability are not just goals but everyday realities. As someone who's walked in those shoes, I can confidently say that Project Price is more than an app; it's your next business partner.
        </IntroParagraph>
      </BlogContainer>
      <Footer />
    </BackgroundContainer>
  );
};

export default Aboutus;
