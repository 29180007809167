import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { FiArrowLeftCircle } from 'react-icons/fi';
import styled, { keyframes } from 'styled-components';
import axios from 'axios';
import Footer from '../components/Footer';
import NavBar from '../components/PublicNav/homenav';

const MainContainer = styled.div`
  background-color: white;
`;

const FlexContainer = styled.div`
  background-color: white;
  display: flex;
  margin-bottom: 50px;
  padding: 0 80px;
  flex-direction: row;
  align-items: start;
  justify-content: space-between;
  @media (max-height: 400px){
      flex-direction: column;
      align-items: center;
  }
  @media (orientation: portrait){
      flex-direction: column;
      align-items: center;
  }
`;

const ArrowButton = styled.div`
  display: flex;
  flex-direction: row;
`;

const SideContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
  margin-left: 20px;
  @media (orientation: portrait){
    padding 2%;
    margin-left: 0%;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  background-color: rgba( 0,0,0, 0.2);
  flex-direction: column;
  padding: 15px;
  align-items: center;
  margin-bottom: 10px;
  border-radius: 20px;
`;

const StyledCanvas = styled.canvas`
  width: 800px; // Default width
  height: 500px; // Default height
  touch-action: none;

  // Media query for screens with a width of 500px or less
  @media (max-width: 1000px) and (orientation: landscape) {
    width: 100%; // Set width to 100% of the parent container
    height: 300px; // Adjust height automatically
  }
  @media (orientation: portrait) {
    width: 100%; // Adjust width for portrait
    height: 100%; // Adjust height for portrait
  }
`;

const Title = styled.h2`
  font-size: 7vh;
  font-weight: bold;
  padding: 5vh 10vh;
  color: rgba(7,55,99);
  @media (orientation: portrait) {
    font-size: 90%;
    padding: 2% 5%;
  }
`;

const EditButton = styled.button`
  display: none;
  background-color: rgba(7,55,99);
  color: white;
  margin-left: 5px;
  margin-bottom: 20px;
  padding: 5;
  border: none;
  border-radius: 5px;
  font-size: 3.3vh;
  margin-top: 5vh;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #007BFF;
  }
  @media (orientation: portrait){
    padding: 5%;
    font-size: 90%;
    margin-top: 5%;
    display: flex;
  }
`;

const Button = styled.button`
  background-color: rgba(7,55,99);
  color: white;
  margin-left: 5px;
  margin-bottom: 20px;
  padding: 5;
  border: none;
  border-radius: 5px;
  font-size: 3.3vh;
  margin-top: 5vh;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #007BFF;
  }
  @media (orientation: portrait){
    padding: 5%;
    font-size: 90%;
    margin-top: 5%;
  }
`;

const CenteredMessage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh; // full height of the viewport
  text-align: center;
`;

const LogoImage = styled.img`
  width: 200px; // adjust as needed
  margin-bottom: 20px;
`;

const Input = styled.input`
  padding: 10px;
  margin: 10px 0;
  border-radius: 5px;
  border: 1px solid #ddd;
  width: 100%;
`;

const StyledButton = styled.button`
  background: transparent;
  display: flex;
  border: none;
  color: white;
  font-size: 0.9rem;
  text-decoration: underline;
  cursor: pointer;
`;

const ErrorMessage = styled.p`
  color: red;
`;

const spinnerAnimation = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const Spinner = styled.div`
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid white;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: ${spinnerAnimation} 1s linear infinite;
  margin: 0 auto;
`;

interface Point {
  x: number;
  y: number;
}

interface Line {
  startPoint: Point;
  endPoint: Point;
  length: number;
}

const AreaCalculator: React.FC = () => {
  const [points, setPoints] = useState<Point[]>([]);
  const [lines, setLines] = useState<Line[]>([]);
  const navigate = useNavigate();
  const [area, setArea] = useState<number | null>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [isShapeClosed, setIsShapeClosed] = useState<boolean>(false);
  const [selectedPointIndex, setSelectedPointIndex] = useState<number | null>(null);
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [recentlyMoved, setRecentlyMoved] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [, setIsLoggedIn] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [perimeter, setPerimeter] = useState<number | null>(null);
  const [, setEmailValidated] = useState(false);


  useEffect(() => {
    const validateToken = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        setShowLoginModal(true);
      } else {
        const user = JSON.parse(localStorage.getItem('user') || '{}');
        setEmailValidated(user.emailValidated);
        if (!user.emailValidated) {
          navigate('/emailvalidation');
          return;
        }
        setIsLoggedIn(true);
      }
    };
  
    validateToken();
  }, [navigate]);
  

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    try {
      const { data: loginData } = await axios.post("https://projectprice-ad06ee250897.herokuapp.com/auth/login", {
        email,
        password,
      });
  
      if (loginData && loginData.errors && loginData.errors.length > 0) {
        setErrorMsg(loginData.errors[0].msg);
      } else {
        if (!loginData.data.user.emailValidated) {
          navigate("/emailvalidation");
          return;
        }
  
        const user = {
          id: loginData.data.user.id,
          email: loginData.data.user.email,
          stripeCustomerId: loginData.data.user.stripeCustomerId,
          emailValidated: loginData.data.user.emailValidated,
        };
  
        localStorage.setItem("user", JSON.stringify(user));
        localStorage.setItem("token", loginData.data.token);
        axios.defaults.headers.common["Authorization"] = `Bearer ${loginData.data.token}`;
  
        setIsLoggedIn(true); // Mark the user as logged in
        setEmailValidated(user.emailValidated); // Set email validated status
        setShowLoginModal(false);
  
        if (!user.emailValidated) {
          navigate("/emailvalidation");
        }
      }
    } catch (error: any) {
      if (error.response && error.response.data && error.response.data.message) {
        setErrorMsg(error.response.data.message);
      } else {
        setErrorMsg("Error, please try again");
      }
    } finally {
      setLoading(false);
    }
  };
  

  const PIXELS_PER_METER = 25; // Example: 100 pixels represent 1 meter

  const drawGrid = (ctx: CanvasRenderingContext2D, canvasWidth: number, canvasHeight: number) => {
    ctx.clearRect(0, 0, canvasWidth, canvasHeight); // Clear the canvas
    ctx.strokeStyle = '#cccccc'; // Color for the grid lines
    ctx.beginPath();

    // Draw vertical lines
    for (let x = 0; x <= canvasWidth; x += PIXELS_PER_METER) {
      ctx.moveTo(x, 0);
      ctx.lineTo(x, canvasHeight);
    }

    // Draw horizontal lines
    for (let y = 0; y <= canvasHeight; y += PIXELS_PER_METER) {
      ctx.moveTo(0, y);
      ctx.lineTo(canvasWidth, y);
    }

    ctx.stroke();
  };

  const redrawCanvasContent = () => {
    const canvas = canvasRef.current;
    if (!canvas || !canvas.getContext) return;

    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    ctx.clearRect(0, 0, canvas.width, canvas.height);
    drawGrid(ctx, canvas.width, canvas.height);
    drawAllPointsAndLines(ctx); // Function to redraw all points and lines
  };

  const drawAllPointsAndLines = (ctx: CanvasRenderingContext2D) => {
    // Draw all points
    ctx.fillStyle = 'black';
    points.forEach(point => {
        ctx.beginPath();
        ctx.arc(point.x, point.y, 3, 0, 2 * Math.PI);
        ctx.fill();
    });

    // Draw all lines
    ctx.strokeStyle = 'black';
    lines.forEach(line => {
        ctx.beginPath();
        ctx.moveTo(line.startPoint.x, line.startPoint.y);
        ctx.lineTo(line.endPoint.x, line.endPoint.y);
        ctx.stroke();
    });
  };

  const resizeCanvas = () => {
      if (!canvasRef.current) return;

      const canvas = canvasRef.current;
      const rect = canvas.getBoundingClientRect(); // Get the displayed size

      // Set the internal size to match the displayed size
      canvas.width = rect.width;
      canvas.height = rect.height;

      redrawCanvasContent(); // Redraw everything after resizing
  };

  useEffect(() => {
      const handleResize = () => {
          resizeCanvas();
      };

      window.addEventListener('resize', handleResize);
      handleResize(); // Initial setup

      return () => {
          window.removeEventListener('resize', handleResize);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Empty dependency array to run only on mount and unmount

  const addLine = (endPoint: Point, length: number) => {
    const lastPoint = points[points.length - 1];
    const newLine: Line = { startPoint: lastPoint, endPoint: endPoint, length: length };
    setLines(prevLines => [...prevLines, newLine]);
  };

  const handleCanvasClick = (event: React.MouseEvent) => {
    if (isDragging || isShapeClosed || recentlyMoved) {
      return;
    }

    const canvas = canvasRef.current;
    if (!canvas) return;

    const rect = canvas.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;
    const newPoint: Point = { x, y };

    if (points.length > 0 && isNearFirstPoint(newPoint, points[0])) {
      finishShape();
    } else {
      if (points.length > 0) {
        const length = calculateLineLength(points[points.length - 1], newPoint);
        addLine(newPoint, length);
      }
      // Add point here after checking if it is not closing the shape
      setPoints(prevPoints => [...prevPoints, newPoint]);
      drawLinesAfterAddingPoint([...points, newPoint, isShapeClosed ? points[0] : newPoint]);
    }
  };

  const finishShape = () => {
    if (points.length > 1 && !isShapeClosed) {
      const firstPoint = points[0];
      const lastPoint = points[points.length - 1];
      // Add line from last point to first point to close the shape
      const closingLineLength = calculateLineLength(lastPoint, firstPoint);
      const closingLine = { startPoint: lastPoint, endPoint: firstPoint, length: closingLineLength };

      // Update lines state and then calculate perimeter
      setLines(prevLines => {
        const newLines = [...prevLines, closingLine];
        setIsShapeClosed(true);
        calculateArea();
        // Ensuring perimeter is calculated after state update
        setTimeout(() => {
          calculatePerimeter(newLines);
        }, 0);
        return newLines;
      });
    }
  };

  const calculateLineLength = (point1: Point, point2: Point): number => {
    return Math.sqrt(Math.pow(point2.x - point1.x, 2) + Math.pow(point2.y - point1.y, 2)) / PIXELS_PER_METER;
  };

  const drawLinesAfterAddingPoint = (updatedPoints: Point[]) => {
    const canvas = canvasRef.current;
    if (!canvas) return;
    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    ctx.clearRect(0, 0, canvas.width, canvas.height);

    // Draw the grid first
    drawGrid(ctx, canvas.width, canvas.height);

    // Then draw points and lines
    ctx.fillStyle = 'black';
    updatedPoints.forEach(point => {
      ctx.beginPath();
      ctx.arc(point.x, point.y, 3, 0, 2 * Math.PI);
      ctx.fill();
    });

    ctx.strokeStyle = 'black';
    ctx.beginPath();
    lines.forEach((line, index) => {
      if (index === 0) {
        ctx.moveTo(line.startPoint.x, line.startPoint.y);
      }
      ctx.lineTo(line.endPoint.x, line.endPoint.y);
    });

    if (updatedPoints.length > 1 && isShapeClosed) {
      const firstPoint = updatedPoints[0];
      ctx.lineTo(firstPoint.x, firstPoint.y);
    }

    if (isShapeClosed && updatedPoints.length > 1) {
      const firstPoint = updatedPoints[0];
      const lastPoint = updatedPoints[updatedPoints.length - 1];
      ctx.moveTo(lastPoint.x, lastPoint.y);
      ctx.lineTo(firstPoint.x, firstPoint.y);
    }

    ctx.stroke();
  };

  const isNearFirstPoint = (point: Point, firstPoint: Point): boolean => {
    const threshold = 10; // pixels, you can adjust this value
    const distance = Math.sqrt(Math.pow(point.x - firstPoint.x, 2) + Math.pow(point.y - firstPoint.y, 2));
    return distance < threshold;
  };


  const calculateArea = () => {
    let pixelArea = 0;
    const numPoints = points.length;

    for (let i = 0; i < numPoints; i++) {
      const j = (i + 1) % numPoints;
      pixelArea += points[i].x * points[j].y;
      pixelArea -= points[j].x * points[i].y;
    }

    pixelArea = Math.abs(pixelArea) / 2;

    // Convert pixel area to square meters
    const areaInSquareMeters = pixelArea * Math.pow(PIXELS_PER_METER, -2);
    setArea(areaInSquareMeters);
  };

  const calculatePerimeter = (linesArray: Line[]) => {
    let totalPerimeter = 0;
    linesArray.forEach((line: Line) => {
      totalPerimeter += calculateLineLength(line.startPoint, line.endPoint);
    });
    setPerimeter(totalPerimeter);
  };

  const removeLastPoint = () => {
    if (points.length > 0) {
      let newPoints = points;
      let newLines = lines;

      if (isShapeClosed) {
        // If the shape is closed, remove the last two lines and the last point
        newLines = lines.length > 1 ? lines.slice(0, -2) : [];
        newPoints = points.slice(0, -1);
        setIsShapeClosed(false); // Reset shape closure since it's being modified
      } else {
        // If the shape is not closed, remove the last line and the last point
        newLines = lines.length > 0 ? lines.slice(0, -1) : [];
        newPoints = points.slice(0, -1);
      }

      setLines(newLines);
      setPoints(newPoints);
      setArea(0);
      setPerimeter(0);

      // Call drawLines to update the canvas after state changes
      drawLinesAfterRemovingPoint(newPoints);
    }
  };

  const drawLinesAfterRemovingPoint = (updatedPoints: Point[]) => {
    const canvas = canvasRef.current;
    if (!canvas) return;
    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    ctx.clearRect(0, 0, canvas.width, canvas.height);

    // Draw the grid
    drawGrid(ctx, canvas.width, canvas.height);

    // Draw points based on the updated points
    ctx.fillStyle = 'black';
    updatedPoints.forEach(point => {
      ctx.beginPath();
      ctx.arc(point.x, point.y, 3, 0, 2 * Math.PI);
      ctx.fill();
    });

    // Draw lines
    ctx.strokeStyle = 'black';
    ctx.beginPath();
    lines.slice(0, updatedPoints.length - 1).forEach((line, index) => {
      if (index === 0) {
        ctx.moveTo(line.startPoint.x, line.startPoint.y);
      }
      ctx.lineTo(line.endPoint.x, line.endPoint.y);
    });

    if (updatedPoints.length > 1 && isShapeClosed) {
      ctx.lineTo(updatedPoints[0].x, updatedPoints[0].y);
    }

    ctx.stroke();
  };

  const handleMouseDown = (event: React.MouseEvent) => {
    document.body.style.overflow = 'hidden';
    const canvas = canvasRef.current;
    if (!canvas) return;

    const rect = canvas.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;
    const THRESHOLD = 10;

    const hitIndex = points.findIndex(p =>
      Math.sqrt(Math.pow(p.x - x, 2) + Math.pow(p.y - y, 2)) < THRESHOLD
    );

    if (hitIndex !== -1) {
      setSelectedPointIndex(hitIndex);
      setIsDragging(true);
    }
  };

  const handleMouseMove = (event: React.MouseEvent) => {
    if (isDragging && selectedPointIndex !== null) {
      const canvas = canvasRef.current;
      if (!canvas) return;

      const rect = canvas.getBoundingClientRect();
      const newX = event.clientX - rect.left;
      const newY = event.clientY - rect.top;

      const newPoints = points.map((point, index) =>
        index === selectedPointIndex ? { x: newX, y: newY } : point
      );

      const newLines = lines.map(line => {
        if (line.startPoint === points[selectedPointIndex]) {
          return { ...line, startPoint: newPoints[selectedPointIndex] };
        } else if (line.endPoint === points[selectedPointIndex]) {
          return { ...line, endPoint: newPoints[selectedPointIndex] };
        }
        return line;
      });

      setPoints(newPoints);
      setLines(newLines);
      setRecentlyMoved(true);

      // Delay the recalculation to ensure state updates are processed
      setTimeout(() => {
        calculateArea();
        calculatePerimeter(lines);
        setRecentlyMoved(false);
      }, 500);
    }
  };

  const redrawCanvas = () => {
    const canvas = canvasRef.current;
    if (!canvas) return;
    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    // Clear the canvas
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    // Redraw grid, points, and lines
    drawGrid(ctx, canvas.width, canvas.height);
    drawAllPointsAndLines(ctx); // Function that redraws all points and lines
  };

  useEffect(() => {
    const handleResize = () => {
      resizeCanvas();
      redrawCanvas(); // Function to redraw everything on the canvas
    };

    const handleScroll = () => {
      redrawCanvas(); // Redraw canvas on scroll
    };

    window.addEventListener('resize', handleResize);
    window.addEventListener('scroll', handleScroll);

    // Initial draw
    redrawCanvas();

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('scroll', handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [points, lines]);

  const drawLinesAfterMovingPoint = (updatedPoints: Point[], updatedLines: Line[]) => {
    const canvas = canvasRef.current;
    if (!canvas) return;
    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    ctx.clearRect(0, 0, canvas.width, canvas.height);
    drawGrid(ctx, canvas.width, canvas.height);

    // Draw updated points
    ctx.fillStyle = 'black';
    updatedPoints.forEach((point: Point) => {
      ctx.beginPath();
      ctx.arc(point.x, point.y, 3, 0, 2 * Math.PI);
      ctx.fill();
    });

    // Draw updated lines
    ctx.strokeStyle = 'black';
    ctx.beginPath();
    updatedLines.forEach((line: Line) => {
      ctx.moveTo(line.startPoint.x, line.startPoint.y);
      ctx.lineTo(line.endPoint.x, line.endPoint.y);
    });

    ctx.stroke();
  };

  const handleMouseUp = () => {
    if (isDragging) {
      setIsDragging(false); // Stop dragging
      setSelectedPointIndex(null);
      calculateArea();
      calculatePerimeter(lines);
    }
    document.body.style.overflow = '';
  };

  const clearAll = () => {
    setPoints([]);
    setLines([]);
    setIsShapeClosed(false);
    setArea(0);
    setPerimeter(0);
  };

  const handleTouchStart = (event: React.TouchEvent<HTMLCanvasElement>) => {
    if (!isEditMode) return;
    event.preventDefault(); // Prevent default touch behavior
    document.body.style.overflow = 'hidden';

    const canvas = canvasRef.current;
    if (!canvas) return;

    // Check if there are touches
    if (event.touches.length === 0) return;

    const touch = event.touches[0];
    const rect = canvas.getBoundingClientRect();
    const x = touch.clientX - rect.left;
    const y = touch.clientY - rect.top;
    const THRESHOLD = 10;

    const hitIndex = points.findIndex(p =>
      Math.sqrt(Math.pow(p.x - x, 2) + Math.pow(p.y - y, 2)) < THRESHOLD
    );

    if (hitIndex !== -1) {
      setSelectedPointIndex(hitIndex);
      setIsDragging(true);
    }
  };

  const handleTouchMove = (event: React.TouchEvent<HTMLCanvasElement>) => {
    if (!isEditMode) return;
    event.preventDefault();
    document.body.style.overflow = 'hidden';
    if (isDragging && selectedPointIndex !== null) {
      const canvas = canvasRef.current;
      if (!canvas) return;

      // Check if there are touches
      if (event.touches.length === 0) return;

      const touch = event.touches[0];
      const rect = canvas.getBoundingClientRect();
      const newX = touch.clientX - rect.left;
      const newY = touch.clientY - rect.top;

      // Update the position of the selected point
      const newPoints = points.map((point, index) =>
        index === selectedPointIndex ? { x: newX, y: newY } : point
      );

      // Update the lines connected to the moved point
      const newLines = lines.map(line => {
        if (line.startPoint === points[selectedPointIndex]) {
          return { ...line, startPoint: newPoints[selectedPointIndex] };
        } else if (line.endPoint === points[selectedPointIndex]) {
          return { ...line, endPoint: newPoints[selectedPointIndex] };
        }
        return line;
      });

      // Update state with new points and lines
      setPoints(newPoints);
      setLines(newLines);
      setRecentlyMoved(true);

      // Redraw the canvas with updated points and lines
      drawLinesAfterMovingPoint(newPoints, newLines);
    }
    setTimeout(() => {
      setRecentlyMoved(false);
    }, 300);
  };

  const handleTouchEnd = () => {
    document.body.style.overflow = '';
    if (isDragging) {
      setIsDragging(false); // Stop dragging
      setSelectedPointIndex(null);
      calculateArea();
      calculatePerimeter(lines);
    }
    document.body.style.overflow = '';
  };

  return (
    <MainContainer>
      {showLoginModal ? (
        <MainContainer>
          <NavBar />
        <CenteredMessage>
          <LogoImage src='project-price-logo-name.png' />
          <h2>Please log in to use this free feature.</h2>
          <form onSubmit={handleLogin}>
            <Input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <Input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <StyledButton type="submit" disabled={loading}>
              {loading ? <Spinner /> : 'Login'}
            </StyledButton>
            {errorMsg && <ErrorMessage>{errorMsg}</ErrorMessage>}
          </form>
        </CenteredMessage>
        </MainContainer>
      ) : (
        <>
          <NavBar />
          <Title>Area Calculator</Title>
          <FlexContainer>
            <StyledCanvas
              ref={canvasRef}
              width="800"
              height="500"
              onClick={handleCanvasClick}
              onMouseDown={handleMouseDown}
              onMouseMove={handleMouseMove}
              onMouseUp={handleMouseUp}
              onTouchStart={handleTouchStart}
              onTouchMove={handleTouchMove}
              onTouchEnd={handleTouchEnd}
            />
            <SideContainer>
              <ButtonsContainer>
                1 Square = 1m²
                <ArrowButton>
                  <Button onClick={removeLastPoint}><FiArrowLeftCircle /></Button>
                  <Button onClick={clearAll}>Clear All</Button>
                  <EditButton onClick={() => setIsEditMode(!isEditMode)}>
                    {isEditMode ? 'Disable Edit' : 'Enable Edit'}
                  </EditButton>
                </ArrowButton>
                {area !== null && <div>Area: {area.toFixed(2)} m²</div>}
                {perimeter !== null && <div>Perimeter: {perimeter.toFixed(2)} m</div>}
              </ButtonsContainer>
            </SideContainer>
          </FlexContainer>
          <Footer />
        </>
      )}
    </MainContainer>
  );
};

export default AreaCalculator;
