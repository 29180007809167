import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';


const BackgroundContainer = styled.div`
  background: #f8f8f8;
  text-align: center;
`;

const SignUpButton = styled.button`
  background-color: rgba(0, 255, 9, 1);
  color: black;
  border: none;
  border-radius: 5vh;
  padding: 2.3vh;
  font-weight: bold;
  font-size: 2.7vh;
  cursor: pointer;
  transition: transform 0.3s;
  margin-bottom: 3vh;

  &:hover {
    background-color: rgba(84, 255, 90, 1);
    animation: none;
    transform: scale(1.03);
  }
    @media(orientation: portrait){
  font-size: 1.8vh;
  padding: 1.3vh;
  }
`;

const SignUpText = styled.p`
  font-size: 1.4rem;
  font-weight: bold;
   margin-top: 3vh;
   margin-bottom: 2vh;
  @media(orientation: portrait){
  font-size: 2vh;
  }
`;


const SignUpSection: React.FC = () => {

    const navigate = useNavigate();

    const handleCreateAccount = () => {
        navigate('/loginpage?signup=true');
      };
    
return (
  <BackgroundContainer>
    <SignUpText>Experience the Efficiency of Automated Quoting—Try it Free!</SignUpText>
    <SignUpButton onClick={handleCreateAccount}>Start Saving Time Now! Free For 7 Days ➜</SignUpButton>
  </BackgroundContainer>
);
}

export default SignUpSection;
