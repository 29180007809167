import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

interface ProfileModalProps {
  onClose: () => void;
  setBackgroundColor: React.Dispatch<React.SetStateAction<string>>;
}

const OverlayContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
`;

const ColorSelector = styled.div<{ bgColor: string }>`
  width: 30px;
  height: 25px;
  background-color: ${props => props.bgColor};
  cursor: pointer;
  margin-right: 10px;
  display: inline-block;
`;

const ColorContainer = styled.div`
  margin-top: 20px;
`;

const OverlayContent = styled.div`
  background-color: rgba(0, 0, 0, 0.7);
  padding: 20px;
  border-radius: 5px;
  max-width: 400px;
  height: fit-content;
  margin-left: 10%;
  margin-top: 2%;
  color: white;
`;

const ButtonContainer = styled.div`
  margin-top: 10%;
`;

const StyledButton = styled.button`
  background: transparent;
  display: flex;
  border: none;
  color: white;
  font-size: 0.9rem;
  text-decoration: underline;
`;

const ProfileModal: React.FC<ProfileModalProps> = ({ onClose, setBackgroundColor }) => {
  const [userEmail, setUserEmail] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await axios.get('https://projectprice-ad06ee250897.herokuapp.com/auth/me', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });

        setUserEmail(response.data.data.user.email);
      } catch (error) {
        console.error('Error fetching user details:', error);
      }
    };

    fetchUserDetails();
  }, []);

  const handleBackgroundColorChange = (color: string) => {
    setBackgroundColor(color); // Update the Dashboard's background color
    localStorage.setItem('dashboardBgColor', color);
  };

  const handleResetPassword = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    navigate('/emailpassword');
  };

  const handleLogout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    navigate('/');
  };

  const handleManageSubscription = () => {
    // Redirect the user to the Stripe subscription management link
    window.location.href = 'https://payments.projectprice.co.uk/p/login/14k8yZfmJbt1eBi8ww';
  };

  return (
    <OverlayContainer onClick={onClose}>
      <OverlayContent onClick={(e) => e.stopPropagation()}>
        <div>{userEmail}</div>
        <ButtonContainer>
          <StyledButton onClick={handleResetPassword}>Reset Password</StyledButton>
        </ButtonContainer>
        <ButtonContainer>
          <StyledButton onClick={handleManageSubscription}>Manage Subscription</StyledButton>
        </ButtonContainer>
        <ButtonContainer>
          <StyledButton onClick={handleLogout}>Logout</StyledButton>
        </ButtonContainer>
        <ButtonContainer>
          <ColorContainer>
            <div>Theme:</div>
            <ColorSelector bgColor="rgba(7, 55, 99)" onClick={() => handleBackgroundColorChange('rgba(7, 55, 99)')} />
            <ColorSelector bgColor="rgba(13, 17, 23, 0.95)" onClick={() => handleBackgroundColorChange('rgba(13, 17, 23, 0.95)')} />
            <ColorSelector bgColor="rgba(245, 245, 245)" onClick={() => handleBackgroundColorChange('rgba(245, 245, 245)')} />
          </ColorContainer>
        </ButtonContainer>
      </OverlayContent>
    </OverlayContainer>
  );
};

export default ProfileModal;
