import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import Footer from '../components/Footer';
import NavBar from '../components/PublicNav/homenav';

const BackgroundContainer = styled.div`
  background: #f8f8f8;
  min-height: 100vh;
`;


interface BlogPostContainerProps {
  backgroundImage: string;
}

const BlogPostsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2vh;
  padding: 4vh;

  @media (min-width: 768px) and (max-width: 1200px) {
    grid-template-columns: repeat(3, 1fr); /* 3 columns on medium screens */
  }

  @media (min-width: 1201px) {
    grid-template-columns: repeat(4, 1fr); /* 4 columns on large screens */
  }

  @media (max-width: 767px) {
    grid-template-columns: repeat(1, 1fr); /* 2 columns on small screens */
  }
`;


const BlogPostContainer = styled.div<BlogPostContainerProps>`
  position: relative;
  background-image: url(${props => props.backgroundImage});
  background-size: cover;
  background-position: center;
  padding: 8vh 3vh;
  text-align: center;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 250px;
  overflow: hidden;
  color: #fff;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Adjust the alpha (fourth value) for transparency */
    z-index: 1;
    border-radius: 8px;
  }
    @media (min-width: 1900px){
  padding: 4vh 2vh;
  }

  @media (orientation: portrait) and (max-width: 1000px){
  padding 2vh 2vh;}
`;


const BlogPostContent = styled.div`
  position: relative;
  z-index: 2;
  color: white;
`;

const BlogPostTitle = styled.h2`
  color: #fff;
  margin-bottom: 5vh;
  font-size: 4vh;
  font-weight: bold;

  @media (orientation: portrait) {
    font-size: 100%;
    margin-bottom: 5%;
  }

  @media (min-width: 1900px){
  font-size: 2.5vh;
  }
`;

const BlogPostDescription = styled.p`
  color: #fff;
  font-size: 2.5vh;
  margin-bottom: 6vh;
  flex-grow: 1;

  @media (orientation: portrait) {
    margin-bottom: 10%;
    font-size: 2vh;
  }
    @media (min-width: 1900px){
  font-size: 2vh;
  }
`;

const BlogPostLinkContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const BlogPostLink = styled.button`
  background-color: rgba(0, 56, 255, 0.8);
  color: white;
  font-size: 2vh;
  padding: 1.5vh 2.5vh;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: rgba(7,55,99);
  }

  @media (orientation: portrait) {
    padding: 5%;
    font-size: 100%;
  }
    @media (min-width: 1900px){
  padding: 1vh;
  }
`;

const Blip = styled.div<{ type: 'blog' | 'guide' }>`
  position: absolute;
  top: 0;
  left: 0;
  padding: 5px 10px;
  background-color: ${props => (props.type === 'guide' ? 'green' : 'blue')};
  color: #ffffff; /* Ensure the text color is white */
  font-size: 0.8rem;
  font-weight: bold;
  border-radius: 0 0 3px 0;
  text-transform: capitalize; /* Capitalize only the first letter */
  z-index: 2;
`;


interface BlogPostProps {
    title: string;
    description: string;
    link: string;
    backgroundImage: string;
    buttonText: string;
    type: 'blog' | 'guide'; // Add this line
  }
  
  const BlogPost: React.FC<BlogPostProps> = ({ title, description, link, backgroundImage, buttonText, type }) => {
    const navigate = useNavigate();

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    navigate(link);
    window.scrollTo(0, 0);
  };

  return (
    <BlogPostContainer backgroundImage={backgroundImage}>
      <Blip type={type}>{type}</Blip> {/* Add this line */}
      <BlogPostContent>
        <BlogPostTitle>{title}</BlogPostTitle>
        <BlogPostDescription>{description}</BlogPostDescription>
        <BlogPostLinkContainer>
          <BlogPostLink onClick={handleClick}>{buttonText}</BlogPostLink>
        </BlogPostLinkContainer>
      </BlogPostContent>
    </BlogPostContainer>
  );
};

const AllBlogPosts: React.FC = () => {
  return (
    <BackgroundContainer>
      <NavBar />
      <BlogPostsContainer>
      <BlogPost
          title='Exporting Project Price designs into SketchUp'
          description='View this step by step guide on how you are able to upload your designs directly into SketchUp'
          link='/sketchuppage'
          backgroundImage='/3dgarden.png'
          buttonText='Read more'
          type='guide'
        />
      <BlogPost
          title='Design ideas for smaller gardens'
          description='How to utilise space for smaller gardens, with different ideas for practicality.'
          link='/blogdesign'
          backgroundImage='/smallgarden.png'
          buttonText='Read more'
          type='blog'
        />
      <BlogPost
          title='Pricing a project using Project Price'
          description='Check out our video of us pricing a landscaping project.'
          link='/pricingdemo'
          backgroundImage='/laptoptable1.png'
          buttonText='View video'
          type='guide'
        />
        <BlogPost
                    title='How to integrate lighting into a garden'
                    description='Tips on how to properly incorperate different lighting into your projects.'
                    link='/bloglights'
                    backgroundImage='/lighting.png'
                    buttonText='Read more'
                    type='blog'
                    />
      <BlogPost
                    title='Watch how our automatic quoting tool works'
                    description='Get professional automated quotes for your customers at a touch of a button.'
                    link='/quotedemo'
                    backgroundImage='/clipboardpaper.png'
                    buttonText='View video'
                    type='guide'
                    />
      <BlogPost
                        title='Check out our free to use Area Calculator'
                        description='See our unmatched, FREE Area Calculator in action! Find out the area of your irregular shapes in seconds.'
                        link='/areacalculatordemo'
                        backgroundImage='/papercalc.png'
                        buttonText='View video'
                        type='guide'
                        />
      <BlogPost
                        title='Our complete set up demo'
                        description='See our complete guide in how to get your account set up and how to send quotes in our guide video.'
                        link='/fulldemo'
                        backgroundImage='/laptopscreen.png'
                        buttonText='View video'
                        type='guide'
                        />
      <BlogPost
          title='Building a Social media presence for your business'
          description='Find out ways to give your business maximum exposure on social media.'
          link='/blogsm'
          backgroundImage='/laptopgarden.png'
          buttonText='Read more'
          type='blog'
        />
      <BlogPost
          title='Small things, big difference!'
          description='Find out why it is important to keep to small details in mind when transforming a Project'
          link='/blogdetails'
          backgroundImage='/garden.png'
          buttonText='Read more'
          type='blog'
        />
        <BlogPost
          title='The importance of accurate pricing'
          description='Discover valuable insights of why it is crucial to deliver accurate pricing when quoting.'
          link='/blogquotes'
          backgroundImage='/barchart.png'
          buttonText='Read more'
          type='blog'
        />
        <BlogPost
          title='Growing your business'
          description='View our helpful guide on how to gain quality leads for your landscaping business.'
          link='/blogleads'
          backgroundImage='/wateringcan.png'
          buttonText='Read more'
          type='blog'
        />
        <BlogPost
          title='Why us?'
          description='Take a look at why landscaping professionals are choosing to use our pricing app.'
          link='/aboutus'
          backgroundImage='/questionmark.png'
          buttonText='Read more'
          type='blog'
        />
        <BlogPost
          title='View our Demo'
          description='View our quick demo video to find out how easy Project Price is to use.'
          link='/demo'
          backgroundImage='/laptoptable.png'
          buttonText='View video'
          type='guide'
        />
        <BlogPost
          title='Check out our quote builder'
          description='View our 1 of a kind custom quote builder.'
          link='/quoter'
          backgroundImage='/laptop.png'
          buttonText='Read more'
          type='guide'
        />
        <BlogPost
          title='How to use Project Price'
          description='Check out our walkthrough to take you through the steps.'
          link='/guide'
          backgroundImage='/gardenman.png'
          buttonText='Read more'
          type='guide'
        />
      </BlogPostsContainer>
      <Footer />
    </BackgroundContainer>
  );
};

export default AllBlogPosts;
