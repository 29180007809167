import React, { useEffect, useState, useContext, useCallback } from "react";
import { UserContext } from "../context";
import axios from "axios";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

// Styled components
const BackgroundContainer = styled.div`
  background: linear-gradient(to right, #1b2b52, #2c51a8);
  padding: 5rem 0;
  height: 100vh;
  @media (orientation: portrait) {
    padding: 10% 2%;
    height: 100vh;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  justify-content: space-around;
  height: 100%;
  @media (orientation: portrait) {
    flex-direction: column;
    width: 100%;
  }
`;

const PlansContainer = styled.div`
  width: 100%;
  margin-top: 5%;
  display: flex;
  flex-direction: column;
  height: fit-content;
  align-items: center;
  padding: 5vh;
  padding-bottom: 1vh;
  background-color: rgba(0, 0, 0, 0.2); /* Add background color with transparency */
  border: 1px solid white; /* Add white outline */
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  @media (orientation: portrait) {
    flex-direction: column;
    padding: 3vh;
    padding-bottom: 1vh;
  }
`;

const PlanCardsRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
  flex-direction: column;
  @media (orientation: portrait) {
    flex-direction: row;
  }
`;

const PlanTextContainer = styled.div`
  width: 100%;
  text-align: center;
  @media (orientation: portrait) {
    order: 2; /* Ensures this is positioned after the PlanCard elements */
  }
`;

const PlanText = styled.p`
  color: white;
  font-size: 2.5vh;
  @media (orientation: portrait) {
  }
`;

const PlanCard = styled.div<{ isSelected: boolean }>`
  width: 100%;
  background: ${({ isSelected }) => (isSelected ? 'rgba(7,55,99)' : 'rgba(15, 23, 48, 1)')}; /* Highlight if selected */
  padding: 1vh;
  margin-bottom: 2.5vh;
  flex-direction: column;
  border: 1px solid #ccc;
  border-radius: 5px;
  color: white;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s;
  position: relative;

  &:hover {
    background-color: ${({ isSelected }) => (isSelected ? 'rgba(7,55,99)' : 'white')};
    color: ${({ isSelected }) => (isSelected ? 'white' : 'rgba(7,55,99)')};
  }

  h3 {
    margin: 0.4vh 0;
    font-size: 4.5vh;
  }
  @media (orientation: portrait) {
    flex-direction: row;
    h3 {
      font-size: 2vh;
    }
  }
`;

const MostPopularTag = styled.div`
  position: absolute;
  top: -10px;
  right: -10px;
  background-color: white;
  color: rgba(7,55,99);
  padding: 0.5vh 1vh;
  font-size: 2vh;
  border-radius: 5px;
  @media (orientation: portrait) {
    font-size: 1.2vh;
    top: -2vh;
    right: 1.8vh;
  }
`;

const PlanDetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2vh;
  @media (orientation: portrait) {
    order: 2; /* Ensures this is positioned after the DescriptionContainer */
  }
`;

const PriceText = styled.p`
  font-size: 3vh;
  margin-right: 3vh;
  margin-top: 2vh;
  color: white;
  @media (max-width: 1070px) and (orientation: portrait){
    font-size: 2vh;
    text-align: center;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2vh;
  @media (orientation: portrait) {
    order: 3; /* Ensures this is positioned after PlanDetailContainer */
    width: 100%;
  }
`;

const PlanDescription = styled.p`
  font-size: 3vh;
  color: black;
  width: 100%;
  text-align: center;
  @media (max-width: 1070px) and (orientation: portrait){
    font-size: 2.4vh;
  }
`;

const DemoVideo = styled.iframe`
  width: 80vh;
  height: 40vh;
  border-radius: 10px;
  margin: 2vh 0;
  @media (orientation: portrait) {
    width: 60vh;
    order: 2;
  }
  @media (max-width: 1070px) and (orientation: portrait){
    width: 30vh;
    height: 18vh;
  }
`;

const SelectButton = styled.button`
  background-color: rgba(7,55,99);
  color: white;
  padding: 1.3vh;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 3vh;
  border: 1px solid #ccc;

  &:hover {
    background-color: white;
    color: rgba(7,55,99)
  }
`;

const LogoutButton = styled.button`
  background-color: transparent;
  color: white;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  font-size: 0.9rem;
`;

const LogoContainer = styled.div`
  position: fixed;
  left: 10px;
  top: 10px;
  cursor: pointer;
`;

const LogoImage = styled.img`
  height: 5vh;
`;

const LogoutContainer = styled.div`
  position: fixed;
  right: 10px;
  top: 10px;
  width: 15vh;
  padding-top: 0rem;
`;

const DescriptionContainer = styled.div`
  background: white;
  padding: 2vh;
  margin-top: 2vh;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  text-align: center;
  @media (orientation: portrait) {
    order: 1; /* Ensures this is positioned after the PlanCardsRow */
    height: 23vh;
  }
`;

const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30%;
  @media (orientation: portrait) {
    width: 100%;
  }
`;

interface Plan {
  id: string;
  name: string;
  price: string;
  description: string;
  videoUrl: string;
}

const SubPage: React.FC = () => {
  const [selectedPlan, setSelectedPlan] = useState<Plan>({
    id: "price_1PdphBC3dzWL0FIkNdGDdOpI",
    name: "Premium Plan",
    price: "49",
    description: "The premium plan allows full access to Project Price, including everything in the Regular plan plus price your projects through the design component.",
    videoUrl: "https://www.youtube.com/embed/i1IEELWX3yo",
  }); // Initialize to Premium Plan
  const [selectedPlanId, setSelectedPlanId] = useState<string>("price_1PdphBC3dzWL0FIkNdGDdOpI");
  const [, setPrices] = useState<any[]>([]);
  const [, setErrorMsg] = useState("");
  const [userState] = useContext(UserContext);
  const navigate = useNavigate();
  const [hasUsedTrial, setHasUsedTrial] = useState<boolean>(false);

  const checkSubscriptionStatus = useCallback(async () => {
    try {
      const { data: subscriptionData } = await axios.get(
        "https://projectprice-ad06ee250897.herokuapp.com/subscriptioncheck",
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

    
      const { isPremium, isRegular, onTrial, isFree, hasUsedTrial } = subscriptionData;

      if (isPremium || isRegular || onTrial) {
        navigate("/dashboard");
      } else if (isFree) {
        setSelectedPlan({
          id: "price_1PexiCC3dzWL0FIkB5Inx92T",
          name: "Free Plan",
          price: "0",
          description: "The free plan allows access to our area calculator. To use Project Price to the fullest please select either our Regular or Premium plans",
          videoUrl: "https://www.youtube.com/embed/G8Cny6pXBbk",
        });
      }
      
      setHasUsedTrial(hasUsedTrial);
    } catch (error) {
      console.error("Error checking subscription status:", error);
      setErrorMsg("An error occurred while checking subscription. Please try again.");
    }
  }, [navigate]);

  useEffect(() => {
    if (userState?.data) {
      checkSubscriptionStatus();
    } else {
      navigate("/loginpage");
    }
  }, [userState, navigate, checkSubscriptionStatus]);

  const fetchPrices = async () => {
    try {
      const response = await axios.get(
        "https://projectprice-ad06ee250897.herokuapp.com/subs/prices"
      );
      
      setPrices(response.data.data);
    } catch (error) {
      console.error("Error fetching prices:", error);
    }
  };

  useEffect(() => {
    fetchPrices();
  }, []);

  const plans: Plan[] = [
    {
      id: "price_1PexiCC3dzWL0FIkB5Inx92T",
      name: "Free Plan",
      price: "0",
      description: "The free plan allows access to our area calculator.<br />To use Project Price to the fullest please select either our Regular or Premium plans",
      videoUrl: "https://www.youtube.com/embed/G8Cny6pXBbk",
    },
    {
      id: "price_1PdphBC3dzWL0FIkNdGDdOpI",
      name: "Premium Plan",
      price: "49",
      description: "The premium plan allows full access to Project Price, including everything in the Regular plan plus price your projects through the design component.",
      videoUrl: "https://www.youtube.com/embed/i1IEELWX3yo",
    },
    {
      id: "price_1OS2l6C3dzWL0FIkAyI9m5sa",
      name: "Regular Plan",
      price: "29",
      description: "The regular plan allows access to the pricing and quoting components. Price and quote your projects by simply entering your measurements.",
      videoUrl: "https://www.youtube.com/embed/Y1xu3gnfoTI",
    },
  ];

  const createSession = async (priceId: string) => {
    try {
      if (priceId === "price_1PexiCC3dzWL0FIkB5Inx92T") {
        await axios.post("https://projectprice-ad06ee250897.herokuapp.com/subs/saveFreePlan", {
          email: userState?.data?.email,
        });
        navigate("/dashboard");
      } else {
        const { data: response } = await axios.post(
          "https://projectprice-ad06ee250897.herokuapp.com/subs/session",
          {
            priceId,
          }
        );
        window.location.href = response.url;
      }
    } catch (error: any) {
      console.error("Error creating session:", error.response ? error.response.data : error.message);
      setErrorMsg("An error occurred while creating the session. Please try again later.");
    }
  };

  const handlePlanClick = (plan: Plan) => {
    setSelectedPlan(plan);
    setSelectedPlanId(plan.id); // Update the selected plan ID
  };

  const handleLogoClick = () => {
    navigate("/");
  };

  const handleLogout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    navigate("/");
  };

  return (
    <BackgroundContainer>
      <LogoContainer onClick={handleLogoClick}>
        <LogoImage src="/project-price-name-white.png" alt="Project Price Logo" />
      </LogoContainer>
      <LogoutContainer>
        <LogoutButton onClick={handleLogout}>Logout</LogoutButton>
      </LogoutContainer>
  
      <ContentContainer>
        <LeftContainer>
          <PlansContainer>
            <PlanCardsRow>
              {plans.map((plan) => (
                <PlanCard
                  key={plan.id}
                  onClick={() => handlePlanClick(plan)}
                  isSelected={selectedPlanId === plan.id} // Highlight if selected
                >
                  <h3>{plan.name}</h3>
                  {plan.id === "price_1PdphBC3dzWL0FIkNdGDdOpI" && (
                    <MostPopularTag>Most Popular</MostPopularTag>
                  )}
                </PlanCard>
              ))}
            </PlanCardsRow>
            <PlanTextContainer>
              <PlanText>Your current plan: Free plan</PlanText>
            </PlanTextContainer>
          </PlansContainer>
          <DescriptionContainer>
            {selectedPlan && (
              <PlanDescription
                dangerouslySetInnerHTML={{ __html: selectedPlan.description }}
              />
            )}
          </DescriptionContainer>
        </LeftContainer>
  
        {selectedPlan && (
          <PlanDetailContainer>
            <DemoVideo
              src={selectedPlan.videoUrl}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
            <ButtonContainer>
              {selectedPlan.id === "price_1PdphBC3dzWL0FIkNdGDdOpI" && (
                <>
                {!hasUsedTrial && <PriceText>7 day free trial then £49 per month</PriceText>}
                {hasUsedTrial && <PriceText>£49 per month</PriceText>}
                <SelectButton onClick={() => createSession(selectedPlan.id)}>Subscribe</SelectButton>
              </>
            )}
            {selectedPlan.id === "price_1OS2l6C3dzWL0FIkAyI9m5sa" && (
              <>
                {!hasUsedTrial && <PriceText>7 day free trial then £29 per month</PriceText>}
                {hasUsedTrial && <PriceText>£29 per month</PriceText>}
                <SelectButton onClick={() => createSession(selectedPlan.id)}>Subscribe</SelectButton>
              </>
              )}
              {selectedPlan.name === "Free Plan" && (
                <>
                  <PriceText style={{ visibility: 'hidden' }}>Hidden Price</PriceText>
                  <SelectButton onClick={() => navigate("/areacalculator")}>Go to Area Calculator</SelectButton>
                </>
              )}
            </ButtonContainer>
          </PlanDetailContainer>
        )}
      </ContentContainer>
    </BackgroundContainer>
  );
}

export default SubPage;
