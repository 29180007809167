import React from "react";
import { Nav } from "react-bootstrap";
import { IconType } from "react-icons";
import { FiMonitor, FiClipboard, FiCheck, FiSettings, FiUser, FiEdit3 } from "react-icons/fi";
import styled from "styled-components";

interface ProjectComponentProps {
  onSubmit: () => void;
  project?: {
    id: number;
    name: string;
    projectName: string;
  };
  projectName: string;
}

interface NavItem {
  icon: IconType;
  text: string;
  component?: React.ReactElement<ProjectComponentProps> | null;
}

const NavItemBox = styled.div<{ active?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 90%;
  text-align: center;
  justify-content: center;
  background-color: transparent;
  width: 10vh;
  height: 8vh;
  color: ${({ active }) => (active ? "rgba(0, 204, 255, 1)" : "white")}; /* Set text color */
  border-color: black;
  margin-bottom: 40%; 

  span {
    font-size: 4px; /* Adjust the font size as desired */
  }

  @media (max-width: 1000px) {
   padding: 0vh;
   font-size: 3vh;
   padding: 0%;
   margin-bottom: 10%;
  }

  @media (min-width: 1900px) {
   padding: 0vh;
   font-size: 2.2vh;
   padding: 0%;
   margin-bottom: 35%;
  }
`;

const ProfileNavItemBox = styled(NavItemBox)`

flex-direction: column;
justify-content: center;
background-color: white;
width: 100%;
height: 100%;

span {
  font-size: 20%; 
}

@media (max-width: 1000px) {
  width: 100%;
  height: 8vh;
  }

`;

interface NavbarProps {
  activeTab: string;
  setActiveTab: React.Dispatch<React.SetStateAction<string>>;
  handleProjectSubmit: () => void;
  projectName: string;
  projectData: any; 
  setSettingsResult?: React.Dispatch<React.SetStateAction<string>>;
  onProfileClick: () => void; // It's already defined here
}

const Navbar: React.FC<NavbarProps> = ({
  activeTab,
  setActiveTab,
  handleProjectSubmit,
  projectName,
  projectData,
  setSettingsResult,
  onProfileClick, 
}) => {
  
  const onNavClick = (text: string) => {
    if (text === "Profile") {
      onProfileClick();
    } else if (text === "Area Calculator") {
      window.open('/areacalculator', '_blank');
    } else {
      setActiveTab(text);
    }
  };
  
  const navItems: NavItem[] = [
    {icon: FiClipboard, text: "Project"},
    {icon: FiCheck, text: "Pricing"},
    {icon: FiSettings, text: "Settings"},
    {icon: FiEdit3, text: "Quoting"},
    {icon: FiMonitor, text: "Design"},
    //{icon: FiFilePlus, text: "Tracker"},
  ];

  return (
    <div>
      <ProfileNavItemBox active={activeTab === "Profile"}>
        <Nav defaultActiveKey="/home" className="flex-column" style={{ padding: 0 }}>
          <Nav.Link onClick={() => onNavClick("Profile")}>
            <FiUser />
          </Nav.Link>
        </Nav>
      </ProfileNavItemBox>
      
      <Nav defaultActiveKey="/home" className="flex-column" style={{ padding: 0 }}>
        {navItems.map((item, index) => (
          <Nav.Link
            key={index}
            onClick={() => onNavClick(item.text)}
            data-testid={`nav-${item.text}`}
          >
            <NavItemBox active={activeTab === item.text}>
              <item.icon />
              {item.text}
            </NavItemBox>
          </Nav.Link>
        ))}
      </Nav>
    </div>
  );
};


export default Navbar;
