import React from 'react';
import styled from 'styled-components';
import NavBar from '../components/PublicNav/homenav';
import Footer from '../components/Footer';


const BackgroundContainer = styled.div`
  background: #f8f8f8;
`;

const BlogContainer = styled.div`
  margin: 0 auto;
  padding: 20px;
  padding-top: 10vh;
  color: rgba(7,55,99);
`;

const BlogTitle = styled.h1`
  text-align: center;
  margin-bottom: 30px;
  padding: 5vh;
`;

const Title = styled.h4`
margin-bottom: 2%;
margin-top: 5%;
margin-left: 10%;
`

const IntroParagraph = styled.p`
  font-size: 1.1rem;
  line-height: 1.6;
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
  color: #333;
  margin-bottom: 30px; // Space between the paragraph and the first image
  text-align: left;
  @media (orientation: portrait){
    max-width: 100%;
  }
`;

    const StepImage = styled.img`
      width: 50%;
      height: auto;
      margin-bottom: 4vh; // Space between the image and the text
      
      display: block;
      margin: auto;
      @media (orientation: portrait){
        margin-bottom: 0%;
        width: 100%;
        margin-right: 0%;
      }
    `;
  

const BlogLeads: React.FC = () => {

  return (
    <BackgroundContainer>
      <NavBar />
      <BlogContainer>
      <BlogTitle>Growing your landscaping business with effective lead strategies</BlogTitle>
        <IntroParagraph>
        In this guide you will learn proven methods of generating successful leads for your landscaping business,
         whether you are a newly formed company or already well established. Owning a landscaping company 
         requires consistent effort to ensure you stay in business as it is rare to have repeat customers. 
         We will take you through everything, step by step, you should be actively doing to ensure you dominate
          the market in your area. We are not affiliated with any 3rd party links. All links away from Project Price 
          are strictly informative and other options are available.
        </IntroParagraph>
        <Title>Being online</Title>
        <IntroParagraph>Having an online presence is essential in todays market, as most customers will only
             be able to judge the quality of your work with visual images. This allows you to have a representation
             of what your business is able to deliver, as landscaping is largely based on design and execution as 
             well as practicality. A huge beneficial asset for your online presence is owning a website. Although 
             this may seem daunting to the non-tech minded, there are many registars the are incredibly simple to use,
             such as <a href="godaddy.com">Godaddy</a>. Here you are able to register your domain name, easily create 
             a website and get a professional business email.
             It is imporant to use clear, high quality photos when displaying your completed projects, to appear as 
             professional as possible.
             <StepImage src="/leads-images/godaddy.png" />
                         
        </IntroParagraph>
        <Title>SEO</Title>
        <IntroParagraph>
             Once you have your website set up, becoming visible on Google requires good SEO techniques. Search Engine 
             Optimisation, is what can help your rankings in search engines, such as Google. One idea could be using 
             wording in your website that aligns with what customers may search. Such as "Landscaping", "Paving" and 
             adding in your working area. There are tools you can use online
             to check your SEO performance, such as <a href="seobility.net">SEObility</a>. This gives you pointers 
             of how to improve in order to get your website seen by more clients.
        </IntroParagraph>
        <StepImage src="/leads-images/seobility.png" />
        <Title>Google ads</Title>
        <IntroParagraph>
            Another way of getting traction on Google is to set up an ad account, to which you are able to pay
            to make your website visible. <a href="https://ads.google.com">Google ads</a>, here you are able
            to specifically target your audience you want to bring to your page. You will want to select your target 
            audience for your area and the radius that you as a company are willing to cover. You are able to 
            select your potential customers browsing habits, for example if they are garden enthusiasts, if they 
            have recently been looking to buy garden furniture or if they have specifically been searching for a 
            landscaper in a certain location. Refining your searches and trying out different keywords will be a 
            great resource as you can see how each perform over time.
        </IntroParagraph>
        <StepImage src="/leads-images/googleads.png" />
        <Title>Google maps for business</Title>
        <IntroParagraph>As a business that relies on demographic location, you will be able to generate 
             high quality leads through having your business shown on Google maps. Customers in your area will
             generally search terms such as "landscapers" in your area. This will return businesses on Google maps
             in a certain radius that have signed up for Google business which is why it is imperative to be 
             signed up. Here you can showcase your work and have your clients give reviews.
        </IntroParagraph>
        <StepImage src="/leads-images/maps.png" />
        <Title>Social Media</Title>
        <IntroParagraph>If you are not opting for owning a website you should certainly have a Facebook 
            page. In today's digital era, a significant portion of your potential customers engage daily 
            on Facebook, making it a fertile ground for connecting with both new and existing clients. 
            Engaging on Facebook isn't just about broadening your reach; it's about creating a community 
            around your brand. It allows you to showcase your landscaping projects through vivid imagery 
            and engaging stories, providing a visual feast that captures the imagination of your audience.
             Being involved in your local Facebook groups will help get people in your local area familiar 
             with your business. If the groups you are in allow it, post finished photos of your work and 
             put your business forward for any potential clients looking for landscapers.
             Much like Google you are able to target potential clients through ads.
        </IntroParagraph>
        <Title>Facebook Ads</Title>
        <IntroParagraph>
        Delving deeper into the fertile grounds of Facebook, we unearth the powerful tool of targeted 
        advertising, a crucial element for nurturing the growth of your landscaping business. Just as a 
        gardener selects the right seeds for the right soil, Facebook's targeted ads allow you to place 
        your services directly in front of the specific audience who are most likely to appreciate and need 
        them. Whether it's homeowners with a penchant for elegant outdoor spaces, or businesses looking to 
        enhance their curb appeal, Facebook's sophisticated algorithm ensures your ads reach those with the
         exact interests and demographics you specify. This precision in advertising is akin to the meticulous 
         care needed in landscaping – ensuring every effort is placed where it will yield the most bountiful 
         results. Moreover, the ability to track and analyze the performance of these ads offers invaluable
          insights.
        </IntroParagraph>
        <Title>Business cards</Title>
        <IntroParagraph>Business cards play a straightforward yet impactful role in the
             growth of your landscaping business. Imagine working on a project, your skills and 
             dedication on full display. A passerby shows interest – this is where a quick exchange of a 
             business card can turn a momentary interaction into a potential client relationship. Simple, 
             effective, and personal, these cards carry your brand's identity and are a direct line to your
              services.

            Taking this strategy a step further, especially for those newly launching their landscaping venture, 
            consider distributing your business cards in showrooms of newly built housing developments. New homeowners, 
            often looking for landscaping services to enhance their fresh property, can find immediate value in your 
            card. It’s a straightforward yet strategic move, placing your service in front of a captive, relevant 
            audience.</IntroParagraph>

        <Title>Local building trade</Title>
        <IntroParagraph>For those just starting out in the landscaping business, building relationships with established 
            local builders can be an invaluable strategy. Often, after a construction project is completed, 
            homeowners look to add the finishing touches to their property with professional landscaping. By 
            connecting with builders, you position yourself as a go-to resource for these landscaping needs. 
            It's a mutually beneficial relationship: builders provide a trusted recommendation to their clients, 
            and you gain access to a steady stream of potential customers who are actively seeking your services.
             Networking with these local construction professionals can open doors to opportunities that might
              otherwise be difficult to access, especially when you're new to the industry.
              </IntroParagraph>
            <Title>Reputation</Title>
            <IntroParagraph>
            In the landscaping business, your reputation is as vital as the quality of your work. 
            Satisfied clients are the cornerstone of your success, often sharing their positive experiences 
            through word-of-mouth or online reviews. This reputation for quality and reliability begins with
             accurate, transparent quoting. Clients appreciate clarity and honesty in pricing, which fosters
              trust and leads to repeat business and referrals. Accurate quoting also sets realistic 
              expectations, ensuring client satisfaction upon project completion.

Understanding this, we've developed <a href="/">Project Price</a>, an app designed to assist you in
 creating precise and reliable quotes. This tool not only streamlines the quoting process but also 
 reinforces your reputation for professionalism and accuracy. With Project Price, you can effortlessly 
 align your pricing with the quality of your services, building a strong foundation of trust with every 
 client. Check out our quick <a href="/demo">demo video</a> here and see just how easy it can be to Price your 
 landscaping projects.
            </IntroParagraph>
            <Title>Project Price</Title>
            <IntroParagraph>
            Project Price is an indispensable app designed to make the process of quoting for landscaping projects significantly more straightforward and precise. It's a comprehensive solution that not only saves time but also enhances the efficiency of generating quotes. With this tool, accuracy is front and center; you can rest assured that no material is overlooked, as the app considers every element based on the measurements you input. The result is an automatic, tailored quote that aligns perfectly with the project's specifics.

To aid in understanding and utilizing the app to its full potential, we have provided a step-by-step guide accessible at our <a href="/guide">guide</a>. Additionally, to see Project Price in action and get a feel for its user-friendly interface and capabilities, please view our <a href="/demo">demo</a>. This resource showcases how Project Price can streamline your quoting process, from start to finish, ensuring that your business operates with maximum efficacy. 
            </IntroParagraph>
            <StepImage src="/leads-images/signature.png" />

      </BlogContainer>
      <Footer />
    </BackgroundContainer>
  );
};

export default BlogLeads;
