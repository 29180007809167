import { useEffect, useState, useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { UserContext } from '../context'; // Adjust the path as necessary
import { Spinner } from 'react-bootstrap';
import axios from 'axios';
import styled from 'styled-components';

const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; // Use full viewport height to center vertically in the entire view
  background-color: rgba(7, 55, 99, 1); // Set the background color
`;

const CustomSpinner = styled(Spinner)`
  color: white; // Set the spinner color to white
`;

function LoadingSpinner() {
    return (
        <SpinnerContainer>
            <CustomSpinner animation="border" />
        </SpinnerContainer>
    );
}

export const ProtectedRoute = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [isSubscribed, setIsSubscribed] = useState(false);
    const [userState] = useContext(UserContext);

    const fetchSubscriptionStatus = async () => {
        try {
            const response = await axios.get('https://projectprice-ad06ee250897.herokuapp.com/subscriptioncheck', {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            });
            return response.data; // Adjust based on your actual API response
        } catch (error) {
            console.error('Error fetching subscription status:', error);
            return {};
        }
    };

    useEffect(() => {
        const checkSubscription = async () => {
            setIsLoading(true);
            const subscriptionData = await fetchSubscriptionStatus();
            const { isPremium, isRegular, onTrial } = subscriptionData;

            if (isPremium || isRegular || onTrial) {
                setIsSubscribed(true);
            } else {
                setIsSubscribed(false);
            }

            setIsLoading(false);
        };

        if (userState.data) { // Assuming userState.data contains user info
            checkSubscription();
        } else {
            setIsLoading(false);
        }
    }, [userState.data]);

    if (isLoading) return <LoadingSpinner />;
    return isSubscribed ? <Outlet /> : <Navigate to="/sub-page" />;
};
