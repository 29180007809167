import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import styled, { keyframes } from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { UserContext } from "../../context";

interface HomepageProfileProps {
  onClose: () => void;
  setBackgroundColor?: React.Dispatch<React.SetStateAction<string>>;
  position?: { top: number; left: number };
}

const OverlayContainer = styled.div<{ position?: { top: number; left: number } }>`
  position: fixed;
  top: ${({ position }) => (position ? `${position.top}px` : '50%')};
  left: ${({ position }) => (position ? `${position.left}px` : '50%')};
  transform: ${({ position }) => (position ? 'none' : 'translate(-50%, -50%)')};
  z-index: 1001;
  display: flex;
`;

const OverlayContent = styled.div`
  background-color: rgba(0, 0, 0, 0.7);
  padding: 20px;
  border-radius: 5px;
  max-width: 400px;
  height: fit-content;
  color: white;
`;

const ButtonContainer = styled.div`
  margin-top: 10%;
`;

const StyledButton = styled.button`
  background: transparent;
  display: flex;
  border: none;
  color: white;
  font-size: 0.9rem;
  text-decoration: underline;
  cursor: pointer;
  margin-top: 10px;
`;

const Input = styled.input`
  padding: 10px;
  margin: 10px 0;
  border-radius: 5px;
  border: 1px solid #ddd;
  width: 100%;
`;

const LoginForm = styled.form`
  display: flex;
  flex-direction: column;
`;

const ErrorMessage = styled.p`
  color: red;
`;

const spinnerAnimation = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const Spinner = styled.div`
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid white;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: ${spinnerAnimation} 1s linear infinite;
  margin: 0 auto;
`;

const HomepageProfile: React.FC<HomepageProfileProps> = ({ onClose, position }) => {
  const [userEmail, setUserEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userType, setUserType] = useState('');
  const [loading, setLoading] = useState(true);
  const [emailValidated, setEmailValidated] = useState(false);
  const [agreeToMarketing, setAgreeToMarketing] = useState(false);
  const [, setState] = useContext(UserContext);
  
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      fetchUserDetails();
      const user = JSON.parse(localStorage.getItem('user') || '{}');
      setEmailValidated(user.emailValidated);

      
    } else {
      setLoading(false);
    }
  }, []);
  
  const fetchUserDetails = async () => {
    try {
      const response = await axios.get('https://projectprice-ad06ee250897.herokuapp.com/auth/me', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      setUserEmail(response.data.data.user.email);
      setAgreeToMarketing(response.data.data.user.agreeToMarketing);
     
  
      const subscriptionResponse = await axios.get('https://projectprice-ad06ee250897.herokuapp.com/subscriptioncheck', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
  
      if (subscriptionResponse.data.isPremium) {
        setUserType('Premium Plan');
      } else if (subscriptionResponse.data.onTrial) {
        setUserType('Trial Plan ');
      } else if (subscriptionResponse.data.isRegular) {
        setUserType('Regular Plan');
      } else {
        setUserType('Free Plan')
      }
  
      setIsLoggedIn(true);
    } catch (error) {
      console.error('Error fetching user details:', error);
    } finally {
      setLoading(false);
    }
  };
  
  const handleSubscribeToMarketing = async () => {
    try {
      const user = JSON.parse(localStorage.getItem('user') || '{}');
      const response = await axios.post('https://projectprice-ad06ee250897.herokuapp.com/auth/subscribe-marketing', {
        userId: user.id,
      });

      if (response.status === 200) {
        setAgreeToMarketing(true);

      }
    } catch (error) {
      console.error('Error subscribing to marketing:', error);
    }
  };

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    try {
      const { data: loginData } = await axios.post("https://projectprice-ad06ee250897.herokuapp.com/auth/login", {
        email: userEmail,
        password,
      });

      if (loginData && loginData.errors && loginData.errors.length > 0) {
        setErrorMsg(loginData.errors[0].msg);
      } else {
        if (!loginData.data.user.emailValidated) {
          navigate("/emailvalidation");
          return;
        }

        const user = {
          id: loginData.data.user.id,
          email: loginData.data.user.email,
          stripeCustomerId: loginData.data.user.stripeCustomerId,
          emailValidated: loginData.data.user.emailValidated,
        };

        setState({
          data: user,
          loading: false,
          error: null,
        });

        localStorage.setItem("user", JSON.stringify(user));
        localStorage.setItem("token", loginData.data.token);
        axios.defaults.headers.common["Authorization"] = `Bearer ${loginData.data.token}`;
        setEmailValidated(user.emailValidated); // Retrieve validation status from local storage

        const subscriptionResponse = await axios.get('https://projectprice-ad06ee250897.herokuapp.com/subscriptioncheck', {
          headers: {
            Authorization: `Bearer ${loginData.data.token}`,
          },
        });

        if (subscriptionResponse.data.isPremium) {
          setUserType('Premium User');
        } else if (subscriptionResponse.data.onTrial) {
          setUserType('Trial User');
        } else if (subscriptionResponse.data.isRegular){
          setUserType('Regular User');
        }
        else {
          setUserType('Free Plan');
        }


        setIsLoggedIn(true); // Mark the user as logged in
      }
    } catch (error: any) {
      if (error.response && error.response.data && error.response.data.message) {
        setErrorMsg(error.response.data.message);
      } else {
        setErrorMsg("Error, please try again");
      }
    } finally {
      setLoading(false);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem('token');
    setIsLoggedIn(false);
    setUserEmail('');
    setPassword('');
  };

  const handleResetPassword = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    navigate('/emailpassword');
  };

  const handleManageSubscription = () => {
    
    if (userType === 'Premium Plan' || userType === 'Regular Plan' || userType === 'Trial Plan') {
      
        // Redirect to the Stripe customer portal for managing subscriptions
        window.location.href = 'https://payments.projectprice.co.uk/p/login/14k8yZfmJbt1eBi8ww';
    } else {
        // Redirect to the subscription page for upgrading or subscribing
        navigate('/sub-page');
    }
  };

  const handleVerifyEmail = () => {
    navigate('/emailvalidation');
  };

  const handleCreateAccount = () => {
    navigate('/loginpage?signup=true');
  };

  return (
    <OverlayContainer position={position} onClick={onClose}>
      <OverlayContent onClick={(e) => e.stopPropagation()}>
        {loading ? (
          <Spinner />
        ) : isLoggedIn ? (
          <>
            <div>{userEmail}</div>
            <div>{emailValidated ? userType : 'Unverified Email'}</div>
            <ButtonContainer>
              <StyledButton onClick={handleResetPassword}>Reset Password</StyledButton>
            </ButtonContainer>
            <ButtonContainer>
              {emailValidated ? (
                <StyledButton onClick={handleManageSubscription}>Manage Subscription</StyledButton>
              ) : (
                <StyledButton onClick={handleVerifyEmail}>Verify Email</StyledButton>
              )}
            </ButtonContainer>
            {!agreeToMarketing && ( // Conditionally render subscribe button
              <ButtonContainer>
                <StyledButton onClick={handleSubscribeToMarketing}>Subscribe to Email Offers & Updates</StyledButton>
              </ButtonContainer>
            )}
            <ButtonContainer>
              <StyledButton onClick={handleLogout}>Logout</StyledButton>
            </ButtonContainer>
          </>
        ) : (
          <LoginForm onSubmit={handleLogin}>
            <Input type="email" placeholder="Email" value={userEmail} onChange={(e) => setUserEmail(e.target.value)} required />
            <Input type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} required />
            <StyledButton type="submit">Login</StyledButton>
            {errorMsg && <ErrorMessage>{errorMsg}</ErrorMessage>}
            <StyledButton onClick={handleCreateAccount}>Create an Account</StyledButton>
          </LoginForm>
        )}
      </OverlayContent>
    </OverlayContainer>
  );
};

export default HomepageProfile;
