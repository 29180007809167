// RotateDeviceOverlay.js or RotateDeviceOverlay.jsx
import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';// Import the rotating phone icon component

const rotatePhone = keyframes`
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(90deg);
  }
  75% {
    transform: rotate(90deg);
  }
  100% {
    transform: rotate(0deg);
  }
`;

const RotatingPhoneIcon = styled.img`
  width: 15vh; // Set the width of the phone icon
  height: auto; // Keep the aspect ratio of the image
  animation: ${rotatePhone} 3s ease-in-out infinite; // Apply the animation
`;

const LandscapeOnlyOverlay = styled.div`
  display: none;

  @media (max-width: 1024px) and (orientation: portrait) {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    min-width: 100vw; // Ensure it covers the full width even when zoomed out
    min-height: 100vh; // Ensure it covers the full height even when zoomed out
    width: 100%;
    height: 100%;
    background-color: #e4e4e4;
    color: black;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    text-align: center;
    padding: 20px;
    font-size: 5vh;
    overflow: hidden; // This only affects content within the overlay

  `;

const Overlay = () => {
  const [isPortrait, setIsPortrait] = useState(
    window.matchMedia("(orientation: portrait)").matches
  );

  useEffect(() => {
    const handleOrientationChange = () => {
        const portrait = window.matchMedia("(orientation: portrait)").matches;
        setIsPortrait(portrait);
   
      };

    window.addEventListener('resize', handleOrientationChange);
    window.addEventListener('orientationchange', handleOrientationChange);

    // Set the body style to prevent scrolling when the overlay is visible
    if (isPortrait) {
      document.body.style.overflow = 'hidden';
    }

    return () => {
      window.removeEventListener('resize', handleOrientationChange);
      window.removeEventListener('orientationchange', handleOrientationChange);
      document.body.style.overflow = '';
    };
  }, [isPortrait]);

  if (!isPortrait) {
    return null;
  }

  return (
    <LandscapeOnlyOverlay>
      <RotatingPhoneIcon src="phone.png" alt="Rotate your device" />
      Please rotate your device to landscape mode for the best experience. 
     
    </LandscapeOnlyOverlay>
  );
};

export default Overlay;
