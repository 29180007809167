import React, { useState, useEffect } from "react";
import axios from "axios";
import { Container } from "react-bootstrap";
import styled from "styled-components";
import Navbar from "../components/Nav/Nav";
import Pricing from "../components/Pricing/Pricing";
import Settings from "../components/Settings/Settings";
import ProjectQuote from "../components/ProjectQuote/ProjectQuote";
import { useNavigate } from "react-router-dom";
import ProjectComponent from "../components/Project/ProjectComponent";
import ProfileModal from "../components/Profile/Profile";
import Design from "../components/Design/Design";
import UpgradePrompt from "../components/UpgradePromt/UpgradePromt";
import Tracker from "../components/Tracker/Tracker";

const BackgroundContainer = styled.div<{ bgColor: string }>`
  background: ${props => props.bgColor};
  padding: 14vh 0;
  height: 100vh;
`;

const LogoContainer = styled.div`
  position: fixed;
  right: 2%;
  top: 0.5%;
  width: 25vh;
  padding-top: 0rem;
  display: flex;
  align-items: center;
  @media (min-width: 1900px){
  width: 15vh}
  @media (max-width: 990px){
  width: 30vh;}
  @media (max-height: 350px) {
    position: absolute; /* Change to absolute for small screens */
    top: 0; /* Align to the top */
    right: 0; /* Align to the right */
    left: auto; /* Ensure no left alignment */
    width: auto; /* Auto width */
    z-index: 1000; /* Keep it above other elements */
    transform: none; /* Adjust position to align it nicely */
  }
`;

const LogoImage = styled.img`
  width: 35px;
  height: 35px;
  cursor: pointer;
   }
    @media (max-height: 350px){
    width: 25px;
    height: 25px;
  }
`;

const HowToButton = styled.button`
  background-color: transparent;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  margin-right: 2vh;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
    @media (max-height: 350px){
    font-size: 2.8vh;}
`;

const HowToModal = styled.div<{ top: number; left: number }>`
  position: fixed;
  top: ${props => props.top}px;
  left: ${props => props.left - 150}px; /* Move modal to the left */
  background-color: rgba(0, 0, 0, 0.7);
  padding: 3vh;
  border-radius: 10px;
  color: white;
  z-index: 1000;
  width: 30vh;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  @media (min-width: 1900px){
  left: ${props => props.left - 250}px; /* Move modal to the left */
  }
`;

const VideoTitle = styled.div`
  cursor: pointer;
  margin-bottom: 10px;
  &:hover {
    text-decoration: underline;
  }
`;

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
`;

const NavbarContainer = styled.div`
  position: absolute;
  left: 0;
  height: 100vh;
  top: 0rem;
  bottom: 0;
  width: 7%;
  background: black;
  overscroll-behavior: none; 

   @media (max-width: 1000px) {
    width: 8%; 
  }
    
`;

const MainContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
  margin-left: 110px;
  margin-right: 10px;
  overflow-y: hidden;
  overscroll-behavior: none;
  @media (max-height: 350px) {
    margin-left: 80px;
  }
`;

const Dashboard: React.FC = () => {
  const [backgroundColor, setBackgroundColor] = useState(() => {
    return localStorage.getItem('dashboardBgColor') || "rgba(7,55,99)"; // Default color if none stored
  });

  const [activeTab, setActiveTab] = useState("Project");
  // eslint-disable-next-line
  const [projectName, setProjectName] = useState("");
  // eslint-disable-next-line
  const [projectData, setProjectData] = useState("");
  // eslint-disable-next-line
  const [creatingProject, setCreatingProject] = useState(false);
   // eslint-disable-next-line
  const [currentProjectId, setCurrentProjectId] = React.useState<string | null>(null);
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [isPremium, setIsPremium] = useState(false);
  const [showHowToModal, setShowHowToModal] = useState(false); // New state for How To modal
  const [, setCurrentVideo] = useState<string | null>(null); // State for the current video
  const [modalPosition, setModalPosition] = useState({ top: 0, left: 0 });
  const [selectedVideo, setSelectedVideo] = useState<string | null>(null);

  const handleHowToClick = (event: React.MouseEvent) => {
    const buttonRect = (event.target as HTMLElement).getBoundingClientRect();
    setModalPosition({ top: buttonRect.bottom + window.scrollY, left: buttonRect.left });
    setShowHowToModal(!showHowToModal);
  };

  const handleLogoClick = () => {
    navigate("/");
  };

  const handleModalOpen = () => {
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  const handleProjectSubmit = () => {
    setCreatingProject(false);
  };
 
  useEffect(() => {
    const checkPremiumStatus = async () => {
      try {
        const response = await axios.get("https://projectprice-ad06ee250897.herokuapp.com/subscriptioncheck", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
        });


        // Allow access if the user is on a premium plan or on a trial
        setIsPremium(response.data.isPremium || response.data.onTrial);
      } catch (error) {
        console.error("Error checking premium status:", error);
      }
    };

    checkPremiumStatus();
  }, []);

  const renderComponent = () => {
    switch (activeTab) {
      case "Profile":
        return (
          <>
            <ProfileModal onClose={handleModalClose} setBackgroundColor={setBackgroundColor} />
            {showModal && <div className="modal-backdrop fade show" onClick={handleModalClose}></div>}
          </>
        );
      case "Project":
        return (
          <ProjectComponent backgroundColor={backgroundColor} isPremium={isPremium} />
        );
      case "Pricing":
        return <Pricing backgroundColor={backgroundColor} />;
      case "Settings":
        return <Settings backgroundColor={backgroundColor} />;
      case "Quoting":
        return <ProjectQuote backgroundColor={backgroundColor}/>;
      case "Design":
        return isPremium ? <Design backgroundColor={backgroundColor} /> : <UpgradePrompt backgroundColor={backgroundColor} />; 
        case "Tracker":
        return <Tracker backgroundColor={backgroundColor}/>;
      default:
        return null;
    }
  };


  const handleHowToModalClose = () => {
    setShowHowToModal(false);
    setCurrentVideo(null);
  };

  const handleVideoClick = (videoUrl: string) => {
    setSelectedVideo(videoUrl);
    setShowHowToModal(false); // Close the How To modal when a video is selected
  };

  const handleBackdropClick = () => {
    setSelectedVideo(null);
    setShowHowToModal(false);
  };

  return (
    <div>
      <BackgroundContainer bgColor={backgroundColor}>
        <Container>
        <LogoContainer>
              <HowToButton onClick={handleHowToClick}>How To...</HowToButton>
              <LogoImage onClick={handleLogoClick} src="/logo512.png" alt="Project Price Logo" />
            </LogoContainer>
          <NavbarContainer>
            <Navbar
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              handleProjectSubmit={handleProjectSubmit}
              projectName={projectName}
              projectData={projectData}
              onProfileClick={handleModalOpen}
            />
          </NavbarContainer>
        </Container>
        <MainContainer>{renderComponent()}</MainContainer>
      </BackgroundContainer>
      {showModal && <ProfileModal onClose={handleModalClose} setBackgroundColor={setBackgroundColor} />}

      {showHowToModal && (
  <>
    <Backdrop onClick={handleHowToModalClose} /> {/* Backdrop component */}
    <div onClick={handleHowToModalClose}>  {/* Outer container to handle clicks outside the modal */}
      <HowToModal top={modalPosition.top} left={modalPosition.left} onClick={(e) => e.stopPropagation()}>
      <VideoTitle onClick={() => handleVideoClick('https://www.youtube.com/embed/yE7oEt_VSvA')}>Use the Project tab</VideoTitle>
        <VideoTitle onClick={() => handleVideoClick('https://www.youtube.com/embed/pzS_n7nH8FU')}>Use the Design tab</VideoTitle>
        <VideoTitle onClick={() => handleVideoClick('https://www.youtube.com/embed/z8rOtzOKzG4')}>Export design to SketchUp</VideoTitle>
      </HowToModal>
    </div>
  </>
)}

      {selectedVideo && (
        <>
          <Backdrop onClick={handleBackdropClick} />
          <div style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 1001 }}>
            <iframe 
              width="840" 
              height="480" 
              src={selectedVideo} 
              title="How To Video" 
              frameBorder="0" 
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
              allowFullScreen>
            </iframe>
          </div>
        </>
      )}
    </div>
  );
};

export default Dashboard;
