import React from 'react';
import styled from 'styled-components';
import NavBar from '../components/PublicNav/homenav';
import Footer from '../components/Footer';

interface StepContainerProps {
    index: number;
  }

const BackgroundContainer = styled.div`
  background: #f8f8f8;
`;

const BlogContainer = styled.div`
  margin: 0 auto;
  padding: 20px;
  @media (orientation: portrait){
    padding 1%;
  }
`;

const BlogTitle = styled.h1`
  text-align: center;
  margin-bottom: 30px;
`;

const StepContainer = styled.div<StepContainerProps>`
  padding: 20px;
  background-color: ${props => props.index % 2 === 0 ? '#f0f0f0' : '#fff'};
  flex-direction: ${props => props.index % 2 === 0 ? 'row' : 'row-reverse'};
  display: flex;    

  @media(orientation: portrait){
    padding 2%;
    flex-direction: column;
  }
`;

const StepImage = styled.img`
  width: 50%;
  height: auto;
  margin-bottom: 4vh; // Space between the image and the text
  
  display: block;
  margin-left: auto;
  margin-right: 5vh;
  @media (orientation: portrait){
    margin-bottom: 0%;
    width: 100%;
    margin-right: 0%;
  }
`;

const StepDescription = styled.p`
  font-size: 1rem;
  line-height: 1.6;
  color: #333;
  max-width: 80%;
  margin-top: 16vh;
  margin-left: auto;
  margin-right: auto;
  @media (orientation: portrait){
    margin-top: 2%;
  }
`;

const IntroParagraph = styled.p`
  font-size: 1rem;
  line-height: 1.6;
  max-width: 70%;
  margin-left: auto;
  margin-right: auto;
  color: #333;
  margin-bottom: 30px; // Space between the paragraph and the first image
  text-align: center;
`;

const AddedParagraph = styled.p`
  font-size: 1rem;
  line-height: 1.6;
  max-width: 70%;
  margin-top: 5%;
  margin-left: auto;
  margin-right: auto;
  color: #333;
  margin-bottom: 30px; // Space between the paragraph and the first image
  text-align: center;
`;

const Quoter: React.FC = () => {
  const steps = [
      {
        image: '/walkthrough-images/12.png',
        description: 'The Quote tool in the navigation bar allows you to select any project you have completed. You can find these in the dropdown menu. All quotes come with a bullet point list of requirements to complete the project. You will find the "Copy quote" button, which allows you to copy and paste the quote, either directly in an email or into your usual text editor.'
      },
      {
        image: '/walkthrough-images/14.png',
        description: 'Each quote comes with a breakdown of the process of the job. A validity note of 14 days for the client to accept and requirements which are needed for most hard landscaping projects and industry standard notes, which ensures protection for you as a company and your clients.'
      },
  ];

  return (
    <BackgroundContainer>
      <NavBar />
      <BlogContainer>
        <BlogTitle>Quoting tool</BlogTitle>
        <IntroParagraph>
    Our unique instant quoting tool is a game-changer in project management, offering real-time, accurate cost estimations as you input your project details. This innovative approach eliminates the need for time-consuming manual calculations, allowing for immediate decision-making and efficient budget planning. As soon as you complete your project inputs, a tailored quote is instantly generated, enhancing productivity and client satisfaction. Embrace this tool to streamline your workflow, ensuring every quote is precise and customized to each project's specifics.
</IntroParagraph>
        {steps.map((step, index) => (
          <StepContainer key={index} index={index}>
            <StepImage src={step.image} alt={`Step ${index + 1}`} />
            <StepDescription>{step.description}</StepDescription>
          </StepContainer>
        ))}
        <AddedParagraph>
The quote tool is an indispensable asset for both businesses and clients, revolutionizing the process of obtaining cost quotes. Traditionally, generating a detailed quote for a job is a time-consuming task, often taking upwards of an hour to complete. This not only delays decision-making but also adds to operational inefficiencies. The quote tool elegantly solves this problem by providing instant, comprehensive breakdowns of costs.<p></p> What makes this tool particularly valuable is its ability to tailor each quote to the specific needs and inputs of the user. By considering the unique aspects of each job, the tool ensures that the quotes are not only swift but also highly personalized and accurate. This level of customization enhances user experience, fosters trust, and streamlines the planning and budgeting process. In today's fast-paced world, where time is a precious commodity, the quote tool is a game-changer, empowering users with quick, reliable, and detailed cost information at their fingertips.
        </AddedParagraph>
      </BlogContainer>
      <Footer />
    </BackgroundContainer>
  );
};

export default Quoter;
