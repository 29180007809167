import React from 'react';
import styled from 'styled-components';
import NavBar from '../components/PublicNav/homenav';
import Footer from '../components/Footer';


const BackgroundContainer = styled.div`
  background: #f8f8f8;
`;

const BlogContainer = styled.div`
  margin: 0 auto;
  padding: 20px;
`;

const BlogTitle = styled.h1`
  text-align: center;
  margin-bottom: 30px;
`;

const IntroParagraph = styled.p`
  font-size: 0.8rem;
  line-height: 1.6;
  max-width: 70%;
  padding-top: 10vh;
  margin-left: auto;
  margin-right: auto;
  color: #333;
  margin-bottom: 30px; // Space between the paragraph and the first image
  text-align: left;
`;

const Disclaimer: React.FC = () => {

  return (
    <BackgroundContainer>
      <NavBar />
      <BlogContainer>
        <BlogTitle>Disclaimer</BlogTitle>
        <IntroParagraph>WEBSITE DISCLAIMER 

  

The information provided by LPW Solutions ("we," “us,” or "our") on projectprice.co.uk (the 

"Site") and our mobile application is for general informational purposes only. All 

information on the Site and our mobile application is provided in good faith, however 

we make no representation or warranty of any kind, express or implied, regarding the 

accuracy, adequacy, validity, reliability, availability, or completeness of any 

information on the Site or our mobile application.<p> UNDER NO CIRCUMSTANCE 

SHALL WE HAVE ANY LIABILITY TO YOU FOR ANY LOSS OR DAMAGE OF ANY 

KIND INCURRED AS A RESULT OF THE USE OF THE SITE OR OUR MOBILE 

APPLICATION OR RELIANCE ON ANY INFORMATION PROVIDED ON THE SITE 

AND OUR MOBILE APPLICATION. YOUR USE OF THE SITE AND OUR MOBILE 

APPLICATION AND YOUR RELIANCE ON ANY INFORMATION ON THE SITE AND 

OUR MOBILE APPLICATION IS SOLELY AT YOUR OWN RISK.</p> </IntroParagraph>
      </BlogContainer>
      <Footer />
    </BackgroundContainer>
  );
};

export default Disclaimer;
