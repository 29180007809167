// eslint-disable-next-line
import React, { useState, useContext, useEffect } from "react";
import { MDBContainer, MDBInput } from 'mdb-react-ui-kit';
import { Button } from "react-bootstrap";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../context";
import styled from "styled-components";
import { Link } from 'react-router-dom';

const ResponsiveMDBContainer = styled(MDBContainer)`
  padding: 0%;
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-top: 3rem;
  margin-bottom: 3rem;
  @media (max-height: 360px) {
    margin-top: 0rem;
    margin-bottom: 2%;
    width: 50%;
    padding: 5%;
  }
`;

const StyledButton = styled(Button)`
  padding: 3% 6%;
  font-size: 100%;
  width: fit-content;
  @media (max-width: 768px) {
    padding: 3% 6%;
    font-size: 90%;
  }
  @media (max-height: 350px) {
    padding: 8% 8%;
    font-size: 90%;
    width: fit-content;
  }
  @media (max-width: 576px) {
    padding: 0.3rem 0.8rem;
    font-size: 0.8rem;
  }
`;

const ErrorMessage = styled.p`
  color: red;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  overflow: hidden;
`;

const StyledInputWrapper = styled.div`
  margin-bottom: 1%;
  font-size: 100%;
  @media (max-height: 360px) {
    font-size: 90%; 
  }
  .form-control {
    height: auto;
    padding: 0.5rem 1rem;
    @media (max-height: 360px) {
      height: 2%;
      padding: 0.2rem 0.7rem;
    }
    @media (max-width: 576px) {
      height: 2.5rem;
      padding: 0.3rem 0.7rem;
    }
  }
`;

const ForgotPasswordLink = styled(Link)`
  color: white;
  margin-top: 1rem;
  display: block;
  text-align: center;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const LoginForm = () => {
  const navigate = useNavigate();
  const [, setState] = useContext(UserContext);
  const [errorMsg, setErrorMsg] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  
  const onPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
    setErrorMsg(""); // Clear the error message when typing in the password field
  };

  const checkSubscription = async (token: string) => {
    try {
      const { data: subscriptionData } = await axios.get("https://projectprice-ad06ee250897.herokuapp.com/subscriptioncheck", {
        headers: { Authorization: `Bearer ${token}` },
      });
      const { isPremium, onTrial, isRegular } = subscriptionData;
     
      if (isPremium || onTrial || isRegular) {
        navigate("/dashboard");
      } else {
        navigate("/sub-page"); 
      }
    } catch (subscriptionError) {
      console.error("Error checking subscription:", subscriptionError);
    }
  };

  useEffect(() => {
    const validateTokenAndCheckSubscription = async () => {
      const token = localStorage.getItem('token');
      if (token) {
        try {
          await axios.get('https://projectprice-ad06ee250897.herokuapp.com/validate', {
            headers: { Authorization: `Bearer ${token}` },
          });
          await checkSubscription(token);
        } catch (error) {
          console.error('Invalid token or error validating:', error);
          localStorage.removeItem('token');
        }
      }
    };

    validateTokenAndCheckSubscription();// eslint-disable-next-line
  }, [navigate]);

  const handleClick = async () => {
    try {
      const { data: loginData } = await axios.post("https://projectprice-ad06ee250897.herokuapp.com/auth/login", {
        email,
        password,
      });

      if (loginData && loginData.errors && loginData.errors.length > 0) {
        setErrorMsg(loginData.errors[0].msg);
      } else {
        if (!loginData.data.user.emailValidated) {
          navigate("/emailvalidation");
          return;
        }

        const user = {
          id: loginData.data.user.id,
          email: loginData.data.user.email,
          stripeCustomerId: loginData.data.user.stripeCustomerId,
          emailValidated: loginData.data.user.emailValidated,
        };

        setState({
          data: user,
          loading: false,
          error: null,
        });

        localStorage.setItem("user", JSON.stringify(user));
        localStorage.setItem("token", loginData.data.token);
        axios.defaults.headers.common["Authorization"] = `Bearer ${loginData.data.token}`;

        const token = loginData.data.token;

        await checkSubscription(token);
      }
    } catch (error: any) {
      if (error.response && error.response.data && error.response.data.message) {
        setErrorMsg(error.response.data.message);
      } else {
        setErrorMsg("Error, please try again");
      }
    }
  };

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleClick();
  };

  return (
    <ResponsiveMDBContainer>
      <form onSubmit={handleFormSubmit}>
        <StyledInputWrapper>
          <MDBInput
            wrapperClass="mb-2"
            label="Email address"
            id="form1"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </StyledInputWrapper>
        <StyledInputWrapper>
          <MDBInput
            wrapperClass="mb-2"
            label="Password"
            id="form2"
            type="password"
            value={password}
            onChange={onPasswordChange}
          />
        </StyledInputWrapper>
        <ButtonContainer>
          <StyledButton type="submit" variant="outline-light">
            Sign in
          </StyledButton>
        </ButtonContainer>
        <ForgotPasswordLink to="/emailpassword">
          Forgot password?
        </ForgotPasswordLink>
        {errorMsg && <ErrorMessage>{errorMsg}</ErrorMessage>}
      </form>
    </ResponsiveMDBContainer>
  );
};

export default LoginForm;
