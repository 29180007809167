import React from 'react';
import styled from 'styled-components';
import NavBar from '../components/PublicNav/homenav';
import Footer from '../components/Footer';


const BackgroundContainer = styled.div`
  background: #f8f8f8;
`;

const BlogContainer = styled.div`
  margin: 0 auto;
  padding: 20px;
  padding-top: 10vh;
  color: rgba(7,55,99);
`;

const BlogTitle = styled.h1`
  text-align: center;
  margin-bottom: 30px;
  padding: 5vh;
`;

const Title = styled.h4`
margin-bottom: 2%;
margin-top: 5%;
margin-left: 10%;
`

const IntroParagraph = styled.p`
  font-size: 1.1rem;
  line-height: 1.6;
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
  color: #333;
  margin-bottom: 30px; // Space between the paragraph and the first image
  text-align: left;
  @media (orientation: portrait){
    max-width: 100%;
  }
`;


const BlogDetails: React.FC = () => {

  return (
    <BackgroundContainer>
      <NavBar />
      <BlogContainer>
      <BlogTitle>The Importance of Small Details in Hard Landscaping a Garden</BlogTitle>
        <IntroParagraph>
        When it comes to hard landscaping a garden, the small details can make a significant difference. From the initial design phase to the finishing touches, every element plays a crucial role in transforming an ordinary outdoor space into an extraordinary oasis. In this post, we'll explore why paying attention to the finer points is essential in hard landscaping and how it can elevate your garden projects.
        </IntroParagraph>
        <Title>Precision in Planning</Title>
        <IntroParagraph>
        Before breaking ground, detailed planning is essential. Accurate measurements, thoughtful layout designs, and a well-considered selection of materials are the foundations of a successful landscaping project. Small errors in the planning stage can lead to significant issues down the line, such as uneven pathways, misaligned features, or drainage problems. Investing time in meticulous planning ensures that the project runs smoothly and the final result is cohesive and functional.
        </IntroParagraph>
        <Title>Material Selection</Title>
        <IntroParagraph>
        The choice of materials can make or break the aesthetics and durability of a garden. Paying attention to the quality, texture, and color of pavers, stones, and other hardscape elements is crucial. For instance, selecting stones with slight color variations can add depth and interest to pathways and patios. Moreover, choosing durable materials that withstand weather conditions and foot traffic ensures the longevity of the garden.
        </IntroParagraph>
        <Title>Edge and Border Details</Title>
        <IntroParagraph>
        Edges and borders are often overlooked but are vital in defining spaces and adding structure to the garden. Clean, well-defined edges between different areas, such as lawns, flower beds, and walkways, create a polished look. Using edging materials that complement the overall design enhances the visual appeal and prevents elements like mulch or gravel from spilling over into unwanted areas.
        </IntroParagraph>
        <Title>Level and Slope Considerations</Title>
        <IntroParagraph>
        Ensuring that surfaces are level or appropriately sloped is crucial for both aesthetics and functionality. Uneven surfaces can cause water pooling, leading to potential damage and safety hazards. Proper grading and leveling techniques ensure that water drains correctly, preventing erosion and maintaining the integrity of the hardscape.
        </IntroParagraph>
        <Title>Incorporating Details</Title>
        <IntroParagraph>
        Incorporating intricate details such as patterned paving, inlaid designs, or decorative elements can transform a simple hardscape into a work of art. These details reflect the homeowner's personality and add a unique touch to the garden. For example, a mosaic patio or a custom-designed stone pathway can become a focal point, enhancing the overall charm and appeal of the garden.
        </IntroParagraph>
        <Title>Attention to Functionality</Title>
        <IntroParagraph>
        While aesthetics are important, functionality should never be compromised. Thoughtful consideration of how each hardscape element will be used ensures that the garden is not only beautiful but also practical. For instance, ensuring that pathways are wide enough for comfortable walking, or that seating areas have adequate shade and privacy, enhances the usability of the space.
        </IntroParagraph>
        <Title>Finishing Touches</Title>
        <IntroParagraph>
        The final touches, such as the choice of plants, lighting, and decorative accessories, bring the hardscape to life. Properly selected and placed lighting can highlight key features, create ambiance, and improve safety. Meanwhile, integrating greenery and decorative elements softens the hardscape, adding warmth and vibrancy to the garden.
        </IntroParagraph>
      </BlogContainer>
      <Footer />
    </BackgroundContainer>

  );
};

export default BlogDetails;
