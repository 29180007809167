import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Footer from '../components/Footer';
import NavBar from '../components/PublicNav/homenav';

const BackgroundContainer = styled.div`
  background: #f8f8f8;
`;

const BlogContainer = styled.div`
  margin: 0 auto;
  padding: 1vh;
`;

const IntroParagraph = styled.p`
  font-size: 4vh;
  font-weight: bold;
  font-family: 'Ubuntu';
  line-height: 1.6;
  max-width: 100%;
  padding-top: 3vh;
  margin-left: auto;
  margin-right: auto;
  color: #333;
  margin-bottom: 3.5vh;
  text-align: center;
  @media(orientation: portrait){
    font-size: 2.5vh;
  }
`;

const SmallParagraph = styled.p`
  font-size: 2.8vh;
  line-height: 1.6;
  font-style: italic;
  margin-left: auto;
  margin-right: auto;
  width: 120vh;
  margin-top: 3vh;
  color: #333;
  text-align: center;
  margin-bottom: 3vh;
  @media(orientation: portrait){
    font-size: 2vh;
    width: 40vh;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  padding-bottom: 1vh;
`;

const EbookImage = styled.img`
  width: 25vh;
  max-width: 80%;
  height: auto;
  @media(orientation: portrait){
    width: 40vw;
  }
`;

const DownloadCount = styled.div`
  font-size: 1.9vh;
  text-align: center;
  margin-top: 2vh;
  color: #333;
`;

const EmailFormContainer = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2vh;
`;

const EmailInput = styled.input`
  padding: 10px;
  font-size: 16px;
  width: 250px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const SubmitButton = styled.button`
  padding: 10px 20px;
  font-size: 16px;
  background-color: #007BFF;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  &:hover {
    background-color: #0056b3;
  }
`;

const Message = styled.p`
  font-size: 2.5vh;
  font-weight: bold;
  text-align: center;
  color: rgba(7,55,99);
  margin-top: 3vh;
`;


const EbookPage: React.FC = () => {
  const [email, setEmail] = useState('');
  const [downloadCount, setDownloadCount] = useState(112);
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchDownloadCount = async () => {
      try {
        const response = await fetch('https://projectprice-ad06ee250897.herokuapp.com/send-ebook/count');
        if (response.ok) {
          const data = await response.json();
          setDownloadCount(data.count);
        }
      } catch (error) {
        console.error('Failed to fetch download count:', error);
      }
    };

    fetchDownloadCount();
  }, []);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
        const response = await fetch('https://projectprice-ad06ee250897.herokuapp.com/send-ebook', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email }),
        });

        if (response.status === 429) {
            setMessage('It looks like you’ve already requested this eBook. Please check your inbox or junk folder for the download link. If you need further assistance, feel free to reach out to us!');
        } else if (response.ok) {
            setMessage('Your eBook has been sent! While you wait, why not check out our Feature page? ➜ ');
            setDownloadCount(prevCount => prevCount + 1);

            setTimeout(() => {
                navigate('/features');
            }, 5000);
        } else {
            setMessage('Sorry, something went wrong. Please try again.');
        }
    } catch (error) {
        setMessage('Sorry, something went wrong. Please try again.');
        console.error('Error:', error);
    }
};

  return (
    <BackgroundContainer>
      <NavBar />
      <BlogContainer>
        <IntroParagraph>
          Download your free ebook now! Maximize your proficiency.
        </IntroParagraph>
        <ImageContainer>
          <EbookImage src="/ebookcover.png" alt="Ebook Cover" />
        </ImageContainer>
        <DownloadCount>
          Downloaded {downloadCount} times
        </DownloadCount>
        <EmailFormContainer onSubmit={handleSubmit}>
          <EmailInput
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter your email"
            required
          />
          
           <SubmitButton type="submit">Send Me the Ebook</SubmitButton>
           {message && <Message>{message}</Message>}
              <p style={{ fontSize: '1.5vh', textAlign: 'center', marginTop: '1vh', color: '#333' }}>
                By providing your email, you agree to receive the eBook and other related communications from us.
                You can unsubscribe at any time. <a href="/privacy" target="_blank" style={{ color: '#007BFF' }}>Learn more</a>.
              </p>
            </EmailFormContainer>
      
        <SmallParagraph>
          <strong>Who is this book for?</strong> This book has been created for landscaping professionals looking to maximise their profits and take their business to the next level. Whether you're new to the landscaping world or a seasoned veteran, this guide offers invaluable insights from firsthand experience. You’ll learn how to avoid selling yourself short and discover proven strategies to price your services effectively, ensuring that every project contributes to your bottom line. Packed with practical advice and actionable tips, this ebook is your roadmap to thriving in the competitive landscaping industry.
        </SmallParagraph>
      </BlogContainer>
      <Footer />
    </BackgroundContainer>
  );
};

export default EbookPage;