import React from 'react';
import styled from 'styled-components';
import NavBar from '../components/PublicNav/homenav';
import Footer from '../components/Footer';

const BackgroundContainer = styled.div`
  background: #f8f8f8;
`;

const BlogContainer = styled.div`
  margin: 0 auto;
  padding: 20px;
  padding-top: 10vh;
  color: rgba(7,55,99);
`;

const BlogTitle = styled.h1`
  text-align: center;
  margin-bottom: 30px;
  padding: 5vh;
`;

const Title = styled.h4`
  margin-bottom: 2%;
  margin-top: 5%;
  margin-left: 10%;
`;

const IntroParagraph = styled.p`
  font-size: 1.1rem;
  line-height: 1.6;
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
  color: #333;
  margin-bottom: 30px; // Space between the paragraph and the first image
  text-align: left;
  @media (orientation: portrait){
    max-width: 100%;
  }
`;

const BlogImage = styled.img`
  display: block;
  max-width: 20%; // Adjusted to a more reasonable width for left alignment
  height: auto;
  margin: 2vh 20px 2vh 0; // Add margin to the right for spacing
  border-radius: 8px;
  float: right; // Float the image to the left
`;


const BlogSM: React.FC = () => {
  return (
    <BackgroundContainer>
      <NavBar />
      <BlogContainer>
        <BlogTitle>Maximizing Social Media for Landscaping Professionals: Best Practices for Using Platforms Like Instagram, Facebook, and LinkedIn to Grow Your Business</BlogTitle>
        <IntroParagraph>
          In today’s digital age, social media has become an indispensable tool for businesses across all industries, and landscaping is no exception. Platforms like Instagram, Facebook, and LinkedIn offer unique opportunities for landscaping professionals to showcase their work, connect with clients, and grow their businesses. Here’s how you can maximize your social media presence to enhance your landscaping business.
        </IntroParagraph>
        <BlogImage src="/instagram.png" alt="Instagram logo" />
        <Title>Instagram: Visual Storytelling at Its Best</Title>
        <IntroParagraph>
          Instagram is a highly visual platform, making it perfect for showcasing the beauty of your landscaping projects. Here are some best practices for using Instagram effectively:
        </IntroParagraph>
        <IntroParagraph>
          <strong>High-Quality Photos and Videos</strong>: Use high-resolution images and videos to highlight your best work. Before-and-after shots, time-lapse videos of project progress, and detailed close-ups of your work can captivate potential clients.
        </IntroParagraph>
        <IntroParagraph>
          <strong>Consistent Posting Schedule</strong>: Regularly post content to keep your audience engaged. Aim for at least 3-4 posts per week. Consistency helps build a loyal following.
        </IntroParagraph>
        <IntroParagraph>
          <strong>Utilize Stories and Reels</strong>: Instagram Stories and Reels are great for sharing quick updates, tips, and behind-the-scenes looks at your projects. These features can increase your visibility and engagement.
        </IntroParagraph>
        <IntroParagraph>
          <strong>Engage with Your Audience</strong>: Respond to comments and messages promptly. Engaging with your audience builds trust and fosters a sense of community.
        </IntroParagraph>
        <IntroParagraph>
          <strong>Use Relevant Hashtags</strong>: Research and use hashtags that are popular within the landscaping industry, such as #landscaping, #gardendesign, and #outdoorliving. Hashtags can help new clients find your work.
        </IntroParagraph>
        <IntroParagraph>
          <strong>Collaborate with Influencers</strong>: Partnering with local influencers or complementary businesses can help you reach a broader audience. Influencer collaborations can lend credibility and expand your follower base.
        </IntroParagraph>
        <BlogImage src="/facebook.png" alt="Facebook logo" />
        <Title>Facebook: Building a Community</Title>
        <IntroParagraph>
          Facebook is an excellent platform for creating a community around your landscaping business. Here’s how to make the most of it:
        </IntroParagraph>
        <IntroParagraph>
          <strong>Create a Business Page</strong>: Ensure you have a professional business page with complete information about your services, contact details, and a portfolio of your work.
        </IntroParagraph>
        <IntroParagraph>
          <strong>Join and Create Groups</strong>: Participate in local community groups and gardening or home improvement groups. Sharing your expertise in these groups can position you as an authority in the field.
        </IntroParagraph>
        <IntroParagraph>
          <strong>Share Valuable Content</strong>: Post articles, tips, and how-to guides related to landscaping. Content that adds value can attract and retain followers.
        </IntroParagraph>
        <IntroParagraph>
          <strong>Run Targeted Ads</strong>: Facebook’s advertising platform allows you to target specific demographics, interests, and locations. This can help you reach potential clients in your service area.
        </IntroParagraph>
        <IntroParagraph>
          <strong>Use Facebook Live</strong>: Hosting live sessions for Q&A, project walkthroughs, or live demonstrations can engage your audience in real-time and showcase your expertise.
        </IntroParagraph>
        <IntroParagraph>
          <strong>Encourage Reviews</strong>: Positive reviews on your Facebook page can build trust and attract new clients. Encourage satisfied customers to leave reviews and respond to them graciously.
        </IntroParagraph>
        <BlogImage src="/linkedin.png" alt="LinkedIn logo" />
        <Title>LinkedIn: Professional Networking and Business Growth</Title>
        <IntroParagraph>
          LinkedIn is the go-to platform for professional networking. Here’s how to leverage it for your landscaping business:
        </IntroParagraph>
        <IntroParagraph>
          <strong>Complete Your Profile</strong>: Ensure your LinkedIn profile and company page are fully completed with a professional bio, high-quality images, and detailed information about your services.
        </IntroParagraph>
        <IntroParagraph>
          <strong>Connect with Industry Professionals</strong>: Build a network of architects, real estate agents, builders, and other professionals who might refer clients to you or collaborate on projects.
        </IntroParagraph>
        <IntroParagraph>
          <strong>Share Industry Insights</strong>: Post articles and updates about the latest trends in landscaping, sustainable practices, or innovative design ideas. Sharing knowledge positions you as a thought leader.
        </IntroParagraph>
        <IntroParagraph>
          <strong>Join Industry Groups</strong>: Participate in LinkedIn groups related to landscaping, gardening, and construction. Engaging in discussions can increase your visibility and reputation.
        </IntroParagraph>
        <IntroParagraph>
          <strong>Showcase Projects</strong>: Use LinkedIn’s features to share detailed case studies of your projects. Highlighting your achievements and the process behind your work can impress potential clients.
        </IntroParagraph>
        <IntroParagraph>
          <strong>Ask for Recommendations</strong>: Client and colleague recommendations on LinkedIn add credibility to your profile. Request recommendations from satisfied clients and partners.
        </IntroParagraph>
      </BlogContainer>
      <Footer />
    </BackgroundContainer>
  );
};

export default BlogSM;
