import React from 'react';
import styled from 'styled-components';
import NavBar from '../components/PublicNav/homenav';
import Footer from '../components/Footer';


const BackgroundContainer = styled.div`
  background: #f8f8f8;
`;

const BlogContainer = styled.div`
  margin: 0 auto;
  padding: 20px;
`;

const BlogTitle = styled.h1`
  text-align: center;
  margin-bottom: 30px;
`;

const IntroParagraph = styled.p`
  font-size: 0.8rem;
  line-height: 1.6;
  max-width: 70%;
  margin-left: auto;
  margin-right: auto;
  color: #333;
  margin-bottom: 30px; // Space between the paragraph and the first image
  text-align: left;
  @media (orientation: portrait){
        max-width: 100%;
      }
`;

const Terms: React.FC = () => {

  return (
    <BackgroundContainer>
      <NavBar />
      <BlogContainer>
        <BlogTitle>Terms & Conditions</BlogTitle>
        <IntroParagraph>
        We are Project Price Ltd (‘Company’, ‘we’, ‘us’, or our’), a company registered in the 
United Kingdom at Whitwell Cottage, Selside, Lake View Landscaping Ltd la89ea. 
We operate the website http://www.projectprice.co.uk (the ‘Site’), the online 
application Project Price (the 'App'), as well as any other related products and 
services that refer or link to these legal terms (the ‘Legal Terms’) (collectively, the 
Services’). 
You can contact us by email at contact@projectprice.co.uk.  
These Legal Terms constitute a legally binding agreement made between you, 
whether personally or on behalf of an entity (‘you’), and Project Price Ltd, concerning 
your access to and use of the Services. You agree that by accessing the Services, 
you have read, understood, and agreed to be bound by all of these Legal Terms. IF 
YOU DO NOT AGREE WITH ALL OF THESE LEGAL TERMS, THEN YOU ARE 

EXPRESSLY PROHIBITED FROM USING THE SERVICES AND YOU MUST 

DISCONTINUE USE IMMEDIATELY. 

  

We will provide you with prior notice of any scheduled changes to the Services you 

are using. The modified Legal Terms will become effective upon posting or notifying 

you by contact@projectprice.co.uk, as stated in the email message. By continuing to 

use the Services after the effective date of any changes, you agree to be bound by 

the modified terms. 

 

The Services are intended for users who are at least 13 years of age. All users who 

are minors in the jurisdiction in which they reside (generally under the age of 18) 

must have the permission of, and be directly supervised by, their parent or guardian 

to use the Services. If you are a minor, you must have your parent or guardian read 

and agree to these Legal Terms prior to you using the Services. 

  

We recommend that you print a copy of these Legal Terms for your records. 
        </IntroParagraph>
        <IntroParagraph>
        1. OUR SERVICES 

  

The information provided when using the Services is not intended for distribution to or 

use by any person or entity in any jurisdiction or country where such distribution or 

use would be contrary to law or regulation or which would subject us to any 

registration requirement within such jurisdiction or country. Accordingly, those 

persons who choose to access the Services from other locations do so on their own 

initiative and are solely responsible for compliance with local laws, if and to the extent 

local laws are applicable. 
        </IntroParagraph>
        <IntroParagraph>
        2. INTELLECTUAL PROPERTY RIGHTS 

  

Our intellectual property 

  

We are the owner or the licensee of all intellectual property rights in our Services, 

including all source code, databases, functionality, software, website designs, audio, 

video, text, photographs, and graphics in the Services (collectively, the ‘Content'), as 

well as the trademarks, service marks, and logos contained therein (the ‘'Marks’). 

  

Our Content and Marks are protected by copyright and trademark laws (and various 

other intellectual property rights and unfair competition laws) and treaties in the 

United Kingdom and around the world. 

 

The Content and Marks are provided in or through the Services ‘AS |S' for your 

internal business purpose only. 

Your use of our Services 

Subject to your compliance with these Legal Terms, including the ‘PROHIBITED 

ACTIVITIES' section below, we grant you a non-exclusive, non-transferable, 

revocable licence to: 

  

» access the Services; and 

  

« download or print a copy of any portion of the Content to which you have 

  

properly gained access. 

  

solely for your internal business purpose. 

Except as set out in this section or elsewhere in our Legal Terms, no part of the 

Services and no Content or Marks may be copied, reproduced, aggregated, 

republished, uploaded, posted, publicly displayed, encoded, translated, transmitted, 

distributed, sold, licensed, or otherwise exploited for any commercial purpose 

whatsoever, without our express prior written permission. 

If you wish to make any use of the Services, Content, or Marks other than as set out 

in this section or elsewhere in our Legal Terms, please address your request to: 

contact@projectprice.co.uk. If we ever grant you the permission to post, reproduce, 

or publicly display any part of our Services or Content, you must identify us as the owners or licensors of the Services, Content, or Marks and ensure that any copyright 

or proprietary notice appears or is visible on posting, reproducing, or displaying our 

Content. 

  

We reserve all rights not expressly granted to you in and to the Services, Content, 

and Marks. 

  

Any breach of these Intellectual Property Rights will constitute a material breach of 

our Legal Terms and your right to use our Services will terminate immediately. 

Your submissions 

  

Please review this section and the ‘PROHIBITED ACTIVITIES’ section carefully prior 

to using our Services to understand the (a) rights you give us and (b) obligations you 

have when you post or upload any content through the Services. 

  

Submissions: By directly sending us any question, comment, suggestion, idea, 

feedback, or other information about the Services (‘Submissions’), you agree to 

assign to us all intellectual property rights in such Submission. You agree that we 

shall own this Submission and be entitled to its unrestricted use and dissemination 

for any lawful purpose, commercial or otherwise, without acknowledgment or 

compensation to you. 

  

You are responsible for what you post or upload: By sending us Submissions through any part of the Services you: 

  

« confirm that you have read and agree with our ‘PROHIBITED ACTIVITIES’ and 

will not post, send, publish, upload, or transmit through the Services any 

Submission that is illegal, harassing, hateful, harmful, defamatory, obscene, 

bullying, abusive, discriminatory, threatening to any person or group, sexually 

explicit, false, inaccurate, deceitful, or misleading; 

  

« to the extent permissible by applicable law, waive any and all moral rights to 

any such Submission; 

  

« warrant that any such Submission are original to you or that you have the 

necessary rights and licences to submit such Submissions and that you have 

full authority to grant us the above-mentioned rights in relation to your 

Submissions; and 

  

« warrant and represent that your Submissions do not constitute confidential 

information. 

  

You are solely responsible for your Submissions and you expressly agree to 

reimburse us for any and all losses that we may suffer because of your breach of (a) 

this section, (b) any third party's intellectual property rights, or (c) applicable law. 
        </IntroParagraph>
        <IntroParagraph>
        3. USER REPRESENTATIONS 

  

By using the Services, you represent and warrant that: (1) all registration information 

you submit will be true, accurate, current, and complete; (2) you will maintain the 

accuracy of such information and promptly update such registration information as necessary; (3) you have the legal capacity and you agree to comply with these Legal 

Terms; (4) you are not under the age of 13; (5) you are not a minor in the jurisdiction 

in which you reside, or if a minor, you have received parental permission to use the 

Services; (6) you will not access the Services through automated or non-human 

means, whether through a bot, script or otherwise; (7) you will not use the Services 

for any illegal or unauthorised purpose; and (8) your use of the Services will not 

violate any applicable law or regulation. 

  

If you provide any information that is untrue, inaccurate, not current, or incomplete, 

we have the right to suspend or terminate your account and refuse any and all 

current or future use of the Services (or any portion thereof). 
        </IntroParagraph>
        <IntroParagraph>
        4. USER REGISTRATION 

  

You may be required to register to use the Services. You agree to keep your 

password confidential and will be responsible for all use of your account and 

password. We reserve the right to remove, reclaim, or change a username you select 

if we determine, in our sole discretion, that such username is inappropriate, obscene, 

or otherwise objectionable. 
        </IntroParagraph>
        <IntroParagraph>
        5. PURCHASES AND PAYMENT 

  

We accept the following forms of payment:  

 
-Visa 

  

- PayPal 

You agree to provide current, complete, and accurate purchase and account 

information for all purchases made via the Services. You further agree to promptly 

update account and payment information, including email address, payment method, 

and payment card expiration date, so that we can complete your transactions and 

contact you as needed. Sales tax will be added to the price of purchases as deemed 

required by us. We may change prices at any time. All payments shall be in GBP. 

You agree to pay all charges at the prices then in effect for your purchases and any 

applicable shipping fees, and you authorise us to charge your chosen payment 

provider for any such amounts upon placing your order. If your order is subject to 

recurring charges, then you consent to our charging your payment method on a 

recurring basis without requiring your prior approval for each recurring charge, until 

such time as you cancel the applicable order. We reserve the right to correct any 

errors or mistakes in pricing, even if we have already requested or received payment. 

We reserve the right to refuse any order placed through the Services. We may, in our 

sole discretion, limit or cancel quantities purchased per person, per household, or per 

order. These restrictions may include orders placed by or under the same customer 

account, the same payment method, and/or orders that use the same billing or 

shipping address. We reserve the right to limit or prohibit orders that, in our sole 

judgement, appear to be placed by dealers, resellers, or distributors.  
        </IntroParagraph>
        <IntroParagraph>
        6. CANCELLATION 

  

All purchases are non-refundable. You can cancel your subscription at any time by 

logging into your account. Your cancellation will take effect at the end of the current 

paid term. 

Waiver of Cooling-Off Period

By making a payment for our services, you expressly agree to waive your right to the statutory cooling-off period. This means that once your payment is processed and confirmed, you will not be entitled to cancel your purchase or request a refund based on the cooling-off period. This waiver is in accordance with relevant consumer protection laws and ensures the immediate commencement of our services to meet your needs promptly.

If you are unsatisfied with our Services, please email us at 

contact@projectprice.co.uk. 
        </IntroParagraph>
        <IntroParagraph>
        7. PROHIBITED ACTIVITIES 

  

You may not access or use the Services for any purpose other than that for which we 

make the Services available. The Services may not be used in connection with any 

commercial endeavours except those that are specifically endorsed or approved by 

us. 

  

As a user of the Services, you agree not to: 

  

« Systematically retrieve data or other content from the Services to create or 

compile, directly or indirectly, a collection, compilation, database, or directory 

without written permission from us. 

  

« Trick, defraud, or mislead us and other users, especially in any attempt to learn 

sensitive account information such as user passwords. 

  

» Circumvent. disable. or otherwise interfere with securitv-related features of the Services, including features that prevent or restrict the use or copying of any 

Content or enforce limitations on the use of the Services and/or the Content 

contained therein. 

  

« Disparage, tarnish, or otherwise harm, in our opinion, us and/or the Services. 

  

« Use any information obtained from the Services in order to harass, abuse, or 

harm another person. 

  

« Make improper use of our support services or submit false reports of abuse or 

misconduct. 

  

« Use the Services in a manner inconsistent with any applicable laws or 

regulations. 

  

« Engage in unauthorised framing of or linking to the Services. 

  

« Upload or transmit (or attempt to upload or to transmit) viruses, Trojan horses, 

or other material, including excessive use of capital letters and spamming 

(continuous posting of repetitive text), that interferes with any party’s 

uninterrupted use and enjoyment of the Services or modifies, impairs, disrupts, 

alters, or interferes with the use, features, functions, operation, or maintenance 

of the Services. 

  

« Engage in any automated use of the system, such as using scripts to send 

comments or messages, or using any data mining, robots, or similar data 

gathering and extraction tools. 

  

« Delete the copyright or other proprietary rights notice from any Content. 

  

« Attempt to impersonate another user or person or use the username of another 

user. 

  

« Upload or transmit (or attempt to upload or to transmit) any material that acts as a passive or active information collection or transmission mechanism, 

including without limitation, clear graphics interchange formats (‘gifs’), 1*1 

pixels, web bugs, cookies, or other similar devices (sometimes referred to as 

‘spyware’ or ‘passive collection mechanisms’ or 'pcms’). 

  

« Interfere with, disrupt, or create an undue burden on the Services or the 

networks or services connected to the Services. 

  

« Harass, annoy, intimidate, or threaten any of our employees or agents 

engaged in providing any portion of the Services to you. 

  

« Attempt to bypass any measures of the Services designed to prevent or restrict 

access to the Services, or any portion of the Services. 

  

« Copy or adapt the Services’ software, including but not limited to Flash, PHP, 

HTML, JavaScript, or other code. 

  

« Except as permitted by applicable law, decipher, decompile, disassemble, or 

reverse engineer any of the software comprising or in any way making up a 

part of the Services. 

  

« Except as may be the result of standard search engine or Internet browser 

usage, use, launch, develop, or distribute any automated system, including 

without limitation, any spider, robot, cheat utility, scraper, or offline reader that 

accesses the Services, or use or launch any unauthorised script or other 

software. 

  

« Use a buying agent or purchasing agent to make purchases on the Services. 

  

« Make any unauthorised use of the Services, including collecting usernames 

and/or email addresses of users by electronic or other means for the purpose 

of sending unsolicited email, or creating user accounts by automated means or under false pretences. 

  

« Use the Services as part of any effort to compete with us or otherwise use the 

Services and/or the Content for any revenue-generating endeavour or 

commercial enterprise. 

  

« Sell or otherwise transfer your profile. 

  

« Use the Services to advertise or offer to sell goods and services.
        </IntroParagraph>
        <IntroParagraph>
        8. USER GENERATED CONTRIBUTIONS 

  

The Services does not offer users to submit or post content. We may provide you 

with the opportunity to create, submit, post, display, transmit, perform, publish, 

distribute, or broadcast content and materials to us or on the Services, including but 

not limited to text, writings, video, audio, photographs, graphics, comments, 

suggestions, or personal information or other material (collectively, ‘Contributions’). 

Contributions may be viewable by other users of the Services and through third-party 

websites. When you create or make available any Contributions, you thereby 

represent and warrant that: 

  

= The creation, distribution, transmission, public display, or performance, and the 

accessing, downloading, or copying of your Contributions do not and will not 

infringe the proprietary rights, including but not limited to the copyright, patent, 

trademark, trade secret, or moral rights of any third party. 

  

= You are the creator and owner of or have the necessary licences, rights, consents, releases, and permissions to use and to authorise us, the Services, 

and other users of the Services to use your Contributions in any manner 

contemplated by the Services and these Legal Terms. 

  

# You have the written consent, release, and/or permission of each and every 

identifiable individual person in your Contributions to use the name or likeness 

of each and every such identifiable individual person to enable inclusion and 

use of your Contributions in any manner contemplated by the Services and 

these Legal Terms. 

  

# Your Contributions are not false, inaccurate, or misleading. 

  

® Your Contributions are not unsolicited or unauthorised advertising, promotional 

materials, pyramid schemes, chain letters, spam, mass mailings, or other 

forms of solicitation. 

  

« Your Contributions are not obscene, lewd, lascivious, filthy, violent, harassing, 

libellous, slanderous, or otherwise objectionable (as determined by us). 

  

® Your Contributions do not ridicule, mock, disparage, intimidate, or abuse 

anyone. 

  

# Your Contributions are not used to harass or threaten (in the legal sense of 

those terms) any other person and to promote violence against a specific 

person or class of people. 

  

® Your Contributions do not violate any applicable law, regulation, or rule. 

  

® Your Contributions do not violate the privacy or publicity rights of any third 

party. 

  

® Your Contributions do not violate any applicable law concerning child 

pornography, or otherwise intended to protect the health or well-being of 

Minors. ® Your Contributions do not include any offensive comments that are connected 

to race, national origin, gender, sexual preference, or physical handicap. 

# Your Contributions do not otherwise violate, or link to material that violates, any 

provision of these Legal Terms, or any applicable law or regulation. 

Any use of the Services in violation of the foregoing violates these Legal Terms and 

may result in, among other things, termination or suspension of your rights to use the 

Services. 
        </IntroParagraph>
        <IntroParagraph>
        9. CONTRIBUTION LICENCE 

You and Services agree that we may access, store, process, and use any information 

and personal data that you provide and your choices (including settings). 

By submitting suggestions or other feedback regarding the Services, you agree that 

we can use and share such feedback for any purpose without compensation to you. 

We do not assert any ownership over your Contributions. You retain full ownership of 

all of your Contributions and any intellectual property rights or other proprietary rights 

associated with your Contributions. We are not liable for any statements or 

representations in your Contributions provided by you in any area on the Services. 

You are solely responsible for your Contributions to the Services and you expressly 

agree to exonerate us from any and all responsibility and to refrain from any legal 

action against us regarding your Contributions. 
        </IntroParagraph>
        <IntroParagraph>
        10. MOBILE APPLICATION LICENCE 

  

Use Licence 

  

If you access the Services via the App, then we grant you a revocable, non-exclusive, 

non-transferable, limited right to install and use the App on wireless electronic 

devices owned or controlled by you, and to access and use the App on such devices 

strictly in accordance with the terms and conditions of this mobile application licence 

contained in these Legal Terms. You shall not: (1) except as permitted by applicable 

law, decompile, reverse engineer, disassemble, attempt to derive the source code of, 

or decrypt the App; (2) make any modification, adaptation, improvement, 

enhancement, translation, or derivative work from the App; (3) violate any applicable 

laws, rules, or regulations in connection with your access or use of the App; (4) 

remove, alter, or obscure any proprietary notice (including any notice of copyright or 

trademark) posted by us or the licensors of the App; (5) use the App for any revenue- 

generating endeavour, commercial enterprise, or other purpose for which it is not 

designed or intended; (6) make the App available over a network or other 

environment permitting access or use by multiple devices or users at the same time; 

(7) use the App for creating a product, service, or software that is, directly or 

indirectly, competitive with or in any way a substitute for the App; (8) use the App to 

send automated queries to any website or to send any unsolicited commercial email; 

or (9) use any proprietary information or any of our interfaces or our other intellectual 

property in the design, development, manufacture, licensing, or distribution of any 

applications, accessories, or devices for use with the App. 

 

Apple and Android Devices 

  

The following terms apply when you use the App obtained from either the Apple Store 

or Google Play (each an ‘App Distributor’) to access the Services: (1) the licence 

granted to you for our App is limited to a non-transferable licence to use the 

application on a device that utilises the Apple iOS or Android operating systems, as 

applicable, and in accordance with the usage rules set forth in the applicable App 

Distributor’s terms of service; (2) we are responsible for providing any maintenance 

and support services with respect to the App as specified in the terms and conditions 

of this mobile application licence contained in these Legal Terms or as otherwise 

required under applicable law, and you acknowledge that each App Distributor has no 

obligation whatsoever to furnish any maintenance and support services with respect 

to the App; (3) in the event of any failure of the App to conform to any applicable 

warranty, you may notify the applicable App Distributor, and the App Distributor, in 

accordance with its terms and policies, may refund the purchase price, if any, paid for 

the App, and to the maximum extent permitted by applicable law, the App Distributor 

will have no other warranty obligation whatsoever with respect to the App; (4) you 

represent and warrant that (i) you are not located in a country that is subject to a UK 

government embargo, or that has been designated by the UK government as a 

‘terrorist supporting’ country and (ii) you are not listed on any UK government list of 

prohibited or restricted parties; (5) you must comply with applicable third-party terms 

of agreement when using the App, e.g. if you have a VoIP application, then you must 

not be in violation of their wireless data service agreement when using the App; and 

(6) you acknowledge and agree that the App Distributors are third-party beneficiaries 

of the terms and conditions in this mobile application licence contained in these Legal 

Terms, and that each App Distributor will have the right (and will be deemed to have 

accepted the right) to enforce the terms and conditions in this mobile application 

licence contained in these Legal Terms against you as a third-party beneficiary 

thereof. 
        </IntroParagraph>
        <IntroParagraph>
        11. SERVICES MANAGEMENT 

  

We reserve the right, but not the obligation, to: (1) monitor the Services for violations 

of these Legal Terms; (2) take appropriate legal action against anyone who, in our 

sole discretion, violates the law or these Legal Terms, including without limitation, 

reporting such user to law enforcement authorities; (3) in our sole discretion and 

without limitation, refuse, restrict access to, limit the availability of, or disable (to the 

extent technologically feasible) any of your Contributions or any portion thereof; (4) in 

our sole discretion and without limitation, notice, or liability, to remove from the 

Services or otherwise disable all files and content that are excessive in size or are in 

any way burdensome to our systems; and (5) otherwise manage the Services in a 

manner designed to protect our rights and property and to facilitate the proper 

functioning of the Services. 
        </IntroParagraph>
        <IntroParagraph>
        12. PRIVACY POLICY 

  

We care about data privacy and security. By using the Services, you agree to be 

bound by our Privacy Policy posted on the Services, which is incorporated into these Legal Terms. Please be advised the Services are hosted in the United Kingdom. If 

you access the Services from any other region of the world with laws or other 

requirements governing personal data collection, use, or disclosure that differ from 

applicable laws in the United Kingdom, then through your continued use of the 

Services, you are transferring your data to the United Kingdom, and you expressly 

consent to have your data transferred to and processed in the United Kingdom. 
        </IntroParagraph>
        <IntroParagraph>
        13. TERM AND TERMINATION 

  

These Legal Terms shall remain in full force and effect while you use the Services. 

WITHOUT LIMITING ANY OTHER PROVISION OF THESE LEGAL TERMS, WE 

RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND WITHOUT NOTICE 

OR LIABILITY, DENY ACCESS TO AND USE OF THE SERVICES (INCLUDING 

BLOCKING CERTAIN IP ADDRESSES), TO ANY PERSON FOR ANY REASON OR 

FOR NO REASON, INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY 

REPRESENTATION, WARRANTY, OR COVENANT CONTAINED IN THESE LEGAL 

TERMS OR OF ANY APPLICABLE LAW OR REGULATION. WE MAY TERMINATE 

YOUR USE OR PARTICIPATION IN THE SERVICES OR DELETE YOUR ACCOUNT 

AND ANY CONTENT OR INFORMATION THAT YOU POSTED AT ANY TIME, 

WITHOUT WARNING, IN OUR SOLE DISCRETION. 

  

If we terminate or suspend your account for any reason, you are prohibited from 

registering and creating a new account under your name, a fake or borrowed name, 

or the name of any third party, even if you may be acting on behalf of the third party. 
 
In addition to terminating or suspending your account, we reserve the right to take 

appropriate legal action, including without limitation pursuing civil, criminal, and 

injunctive redress. 
        </IntroParagraph>
        <IntroParagraph>
        14. MODIFICATIONS AND INTERRUPTIONS 

  

We reserve the right to change, modify, or remove the contents of the Services at any 

time or for any reason at our sole discretion without notice. However, we have no 

obligation to update any information on our Services. We will not be liable to you or 

any third party for any modification, price change, suspension, or discontinuance of 

the Services. 

  

We cannot guarantee the Services will be available at all times. We may experience 

hardware, software, or other problems or need to perform maintenance related to the 

Services, resulting in interruptions, delays, or errors. We reserve the right to change, 

revise, update, suspend, discontinue, or otherwise modify the Services at any time or 

for any reason without notice to you. You agree that we have no liability whatsoever 

for any loss, damage, or inconvenience caused by your inability to access or use the 

Services during any downtime or discontinuance of the Services. Nothing in these 

Legal Terms will be construed to obligate us to maintain and support the Services or 

to supply any corrections, updates, or releases in connection therewith. 
        </IntroParagraph>
        <IntroParagraph>
        15. GOVERNING LAW 

These Legal Terms are governed by and interpreted following the laws of the United 

Kingdom, and the use of the United Nations Convention of Contracts for the 

International Sales of Goods is expressly excluded. If your habitual residence is in 

the EU, and you are a consumer, you additionally possess the protection provided to 

you by obligatory provisions of the law in your country to residence. Project Price Ltd

and yourself both agree to submit to the non-exclusive jurisdiction of the courts of 

Cumbria, which means that you may make a claim to defend your consumer 

protection rights in regards to these Legal Terms in the United Kingdom, or in the EU 

country in which you reside. 
        </IntroParagraph>
        <IntroParagraph>
        16. DISPUTE RESOLUTION 

  

Informal Negotiations 

  

To expedite resolution and control the cost of any dispute, controversy, or claim 

related to these Legal Terms (each a ‘Dispute’ and collectively, the ‘Disputes') brought 

by either you or us (individually, a ‘Party’ and collectively, the ‘Parties'), the Parties 

agree to first attempt to negotiate any Dispute (except those Disputes expressly 

provided below) informally for at least thirty (30) days before initiating arbitration. 

Such informal negotiations commence upon written notice from one Party to the other 

Party. 

  

Binding Arbitration Any dispute arising from the relationships between the Parties to these Legal Terms 

shall be determined by one arbitrator who will be chosen in accordance with the 

Arbitration and Internal Rules of the European Court of Arbitration being part of the 

European Centre of Arbitration having its seat in Strasbourg, and which are in force 

at the time the application for arbitration is filed, and of which adoption of this clause 

constitutes acceptance. The seat of arbitration shall be London, United Kingdom. The 

language of the proceedings shall be English. Applicable rules of substantive law 

shall be the law of the United Kingdom. 

  

Restrictions 

  

The Parties agree that any arbitration shall be limited to the Dispute between the 

Parties individually. To the full extent permitted by law, (a) no arbitration shall be 

joined with any other proceeding; (b) there is no right or authority for any Dispute to 

be arbitrated on a class-action basis or to utilise class action procedures; and (c) 

there is no right or authority for any Dispute to be brought in a purported 

representative capacity on behalf of the general public or any other persons. 

Exceptions to Informal Negotiations and Arbitration 

  

The Parties agree that the following Disputes are not subject to the above provisions 

concerning informal negotiations binding arbitration: (a) any Disputes seeking to 

enforce or protect, or concerning the validity of, any of the intellectual property rights 

of a Party; (b) any Dispute related to, or arising from, allegations of theft, piracy, invasion of privacy, or unauthorised use; and (c) any claim for injunctive relief. If this 

provision is found to be illegal or unenforceable, then neither Party will elect to 

arbitrate any Dispute falling within that portion of this provision found to be illegal or 

unenforceable and such Dispute shall be decided by a court of competent jurisdiction 

within the courts listed for jurisdiction above, and the Parties agree to submit to the 

personal jurisdiction of that court. 
        </IntroParagraph>
        <IntroParagraph>
        17. CORRECTIONS 

  

There may be information on the Services that contains typographical errors, 

inaccuracies, or omissions, including descriptions, pricing, availability, and various 

other information. We reserve the right to correct any errors, inaccuracies, or 

omissions and to change or update the information on the Services at any time, 

without prior notice. 
        </IntroParagraph>
        <IntroParagraph>
        18. DISCLAIMER 

  

THE SERVICES ARE PROVIDED ONAN AS-IS AND AS-AVAILABLE BASIS. YOU 

AGREE THAT YOUR USE OF THE SERVICES WILL BE AT YOUR SOLE RISK. TO 

THE FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES, 

EXPRESS OR IMPLIED, IN CONNECTION WITH THE SERVICES AND YOUR USE 

THEREOF, INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF 

MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON- 

INFRINGEMENT. WE MAKE NO WARRANTIES OR REPRESENTATIONS ABOUT THE ACCURACY OR COMPLETENESS OF THE SERVICES' CONTENT OR THE 

CONTENT OF ANY WEBSITES OR MOBILE APPLICATIONS LINKED TO THE 

SERVICES AND WE WILL ASSUME NO LIABILITY OR RESPONSIBILITY FOR ANY 

(1) ERRORS, MISTAKES, OR INACCURACIES OF CONTENT AND MATERIALS. *ALL MATERIAL VOLUMES AND QUANTITIES

DIFFER DUE TO DENSITIES AND WATER VOLUME, WE CALCULATE MATERIALS BASED ON DRY WEIGHT WITH A MEDIAN DENSITY

OF EACH MATERIAL. 

(2) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE 

WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF THE 

SERVICES, (3) ANY UNAUTHORISED ACCESS TO OR USE OF OUR SECURE 

SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR 

FINANCIAL INFORMATION STORED THEREIN, (4) ANY INTERRUPTION OR 

CESSATION OF TRANSMISSION TO OR FROM THE SERVICES, (5) ANY BUGS, 

VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED TO 

OR THROUGH THE SERVICES BY ANY THIRD PARTY, AND/OR (6) ANY ERRORS 

OR OMISSIONS IN ANY CONTENT AND MATERIALS OR FOR ANY LOSS OR 

DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY 

CONTENT POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA 

THE SERVICES. WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME 

RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR 

OFFERED BY A THIRD PARTY THROUGH THE SERVICES, ANY HYPERLINKED 

WEBSITE, OR ANY WEBSITE OR MOBILE APPLICATION FEATURED IN ANY 

BANNER OR OTHER ADVERTISING, AND WE WILL NOT BE A PARTY TO OR IN 

ANY WAY BE RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN 

YOU AND ANY THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES. AS 

WITH THE PURCHASE OF A PRODUCT OR SERVICE THROUGH ANY MEDIUM 

OR IN ANY ENVIRONMENT, YOU SHOULD USE YOUR BEST JUDGEMENT AND EXERCISE CAUTION WHERE APPROPRIATE. 
        </IntroParagraph>
        <IntroParagraph>
        19. LIMITATIONS OF LIABILITY 

  

IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE 

LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT, 

CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE 

DAMAGES, INCLUDING LOST PROFIT, LOST REVENUE, LOSS OF DATA, OR 

OTHER DAMAGES ARISING FROM YOUR USE OF THE SERVICES, EVEN IF WE 

HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. 
        </IntroParagraph>
        <IntroParagraph>
        20. INDEMNIFICATION 

  

You agree to defend, indemnify, and hold us harmless, including our subsidiaries, 

affiliates, and all of our respective officers, agents, partners, and employees, from 

and against any loss, damage, liability, claim, or demand, including reasonable 

attorneys’ fees and expenses, made by any third party due to or arising out of: (1) 

use of the Services; (2) breach of these Legal Terms; (3) any breach of your 

representations and warranties set forth in these Legal Terms; (4) your violation of 

the rights of a third party, including but not limited to intellectual property rights; or (5) 

any overt harmful act toward any other user of the Services with whom you 

connected via the Services. Notwithstanding the foregoing, we reserve the right, at 

your expense, to assume the exclusive defence and control of any matter for which 

you are required to indemnify us, and you agree to cooperate, at your expense, with our defence of such claims. We will use reasonable efforts to notify you of any such 

claim, action, or proceeding which is subject to this indemnification upon becoming 

aware of it. 
        </IntroParagraph>
        <IntroParagraph>
        21. USER DATA 

  

We will maintain certain data that you transmit to the Services for the purpose of 

managing the performance of the Services, as well as data relating to your use of the 

Services. Although we perform regular routine backups of data, you are solely 

responsible for all data that you transmit or that relates to any activity you have 

undertaken using the Services. You agree that we shall have no liability to you for any 

loss or corruption of any such data, and you hereby waive any right of action against 

us arising from any such loss or corruption of such data. 

  
        </IntroParagraph>
        <IntroParagraph>
        22. ELECTRONIC COMMUNICATIONS, TRANSACTIONS, 

AND SIGNATURES 

  

Visiting the Services, sending us emails, and completing online forms constitute 

electronic communications. You consent to receive electronic communications, and 

you agree that all agreements, notices, disclosures, and other communications we 

provide to you electronically, via email and on the Services, satisfy any legal 

requirement that such communication be in writing. YOU HEREBY AGREE TO THE 

USE OF ELECTRONIC SIGNATURES, CONTRACTS, ORDERS, AND OTHER 

RECORDS, AND TO ELECTRONIC DELIVERY OF NOTICES, POLICIES, AND RECORDS OF TRANSACTIONS INITIATED OR COMPLETED BY US OR VIA THE 

SERVICES. You hereby waive any rights or requirements under any statutes, 

regulations, rules, ordinances, or other laws in any jurisdiction which require an 

original signature or delivery or retention of non-electronic records, or to payments or 

the granting of credits by any means other than electronic means. 
        </IntroParagraph>
        <IntroParagraph>
        23. MISCELLANEOUS 

  

These Legal Terms and any policies or operating rules posted by us on the Services 

or in respect to the Services constitute the entire agreement and understanding 

between you and us. Our failure to exercise or enforce any right or provision of these 

Legal Terms shall not operate as a waiver of such right or provision. These Legal 

Terms operate to the fullest extent permissible by law. We may assign any or all of 

our rights and obligations to others at any time. We shall not be responsible or liable 

for any loss, damage, delay, or failure to act caused by any cause beyond our 

reasonable control. If any provision or part of a provision of these Legal Terms is 

determined to be unlawful, void, or unenforceable, that provision or part of the 

provision is deemed severable from these Legal Terms and does not affect the 

validity and enforceability of any remaining provisions. There is no joint venture, 

partnership, employment or agency relationship created between you and us as a 

result of these Legal Terms or use of the Services. You agree that these Legal Terms 

will not be construed against us by virtue of having drafted them. You hereby waive 

any and all defences you may have based on the electronic form of these Legal 

Terms and the lack of signing by the parties hereto to execute these Legal Terms. 

By creating an account and using our services, you hereby understand and agree to these terms and conditions.

        </IntroParagraph>
        <IntroParagraph>
             
24. CONTACT US 

  

In order to resolve a complaint regarding the Services or to receive further 

information regarding use of the Services, please contact us at: 

  

Project Price Ltd

Whitwell Cottage 

Selside 

LA89EA 

United Kingdom  

contact@projectprice.co.uk 
        </IntroParagraph>
      </BlogContainer>
      <Footer />
    </BackgroundContainer>
  );
};

export default Terms;
