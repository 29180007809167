import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';

const ValidationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: rgba(7, 55, 99);
  color: white;
  font-size: 1.5em;
  text-align: center;
`;

const spinnerAnimation = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const Spinner = styled.div`
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid white;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: ${spinnerAnimation} 1s linear infinite;
  margin-bottom: 20px;
`;

const EmailValidationPage = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const token = queryParams.get('token');
        const email = queryParams.get('email');
    
        console.log("Token: ", token, "Email: ", email);
    
        if (token && email) {
            axios.post('https://projectprice-ad06ee250897.herokuapp.com/validationcheck', { token, email })
                .then(response => {
                    console.log("Validation Response: ", response.data);
                    if (response.data.alreadyValidated) {
                        navigate('/loginpage');
                    } else {
                        // Handle successful validation
                        navigate('/loginpage');
                    }
                })
                .catch(error => {
                    console.error('Email validation error:', error);
                    // Handle validation error, show error message or redirect
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            console.error("Token or email missing");
            // Handle missing token or email, show error message or redirect
            setLoading(false);
        }
    }, [navigate]);

    return (
        <ValidationContainer>
            {loading ? (
                <>
                    <Spinner />
                    <div>Validating your email...</div>
                </>
            ) : (
                <div>Email validation completed. Redirecting... or click <a href={'/loginpage'}>here</a></div>
            )}
        </ValidationContainer>
    );
};

export default EmailValidationPage;
