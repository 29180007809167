/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';

interface TrackerProps {
  backgroundColor: string;
}

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const TableContainer = styled.div`
display: flex;
height: 90%;
`

const LabourContainer = styled.div`
display: flex;
flex-direction: column;
background-color: transparent;
flex: 1;
flex-basis: 5%;
width: 300px;
margin-right: 3rem;
height: 90%;
overflow-y: auto;
margin-top: 2vh;
`;

const Input = styled.input`
  width: 32px;
  color: black;
  border: none;
  border-radius: 0.2rem;
  background-color: rgba(52, 52, 52, 0.3);
  text-align: right;
  margin-right: 0.5rem;

  /* Disable spinners */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type="number"] {
    -moz-appearance: textfield;
  }
`;

const ResultTableData = styled.td`
  padding: 0.15rem;
  border: 1px solid black;
  font-size: 0.8rem;
  height: 1.8rem;
`;

const ResultTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  right: 0px;
  overflow-y: auto;
`;

const ResultTableHeader = styled.th`
  padding: 0.5rem;
  text-align: left;
  height: 3px;
  border-bottom: 1px solid black;
  font-size: 0.9rem;
  color: black;
  background-color: white;
`;

const ResultTableRow = styled.tr`
  &:nth-child(even) {
    background-color: rgba(180, 180, 180);
  }
  background-color: white;
  height: fit-content;
  width: 100%;
  color: black;
`;


interface FormData {
  excavationValue: number;
  subBaseValue: number;
  edgingsValue: number;
  pavingValue: number;
  blockPavingValue: number;
  asphaltValue: number;
  wallingValue: number;
  pointingValue: number;
  fencingValue: number;
  timberValue: number;
  deckingValue: number;
  turfingValue: number;
  gravelValue: number;
  additionalDaysValue: number;
}

interface Project {
    _id: string;
    userId: string;
    name: string;
    projectName: string;

    [key: string]: any;
  }

interface FormData {
    [key: string]: number | undefined; 
  }

  
  const initialFormData: FormData = {
    excavationValue: 0,
    subBaseValue: 0,
    edgingsValue: 0,
    pavingValue: 0,
    blockPavingValue: 0,
    asphaltValue: 0,
    wallingValue: 0,
    pointingValue: 0,
    fencingValue: 0,
    timberValue: 0,
    deckingValue: 0,
    turfingValue: 0,
    gravelValue: 0,
    additionalDaysValue: 0,
  }

  const phaseToKey: Record<string, keyof FormData> = {
    'Excavation': 'excavationValue',
    'Sub base': 'subBaseValue',
    'Edgings': 'edgingsValue',
    'Paving': 'pavingValue',
    'Block paving': 'blockPavingValue',
    'ashpalt': 'asphaltValue',
    'Walling': 'wallingValue',
    'Pointing': 'pointingValue',
    'Fencing': 'fencingValue',
    'Timber': 'timberValue',
    'Decking': 'deckingValue',
    'Turfing': 'turfingValue',
    'Gravel': 'gravelValue',
    'Additional days': 'additionalDaysValue',
  };
  
  const fetchExcavationSum = async () => {
    try {
      const response = await axios.get('http://localhost:8080/form/form', {

      });
  
      if (response.data.data) {
        const excavationSum = response.data.data.Excavation;
        console.log('Sum of Excavation:', excavationSum);
        // Do something with the excavationSum, e.g., set it in state or display it.
      } else {
        console.error('No data found for the sum of Excavation.');
      }
    } catch (error) {
      console.error('Error fetching Excavation sum:', error);
    }
  };
  
  fetchExcavationSum();
  
  

  const Tracker:  React.FC<TrackerProps> = ({ backgroundColor }) => {
    const [projects, setProjects] = useState<Project[]>([]);
    const [loadingData, setLoadingData] = useState(false);
    const [selectedProject, setSelectedProject] = useState<Project | null>({
      _id: "", 
      userId: "", 
      name: "",
      projectName: ""
    });
    const [formData, setFormData] = useState<FormData | null>(initialFormData);
    const [inputValues, setInputValues] = useState<{ [projectId: string]: { [phaseName: string]: number | undefined } }>({});


    const loadSelectedProjectData = async (projectId: string) => {
      try {
        setLoadingData(true);
  
        // Fetch data for the selected project based on its ID
        const response = await axios.get(`http://localhost:8080/form/form/${projectId}`);
        const loadedData = response.data.data;
        
        // Set the loaded data into state
        setFormData(loadedData);
        setSelectedProject(loadedData);
  
        // Update the inputValues state
        setInputValues((prevInputValues) => ({
          ...prevInputValues,
          [projectId]: loadedData, // Assuming loadedData contains the values for the selected project
        }));
  

      } catch (error) {
        console.error('Error fetching project data:', error);
      } finally {
        setLoadingData(false);
      }
    };
  
    useEffect(() => {
      // Check if a project name exists in local storage
      const lastUsedProjectName = localStorage.getItem('lastUsedProject');
      if (lastUsedProjectName) {
        // Find the selected project in the list of projects
        const selectedProject = projects.find((project: any) => project.projectName === lastUsedProjectName);
        if (selectedProject) {
          // Load data for the selected project
          loadSelectedProjectData(selectedProject._id);
        }
      }
    }, [projects]);
  
    const fetchProjects = async () => {
      try {
        const response = await axios.get('http://localhost:8080/form/form');
        const projectList = response.data.data;
        setProjects(projectList);
      } catch (error) {
        console.error('Error fetching projects:', error);
      }
    };

    const inputValuesToDisplay = [
      'inputValue26',
      'inputValue36',
      'inputValue27',
      'inputValue28',
      'inputValue29',
      'inputValue57',
      'inputValue30',
      'inputValue31',
      'inputValue32',
      'inputValue43',
      'inputValue40',
      'inputValue41',
      'inputValue58',
      'inputValue34',
    ];

    const saveInputValues = async () => {
  try {
    const projectId = selectedProject?._id;
    if (!projectId) {
      console.error('No selected project to update.');
      return;
    }

    const updatedProjectData = {
      ...selectedProject, // Copy the existing project data
      ...inputValues[projectId], // Update with the new input values
    };

    const response = await axios.put(`http://localhost:8080/form/form/${projectId}`, updatedProjectData);
   
  } catch (error) {
    console.error('Error saving input values:', error);
  }
};

    
    
    
  
    useEffect(() => {
      fetchProjects();
    }, []);
  
    const handleProjectChange = async (e: React.ChangeEvent<HTMLSelectElement>) => {
      const projectId = e.target.value;
    
      try {
        const response = await axios.get(`http://localhost:8080/form/form/${projectId}`);
        const loadedData = response.data.data;
        setFormData(loadedData);
        setSelectedProject(loadedData);
    
        // Here, you should update the inputValues state with the loadedData
        setInputValues((prevInputValues) => ({
          ...prevInputValues,
          [projectId]: loadedData, // Assuming loadedData contains the values for the selected project
        }));
    
         
      } catch (error) {
        console.error('Error fetching project data:', error);
      }
    };

      const phaseNames = [
        'Excavation',
        'Sub base',
        'Edgings',
        'Paving',
        'Block paving',
        'Asphalt',
        'Walling',
        'Pointing',
        'Fencing',
        'Timber',
        'Decking',
        'Turfing',
        'Gravel',
        'Additional days'
      ];

      const phaseMappedNames = {
        inputValue26: "Excavation",
        inputValue36: "Sub base",
        inputValue27: "Edgings",
        inputValue28: "Paving",
        inputValue29: "Block paving",
        inputValue57: "Asphalt",
        inputValue30: "Walling",
        inputValue31: "Pointing",
        inputValue32: "Fencing",
        inputValue43: "Timber",
        inputValue40: "Decking",
        inputValue41: "Turfing",
        inputValue58: 'Gravel',
        inputValue34: "Additional days"
      
      };
    

      const valueKeysMap: Record<string, string> = {};
  inputValuesToDisplay.forEach(inputValue => {
    valueKeysMap[inputValue] = `${inputValue}`;
  }); 


  
    return (
      <MainContainer>
       <select
          value={selectedProject ? selectedProject._id : ""}
          onChange={handleProjectChange}
        >
          <option value="" disabled>
            Select a project
          </option>
          {projects.map((project) => (
            <option key={project._id} value={project._id}>
              {project.projectName}
            </option>
          ))}
        </select>

        <TableContainer>

        <LabourContainer>
  {formData && (
    <TableContainer>
      <ResultTable>
        <thead>
          <tr>
            <ResultTableHeader>Phase</ResultTableHeader>
            <ResultTableHeader>Completion</ResultTableHeader>
          </tr>
        </thead>
        <tbody>
          {inputValuesToDisplay.map((inputValue, index) => (
            <ResultTableRow key={index}>
              <ResultTableData>
                {phaseMappedNames[inputValue as keyof typeof phaseMappedNames]}
              </ResultTableData>
              <ResultTableData>
                {formData && formData[inputValue as keyof FormData]} Days
              </ResultTableData>
            </ResultTableRow>
          ))}
        </tbody>
      </ResultTable>
    </TableContainer>
  )}
</LabourContainer>

        <LabourContainer>
  <ResultTable>
    <thead>
      <tr>
        <ResultTableHeader>Phase</ResultTableHeader>
        <ResultTableHeader>Completion</ResultTableHeader>
      </tr>
    </thead>
    <tbody>
      {phaseNames.map((phaseName, index) => (
        <ResultTableRow key={phaseName}>
          <ResultTableData>{phaseName}</ResultTableData>
          <ResultTableData>
          <Input
  type="number"
  name={phaseToKey[phaseName] ? phaseToKey[phaseName].toString() : ''}
  disabled={!selectedProject}
  title={!selectedProject ? 'Create a new project to use' : undefined}
  value={
    inputValues[selectedProject?._id || '']
      ? inputValues[selectedProject?._id || ''][phaseToKey[phaseName]]?.toString() || '0'
      : '0'
  }
  onChange={(e) => {
    const newValue = e.target.value;

    if (selectedProject) {
      setInputValues((prevInputValues) => ({
        ...prevInputValues,
        [selectedProject._id]: {
          ...prevInputValues[selectedProject._id],
          [phaseToKey[phaseName]]: newValue === '' ? undefined : Number(newValue),
        },
      }));
    }

    if (formData) {
      setFormData((prevFormData) => ({
        ...(prevFormData as FormData),
        [phaseToKey[phaseName]]: newValue === '' ? undefined : Number(newValue),
      }));
    }
  }}
/>


            {' '}
            Days
          </ResultTableData>
        </ResultTableRow>
      ))}
    </tbody>
  </ResultTable>
</LabourContainer>



</TableContainer>


              <button onClick={saveInputValues}>Save</button>
    </MainContainer>
);
};
  
  export default Tracker;