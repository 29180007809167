/* eslint-disable @typescript-eslint/no-unused-vars */
import styled from 'styled-components';
import { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const HeroComponent = styled.header`
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(to right, #1b2b52, #2c51a8);
  padding: 0; // No vertical padding needed since the content is centered
  height: 100vh;
`;

const HeaderContainer = styled.div`
  background-color: transparent;
  border-radius: 1.5rem;
  border: 4px solid white;
  padding: 4%;
  color: white;
  width: 35%;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center; // Center the content inside the container
  align-items: center; // Center the content horizontally
  @media (orientation: portrait){
    width: 80%;
    height: 60%;
    margin-top: 5%;
  }
`;

const Heading = styled.h1`
  font-size: 200%;
  font-family: 'Ubuntu', sans-serif;
  a {
    text-decoration: none;  // Removes the underline
    color: inherit;         // Inherits the color from the parent element
    &:hover {
      text-decoration: none;  // Removes the underline on hover as well
    }
  }
  @media (max-height: 360px) {
    font-size: 150%;
  }
  @media (orientation: portrait){
    font-size: 150%;
  }
`;

const SubHeading = styled.h4`
  font-weight: 80;
  margin: 0 auto;
  justify-content: center; // Center the content inside the container
  align-items: center;
  text-align: center; 
  @media (max-width: 1000px) {
    font-size: 100%;
    margin-bottom: 2vw;
  }
  @media (orientation: portrait){
    font-size: 100%;
  }
  `

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center; // Center form items horizontally
  width: 100%; // Take the full width of the container
`;



const FormField = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center; // Align the form field centrally
  width: 100%;
  margin-top: 20%; // Adjust top margin as needed

   @media (max-width: 1000px) {
    margin-top:0;
   }
`;

const Label = styled.label`
  margin-bottom: 0.5rem;
  color: white;
  margin-right: 40%;
  text-align: center; // Ensure the label is centered
  width: 100%; // Make label full width to center text
  @media (orientation: portrait){
    font-size: 100%;
  }

`;

// Modify the StyledInput width to be consistent
const StyledInput = styled.input`
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 60%; // Match the width of the SubmitButton
  @media (max-height: 360px) {
    margin-bottom: 0;
    height: 40%;
  }
`;

const SubmitButton = styled.button`
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: white;
  color: rgba(7,55,99);
  margin-top: 5%;
  cursor: pointer;
  width: 20%; // Match the input width

  &:hover {
    background-color: rgba(239,239,239);
  }

  @media (max-width: 1000px) {
    width: 30%;
    margin-top: 9%;
  }
`;


const EmailPassword = () => {
    const [email, setEmail] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();


     const handleSubmit = async (e:any) => {
        e.preventDefault();
        setErrorMessage(''); // Clear any previous error messages

        try {
           
            // your backend service checks the email and sends the reset link
            const response = await axios.post('https://projectprice-ad06ee250897.herokuapp.com/resetpassword', { email });

            // If the email is sent successfully, you may want to inform the user or redirect them
            console.log('Reset link sent to email:', response.data);
            // You can set a success message or redirect the user to a confirmation page
            navigate('/loginpage');
        } catch (error: unknown) {
            const e = error as { response: { data: { message: string } }; message: string };
            if (axios.isAxiosError(error) && e.response) {
                // Backend responded with an error status code
                console.error('Error response:', e.response);
                setErrorMessage(e.response.data.message || 'An error occurred. Please try again.');
            } else {
                // Something else happened while setting up the request
                console.error('Setup error:', e.message);
                setErrorMessage('An error occurred. Please try again.');
            }
        }
    }        
      return (
        <HeroComponent>
            <HeaderContainer>
                <Heading><a href="/">Project Price</a></Heading>
                <SubHeading>Enter your email to send a password reset link</SubHeading> 
                <StyledForm onSubmit={handleSubmit}>
                    <FormField>
                        <Label htmlFor="email">Email</Label>
                        <StyledInput 
                            id="email"
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </FormField>
                    <SubmitButton type="submit" onClick={handleSubmit}>Send</SubmitButton>
                </StyledForm>
            </HeaderContainer>
        </HeroComponent>
      );
    }
export default EmailPassword;