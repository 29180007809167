import React from 'react';
import styled from 'styled-components';
import NavBar from '../components/PublicNav/homenav';
import Footer from '../components/Footer';


const BackgroundContainer = styled.div`
  background: #f8f8f8;
`;

const BlogContainer = styled.div`
  margin: 0 auto;
  padding: 20px;
`;

const IntroParagraph = styled.p`
  font-size: 1.6rem;
  font-weight: bold;
  font-style: italic;
  font-family: 'Ubuntu';
  line-height: 1.6;
  max-width: 100%;
  padding-top: 10vh;
  margin-left: auto;
  margin-right: auto;
  color: #333;
  margin-bottom: 30px; // Space between the paragraph and the first image
  text-align: center;
`;

const VideoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  padding-bottom: 10vh;
`;

const StyledIframe = styled.iframe`
  width: 60vw;      // Responsive width
  height: 30vw; // Responsive height, maintaining a 16:9 aspect ratio
  max-width: 80%; // Maximum size
  max-height: 80%;
  border: none;
`;

const Demo: React.FC = () => {

  return (
    <BackgroundContainer>
      <NavBar />
      <BlogContainer>
        <IntroParagraph>
          Check out our demo to learn how quick and easy Project Price is to use.
        </IntroParagraph>
        <VideoContainer>
        <StyledIframe 
    src="https://www.youtube.com/embed/i1IEELWX3yo?si=HuregWESpEZh1Oh-" 
    title="Project Price Demo Video"
    allowFullScreen
/>

        </VideoContainer>
      </BlogContainer>
      <Footer />
    </BackgroundContainer>
  );
};

export default Demo;
