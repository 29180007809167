import React from 'react';
import styled from 'styled-components';
import NavBar from '../components/PublicNav/homenav';
import Footer from '../components/Footer';


const BackgroundContainer = styled.div`
  background: #f8f8f8;
`;

const BlogContainer = styled.div`
  margin: 0 auto;
  padding: 20px;
  padding-top: 10vh;
  color: rgba(7,55,99);
`;

const BlogTitle = styled.h1`
  text-align: center;
  margin-bottom: 30px;
`;

const IntroParagraph = styled.p`
  font-size: 1.2rem;
  line-height: 1.6;
  max-width: 70%;
  padding-top: 10vh;
  margin-left: auto;
  margin-right: auto;
  color: #333;
  margin-bottom: 30px; // Space between the paragraph and the first image
  text-align: left;
  @media (orientation: portrait){
    max-width: 100%
  }
`;

const BlogManagement: React.FC = () => {

  return (
    <BackgroundContainer>
      <NavBar />
      <BlogContainer>
      <BlogTitle>Project management for the landscaping industry</BlogTitle>
        <IntroParagraph>
          Efficient project management is the backbone of a successful landscaping business. Managing a landscaping project effectively from the initial client consultation to project completion involves a blend of good communication, planning, and the right use of tools and techniques. This article will delve into key strategies that can streamline your landscaping projects, ensuring they are completed on time, within budget, and to the satisfaction of your clients.
          <br /><br />
          <strong>Understanding Client Needs: The Foundation of Project Management</strong>
          <ul>
            <li>Initial Consultation: The first step in efficient project management is a thorough initial consultation. Understand the client's vision, budget, and timeline.</li>
            <li>Setting Realistic Expectations: Clearly communicate what is feasible within the given budget and timeline.</li>
          </ul>
          <br />
          <strong>Planning</strong>
          <ul>
            <li>Detailed Project Plans: Create detailed project plans including timelines, material lists, and labor requirements.</li>
            <li>Resource Allocation: Allocate resources effectively, ensuring the right amount of materials and workforce is available when needed, use the <a href='/dashboard'>Project Price app</a> to ensure accuracy on materials and timings.</li>
          </ul>
          <br />
          <strong>Effective Communication</strong>
          <ul>
            <li>Regular Updates: Keep the client informed with regular updates.</li>
            <li>Team Meetings: Hold regular team meetings to ensure all members are aware of their responsibilities.</li>
          </ul>
          <br />
          <strong>Handling Unforeseen Challenges</strong>
          <ul>
            <li>Contingency Planning: Always have a contingency plan for unexpected challenges.</li>
            <li>Adaptability: Be prepared to make swift decisions and adjustments to the project plan.</li>
          </ul>
          <br />
          <strong>Quality Control and Project Completion</strong>
          <ul>
            <li>Regular Monitoring: Regularly monitor the progress and quality of work.</li>
            <li>Client Walkthroughs: Before finalizing the project, conduct a client walkthrough.</li>
          </ul>
          <br />
          <strong>Post-Completion Review</strong>
          <ul>
            <li>Feedback Sessions: Conduct a post-completion review with your team and, if possible, with the client.</li>
            <li>Documentation: Document lessons learned and successful strategies for future reference.</li>
          </ul>
          <br />
          In conclusion, efficient project management in landscaping is not just about keeping projects on track; it's about creating a harmonious flow of work that ensures client satisfaction, team effectiveness, and business profitability.
        </IntroParagraph>
      </BlogContainer>
      <Footer />
    </BackgroundContainer>
  );
};

export default BlogManagement;
