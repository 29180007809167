import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import LandingPage from "./pages/LandingPage";
import Dashboard from "./pages/Dashboard";
import Guide from "./pages/Guide";
import Quoter from "./pages/Quoter";
import { ProtectedRoute } from "./routes/ProtectedRoute";
import SubPage from "./pages/SubPage";
import ResetPassword from "./pages/ResetPassword";
import { ProjectsProvider } from "./ProjectContext";
import LoginPage from "./components/LoginPage/LoginPage";
import Aboutus from "./pages/AboutUs";
import Terms from "./pages/TaC";
import Privacy from "./pages/Privacy";
import Disclaimer from "./pages/Disclaimer";
import EmailPassword from "./pages/EmailPassword";
import Overlay from "./Overlay/Overlay";
import BlogQuotes from "./pages/BlogQuotes";
import BlogManagement from "./pages/BlogManagement";
import Demo from "./pages/Demo";
import BlogLeads from "./pages/BlogLeads";
import AreaCalculator from "./pages/AreaCalculator";
import EmailValidation from "./pages/EmailValidation";
import EmailValidationPage from "./pages/EmailValidationPage";
import AllBlogPosts from "./pages/AllBlogPosts";
import ContactPage from "./pages/ContactUs";
import BlogDetails from "./pages/BlogDetails";
import BlogSM from "./pages/BlogSM";
import FullDemo from "./pages/FullDemo";
import AreaCalculatorDemo from "./pages/AreaCalculatorDemo";
import QuoteDemo from "./pages/Quotedemo";
import PricingDemo from "./pages/PricingDemo";
import BlogLights from "./pages/BlogLights";
import BlogDesign from "./pages/BlogDesign";
import DesignDemo from "./pages/DesignDemo";
import SketchUpPage from "./pages/SketupPage";
import Project from "./pages/Project";
import EbookPage from "./pages/eBook";
import Features from "./pages/Features";
import Pricing from "./pages/Pricing";
import Unsubscribe from "./pages/Unsubscribe";

function App() {

  const OverlayWrapper = () => {
    const location = useLocation();

    const noOverlayRoutes = [
      "/", "/demo", "/guide", "/quoter", "/aboutus",
      "/terms", "/privacy", "/blogquotes", "/blogmanagement", 
      "/emailpassword", "/resetpassword", "/loginpage", "/sub-page",
      "/blogleads", "/areacalculator", "/emailvalidation", "/emailvalidationpage",
      "/allblogposts", "/contactus", "/blogdetails", "/blogsm", "/fulldemo",
      "/areacalculatordemo", "/quotedemo", "/pricingdemo", "/bloglights",
      "/blogdesign", "/designdemo", "/SketchUpPage", "/project", "/ebook", "/features",
      "/pricing", "/unsubscribe"
    ];
    if (noOverlayRoutes.includes(location.pathname.toLowerCase())) {
      return null; // Don't render Overlay
    }

    return <Overlay />;
  };

  return (
    <BrowserRouter>
      <ProjectsProvider>
        <OverlayWrapper />
      
        <Routes>
          <Route path="/sub-page" element={<SubPage />} />
          <Route path="/loginpage" element={<LoginPage />} />
          <Route path="/Guide" element={<Guide />} />
          <Route path="/Quoter" element={<Quoter />} />
          <Route path="/areacalculator" element={<AreaCalculator />} />
          <Route path="/aboutus" element={<Aboutus />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/blogquotes" element={<BlogQuotes />} />
          <Route path="/bloglights" element={<BlogLights />} />
          <Route path="/blogleads" element={<BlogLeads />} />
          <Route path="/blogsm" element={<BlogSM />} />
          <Route path="/features" element={<Features />} />
          <Route path="/blogdetails" element={<BlogDetails />} />
          <Route path="/blogdesign" element={<BlogDesign />} />
          <Route path="/allblogposts" element={<AllBlogPosts />} />
          <Route path="/blogmanagement" element={<BlogManagement />} />
          <Route path="/contactus" element={<ContactPage />} />
          <Route path="/disclaimer" element={<Disclaimer />} />
          <Route path="/demo" element={<Demo />} />
          <Route path="/quotedemo" element={<QuoteDemo />} />
          <Route path="/fulldemo" element={<FullDemo />} />
          <Route path="/designdemo" element={<DesignDemo />} />
          <Route path="/pricingdemo" element={<PricingDemo />} />
          <Route path="/sketchuppage" element={<SketchUpPage/>} />
          <Route path="/unsubscribe" element={<Unsubscribe />} />
          <Route path="/areacalculatordemo" element={<AreaCalculatorDemo />} />
          <Route path="/emailvalidation" element={<EmailValidation />} />
          <Route path="/emailvalidationpage" element={<EmailValidationPage />} />
          <Route path="/project" element={<Project/>} />
          <Route path="/ebook" element={<EbookPage/>} />
          <Route path="/pricing" element={<Pricing/>} />
          <Route path="/resetpassword" element={<ResetPassword />} />
          <Route path="/emailpassword" element={<EmailPassword />} />
          <Route path="/dashboard" element={<ProtectedRoute/>}>
            <Route index element={<Dashboard />} />
          </Route>
          <Route path="/" element={<LandingPage />} />
        </Routes>
      </ProjectsProvider>
    </BrowserRouter>
  );
}

export default App;
